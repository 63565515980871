import { faBuilding } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLang } from "../../../language"
import { useNavigate } from "react-router"
import { DropdownAdvanced } from "../../DropdownAdvanced/DropdownAdvanced"
import { isAccesoRestringido } from "../../../helpers/helpers"
import { ModalContacto } from "../../../pages/Contacto/ModalContacto"
import { useModalData } from "../../../hooks/useModalData"

export const DropdownUser = ({ auth, dispatch, logOut }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const isRestringido = isAccesoRestringido(auth?.user || {})

    const [dataModal_Contacto, handleShowModal_Contacto, handleCloseModal_Contacto] = useModalData()

    let objectList: any = {}

    if (isRestringido) {
        objectList = {
            title: auth?.user?.user?.nombre || Lang('USUARIO_INVITADO'),
            list: [
                {
                    content: <>{Lang('CONTACTAR')}</>,
                    action: () => handleShowModal_Contacto()
                },
                {
                    content: <>{Lang('SALIR')}</>,
                    action: () => dispatch(logOut())
                },
            ]
        }

    } else {

        objectList = {
            title: auth?.user?.user?.nombre || 'Usuario',
            list: [
                {
                    content: <><FontAwesomeIcon icon={faBuilding} className='me-2' /> {Lang('MIS_EDIFICIOS')}</>,
                    action: () => navigate('/edificios'),
                    divider: true,
                },
                {
                    content: <>{Lang('MIS_DATOS')}</>,
                    action: () => navigate('/datos'),
                },
                {
                    content: <>{Lang('CONTACTAR')}</>,
                    action: () => handleShowModal_Contacto()
                },
                {
                    content: <>{Lang('SALIR')}</>,
                    action: () => dispatch(logOut())
                },
            ]
        }
    }

    return (
        <>
            <DropdownAdvanced
                objectList={objectList}
                lastDivider
            />
            <ModalContacto
                data={dataModal_Contacto}
                handleClose={handleCloseModal_Contacto}
            />
        </>
    )
}