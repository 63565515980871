import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileZipper, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useModalData } from "../../../../../../../hooks/useModalData";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useLang } from "../../../../../../../language";
import { ModalMantCorrectivo } from "./ModalMantCorrectivo";
import { CalificacionesDeficienciasStaticServices } from "../../../../../../../controllers/statics/statics.services";
import { ModalCargarITE } from "../DeficienciasFicheroITE/ModalCargarITE/ModalCargarITE";
import { useNavigate } from "react-router";

export const TableMantCorrectivo = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, idedificio, fetchSuccess, fetchParamsAgentesGETData, setactiveImportITE,
    fetchParamsDocumentosGETData, id, isImportado, setisImportado }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const [dataModal_ITE, handleShowModal_ITE, handleCloseModal_ITE] = useModalData()

    const allActions = [
        {
            label: <span><FontAwesomeIcon icon={faFileZipper} className='me-2' />{Lang('IMPORTAR_ITE')}</span>,
            variant: 'outlined',
            color: 'success',
            action: () => isImportado ? navigate('./importar-ite') : handleShowModal_ITE({
                action: 'CREATE',
            }),
        },
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_ACTUACION')}</span>,
            variant: 'contained',
            action: () => handleShowUsuario({
                action: 'CREATE',
            }),
        }
    ]

    const allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('EDITAR')
            }
        }
    ]
    const calificacionesDeficienciasStaticServices = CalificacionesDeficienciasStaticServices.GET()

    const columnsTable = {
        actuacio: {
            title: Lang('ACTUACION'),
        },
        // descripcio_curta: {
        //     title: Lang('DESCRIPCION'),
        // },
        qualificacio: {
            title: Lang('CALIFICACION'),
            adapter: calificacionesDeficienciasStaticServices
        },
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('ACTUACIONES_DE_REHABILITACION')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalMantCorrectivo
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchSuccess={fetchSuccess}
                fetchParamsDocumentosGETData={fetchParamsDocumentosGETData}
                id={id}
            />

            <ModalCargarITE
                data={dataModal_ITE}
                handleClose={handleCloseModal_ITE}
                idedificio={idedificio}
                fetchSuccess={() => {
                    setisImportado(true)
                    setactiveImportITE(true)
                }}
            />
        </>
    )
}
