import { useAxios } from "../../hooks/useAxios"
import { EdificioAdapters, EdificiosAdapters } from "./edificios.adapters"

const EdificiosServices = {

    GET_MIS_EDIFICIOS: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio',
            type: 'json',
            adapter: {
                method: EdificiosAdapters.GET_MIS_EDIFICIOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_MIS_EDIFICIOS_PROPIETARIO: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio',
            type: 'json',
            adapter: {
                method: EdificiosAdapters.GET_MIS_EDIFICIOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_ALL_EDIFICIOS: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio/admin/all',
            type: 'json',
            adapter: {
                method: EdificiosAdapters.GET_ALL_EDIFICIOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const EdificioServices = {

    GET: function (id: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio/${id}`,
            type: 'json',
            adapter: {
                method: EdificioAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio',
            type: 'json',
            adapter: {
                method: EdificioAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_VERIFICAR_CATASTRO: function () {

        return useAxios({
            method: 'post',
            url: '/edificio/verificar_catastro',
            type: 'json',
            adapter: {
                method: EdificioAdapters.POST_VERIFICAR_CATASTRO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    GET_MIS_ROLES: function (idEdificio: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio/${idEdificio}/roles`,
            type: 'json',
            adapter: {
                method: EdificioAdapters.GET_MIS_ROLES,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    POST_SINCRONIZAR: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio/sincronizar_catastro',
            type: 'json',
            adapter: {
                method: EdificioAdapters.POST_SINCRONIZAR,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: `/edificio`,
            type: 'json',
            adapter: {
                method: EdificioAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_BAJA_SUSCRIPCION: function (idEdificio: string) {

        return useAxios({
            method: 'PATCH',
            url: `edificio/${idEdificio}/baja-suscripcion`,
            type: 'json',
            adapter: {
                method: EdificioAdapters.PATCH_BAJA_SUSCRIPCION,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_ESTADO: function () {

        return useAxios({
            method: 'POST',
            url: `edificio`,
            type: 'json',
            adapter: {
                method: EdificioAdapters.PATCH_ESTADO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

}

export {
    EdificiosServices,
    EdificioServices
}