import { useEffect } from "react"
import { useLang } from '../../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../../helpers/validations'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { getDateInput, getValuesParams, isStatusSuccess } from '../../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DocumentoServices } from './controllers/documentacion.services'
import { InputFile } from '../../../../../../../../components/Inputs/InputFile'
import { InputChecks } from "../../../../../../../../components/Inputs/InputChecks"

export const ModalDocumento = ({ data, handleClose, fetchParamsAgentesGETData, idEdificio, fetchDocumentacionGET,
    formValuesSearch, idActividad, idCurrentProject }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchDocumentoGET, fetchDocumentoGETData, resetfetchDocumentoGETData] = DocumentoServices.GET()

    const [fetchDocumentoPOST, fetchDocumentoPOSTData, resetfetchDocumentoPOSTData] = DocumentoServices.POST()

    const [fetchDocumentoPATCH, fetchDocumentoPATCHData, resetfetchDocumentoPATCHData] = DocumentoServices.PATCH()

    const [fetchDocumentoDELETE, fetchDocumentoDELETEData, resetfetchDocumentoDELETEData] = DocumentoServices.DELETE()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        file: '',
        afegir_al_libre: [],
        data_validesa: '',
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        titol: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchDocumentoGETData.status)) {

            handleFormDocumento({
                ...fetchDocumentoGETData.data
            })
        }

    }, [fetchDocumentoGETData]);


    // ------------ POST Documento

    const handleSubmitDocumentoPOST = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchDocumentoPOST({
                id: formDocumento.tipo,
                body: {
                    ...formDocumento,
                    projecte_id: idCurrentProject,
                    projecte_seguiment_id: data?.idAnotacion,
                    projecte_intervencion_id: data?.idActividad,
                    data_adjunt: getDateInput(),
                    tipus: 'document',
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchDocumentoPOSTData.status)) {
            fetchDocumentacionGET({
                params: {
                    ...getValuesParams(formValuesSearch)
                }
            })
            handleClose()
        }

    }, [fetchDocumentoPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchDocumentoPATCH({
                id: data?.id + '/actualizar',
                body: {
                    ...formDocumento,
                    // edifici_id: idEdificio,
                    // file: formDocumento.ruta,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchDocumentoPATCHData.status)) {
            fetchDocumentacionGET({
                params: {
                    ...getValuesParams(formValuesSearch)
                }
            })
            handleClose()
        }

    }, [fetchDocumentoPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchDocumentoDELETE({
            id: data?.id + '/eliminar',
            body: {
                ...formDocumento,
                edifici_id: idEdificio
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchDocumentoDELETEData.status)) {
            fetchDocumentacionGET({
                params: {
                    ...getValuesParams(formValuesSearch)
                }
            })
            handleClose()
        }

    }, [fetchDocumentoDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {

                fetchDocumentoGET({
                    id: data?.id + '/mostrar'
                })
            }

        } else {
            resetFormDocumento()
            resetvalidDocumento()
            resetfetchDocumentoGETData()
            resetfetchDocumentoPOSTData()
            resetfetchDocumentoPATCHData()
            resetfetchDocumentoDELETEData()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='lg'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVO_DOCUMENTO') : Lang('EDITAR_DOCUMENTO')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchDocumentoGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12} md={6} lg={8}>
                                        <InputText
                                            label={Lang('TITULO') + ': *'}
                                            name='titol'
                                            value={formDocumento.titol}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchDocumentoPOSTData : fetchDocumentoPATCHData}
                                            localValidation={validDocumento.titol}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={8} lg={8}>

                                        <div className="pt-4">
                                            <InputFile
                                                label={Lang('DOCUMENTO') + ': *'}
                                                path={formDocumento.url}
                                                name='file'
                                                onChange={handleInputDocumento}
                                                readOnly={data?.action === 'EDIT'}
                                            />
                                        </div>

                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className=" ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchDocumentoPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className=""
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchDocumentoDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className=" ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchDocumentoPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchDocumentoPOSTData} />

            <ModalStatusServer fetchData={fetchDocumentoPATCHData} />
        </>
    )
}