import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import './index.scss'

export const NavLeftSecondary = ({ navLinks, setactiveNav }: any) => {

    useEffect(() => {

        const linkActive = window.location.href?.split('/agentes/')?.[1]?.split('/')?.[0] || ''

        setactiveNav(navLinks?.find((item: any) => item.link === linkActive) || {})

    }, []);


    return (
        <>
            <nav className='NavigationSecondary d-flex flex-column'>

                <ul className='list-unstyled p-0 m-0'>
                    {
                        navLinks?.map((item: any) => {
                            return (
                                <NavLink
                                    to={`./${item.link}`}
                                    className={({ isActive }) => isActive ? `NavigationSecondary__li--active ${item.className}` : `${item.className}`}
                                    onClick={() => setactiveNav(item)}
                                    key={item.link}
                                >
                                    {item.title}
                                </NavLink>
                            )
                        })
                    }
                </ul>

            </nav>
        </>
    );
};
