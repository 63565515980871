import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'

export const InputRadio = ({ label, readOnly, value, values, onChange, name, className='', classNameGroup='' }: any) => {
    return (
        <FormControl fullWidth variant="standard" className={className}>
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className="my-0 pb-1" component="span">
                            {values?.find((item: any) => item.id === value)?.name || '-'}
                        </Typography>
                    </> :
                    <>
                        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
                        <RadioGroup value={value} name={name} row onChange={onChange} className={classNameGroup}>
                            {
                                values?.map((item: any, index: number) => {
                                    return (
                                        <FormControlLabel 
                                            key={index} 
                                            value={item.id} 
                                            control={<Radio />} 
                                            label={item.name}
                                            disabled={item.disabled}
                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </>
            }
        </FormControl>
    )
}
