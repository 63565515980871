import { useAxios } from "../../../../../../../hooks/useAxios"
import { PotencialMejoraAdapters, PotencialMejorasAdapters } from "./potencialmejora.adapters"

const PotencialMejorasServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_documentos',
            type: 'json',
            adapter: {
                method: PotencialMejorasAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_PARAM_ARBOL: function () {

        return useAxios({
            method: 'GET',
            url: '/potencial_mejora/obtener_arbol',
            type: 'json',
            adapter: {
                method: PotencialMejorasAdapters.GET_PARAM_ARBOL,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'defaultArray', // object, array, list (with pagination)
                }
            },
        })
    },
}

const PotencialMejoraServices = {

    GET: function () {

        return useAxios({
            method: 'get',
            url: `/edificio_documentos`,
            type: 'json',
            adapter: {
                method: PotencialMejoraAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'post',
            url: '/edificio_documentos',
            type: 'json',
            adapter: {
                method: PotencialMejoraAdapters.POST,
                types: {
                    send: 'multipart',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'post',
            url: '/edificio_documentos',
            type: 'json',
            adapter: {
                method: PotencialMejoraAdapters.PATCH,
                types: {
                    send: 'multipart',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_documentos',
            type: 'json',
            adapter: {
                method: PotencialMejoraAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    PotencialMejorasServices,
    PotencialMejoraServices
}