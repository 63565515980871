
import { useLang } from '../../language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faQuestionCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { ButtonAdvanced } from '../../components/ButtonAdvanced/ButtonAdvanced';
import { useState } from 'react';

import { Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import { DropdownLanguage } from '../../components/Layouts/DropdownLanguage';
import { Footer } from '../../components/Layouts/Footer/Footer';
import { useNavigate } from 'react-router';
import { useModalData } from '../../hooks/useModalData';
import logo from '../../assets/images/logo.png'

import './Login.scss'
import { ModalRegistroUsuario } from './ModalRegistroUsuario/ModalRegistroUsuario';
import { AccesoGeneral } from './TabsLogin/AccesoGeneral/AccesoGeneral';
import { AccesoEdificio } from './TabsLogin/AccesoEdificio/AccesoEdificio';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ModalContacto } from '../Contacto/ModalContacto';
import { Faqs } from '../Faqs';

const Login = () => {

  const Lang = useLang()

  const navigate = useNavigate()

  // const [fetchRelacionPOST, fetchRelacionPOSTData] = ActProfesionalServices.POST()
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

  const { language } = useSelector((state: RootState) => state.language)

  const [dataModal_Faqs, handleShowModal_Faqs, handleCloseModal_Faqs] = useModalData()

  const [dataModal_Contacto, handleShowModal_Contacto, handleCloseModal_Contacto] = useModalData()

  return (
    <section className='PageLogin'>

      <nav className="PageIndex__NavTop d-flex align-items-center">

        <div className='px-3 d-flex justify-content-between w-100 align-items-center'>

          <img src={logo} alt="logo" className='cursor-pointer d-block d-lg-none' onClick={() => navigate('/')} />
          <img src={`${process.env.PUBLIC_URL}/images/Logo_LED_${language}.png`} alt="logo" className='cursor-pointer d-none d-lg-block' onClick={() => navigate('/')} />

          <div className='d-flex align-items-center'>

            <a href="/" className='me-4 d-none d-lg-block' onClick={(e) => {
              e.preventDefault();
              handleShowModal_Faqs()
            }}>
              <FontAwesomeIcon icon={faQuestionCircle} className='me-2' />
              <span className='text-capitalize'>FAQs</span>
            </a>

            <a href="/" className='me-2' onClick={(e) => {
              e.preventDefault();
              handleShowModal_Contacto()
            }}>{Lang('CONTACTAR')}</a>

            <ModalContacto
              data={dataModal_Contacto}
              handleClose={handleCloseModal_Contacto}
            />

            <DropdownLanguage />

          </div>

        </div>

      </nav>

      <section className='PageLogin__Cards'>

        <Typography variant='h4' className='my-5 fw-normal text-center z-index-10 position-relative' color='white'>
          <span className='ps-3 fw-bold'>{Lang('AREA_DE_ACCESO')}</span>
        </Typography>

        <Container maxWidth="lg" className='my-auto'>

          <Grid container justifyContent='center' spacing={7}>

            <Grid item md={5} className='d-flex flex-column'>

              <div className="PageLogin__CardsCard animate__animated animate__fadeInUpSmall shadow-sm flex-1">

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-2">
                  <Tabs value={1} onChange={handleChange} aria-label="basic tabs example" className='justify-content-center'>
                    <Tab className='flex-1 mw-100'
                      label={
                        <div className='d-flex align-items-center' style={{ color: '#333' }}>
                          <FontAwesomeIcon icon={faUsers} className='me-2' />
                          <Typography variant='subtitle1' className='my-0 fw-bold'>
                            {Lang('ACCESO_GENERAL')}
                          </Typography>
                        </div>
                      } id='1' />
                  </Tabs>
                </Box>
                <TabPanel value={0} index={0}>

                  <AccesoGeneral />

                </TabPanel>

              </div>

            </Grid>

            <Grid item md={5} className='d-flex flex-column'>

              <div className="PageLogin__CardsCard animate__animated animate__fadeInUpSmall shadow-sm  flex-1">

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-2">
                  <Tabs value={1} onChange={handleChange} aria-label="basic tabs example2" className='justify-content-center'>
                    <Tab className='flex-1 mw-100'
                      label={
                        <div className='d-flex align-items-center' style={{ color: '#333' }}>
                          <FontAwesomeIcon icon={faBuilding} className='me-2' />
                          <Typography variant='subtitle1' className='my-0 fw-bold'>
                            {Lang('ACCESO_EDIFICIO')}
                          </Typography>
                        </div>
                      } id='3' />
                  </Tabs>
                </Box>

                <TabPanel value={1} index={1}>

                  <AccesoEdificio />

                </TabPanel>

              </div>

            </Grid>

          </Grid>

          <Grid container justifyContent='center' spacing={4} className='mt-0'>

            <Grid item className='z-index-10 position-relative text-center mb-5 mb-lg-4'>
              <Typography variant='body1'>
                {Lang('PARRAFO_REGISTRATE')}
              </Typography>
              <ButtonAdvanced
                variant="outlined" type="submit" className="mt-3"
                handleSubmit={handleShowUsuario}
                value={Lang('REGISTRATE')}
              />
            </Grid>

          </Grid>

        </Container>

        <section className="PageLogin__CardsOverlay"></section>

      </section>

      <Footer showLoginAdmin />

      <ModalRegistroUsuario
        data={showUsuario}
        handleClose={handleCloseUsuario}
      />

      <Faqs
        data={dataModal_Faqs}
        handleClose={handleCloseModal_Faqs}
      />

    </section>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='px-4 pb-3'>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default Login;
