export const CondParSeccion7 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Se podrá desistir de la compra durante 14 días naturales desde que tengas acceso al módulo seleccionado. Pero al tratarse de contenido digital, no se podrá ejercer el derecho de desistimiento, una vez iniciado el uso de la aplicación.
      </p>
      <p>
        El desistimiento de la compra comportará la devolución del importe abonado, sin retenciones en concepto de gastos de devolución.
      </p>
      <p>
        Para ejercer el derecho de desistimiento se tendrá que emitir un correo electrónico <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>, con el asunto “DESISTIMIENTO”, manifestando de forma expresa que desea desistir del contrato.
      </p>
      <p>
        Una vez ejercido su derecho de devolución, CATEB le reembolsará el precio abonado y, en su caso, los gastos de envío que haya abonado, salvo que haya elegido expresamente una modalidad de entrega diferente a la menos costosa ofrecida por nosotros. El importe le será reembolsado en un plazo máximo de 14 días naturales desde que hayamos recibido el producto utilizando el mismo medio que se utilizó para el pago. Si tras la recepción del producto detectamos una disminución del valor como resultado de una manipulación distinta a la necesaria para verificar sus características y funcionalidades, dicha disminución no se le reembolsará.
      </p>
      <p>
        Los Clientes que no reúnan la condición de consumidor y usuario, no tendrán derecho a desistir sobre ningún producto o servicio ofrecido en nuestra web.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Es podrà desistir de la compra durant 14 dies naturals des que tinguis accés al mòdul seleccionat. Però en tractar-se de contingut digital, no es podrà exercir el dret de desistiment, una vegada iniciat l' ús de l' aplicació.
      </p>
      <p>
        El desistiment de la compra comportarà la devolució de l' import abonat, sense retencions en concepte de despeses de devolució.
      </p>
      <p>
        Per exercir el dret de desistiment s'haurà d'emetre un correu electrònic <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>, amb l'assumpte "DESISTIMENT", manifestant de forma expressa que desitja desistir del contracte.
      </p>
      <p>
        Una vegada exercit el seu dret de devolució, CATEB li reemborsarà el preu abonat i, si s' escau, les despeses d' enviament que hagi abonat, llevat que hagi triat expressament una modalitat de lliurament diferent a la menys costosa oferta per nosaltres. L' import li serà reemborsat en un termini màxim de 14 dies naturals des que hàgim rebut el producte utilitzant el mateix mitjà que es va utilitzar per al pagament. Si després de la recepció del producte detectem una disminució del valor com a resultat d' una manipulació diferent a la necessària per verificar les seves característiques i funcionalitats, aquesta disminució no se li reemborsarà.
      </p>
      <p>
        Els Clients que no reuneixin la condició de consumidor i usuari, no tindran dret a desistir sobre cap producte o servei ofert a la nostra web.
      </p>
    </div>
  )
}