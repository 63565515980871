import { parseAny, parseArray, parseBoolean, parseCheck, parseNumber, parseObject, parseString, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../controllers"

const AuthAdapters = {

    POST_LOGIN_GENERAL: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nif: parseString(item.nif),
                    password: parseString(item.password),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    token: parseString(item.token),
                    message: parseString(item.message),
                    status: parseString(item.status),
                    datos_profesionales: parseBoolean(item.datos_profesionales),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    // POST_LOGIN_CATEB: {

    //     send: {

    //         schema: function (item: any) {

    //             if (!item) item = {}

    //             const data = {
    //                 usercode: parseString(item.usercode),
    //                 password: parseString(item.password),
    //             }

    //             return renderSchema(data)
    //         },

    //         adapter: function (data: any, type: string) {

    //             return renderAdapter(data, type, this.schema)
    //         }
    //     },

    //     receive: {

    //         schema: function (item: any) {

    //             if (!item) item = {}

    //             const data = {
    //                 data: parseObject(item.data),
    //                 token: parseString(item.token),
    //                 message: parseString(item.message),
    //                 status: parseString(item.status),
    //             }

    //             return renderSchema(data)
    //         },

    //         adapter: function (data: any, type: string) {

    //             return renderAdapter(data, type, this.schema)
    //         }
    //     },
    // },

    POST_LOGIN_EDIFICIO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nif: parseString(item.nif),
                    code: parseString(item.code),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    token: parseString(item.token),
                    message: parseString(item.message),
                    status: parseString(item.status),
                    edificio: parseObject(item.edificio),
                    roles: parseArray(item.roles),
                    activ: parseAny(item.activ),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_REGISTER: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    email: parseString(item.email),
                    nif: parseString(item.nif),
                    password: parseString(item.password),
                    password_confirmation: parseString(item.password_confirmation),
                    first_name: parseString(item.first_name),
                    last_name: parseString(item.last_name),
                    comunitat_autonoma: parseString(item.comunitat_autonoma),
                    professio: parseNumber(item.professio),
                    role_altres: parseString(item.role_altres, { removeNullable: true }),
                    colegiado_externo_num_colegiado: parseString(item.colegiado_externo_num_colegiado, { removeNullable: true }),
                    collegi_professional: parseString(item.collegi_professional),
                    politica_privacitat_acceptada_en: parseCheck(item.politica_privacitat_acceptada_en, 'politica_privacitat_acceptada_en'),
                    entitat_conveni_id: parseAny(item.entitat_conveni_id, { removeEmpty: true }),
                    tipo_usuario: parseString(item.tipo_usuario),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_RECUPERAR_CONTRASENIA: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nif: parseString(item.nif),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_COMPROBAR_NIF: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nif: parseString(item.nif),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_USUARIO_NUEVO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    professio: parseString(item.professio),
                    collegi_professional: parseString(item.collegi_professional),
                    colegiado_externo_num_colegiado: parseString(item.colegiado_externo_num_colegiado),
                    comunitat_autonoma: parseString(item.comunitat_autonoma),
                    politica_privacitat_acceptada_en: parseCheck(item.politica_privacitat_acceptada_en, 'politica_privacitat_acceptada_en'),
                    role_altres: parseString(item.role_altres),
                    tipo_usuario: parseString(item.tipo_usuario),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_ACEPTAR_RECHAZAR_EDIFICIO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_EDIFICIOS: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nif: parseString(item.nif),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseArray(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    AuthAdapters,
}