import { Placeholder } from "../Placeholder/Placeholder";

export const DropdownAdvancedLoader = () => {
    return (
        <>
            <div className="p-2">
                <Placeholder className='px-0 py-2 delay-1' height='1rem' />
                <Placeholder className='px-0 py-2 delay-2' height='1rem' />
                <Placeholder className='px-0 py-2 delay-3' height='1rem' />
            </div>
        </>

    )
};
