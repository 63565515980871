import { useAxios } from "../../../../../../../../../hooks/useAxios"
import { DocumentacionAdapters, DocumentoAdapters } from "./documentacion.adapters"

const DocumentacionServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/adjuntos/lista',
            type: 'json',
            adapter: {
                method: DocumentacionAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const DocumentoServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/adjuntos`,
            type: 'json',
            adapter: {
                method: DocumentoAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio_proyectos/adjuntos/upload',
            type: 'json',
            adapter: {
                method: DocumentoAdapters.POST,
                types: {
                    send: 'multipart',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/adjuntos',
            type: 'json',
            adapter: {
                method: DocumentoAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/adjuntos',
            type: 'json',
            adapter: {
                method: DocumentoAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    DocumentacionServices,
    DocumentoServices
}