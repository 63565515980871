import React, { useState } from 'react'
import { useEffect } from "react"
import { useLang } from '../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../hooks/useValidator'
import { validExiste, validNIFNIECIF } from '../../../../../../helpers/validations'
import { groupBy } from 'lodash'
import { InputText } from '../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { ListAutocomplete } from '../../../../../../components/ListAutocomplete/ListAutocomplete'
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import ModalAdvanced from '../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSync, faTrash } from '@fortawesome/free-solid-svg-icons'
import { GremioServices, GremiosServices } from './controllers/gremios.services'
import { GremioAdapters } from './controllers/gremios.adapters'
import { useModalData } from '../../../../../../hooks/useModalData'

export const ModalGremioProyecto = ({ data, handleClose, idCurrentProject, fetchSuccess, fetchSuccessActividad }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchGremioGET, fetchGremioGETData, resetfetchGremioGETData] = GremioServices.GET()

    const [fetchGremioPOST, fetchGremioPOSTData, resetfetchGremioPOSTData] = GremioServices.POST()

    const [fetchGremioPATCH, fetchGremioPATCHData, resetfetchGremioPATCHData] = GremioServices.PATCH()

    const [fetchGremioDELETE, fetchGremioDELETEData, resetfetchGremioDELETEData] = GremioServices.DELETE()

    const [formGremio, handleInputGremio, handleFormGremio, resetFormGremio] = useForm({
        ...GremioAdapters.GET.receive.schema({}),
        cp: '',
    })

    const [validDNIGremio, handleValidDNIGremio, resetvalidDNIGremio] = useValidator({
        nif: [
            { functionValid: validNIFNIECIF, msgError: Lang('VALID_CORRECT_DNI'), isOk: true },
        ],
    })

    const [validGremio, handleValidGremio, resetvalidGremio] = useValidator({
        nom: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        tipus_gremi: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        direccio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        cp: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        poblacio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        provincia: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET

    useEffect(() => {

        if (isStatusSuccess(fetchGremioGETData.status)) {

            handleFormGremio({
                ...fetchGremioGETData.data
            })
        }

    }, [fetchGremioGETData]);


    // ------------ POST

    const handleSubmitGremioPOST = () => {

        if (handleValidDNIGremio(formGremio) && handleValidGremio(formGremio)) {

            fetchGremioPOST({
                body: {
                    ...formGremio,
                    projecte_id: idCurrentProject,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioPOSTData.status)) {

            if (fetchSuccessActividad) {

                fetchSuccessActividad(fetchGremioPOSTData?.data?.data)

            } else {

                fetchSuccess()
            }

            handleClose()
        }

    }, [fetchGremioPOSTData]);


    // ------------ PATCH

    const handleSubmitEditarAgente = () => {

        if (handleValidGremio(formGremio)) {

            fetchGremioPATCH({
                id: data?.id,
                body: {
                    ...formGremio,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchGremioPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchGremioDELETE({
            id: data?.id,
            body: {
                ...formGremio,
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchGremioDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {
                fetchGremioGET({
                    id: data?.id
                })
            }

        } else {
            resetFormGremio()
            resetvalidDNIGremio()
            resetvalidGremio()
            resetfetchGremioPOSTData()
            resetfetchGremioGETData()
            resetfetchGremioPATCHData()
            resetfetchGremioDELETEData()
            resetfetchGremiosGETData()
        }

    }, [data.value]);


    // -------------------- Autocompletado de Google

    const getPlaceDetails = (placeId: any) => {

        return new Promise(function (resolve, reject) {

            placesService.getDetails({
                placeId,
            },
                (place: any) => {
                    resolve(place);
                }
            );
        });
    };

    const handleSelectOption = async (place: any) => {

        const placeDetails: any = await getPlaceDetails(place.place_id)

        const location = groupBy(placeDetails?.address_components, 'types')

        handleFormGremio({
            ...formGremio,
            cp: location?.['postal_code']?.[0]?.long_name || '',
            // numero: location?.['street_number']?.[0]?.long_name || '',
            direccio: location?.['route']?.[0]?.long_name || '',
            provincia: location?.['administrative_area_level_2,political']?.[0]?.long_name || '',
            poblacio: location?.['locality,political']?.[0]?.long_name || '',
            // lat: String(placeDetails?.geometry?.location?.lat() || ''),
            // lng: String(placeDetails?.geometry?.location?.lng() || ''),
        })
        handleResetPredictions()

    }

    const handleResetPredictions = () => {

        getPlacePredictions({ input: '' });
    }

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_GEO_API_KEY,
        options: {
            types: ['address'],
        }
    });

    const [isHoverAutocomplete, setisHoverAutocomplete] = useState(false);

    const [fetchGremiosGET, fetchGremiosGETData, resetfetchGremiosGETData] = GremiosServices.GET_BY_USER()

    const handleGremiosGETNIFCIF = () => {

        if (handleValidDNIGremio(formGremio)) {

            fetchGremiosGET({
                params: {
                    gremionif: formGremio.nif
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremiosGETData.status)) {

            const gremio = fetchGremiosGETData.data?.items?.[0]

            if (gremio) {

                handleFormGremio({
                    ...gremio
                })
            }

        }

    }, [fetchGremiosGETData]);



    // --------------------- Modal Nuevo gremio ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()
    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVO_GREMIO') : Lang('EDITAR_GREMIO')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchGremioGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12} md={6} lg={8}>
                                        <InputText
                                            label={Lang('NOMBRE') + ' / ' + Lang('RAZON_SOCIAL') + ': *'}
                                            name='nom'
                                            value={formGremio.nom}
                                            onChange={handleInputGremio}
                                            fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                            localValidation={validGremio.nom}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <div className="d-flex align-items-end">
                                            <InputText
                                                label='NIF / CIF: *'
                                                name='nif'
                                                value={formGremio.nif}
                                                onChange={handleInputGremio}
                                                fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                                localValidation={validDNIGremio.nif}
                                            />

                                            <ButtonAdvanced
                                                variant="outlined" type="button" className="ms-2 flex-shrink-0" size='small'
                                                handleSubmit={handleGremiosGETNIFCIF}
                                                loadings={[fetchGremiosGETData.loading]}
                                                value={Lang('IMPORTAR')} icon={<FontAwesomeIcon icon={faSync} className='me-1' />}
                                            />
                                        </div>


                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        {/* <InputSelect
                                            label={Lang('TIPO_GREMIO') + ': *'}
                                            name='tipus_gremi'
                                            value={formGremio.tipus_gremi}
                                            values={[]}
                                            // onChange={handleInputGremio}
                                            localValidation={validGremio.tipus_gremi}
                                        /> */}

                                        <InputText
                                            label={Lang('TIPO_GREMIO') + ': *'}
                                            name='tipus_gremi'
                                            value={formGremio.tipus_gremi}
                                            onChange={handleInputGremio}
                                            fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                            localValidation={validGremio.tipus_gremi}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputText
                                            label={Lang('EMAIL') + ':'}
                                            name='email'
                                            value={formGremio.email}
                                            onChange={handleInputGremio}
                                            localValidation={validGremio.email}
                                            fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('TELEFONO') + ':'}
                                            name='telefono'
                                            type='number'
                                            value={formGremio.telefono}
                                            onChange={handleInputGremio}
                                            localValidation={validGremio.telefono}
                                            fetchData={data?.action === 'CREATE' ? fetchGremioPOSTData : fetchGremioPATCHData}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('TIPO_DE_VIA') + ':'}
                                            name='tipus_via'
                                            value={formGremio.tipus_via}
                                            // values={fetchParamsPublicGETData?.data?.['tipo_via'] || []}
                                            onChange={handleInputGremio}
                                            localValidation={validGremio.tipus_via}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6} lg={6}>
                                        <InputText
                                            label={Lang('DIRECCION') + ': *'}
                                            name='direccio'
                                            value={formGremio.direccio}
                                            localValidation={validGremio.direccio}
                                            onChange={(e: any) => {
                                                getPlacePredictions({ input: e.target.value });
                                                handleInputGremio(e);
                                            }}
                                            onBlur={(e: React.SyntheticEvent) => { isHoverAutocomplete ? e.preventDefault() : handleResetPredictions() }}
                                        />
                                        <div className='position-relative'>
                                            <ListAutocomplete
                                                list={placePredictions}
                                                handleClick={handleSelectOption}
                                                nameTitle='description'
                                                onMouseOver={() => setisHoverAutocomplete(true)}
                                                onMouseOut={() => setisHoverAutocomplete(false)}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('CODIGO_POSTAL') + ': *'}
                                            name='cp'
                                            value={formGremio.cp}
                                            onChange={handleInputGremio}
                                            // fetchData={data?.action === 'CREATE' ? fetchNuevoAgentePOSTData : fetchAgentePATCHData}
                                            localValidation={validGremio.cp}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('POBLACION') + ': *'}
                                            name='poblacio'
                                            value={formGremio.poblacio}
                                            onChange={handleInputGremio}
                                            // fetchData={data?.action === 'CREATE' ? fetchNuevoAgentePOSTData : fetchAgentePATCHData}
                                            localValidation={validGremio.poblacio}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('PROVINCIA') + ': *'}
                                            name='provincia'
                                            value={formGremio.provincia}
                                            onChange={handleInputGremio}
                                            // fetchData={data?.action === 'CREATE' ? fetchNuevoAgentePOSTData : fetchAgentePATCHData}
                                            localValidation={validGremio.provincia}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitGremioPOST}
                                loadings={[fetchGremioPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className=""
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchGremioDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <div>
                                    <ButtonAdvanced
                                        variant="contained" type="button" className=" ms-auto"
                                        handleSubmit={handleSubmitEditarAgente}
                                        loadings={[fetchGremioPATCHData.loading]}
                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                    />
                                </div>
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchGremioPOSTData} />

            <ModalStatusServer fetchData={fetchGremioPATCHData} />

            <ModalStatusServer fetchData={fetchGremioDELETEData} />

            <ModalStatusServer fetchData={fetchGremiosGETData} onlyError={true} />
        </>
    )
}