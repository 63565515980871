import { useEffect, useState } from "react";
import { useLang } from "../../../../../../../../language";
import { Grid } from "@mui/material";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../../hooks/useForm";
import queryString from 'query-string';
import { useLocation } from "react-router";
import { TableAnotaciones } from "./TableAnotaciones";
import FilterAdvanced from "../../../../../../../../components/FilterAdvanced/FilterAdvanced";
import { FormSimple } from "./Filters/FormSimple";
import { AnotacionesServices } from "./controllers/anotaciones.services";

export const Anotaciones = ({ readOnly, idEdificio, fetchEdificioGETData, idCurrentProject, idActividad }: any) => {

    const Lang = useLang()

    const [fetchIncidenciasGET, fetchIncidenciasGETData] = AnotacionesServices.GET()

    // --------------------- Modal Nuevo edificio ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            edifici_id: idEdificio,
            order: '',
            fechafinalizacion_desde: '',
            fechafinalizacion_hasta: '',
            projecteid: idCurrentProject,
            projecteintervencionid: idActividad,
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        fechafinalizacion_desde: params.fechafinalizacion_desde || allValuesSearch.fechafinalizacion_desde,
        fechafinalizacion_hasta: params.fechafinalizacion_hasta || allValuesSearch.fechafinalizacion_hasta,
        projecteid: params.projecteid || allValuesSearch.projecteid,
        projecteintervencionid: params.projecteintervencionid || allValuesSearch.projecteintervencionid,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecteintervencionid')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchIncidenciasGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecteintervencionid')

        fetchIncidenciasGET({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const [showFilter, setshowFilter] = useState(false);

    return (
        <>
            <div className="">
                {/* <Container maxWidth="lg" className='my-auto py-2'> */}

                <Grid container>

                    <Grid item xs={12} className='py-0 py-lg-0'>

                        {
                            showFilter &&
                            <div className="my-3 animate__animated animate__fadeIn animate__faster">

                                <FilterAdvanced
                                    fetchData={fetchIncidenciasGETData}
                                    handleSubmitSearch={handleSubmitSearch}
                                    formValuesSearch={formValuesSearch}
                                    handleFormChange={handleFormChange}
                                    handleInputChangeSearch={handleInputChangeSearch}
                                    resetformValuesSearch={resetformValuesSearch}
                                    valuesDefault={valuesSearch.default}
                                    valuesSimple={valuesSearch.onlySimple}
                                    valuesExpanded={valuesSearch.onlyExpanded}
                                >

                                    <FilterAdvanced.Simple label={Lang('BUSCADOR')}>

                                        <FormSimple
                                        // fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                                        />

                                    </FilterAdvanced.Simple>

                                    <FilterAdvanced.Expanded></FilterAdvanced.Expanded>

                                </FilterAdvanced>
                            </div>
                        }

                        <section>
                            <TableAnotaciones
                                data={fetchIncidenciasGETData.data?.items}
                                loading={fetchIncidenciasGETData.loading}
                                total={fetchIncidenciasGETData.data?.total}
                                perPage={fetchIncidenciasGETData.data?.per_page}

                                formValuesSearch={formValuesSearch}
                                handleFormChange={handleFormChange}
                                handleInputChangeSearch={handleInputChangeSearch}

                                idEdificio={idEdificio}
                                fetchIncidenciasGET={fetchIncidenciasGET}
                                // fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                                fetchEdificioGETData={fetchEdificioGETData}

                                setshowFilter={setshowFilter}
                                showFilter={showFilter}
                                idCurrentProject={idCurrentProject}
                                idActividad={idActividad}
                                readOnly={readOnly}
                                fetchSuccess={(e: any) => handleSubmitSearch(e, formValuesSearch)}
                            />
                        </section>

                    </Grid>

                </Grid>

                {/* </Container> */}
            </div>

        </>
    );
};
