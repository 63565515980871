import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../controllers"

const ComunicacionesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    assumpte: parseString(item.assumpte),
                    comunicacio_id: parseString(item.comunicacio_id),
                    created_at: receiveFechaFormat2(receiveFecha(item.created_at)),
                    data_enviada: receiveFechaFormat2(receiveFecha(item.data_enviada)),
                    data_inici: receiveFechaFormat2(receiveFecha(item.data_inici)),
                    data_fin: receiveFechaFormat2(receiveFecha(item.data_fin)),
                    destinatari_email: parseString(item.destinatari_email),
                    destinatari_id: parseString(item.destinatari_id),
                    edifici_id: parseString(item.edifici_id),
                    edifici_nom: parseString(item.edifici_nom) || '-',
                    error: parseString(item.error),
                    estat: parseString(item.estat),
                    leido: parseBoolean(item.leido),
                    message: parseString(item.message),
                    updated_at: parseString(item.updated_at),
                    total_comunicacions: parseNumber(item.total_comunicacions),
                    total_enviades: parseString(item.total_enviades),
                    total_no_enviades: parseString(item.total_no_enviades),
                    adjuntos: parseArray(item.adjuntos),
                    remitent_nom: parseString(item.remitent_nom),
                    label__enviado_por: `${parseString(item.first_name)} ${parseString(item.last_name)}`
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_MIS_COMUNICACIONES_SIMPLE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    comunicaciones_no_leidas: parseObject(item.comunicaciones_no_leidas),
                    documentos_edificio_caducados: parseObject(item.documentos_edificio_caducados),
                    documentos_inmueble_caducados: parseObject(item.documentos_inmueble_caducados),
                    actividades_proximas: parseObject(item.actividades_proximas),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_MIS_NO_LEIDOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    num_comunicaciones_no_leidas: parseNumber(item.num_comunicaciones_no_leidas),
                    num_docs_edificios_caducar: parseNumber(item.num_docs_edificios_caducar),
                    num_docs_inmuebles_caducar: parseNumber(item.num_docs_inmuebles_caducar),
                    num_actividades_proximas: parseNumber(item.num_actividades_proximas),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },


    GET_CURRENT_PLANTILLAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    assumpte: parseString(item.assumpte),
                    codi: parseString(item.codi),
                    descripcio: parseString(item.descripcio),
                    fechabaja: parseString(item.fechabaja),
                    locale: parseString(item.locale),
                    remitent: parseString(item.remitent),
                    remitent_email: parseString(item.remitent_email),
                    name: parseString(item.assumpte),
                    message: parseString(item.message),
                    plantilles_email_id: parseNumber(item.id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_ALL_EDIFICIOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    created_at: parseString(),
                    label__created_at: receiveFechaFormat2(receiveFecha(item.created_at)),
                    updated_at: parseString(item.updated_at),
                    tipus_edifici: parseString(item.tipus_edifici),
                    tipus_producte: parseObject(item.tipus_producte),
                    label__tipus_producte: parseString(item.tipus_producte?.producte),
                    inmuebles: parseArray(item.inmuebles),
                    identificacion: parseArray(item.identificacion),
                    propiedad: parseArray(item.propiedad),
                    fotos: parseArray(item.fotos),
                    planos: parseArray(item.planos),
                    pagos: parseArray(item.pagos),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const ComunicacionAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    edifici_id: parseString(item.edifici_id),
                    nom: parseString(item.nom),
                    assumpte: parseString(item.assumpte),
                    comunicacio_id: parseString(item.comunicacio_id),
                    created_at: receiveFechaFormat2(receiveFecha(item.created_at)),
                    data_enviada: receiveFechaFormat2(receiveFecha(item.data_enviada)),
                    data_inici: receiveFechaFormat2(receiveFecha(item.data_inici)),
                    data_fin: receiveFechaFormat2(receiveFecha(item.data_fin)),
                    destinatari_email: parseString(item.destinatari_email),
                    destinatari_id: parseString(item.destinatari_id),
                    error: parseString(item.error),
                    estat: parseString(item.estat),
                    leido: parseBoolean(item.leido),
                    message: parseString(item.message),
                    updated_at: parseString(item.updated_at),
                    total_comunicacions: parseNumber(item.total_comunicacions),
                    total_enviades: parseString(item.total_enviades),
                    total_no_enviades: parseString(item.total_no_enviades),
                    adjuntos: parseArray(item.adjuntos)?.map((item: any) => {
                        return {
                            ...item,
                            path: item.nombre_adjunt,
                            url: item.ruta_adjunt,
                        }
                    }),
                    remitent_nom: parseString(item.remitent_nom),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    adreca: parseString(item.adreca),
                    codi_postal: parseString(item.codi_postal),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    pais: parseString(item.pais),
                    telefon: parseString(item.telefon),
                    email: parseString(item.email),
                    web: parseString(item.web),
                    titulacio: parseString(item.titulacio),
                    rao_social: parseString(item.rao_social),
                    ambit_colaboracio: parseString(item.ambit_colaboracio),
                    projecte_parcial: parseString(item.projecte_parcial),
                    treball_obra: parseString(item.treball_obra),
                    tipo: parseString(item.tipo),

                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_COMUNICACION_EDIFICIO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    assumpte: parseString(item.assumpte),
                    message: parseString(item.message),
                    plantilles_email_id: parseNumber(item.plantilles_email_id),
                    remitent_nom: parseString(item.remitent_nom, { removeNullable: true }),
                    edifici_id: parseString(item.edifici_id),
                    // remitent_id: parseString(item.remitent_id),
                    remitent_email: parseString(item.remitent_email, { removeNullable: true }),
                    filtros: parseAny(item.filtros, { removeNullable: true }),
                    lista_ids: parseAny(item.lista_ids, { removeNullable: true }),
                    lista_exclusion: parseAny(item.lista_exclusion, { removeNullable: true }),
                    adjuntos: parseAny(item.adjuntos, { removeNullable: true }),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    adreca: parseString(item.adreca),
                    codi_postal: parseString(item.codi_postal),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    pais: parseString(item.pais),
                    telefon: parseString(item.telefon),
                    email: parseString(item.email),
                    web: parseString(item.web),
                    titulacio: parseString(item.titulacio),
                    rao_social: parseString(item.rao_social),
                    ambit_colaboracio: parseString(item.ambit_colaboracio),
                    projecte_parcial: parseString(item.projecte_parcial),
                    treball_obra: parseString(item.treball_obra),
                    tipo: parseString(item.tipo),

                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_LEER: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: parseNumber(item.id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_LEER_DOCS_EDIFICIO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    ocultar_notificacion : parseAny(item.ocultar_notificacion ),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}

const ContactoAdapters = {
    
    GET_UNIQUE_CODE: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    code: parseString(item.code),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    assumpte: parseString(item.assumpte),
                    message: parseString(item.message),
                    telefono: parseString(item.telefono),
                    code: parseString(item.code),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    
    POST_PUBLICO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    assumpte: parseString(item.assumpte),
                    message: parseString(item.message),
                    remitent_nom: parseString(item.remitent_nom),
                    remitent_email: parseString(item.remitent_email),
                    telefono: parseString(item.telefono),
                    code: parseString(item.code),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}

export {
    ComunicacionesAdapters,
    ComunicacionAdapters,
    ContactoAdapters
}