import { Grid } from "@mui/material";
import { useState, useEffect } from 'react'
import { TableMantCorrectivo } from "./TableMantCorrectivo";
import { useLang } from "../../../../../../../language";
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../../../../helpers/helpers";

export const MantenimientoCorrectivo = ({ idedificio, id, fetchMiElemConstData, formValuesSearch, handleFormChange,
    handleInputChangeSearch, fetchMiElemConst, isImportado, setisImportado }: any) => {

    const Lang = useLang()


    const [activeImportITE, setactiveImportITE] = useState(false);


    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'sistema', 'edifici_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMiElemConst({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'sistema', 'edifici_id')

        fetchMiElemConst({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])



    return (
        <Grid container spacing={2} columnSpacing={3}>

            <Grid item xs={12} >
                <TableMantCorrectivo
                    data={fetchMiElemConstData.data?.items}
                    // data={elementSelected?.operations}
                    // loading={false}
                    loading={fetchMiElemConstData.loading}
                    total={fetchMiElemConstData.data?.total}
                    perPage={fetchMiElemConstData.data?.per_page}

                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    handleInputChangeSearch={handleInputChangeSearch}

                    idedificio={idedificio}
                    fetchSuccess={() => {
                        fetchMiElemConst({
                            params: {
                                ...getValuesParams(formValuesSearch)
                            }
                        })
                    }}
                    id={id}
                    setactiveImportITE={setactiveImportITE}
                    isImportado={isImportado}
                    setisImportado={setisImportado}
                />
            </Grid>

        </Grid>
    );
};
