import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../controllers/controllers"

const PotencialMejorasAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    tipus: parseString(item.tipus),
                    nom: parseString(item.nom),
                    ruta: parseString(item.ruta),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
    GET_PARAM_ARBOL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    actiu: parseBoolean(item.actiu),
                    estado: parseString(item.estado),
                    admite_hijos: parseBoolean(item.admite_hijos),
                    es_seleccionable: parseBoolean(item.es_seleccionable),
                    metricas: parseArray(item.metricas),
                    order: parseAny(item.order),
                    padre_id: parseAny(item.padre_id),
                    texto: parseString(item.texto),
                    help: parseString(item.ayuda),
                    name: parseString(item.texto),
                    hijos: parseArray(item.hijos),
                    otros_elementos: parseArray(item.otros_elementos),
                    elemento_completado: parseBoolean(item.elemento_completado),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const PotencialMejoraAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    tipus_document: parseString(item.tipus_document),
                    nom: parseString(item.nom),
                    ruta: parseString(item.ruta),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
  
    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    tipus_document: parseString(item.tipus_document),
                    file: parseAny(item.file),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    tipus_document: parseString(item.tipus_document),
                    file: parseAny(item.file, {removeNullable: true}),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    PotencialMejorasAdapters,
    PotencialMejoraAdapters,
}