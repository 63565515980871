import { useState, useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useLang } from "../../../../../../language";
import { useNavigate, useParams } from "react-router";
import { AccordionAdvancedPM } from "./ArbolPM/AccordionAdvancedPM";
import { useModalData } from "../../../../../../hooks/useModalData";
import { PotencialMejorasServices } from "./controllers/potencialmejora.services";
import { isStatusSuccess } from "../../../../../../helpers/helpers";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";
import { MaestrosServices } from "../../../../../../controllers/maestros/maestros.services";
import { Medidas } from "./Medidas/Medidas";
import { ReadOnlyPotencialMejoras } from "./ReadOnlyPotencialMejoras/ReadOnlyPotencialMejoras";

export const PotencialMejoras = () => {

  const [tree, settree] = useState<any>({});

  const Lang = useLang()

  const { idPM, id: idEdificio } = useParams()

  const [fetchParamPotencialMejoraGET, fetchParamPotencialMejoraGETData] = PotencialMejorasServices.GET_PARAM_ARBOL()

  const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

  useEffect(() => {

    fetchParamPotencialMejoraGET()

    fetchParamsPublicGET({
      body: [
        {
          parametroPadre: "tipus_cost_milloras",
        },
        {
          parametroPadre: "tipus_dificultat_milloras",
        },
      ]
    })

  }, []);

  useEffect(() => {

    if (isStatusSuccess(fetchParamPotencialMejoraGETData.status)) {

      const treeRoot = fetchParamPotencialMejoraGETData.data?.[0]

      handleRecorrerArbol(treeRoot, '')

      settree(treeRoot)
    }

  }, [fetchParamPotencialMejoraGETData]);

  const [expandedO, setExpandedO] = useState<number | false>(false);
  const [selected, setSelected] = useState<any>([]);

  const [elementSelected, setelementSelected] = useState<any>({});

  useEffect(() => {

    if (typeof selected === 'string') {

      const indexPath = selected?.split('|_')?.[1]?.split('_') || []

      let objTemp: any = tree

      for (let i = 0; i < indexPath.length; i++) {
        objTemp = objTemp.children?.[indexPath[i]]
      }

      if (!objTemp.allowChildren) {

        setelementSelected(objTemp)

      } else {

        setelementSelected({})
      }
    }

  }, [selected]);

  const navigate = useNavigate()

  useEffect(() => {

    if (!isEmpty(elementSelected)) {

      navigate(`../potencial-mejoras/${elementSelected.idOriginal}`)

    } else {

      navigate(`../potencial-mejoras`)
    }

  }, [elementSelected]);

  const handleRecorrerArbol = (nodo: any, path: string) => {

    nodo.name = nodo.texto
    nodo.help = nodo.ayuda
    nodo.allowChildren = nodo.admite_hijos
    nodo.children = nodo.hijos
    nodo.idOriginal = nodo.id
    nodo.id = nodo.id + '|' + path

    if (Array.isArray(nodo.children)) {
      nodo.children?.map((item: any, index: number) => handleRecorrerArbol(item, path + '_' + index))
    }
  }

  const [dataModal_PM, handleShowModal_PM, handleCloseModal_PM] = useModalData()

  const [isAhorroEnergia, setisAhorroEnergia] = useState<any>(0);

  const isNivelAhorroEnergia = (e: any) => {

    const idAhorroEnergia = 7

    setisAhorroEnergia(Number(e.target.closest('article').id) === idAhorroEnergia)
  }

  return (
    <Grid container spacing={2} columnSpacing={4} className="">

      {
        fetchParamPotencialMejoraGETData?.loading ?
          <LoaderPotencialMejoras />
          :
          <>
            <Grid item xs={12} md={4} lg={3} className="mb-4">

              <AccordionAdvancedPM
                onlyOne
                tree={tree}
                setExpandedO={setExpandedO}
                expandedO={expandedO}
                setSelected={setSelected}
                selected={selected}
                dataModal_PM={dataModal_PM}
                handleShowModal_PM={handleShowModal_PM}
                isNivelAhorroEnergia={isNivelAhorroEnergia}
              />
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              {
                idPM &&
                <Medidas
                  fetchParamsPublicGETData={fetchParamsPublicGETData}
                  idEdificio={idEdificio}
                  idPM={idPM}
                  isAhorroEnergia={isAhorroEnergia}
                />
              }

              {
                !idPM &&
                <Card className="mb-5">
                  <CardContent>
                    <ReadOnlyPotencialMejoras
                      idEdificio={idEdificio}
                      fetchParamsPublicGETData={fetchParamsPublicGETData}
                      idPM={idPM}
                      tree={tree}
                    />
                  </CardContent>
                </Card>
              }

            </Grid>
          </>
      }

    </Grid>
  );
};

const LoaderPotencialMejoras = () => {

  return (
    <>
      <Grid item xs={12} md={4} lg={3} className="mb-4">

        <Placeholder className='px-0 pb-3' height='2rem' />
        <Placeholder className='px-0 py-3 delay-1' height='2rem' />
        <Placeholder className='px-0 py-3 delay-2' height='2rem' />
        <Placeholder className='px-0 py-3 delay-3' height='2rem' />
        <Placeholder className='px-0 py-3 delay-4' height='2rem' />

      </Grid>

      <Grid item xs={12} md={8} lg={9}>

        <Placeholder className='px-0 pb-3' height='15rem' />

      </Grid>
    </>
  )
}