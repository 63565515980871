export const AvisoLegalSeccion8 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        La prestación del servicio de la WEB y de otros Servicios tiene, en principio, una duración indefinida. No obstante, el Cateb está autorizado para finalizar o suspender la prestación del servicio Web y/o de los Servicios en cualquier momento, sin perjuicio de lo que se hubiera dispuesto en lo que se refiere al tema de las condiciones particulares correspondientes. Cuando esto sea razonablemente posible, el Cateb advertirá previa a la finalización o suspensión de la prestación del servicio de la Web y de otros Servicios.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        La prestació del servei de la WEB i d' altres Serveis té, en principi, una durada indefinida. No obstant això, el Cateb està autoritzat per finalitzar o suspendre la prestació del servei Web i/o dels Serveis en qualsevol moment, sense perjudici del que s'hagués disposat pel que fa al tema de les condicions particulars corresponents. Quan això sigui raonablement possible, el Cateb advertirà prèvia a la finalització o suspensió de la prestació del servei de la Web i d'altres Serveis.
      </p>
    </div>
  )
}