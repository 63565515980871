import { parseAny, parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../controllers/controllers"

const GremiosAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    tipus_gremi: parseString(item.tipus_gremi),
                    email: parseString(item.email),
                    telefono: parseString(item.telefono),
                    tipus_via: parseString(item.tipus_via),
                    direccio: parseString(item.direccio),
                    cp: parseString(item.cp),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    projecte_id: parseAny(item.projecte_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const GremioAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    tipus_gremi: parseString(item.tipus_gremi),
                    email: parseString(item.email),
                    telefono: parseString(item.telefono),
                    tipus_via: parseString(item.tipus_via),
                    direccio: parseString(item.direccio),
                    cp: parseString(item.cp),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    projecte_id: parseAny(item.projecte_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
  
    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    tipus_gremi: parseString(item.tipus_gremi),
                    email: parseString(item.email),
                    telefono: parseString(item.telefono),
                    tipus_via: parseString(item.tipus_via),
                    direccio: parseString(item.direccio),
                    cp: parseString(item.cp),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    projecte_id: parseAny(item.projecte_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    tipus_gremi: parseString(item.tipus_gremi),
                    email: parseString(item.email),
                    telefono: parseString(item.telefono),
                    tipus_via: parseString(item.tipus_via),
                    direccio: parseString(item.direccio),
                    cp: parseString(item.cp),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    projecte_id: parseAny(item.projecte_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    GremiosAdapters,
    GremioAdapters,
}