import { Container, Grid } from "@mui/material";
import { Navigate, Route, Routes } from "react-router";
import { NavLeft } from "../../../layout/NavLeft";
import { useLang } from "../../../../../language";
import { LayoutFases } from "./LayoutProgramacionGestion/LayoutFases";
import { GremiosProyecto } from "./Gremios/GremiosProyecto";
import { IndexPlanificacionFinanciera } from "./PlanificacionFinanciera";
import { isGestorMantenimiento } from "../../../../../helpers/helpers";

export const ProgramacionGestion = ({ idedificio, setDefaultLink, defaultLink, idCurrentProject,
    readOnly }: any) => {

    const Lang = useLang()

    const navLinks = [
        {
            title: Lang('ACTIVIDADES'),
            link: 'actividades'
        },
        {
            title: Lang('PLANIFICACION_FINANCIERA'),
            link: 'planificacion-financiera'
        },
        {
            title: Lang('GREMIOS'),
            link: 'gremios'
        },
    ]

    return (
        <>

            <NavLeft
                navLinks={navLinks}
            />

            <main className='Main'>

                <Container maxWidth={false} className='my-auto'>

                    <Grid container spacing={3}>

                        <Grid item xs={12} className='py-0 py-lg-0 mt-3'>

                            <Routes>

                                <Route
                                    path='actividades/*'
                                    element={
                                        <LayoutFases
                                            idedificio={idedificio}
                                            idCurrentProject={idCurrentProject}
                                            readOnly={readOnly}
                                        />
                                    }
                                />

                                <Route
                                    path='planificacion-financiera/*'
                                    element={
                                        <IndexPlanificacionFinanciera
                                            idCurrentProject={idCurrentProject}
                                            readOnly={readOnly}
                                        />
                                    }
                                />

                                <Route
                                    path='gremios/*'
                                    element={
                                        <GremiosProyecto
                                            idCurrentProject={idCurrentProject}
                                            readOnly={false}
                                        />
                                    }
                                />

                                <Route
                                    path="*"
                                    element={<Navigate to='actividades' replace />}
                                />

                            </Routes>
                        </Grid>

                    </Grid>

                </Container>

            </main>
        </>
    );
};
