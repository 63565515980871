export const CondGenSeccion10 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <strong>10.1. Titularidad de los productos y servicios ofrecidos</strong>
      </p>
      <p>
        El titular de todo el software disponible y de sus actualizaciones de mejora, rendimiento o seguridad es CATEB, sus licenciatarios, empresas asociadas u otros proveedores que hayan podido proporcionar materiales para el desarrollo y diseño de los productos y servicios. De manera que CATEB y sus licenciatarios poseen todos los títulos y derechos de propiedad intelectual sobre las aplicaciones.
      </p>
      <p>
        Se prohíbe expresamente, salvo autorización por escrito y expresa de CATEB, la reproducción, transformación, distribución, comunicación pública, puesta a disposición al público, venta u otros usos distintos a los expresamente autorizados por CATEB en las presentes condiciones, en las  políticas y demás avisos publicados en su página web, así como en las condiciones particulares, en su caso, ya sea con fines comerciales o no, cuando conlleven la explotación de los contenidos, productos o servicios ofrecidos CATEB cuyos fines sean distintos a los previstos en estas condiciones. Los derechos otorgados al usuario en las presentes condiciones son otorgados únicamente por para uso personal.
      </p>
      <p>
        <strong>10.2. Otorgamiento de licencia de uso para las aplicaciones informáticas</strong>
      </p>
      <p>
        Por medio del presente acuerdo de licencia CATEB le otorga al usuario una licencia de uso personal no exclusiva, intransferible y no sublicenciable respecto a las aplicaciones informáticas y software que sean objeto de compra o contratación a través de nuestro sitio web.
      </p>
      <p>
        La Licencia no le concede ninguna titularidad al usuario o cliente y será efectiva a partir de la fecha de aceptación de estos términos. En el caso de suscripción a servicios digitales, la licencia de uso finalizará cuando finalice la suscripción.
      </p>
      <p>
        <strong>10.3. Condiciones de la licencia de uso</strong>
      </p>
      <p>
        Las aplicaciones informáticas están protegidas por las leyes de propiedad intelectual, permitiéndose exclusivamente un uso personal y prohibiéndose los usos con fines comerciales.  De manera que el usuario no podrá hacer nada de lo mencionado a continuación con respecto a las aplicaciones o a cualquiera de sus partes:
      </p>
      <p>
        (a) utilizarlo con fines comerciales o promocionales;
      </p>
      <p>
        (b) copiarlo, reproducirlo, distribuirlo, exhibirlo o utilizarlo de una manera que no esté expresamente autorizada en el presente Contrato;
      </p>
      <p>
        (c) venderlo, alquilarlo, licenciarlo, distribuirlo o transferirlo;
      </p>
      <p>
        (d) aplicar ingeniería inversa, derivar su código fuente, modificarlo, adaptarlo, traducirlo, descompilarlo o desensamblarlo, o realizar trabajos derivados basados en este;
      </p>
      <p>
        (e) eliminar, desactivar, eludir o modificar cualquier aviso o etiqueta de propiedad o tecnología de seguridad incluida en este;
      </p>
      <p>
        (f) utilizarlo para infringir o violar los derechos de terceros, incluidos, entre otros, cualquier derecho de propiedad intelectual, industrial o de privacidad;
      </p>
      <p>
        (g) obstaculizar, interferir o desactivar cualquier función de seguridad instalada.
      </p>
      <p>
        (h) utilizarlo, exportarlo o reexportarlo violando cualquier ley o regulación aplicable;
      </p>
      <p>
        (i) comportarse de una manera que sea perjudicial para el uso de la aplicación informática por otros usuarios según lo previsto y a juicio exclusivo del CATEB, en base a la buena fe, la moral y el orden público, y, concretamente, introducir <i>addwares</i>, <i>malwares</i> o cualquier otro material que pudiera dañar las aplicaciones.
      </p>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <strong>10.1. Titularitat dels productes i serveis oferts</strong>
      </p>
      <p>
        El titular de tot el programari disponible i de les seves actualitzacions de millora, rendiment o seguretat és CATEB, els seus llicenciataris, empreses associades o altres proveïdors que hagin pogut proporcionar materials per al desenvolupament i disseny dels productes i serveis. De manera que CATEB i els seus llicenciataris posseeixen tots els títols i drets de propietat intel·lectual sobre les aplicacions.
      </p>
      <p>
        Es prohibeix expressament, llevat d' autorització per escrit i expressa de CATEB, la reproducció, transformació, distribució, comunicació pública, posada a disposició al públic, venda o altres usos diferents als expressament autoritzats per CATEB en les presents condicions, en les polítiques i altres avisos publicats a la seva pàgina web, així com a les condicions particulars, si s' escau,  ja sigui amb finalitats comercials o no, quan comportin l' explotació dels continguts, productes o serveis oferts CATEB les finalitats dels quals siguin diferents a les previstes en aquestes condicions. Els drets atorgats a l' usuari en les presents condicions són atorgats únicament per per a ús personal.
      </p>
      <p>
        <strong>10.2. Atorgament de llicència d'ús per a les aplicacions informàtiques</strong>
      </p>
      <p>
        Per mitjà del present acord de llicència CATEB li atorga a l'usuari una llicència d'ús personal no exclusiva, intransferible i no sublicenciable respecte a les aplicacions informàtiques i programari que siguin objecte de compra o contractació a través del nostre lloc web.
      </p>
      <p>
        La Llicència no li concedeix cap titularitat a l' usuari o client i serà efectiva a partir de la data d' acceptació d' aquests termes. En el cas de subscripció a serveis digitals, la llicència d' ús finalitzarà quan finalitzi la subscripció.
      </p>
      <p>
        <strong>10.3. Condicions de la llicència d'ús</strong>
      </p>
      <p>
        Les aplicacions informàtiques estan protegides per les lleis de propietat intel·lectual, permetent-se exclusivament un ús personal i prohibint-se els usos amb finalitats comercials.  De manera que l' usuari no podrà fer res de l' esmentat a continuació respecte a les aplicacions o a qualsevol de les seves parts:
      </p>
      <p>
        (a) utilitzar-lo amb finalitats comercials o promocionals;
      </p>
      <p>
        (b) copiar-lo, reproduir-lo, distribuir-lo, exhibir-lo o utilitzar-lo d'una manera que no estigui expressament autoritzada en el present Contracte;
      </p>
      <p>
        (c) vendre'l, llogar-lo, llicenciar-lo, distribuir-lo o transferir-lo;
      </p>
      <p>
        (d) aplicar enginyeria inversa, derivar el seu codi font, modificar-lo, adaptar-lo, traduir-lo, descompilar-lo o desensamblar-lo, o realitzar treballs derivats basats en aquest;
      </p>
      <p>
        (e) eliminar, desactivar, eludir o modificar qualsevol avís o etiqueta de propietat o tecnologia de seguretat inclosa en aquest;
      </p>
      <p>
        (f) utilitzar-lo per infringir o violar els drets de tercers, inclosos, entre d'altres, qualsevol dret de propietat intel·lectual, industrial o de privacitat;
      </p>
      <p>
        (g) obstaculitzar, interferir o desactivar qualsevol funció de seguretat instal·lada.
      </p>
      <p>
        (h) utilitzar-lo, exportar-lo o reexportar-lo violant qualsevol llei o regulació aplicable;
      </p>
      <p>
        (i) comportar-se d'una manera que sigui perjudicial per a l'ús de l'aplicació informàtica per altres usuaris segons el previst i a judici exclusiu del CATEB, en base a la bona fe, la moral i l'ordre públic, i, concretament, introduir addwars, malwars o qualsevol altre material que pogués danyar les aplicacions.
      </p>

    </div>
  )
}