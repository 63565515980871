import React from 'react'
import '../Table.scss'
import { TableAdvancedBody } from './TableAdvancedBody'
import { TableAdvancedFooter } from './TableAdvancedFooter'
import { TableAdvancedHead } from './TableAdvancedHead'

const TableAdvanced = ({ selectAllItems, unselectAllItems, handleInputChange, children, 
    classNameTableAdvanced = '', handleFormChange, formValuesSearch }: any) => {

    function addPropsToReactElement(element: any, props: any) {
        if (React.isValidElement(element)) {
            return React.cloneElement(element, props)
        }
        return element
    }

    return (
        <section className={`TableAdvanced ${classNameTableAdvanced}`}>
            <>
                {
                    children?.map((childElement: any, indexchildElement: any) =>
                        addPropsToReactElement(childElement, { 
                            selectAllItems, unselectAllItems, handleFormChange, formValuesSearch, handleInputChange,
                            'key': indexchildElement })
                    )
                }
            </>
        </section>
    )
}

TableAdvanced.Head = TableAdvancedHead;
TableAdvanced.Body = TableAdvancedBody;
TableAdvanced.Footer = TableAdvancedFooter;

export default TableAdvanced;