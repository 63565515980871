import { parseNumber, parseObject, parseString, receiveCheck, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../../../../controllers/controllers"

const MantCorrectDeficienciasAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    edifici_id: parseString(item.edifici_id),
                    descripcio_curta: parseString(item.descripcio_curta),
                    descripcio: parseString(item.descripcio),
                    observacions: parseString(item.observacions),
                    qualificacio: parseString(item.qualificacio),
                    elemento_constructivo_id: parseString(item.elemento_constructivo_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const MantCorrectDeficienciaAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    edifici_id: parseNumber(item.edifici_id),
                    descripcio_curta: parseString(item.descripcio_curta),
                    descripcio: parseString(item.descripcio),
                    observacions: parseString(item.observacions),
                    qualificacio: parseString(item.qualificacio),
                    elemento_constructivo_id: parseString(item.elemento_constructivo_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    descripcio_curta: parseString(item.descripcio_curta),
                    descripcio: parseString(item.descripcio),
                    observacions: parseString(item.observacions),
                    qualificacio: parseString(item.qualificacio),
                    elemento_constructivo_id: parseString(item.elemento_constructivo_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    descripcio_curta: parseString(item.descripcio_curta),
                    descripcio: parseString(item.descripcio),
                    observacions: parseString(item.observacions),
                    qualificacio: parseString(item.qualificacio),
                    elemento_constructivo_id: parseString(item.elemento_constructivo_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}

export {
    MantCorrectDeficienciasAdapters,
    MantCorrectDeficienciaAdapters,
}