import { useEffect, useLayoutEffect, useState } from "react";
import './AccordionAdvanced.scss'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const AccordionAdvanced = ({ list, onlyOne, indexAccordion, id = '' }: any) => {

    const [expanded, setExpanded] = useState<number | false>(false);

    const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useLayoutEffect(() => {

        let timeoutId: any = null

        if (indexAccordion) {

            timeoutId = setTimeout(() => {
                
                let hero = document.getElementById(`AccordionAdvanced--${id}-${indexAccordion}`);
 
                hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });

            }, 300);
        }

        return () => clearTimeout(timeoutId);


    }, [indexAccordion]);


    return (
        <section className="AccordionAdvanced">
            {
                list?.map((item: any, index: number) => {
                    return (
                        <Accordion
                            id={`AccordionAdvanced--${id}-${index}`}
                            defaultExpanded={indexAccordion && index === Number(indexAccordion)}
                            key={`AccordionAdvanced-${index}`} expanded={onlyOne ? expanded === index : undefined} onChange={handleChange(index)}
                        >
                            <AccordionSummary
                                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                                aria-controls="panel1bh-content"
                                id={`AccordionAdvanced-${index}`}
                            >
                                <Typography className="fw-bold">
                                    {item.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {item.content}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </section>
    );
};
