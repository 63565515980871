export const CondParSeccion9 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El COL·LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb) trata los datos personales que nos facilitan y aquellos que sean obtenidos de su relación con nosotros, y serán incorporados a un fichero o ficheros, responsabilidad del COL·LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb). Sus datos serán dedicados exclusivamente a las finalidades relacionadas con la compra y la gestión de uso del Libro del Edificio Web (LED), en nuestra calidad de administradores del programa LED. En este sentido, se comunica que se realizarán análisis de perfiles y de uso a partir del historial de servicios y actividades del interesado, con el fin de mejorar y dotar de valor añadido a los servicios y actividades a disposición de los usuarios.
      </p>
      <p>
        Los datos se conservarán durante todo el período en que se mantenga la relación con nosotros, así como durante el período de prescripción de las acciones de responsabilidad para el ejercicio y/o defensa de eventuales reclamaciones.
      </p>
      <p>
        Los datos tratados están protegidos con los medios y sistemas técnicos necesarios para preservar su confidencialidad y evitar la pérdida, alteración y acceso no autorizado a los mismos, de acuerdo con los niveles de seguridad legamente demandados.
      </p>
      <p>
        Derechos de los afectados: en caso necesario, usted puede ejercer los derechos de acceso, rectificación, supresión, limitación, portabilidad y oposición al tratamiento de los datos, dirigiéndonos por escrito o por correo electrónico y acompañando copia del documento oficial que le identifique, al COL·LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb), con domicilio en la calle Bon Pastor, nº 5, Cp 08021, Barcelona, o enviando un e-mail a info@apabcn.cat, indicando en el sobre o en el asunto del mensaje: Aviso legal y de privacidad. Usted también tiene derecho a formular reclamación delante de la autoridad de control y a revocar el consentimiento otorgado.
      </p>
      <p>
        Otros datos de interés:
      </p>
      <p>
        Responsable: COL·LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb)
      </p>
      <p>
        CIF	Q0875009C
      </p>
      <p>
        Dirección	C. Bon Pastor, 5. 08021 Barcelona. España
      </p>
      <p>
        Contacto	info@cateb.cat o tel. 93 240 20 60
      </p>
      <p>
        Delegado de Protección de Datos: Institut Qualitas d’Assessoria BCN, S.L.
      </p>
      <p>
        Contacto del Delegado de Protección de Datos: dpd@cateb.cat.
      </p>
      <p>
        Legitimación: la base legal es el consentimiento del interesado.
      </p>
      <p>
        Destinatarios: los usuarios que consten como autorizados para el uso del Libro del Edificio (LED), podrán acceder a los datos que sean necesarios para este uso (datos de identificación y de contacto). Así mismo, su datos podrán ser comunicados a terceros para el cumplimiento de las obligaciones legales y/o contractuales que puedan existir, por ejemplo, a la Administración Pública, colegio profesional o consejo de colegios profesionales que puedan ser competentes en cada caso. En este sentido, le informamos que, en el caso de facilitar datos bancarios para el pago de los productos adquiridos, se procederá a su comunicación a nuestra entidad financiera al exclusivo efecto de gestionar su cobro.
      </p>
      <p>
        <strong>ACCESO A DATOS DE TERCEROS</strong>:
      </p>
      <p>
        En el caso de acceso a servicios que comporten el acceso a datos de terceros, éstos solo podrán ser utilizados para la finalidad del servicio de uso del Libro del Edificio Web (LED).
      </p>
      <p>
        En cualquier caso, le recordamos la obligación de confidencialidad y de cumplir la normativa vigente en materia de protección de datos de carácter personal y, muy especialmente, aunque sin carácter exhaustivo ni limitativo:
      </p>
      <ul>
        <li>
          Mantener la máxima confidencialidad en el tratamiento de los datos personales y garantizar que las personas autorizadas para tratar los datos personales se comprometen, de forma expresa y por escrito, a respetar la confidencialidad y a cumplir las medidas de seguridad correspondientes.
        </li>
        <li>
          Tratar los datos con la finalidad única y exclusiva de gestionar el servicio contratado. En ningún caso, podrá utilizar los datos para otras finalidades diferentes.
        </li>
        <li>
          No comunicar los datos a terceras personas, salvo que cuente con la autorización expresa del titular de los datos, y solo en los supuestos legalmente admisibles.
        </li>
        <li>
          Informar a las personas afectadas, en los términos legalmente establecidos, del tratamiento de sus datos del origen de los mismos y de sus derechos en este ámbito.
        </li>
      </ul>
      <p>
        <strong>DATOS DE TERCERAS PERSONAS</strong>:
      </p>
      <p>
        Si usted nos facilita datos de terceros, o nos las facilita en el futuro, con carácter previo, usted tendrá que haber informado y obtenido el consentimiento del tercero, en relación con todo lo expuesto anteriormente.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        El COL· LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb) tracta les dades personals que ens faciliten i aquelles que siguin obtingudes de la seva relació amb nosaltres, i seran incorporades a un fitxer o fitxers, responsabilitat del COL· LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb). Les seves dades seran dedicades exclusivament a les finalitats relacionades amb la compra i la gestió d'ús del Llibre de l'Edifici Web (LED), en la nostra qualitat d'administradors del programa LED. En aquest sentit, es comunica que es realitzaran anàlisis de perfils i d' ús a partir de l' historial de serveis i activitats de l' interessat, per tal de millorar i dotar de valor afegit els serveis i activitats a disposició dels usuaris.
      </p>
      <p>
        Les dades es conservaran durant tot el període en què es mantingui la relació amb nosaltres, així com durant el període de prescripció de les accions de responsabilitat per a l'exercici i/o defensa d'eventuals reclamacions.
      </p>
      <p>
        Les dades tractades estan protegides amb els mitjans i sistemes tècnics necessaris per preservar la seva confidencialitat i evitar la pèrdua, alteració i accés no autoritzat als mateixos, d' acord amb els nivells de seguretat legament demandats.
      </p>
      <p>
        Drets dels afectats: en cas necessari, vostè pot exercir els drets d'accés, rectificació, supressió, limitació, portabilitat i oposició al tractament de les dades, dirigint-nos per escrit o per correu electrònic i acompanyant còpia del document oficial que l'identifiqui, al COL· LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (Cateb), amb domicili al carrer Bon Pastor, nº 5, Cp 08021, Barcelona, o enviant un e-mail a info@apabcn.cat, indicant en el sobre o en l'assumpte del missatge: Avís legal i de privacitat. Vostè també té dret a formular reclamació davant l'autoritat de control i a revocar el consentiment atorgat.
      </p>
      <p>
        Altres dades d' interès:
      </p>
      <p>
        Responsable: COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (Cateb)
      </p>
      <p>
        CIF	Q0875009C
      </p>
      <p>
        Direcció	C. Bon Pastor, 5. 08021 Barcelona. Espanya
      </p>
      <p>
        Contacte	Tel info@cateb.cat. 93 240 20,60
      </p>
      <p>
        Delegat de Protecció de Dades: Institut Qualitas d' Assessoria BCN, S.L.
      </p>
      <p>
        Contacte del Delegat de Protecció de Dades: dpd@cateb.cat.
      </p>
      <p>
        Legitimació: la base legal és el consentiment de l' interessat.
      </p>
      <p>
        Destinataris: els usuaris que constin com a autoritzats per a l'ús del Llibre de l'Edifici (LED), podran accedir a les dades que siguin necessàries per a aquest ús (dades d'identificació i de contacte). Així mateix, les seves dades podran ser comunicades a tercers per al compliment de les obligacions legals i/o contractuals que puguin existir, per exemple, a l'Administració Pública, col·legi professional o consell de col·legis professionals que puguin ser competents en cada cas. En aquest sentit, l' informem que, en el cas de facilitar dades bancàries per al pagament dels productes adquirits, es procedirà a la seva comunicació a la nostra entitat financera a l' exclusiu efecte de gestionar el seu cobrament.
      </p>
      <p>
        <strong>ACCÉS A DADES DE TERCERS</strong>:
      </p>
      <p>
        En el cas d'accés a serveis que comportin l'accés a dades de tercers, aquestes només podran ser utilitzades per a la finalitat del servei d'ús del Llibre de l'Edifici Web (LED).
      </p>
      <p>
        En qualsevol cas, li recordem l' obligació de confidencialitat i de complir la normativa vigent en matèria de protecció de dades de caràcter personal i, molt especialment, encara que sense caràcter exhaustiu ni limitatiu:
      </p>
      <ul>
        <li>
          Mantenir la màxima confidencialitat en el tractament de les dades personals i garantir que les persones autoritzades per tractar les dades personals es comprometen, de forma expressa i per escrit, a respectar la confidencialitat i a complir les mesures de seguretat corresponents.
        </li>
        <li>
          Tractar les dades amb la finalitat única i exclusiva de gestionar el servei contractat. En cap cas, podrà utilitzar les dades per a altres finalitats diferents.
        </li>
        <li>
          No comunicar les dades a terceres persones, llevat que compti amb l' autorització expressa del titular de les dades, i només en els supòsits legalment admissibles.
        </li>
        <li>
          Informar les persones afectades, en els termes legalment establerts, del tractament de les seves dades de l' origen d' aquestes i dels seus drets en aquest àmbit.
        </li>
      </ul>
      <p>
        <strong>DADES DE TERCERES PERSONES</strong>:
      </p>
      <p>
        Si vostè ens facilita dades de tercers, o ens les facilita en el futur, amb caràcter previ, vostè haurà d'haver informat i obtingut el consentiment del tercer, en relació amb tot l'exposat anteriorment.
      </p>
    </div>
  )
}