import { useLang } from "../../../../../../../language";
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { ModalMedida } from "./ModalMedida";
import { ModalConfirm } from "../../../../../../../components/Modals/ModalConfirm";
import { convertParamToObject } from "../../../../../../../helpers/helpers";
import { MedidaServices } from "./controllers/medidas.services";
import { Tooltip } from "@mui/material";

export const TableMedidas = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, fetchFacturasGET, idEdificio, fetchPropiedadAgentesGET, isAhorroEnergia,
    fetchParamsAgentesGETData, elemento, fetchSuccess, fetchParamsPublicGETData, title, disabledFooter, disabledCreate }: any) => {

    const Lang = useLang()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const [dataModal_ConfirmDelete, handleShowModal_ConfirmDelete, handleCloseModal_ConfirmDelete] = useModalData()

    let allActions: any = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_MEDIDA')}</span>,
            variant: 'contained',
            action: (e: any, item: any) => {

                handleShowUsuario({
                    action: 'CREATE',
                    isAhorroEnergia: isAhorroEnergia
                })
            },
        }
    ]

    if (disabledCreate) {
        allActions = []
    }

    let allActionsPerLine: any = [
        // {
        //     variant: 'outlined',
        //     label: <FontAwesomeIcon icon={faTrash} className='' />,
        //     action: (item: any) => {
        //         handleShowModal_ConfirmDelete({
        //             ...item,
        //             action: 'DELETE',
        //         })
        //     },
        // },
        {
            variant: 'contained',
            // color: 'secondary',
            // className: 'ms-3',
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (item: any) => handleShowUsuario({
                ...item,
                action: 'EDIT',
                isAhorroEnergia: isAhorroEnergia
            }),
            tooltip: {
                text: Lang('EDITAR')
            },
        },
    ]

    let columnsTable: any = {
        medida: {
            title: Lang('MEDIDA'),
        },
        beneficio: {
            title: Lang('BENEFICIO'),
        },
        reduccion_cepnren: {
            title: <span>{Lang('REDUCCION')} <div className="text-nowrap">Cep,nren (%)</div></span>
        },
        coste: {
            className: 'text-nowrap',
            title: Lang('COSTE'),
            adapter: convertParamToObject(fetchParamsPublicGETData?.data?.['tipus_cost_milloras']),
        },
        // coste_maximo: {
        //     title: Lang('COSTE_MAXIMO'),
        // },
        complejidad: {
            title: Lang('COMPLEJIDAD'),
            adapter: convertParamToObject(fetchParamsPublicGETData?.data?.['tipus_dificultat_milloras']),
        },
        comentario: {
            title: Lang('COMENTARIOS'),
        },
    }

    if (!isAhorroEnergia) {
        delete columnsTable.reduccion_cepnren
    }

    // Acciones

    const handleSubmitUsuarioDelete = () => {
        // if (handleValidationRegistro(formRoles)) {

        // fetchUsuarioDelete({
        //     id: formRegistro.id
        // })
        // }
    }


    const dataTableChecksInit = {
        incluidos: [],
        excluidos: [],
        checkAll: false,
        activeChecks: true,
        // idRow: 'user_id'
    }

    const [dataTableChecks, setDataTableChecks] = useState<any>({
        ...dataTableChecksInit
    })

    useEffect(() => {

        if (data) {

            setDataTableChecks({
                ...dataTableChecks,
                incluidos: data?.filter((item: any) => item.seleccionado)?.map((item2: any) => item2.id)
            })
        }

    }, [data]);

    const [fetchMedidaPATCH, fetchMedidaPATCHData, resetfetchMedidaPATCHData] = MedidaServices.PATCH_SELECCIONADO()

    const handleCheckTable = (id: string, value: boolean) => {

        fetchMedidaPATCH({
            id,
            body: {
                seleccionado: value
            }
        })
    }


    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-3'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={title || <>
                        {Lang('MEDIDAS')}
                        <small className="d-block fw-normal fs-085">{Lang('PARRAFO_MEDIDAS_SELECCIONADAS')} </small>
                    </>}
                    allActions={allActions}
                    classNameTitle='fs-105'
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}

                    setDataTableChecks={setDataTableChecks}
                    dataTableChecks={dataTableChecks}
                    disableCheckAll={true}
                    handleCheckTable={handleCheckTable}
                    labelOnCheck={
                        <Tooltip title={Lang('PARRAFO_MEDIDAS_SELECCIONADAS')} placement="top">
                            <FontAwesomeIcon icon={faQuestionCircle} className="fs-105 cursor-pointer" />
                        </Tooltip>
                    }
                />

                {
                    !disabledFooter &&
                    <TableAdvanced.Footer
                        total={total}
                        perPage={perPage}
                    />
                }

            </TableAdvanced>


            <ModalMedida
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idEdificio={idEdificio}
                formValuesSearch={formValuesSearch}
                fetchPropiedadAgentesGET={fetchPropiedadAgentesGET}
                elemento={elemento}
                fetchSuccess={fetchSuccess}
                fetchParamsPublicGETData={fetchParamsPublicGETData}
            />

            <ModalConfirm
                data={dataModal_ConfirmDelete}
                handleClose={handleCloseModal_ConfirmDelete}
                message={<p>{Lang('PARRAFO_DESEA_ELIMINAR_PROPUESTA')}</p>}
                textOnClickOk={Lang('SI_ELIMINAR')}
                variantClickOk='contained'
                onClickOK={handleSubmitUsuarioDelete}
            // fetchData={fetchUsuarioDeleteData}
            />
        </>
    )
}
