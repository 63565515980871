import { useAxios } from "../../../../../hooks/useAxios"
import { ElementosConstructivosParamAdapters, PlanificacionFinancieraAdapters } from "./elemconst.adapters"

const ElementosConstructivosParamServices = {

    GET: function (idEdificio: string) {

        return useAxios({
            method: 'GET',
            url: `/elementos_constructivos/elementos/${idEdificio}/edificio/sistemas`,
            type: 'json',
            adapter: {
                method: ElementosConstructivosParamAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

}


const PlanificacionFinancieraServices = {

    GET_INFORMACION_PREVIA: function (idProyecto: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/planificacion/${idProyecto}/informacion_previa`,
            type: 'json',
            adapter: {
                method: PlanificacionFinancieraAdapters.GET_INFORMACION_PREVIA,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_RESUMEN_FINANCIERO: function (idProyecto: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/planificacion/${idProyecto}/resumen_financiero`,
            type: 'json',
            adapter: {
                method: PlanificacionFinancieraAdapters.GET_RESUMEN_FINANCIERO,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_CALENDARIO_FINANCIERO: function (idProyecto: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/planificacion/${idProyecto}/calendario_financiero`,
            type: 'json',
            adapter: {
                method: PlanificacionFinancieraAdapters.GET_CALENDARIO_FINANCIERO,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_RESUMEN_FINANCIERO_BLOB: function (idProyecto: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/resumen_financiero_pdf/${idProyecto}`,
            type: 'blob',
            adapter: {
                method: PlanificacionFinancieraAdapters.GET_RESUMEN_FINANCIERO_BLOB,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'blob', // object, array, list (with pagination)
                }
            },
        })
    },

}



export {
    ElementosConstructivosParamServices,
    PlanificacionFinancieraServices,
}