import React, { useState } from "react";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { Typography } from "@mui/material";
import { useLang } from "../../../language";
import { Proyectos } from "./Proyectos";
import { isEmpty } from "lodash";
import { Fases } from "./Fases";

export const ModalProyectosFases = ({ data, handleClose, idedificio }: any) => {

    const Lang = useLang()

    const [proyectoSelected, setproyectoSelected] = useState<any>({});


    return (
        <ModalAdvanced data={data} handleClose={handleClose} size='675'>

            <ModalAdvanced.Head>

                <Typography variant="h6" component="h2">
                    {isEmpty(proyectoSelected) ? Lang('PERIODOS_Y_FASES') : Lang('ASOCIAR_FASES_AL_PERIODO')}
                </Typography>

            </ModalAdvanced.Head>

            <ModalAdvanced.Body className='p-0'>

                {
                    isEmpty(proyectoSelected) ?
                        <Proyectos
                            idedificio={idedificio}
                            setproyectoSelected={setproyectoSelected}
                        />
                        :
                        <Fases
                            idedificio={idedificio}
                            proyectoSelected={proyectoSelected}
                            setproyectoSelected={setproyectoSelected}
                        />
                }

            </ModalAdvanced.Body>

        </ModalAdvanced>
    )
};
