export const CondParSeccion4 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Para adquirir alguno de los productos ofrecidos en la web será requisito previo registrarse, siguiendo los pasos establecidos en la web y aceptando las condiciones generales y particulares de este contrato.
      </p>
      <p>
        Una vez registrado se podrá realizar la selección de la aplicación introduciendo los datos del edificio que vaya a ser dado de alta, posteriormente se realizará el pago del importe correspondiente al producto que se quiera adquirir. Una vez confirmado el pago se activará la aplicación correspondiente.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Per adquirir algun dels productes oferts a la web serà requisit previ registrar-se, seguint els passos establerts a la web i acceptant les condicions generals i particulars d'aquest contracte.
      </p>
      <p>
        Un cop registrat es podrà realitzar la selecció de l' aplicació introduint les dades de l' edifici que hagi de ser donat d' alta, posteriorment es realitzarà el pagament de l' import corresponent al producte que es vulgui adquirir. Un cop confirmat el pagament s' activarà l' aplicació corresponent.
      </p>
    </div>
  )
}