import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useModalData } from "../../../../hooks/useModalData";
import TableAdvanced from "../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useLang } from "../../../../language";
import { ModalProyecto } from "./ModalProyecto";

export const TableProyectos = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
     handleInputChangeSearch, idedificio, fetchSuccess, fetchParamsAgentesGETData, 
     fetchParamsDocumentosGETData, id, setproyectoSelected }: any) => {

    const Lang = useLang()
    
    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const allActions = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVO_PERIODO')}</span>,
            variant: 'contained',
            action: () => handleShowUsuario({
                action: 'CREATE',
            }),
        }
    ]

    const allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <span><FontAwesomeIcon icon={faEdit} className=''  /> {Lang('')}</span>,
            className: 'ms-2',
            size: 'small',
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('EDITAR')
            },
        },
        {
            variant: 'outlined',
            color: 'secondary',
            size: 'small',
            className: 'ms-2',
            label: <span><FontAwesomeIcon icon={faArrowRight} className='me-2' /> {Lang('FASES')}</span>,
            action: (item: any) => setproyectoSelected(item),
        }
    ]

    const columnsTable = {
        nom: {
            title: Lang('NOMBRE'),
        },
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('PERIODOS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                    classNameTable='min-w-auto'
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalProyecto 
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchSuccess={fetchSuccess}
                fetchParamsDocumentosGETData={fetchParamsDocumentosGETData}
                id={id}
                setproyectoSelected={setproyectoSelected}
            />
        </>
    )
}
