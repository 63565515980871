import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { DeficienciaFotos } from "./DeficienciaFotos/DeficienciaFotos"
import { MaestrosServices } from "../../../../../../../controllers/maestros/maestros.services"
import { TabsAdvanced } from "../../../../../../../components/TabsAdvanced/TabsAdvanced"
import { ImportarActividadServices } from "../controllers/importar-actividades.services"

export const ModalMantCorrectivo = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchMantPrevGET, fetchMantPrevGETData] = ImportarActividadServices.GET_CORRECTIVO()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
        qualificacio: '',
        observacions: '',
        descripcio: '',
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        qualificacio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        actuacio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevGETData.status)) {

            handleFormDocumento({
                ...fetchMantPrevGETData.data
            })
        }

    }, [fetchMantPrevGETData]);


    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "calificacion_deficiencia",
                    },
                ]
            })

            if (data?.id) {

                fetchMantPrevGET({
                    id: data?.id + '/edit'
                })

            }

        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('ACTUACION')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchMantPrevGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>

                                        <TabsAdvanced>
                                            <TabsAdvanced.Head
                                                // centered={true}
                                                values={[
                                                    {
                                                        id: 'actuacion',
                                                        name: Lang('ACTUACION'),
                                                    },
                                                    {
                                                        id: 'descripcion',
                                                        name: Lang('IMAGENES'),
                                                    },
                                                ]}
                                            />
                                            <TabsAdvanced.Body>

                                                <section className="pt-3">

                                                    <Grid container spacing={3} rowSpacing={3}>

                                                        <Grid item xs={12}>
                                                            <InputTextarea
                                                                label={Lang('ACTUACION')}
                                                                name='actuacio'
                                                                value={formDocumento.actuacio}
                                                                onChange={handleInputDocumento}
                                                                readOnly={true}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography variant='body1' className='mb-1 fw-semibold' component="h5">
                                                                {Lang('DEFICIENCIA')}:
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='pt-0'>
                                                            <InputTextarea
                                                                label={Lang('DESCRIPCION_CORTA') + ': *'}
                                                                name='descripcio_curta'
                                                                value={formDocumento.descripcio_curta}
                                                                onChange={handleInputDocumento}
                                                                readOnly={true}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <InputTextarea
                                                                label={Lang('DESCRIPCION') + ': *'}
                                                                name='descripcio'
                                                                value={formDocumento.descripcio}
                                                                onChange={handleInputDocumento}
                                                                readOnly={true}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <InputTextarea
                                                                label={Lang('OBSERVACIONES')}
                                                                name='observacions'
                                                                value={formDocumento.observacions}
                                                                onChange={handleInputDocumento}
                                                                readOnly={true}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6} lg={4}>
                                                            <InputSelect
                                                                label={Lang('CALIFICACION')}
                                                                name='qualificacio'
                                                                value={formDocumento.qualificacio}
                                                                values={fetchParamsPublicGETData?.data?.['calificacion_deficiencia'] || []}
                                                                onChange={handleInputDocumento}
                                                                readOnly={true}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </section>
                                                <section className="pt-3">

                                                    <Grid container spacing={3} rowSpacing={3}>

                                                        <Grid item xs={12}>
                                                            <DeficienciaFotos
                                                                iddeficiencia={formDocumento.id}
                                                            />
                                                        </Grid>

                                                    </Grid>
                                                </section>
                                            </TabsAdvanced.Body>

                                        </TabsAdvanced>

                                    </Grid>



                                </Grid>

                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="outlined" type="button" className="mt-3 ms-auto"
                        handleSubmit={handleClose}
                        value={Lang('SALIR')}
                    />
                </ModalAdvanced.Footer>

            </ModalAdvanced>

        </>
    )
}