import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { logOut } from '../store/slices/auth';

interface UseAxios {
    method: string,
    url: string,
    type: any,
    adapter: any,
}

interface IState {
    data: any,
    loading: any,
    error: any,
    status: any
}


interface IReturn {
    data: any,
    loading: any,
    error: any,
    status: any
}

export const useAxios = ({ method, url, type, adapter }: UseAxios) => {

    // const { user: { token, lang }, dispatch } = useContext(AuthContext);

    const { auth } = useSelector((state: RootState) => state.auth)

    const { language } = useSelector((state: RootState) => state.language)

    const token = auth.token
    const dispatch = useDispatch()

    const isMounted = useRef(true)

    const [state, setState] = useState<IState>({ data: null, loading: null, error: null, status: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const existenErroresAuth = (data: any) => {
        if (data && data.status === 'Error' &&
            (data.message === 'El token ha expirat' ||
                data.message === `No s'ha trobat el token` ||
                data.message === `La sessió no existeix` ||
                data.message === `El token és invàlid` ||
                data.message === `Unauthenticated.` ||
                data.message === `L'usuari ja no existeix` ||
                data.message === `No autoritzat`)) {
                    
            if (process.env.REACT_APP_WITH_LOGIN === 'true') {

                // navigate('/login');
                dispatch(logOut())

            } else {

                sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', '{}')
                window.location.replace(process.env.REACT_APP_PUBLIC_URL_PORTAL + '/login.php');
            }
        }
        return false
    }

    const existenErroresAuthCode = (status: any) => {

        // No autorizado
        if (status === 401 && token) {

            sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', '{}')
            window.location.replace((process.env.REACT_APP_SUBDOMAIN || '/') + '?msg=sesion_caducada');
        }

    }

    const handleFetch = (values?: any) => {

        setState({ data: null, loading: true, error: null, status: null })

        axios.request<IState>({
            method,
            url: values?.id ? url + '/' + values.id : url,
            headers: {
                Authorization: token ? 'Bearer ' + token : values?.tokenParam ? 'Bearer ' + values?.tokenParam : '',
                'Accept-Language': String(language).toLowerCase()
            },
            responseType: type,
            data: values?.body ? adapter['method']['send']?.['adapter'] ? adapter['method']['send']?.['adapter'](values.body, adapter['types']['send'])['data'] : values.body : {},
            params: values?.params
        })
            .then((res) => {
                
                if (!existenErroresAuth(res) && isMounted.current) {
                    const dataAdapted = adapter['method']['receive']?.['adapter'] ? adapter['method']['receive']?.['adapter'](res, adapter['types']['receive']) : res

                    setState({
                        loading: false,
                        error: null,
                        data: dataAdapted.data,
                        status: res.status,
                    });
                }
            })
            .catch(async (err) => {
                
                let data = err?.response?.data

                if (type === 'blob' && data instanceof Blob) {
                    data = JSON.parse((await data?.text() || ''))
                }

                existenErroresAuth(data)
                existenErroresAuthCode(err?.response?.status)

                setState({
                    data: null,
                    loading: false,
                    error: data,
                    status: err?.response?.status
                })
            })
            .finally(() => {
                // setloading(false);
            });

    }

    const handleResetData = () => {
        setState({ data: null, loading: null, error: null, status: null });
    }

    return [handleFetch, state, handleResetData] as const;
}