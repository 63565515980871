import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isFunction } from 'lodash'

export interface AuthState {
  auth: any
}

const initialState: AuthState = {
  auth: sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE || '') ? JSON.parse(sessionStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE || '') || '') : {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logOut: (state) => {
      state.auth = {}
      sessionStorage.removeItem(process.env.REACT_APP_MAIN_VARIABLE || '')
      sessionStorage.removeItem(process.env.REACT_APP_MAIN_VARIABLE + '_IDENTIDADPROPIA_CURRENT')
    },
    logIn: (state, action: PayloadAction<any>) => {
      state.auth = action.payload
      sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE || '', JSON.stringify(action.payload || '{}') )
      
      if (isFunction(action.payload.navigate)) {
        action.payload?.navigate()
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { logOut, logIn } = authSlice.actions

// export default authSlice.reducer