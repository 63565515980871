import { useLang } from "../../language"

const IdiomasStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'es': Lang('CASTELLANO'),
            'ca': Lang('CATALAN'),
        }
    }
    
}

const RolesAdminStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'Administrador': Lang('ADMINISTRADOR'),
            'Cateb': Lang('CATEB'),
            'Ares': Lang('ARES'),
        }
    }
}

const MetricasTiposHTMLStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'text': Lang('TEXTO'),
            'numeric': Lang('NUMERICO'),
            'textarea': 'Textarea',
        }
    }
}

const CosteEconomicoMejoraStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'val_menor_500': `${Lang('COSTO')} < 500 €`,
            'val_500_1000': `500 € ≤ ${Lang('COSTO')} ≤ 1.000 €`,
            'val_1000_10000': `1.000 € ≤ ${Lang('COSTO')} ≤ 10.000 €`,
            'val_10000_25000': `10.000 € ≤ ${Lang('COSTO')} ≤ 25.000 €`,
            'val_25000_50000': `25.000 € ≤ ${Lang('COSTO')} ≤ 50.000 €`,
            'val_50000_100000': `50.000 € ≤ ${Lang('COSTO')} ≤ 100.000 €`,
            'val_mayor_100000': `${Lang('COSTO')} > 100.000 €`,
        }
    }
}

const DificultadMejoraStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'nula': Lang('DIFICULTAD_EJECUCION_NULA'),
            'baja': Lang('DIFICULTAD_EJECUCION_BAJA'),
            'media': Lang('DIFICULTAD_EJECUCION_MEDIA'),
            'elevada': Lang('DIFICULTAD_EJECUCION_ELEVADA'),
        }
    }
}

const MesesStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            '1': Lang('ENERO'),
            '2': Lang('FEBRERO'),
            '3': Lang('MARZO'),
            '4': Lang('ABRIL'),
            '5': Lang('MAYO'),
            '6': Lang('JUNIO'),
            '7': Lang('JULIO'),
            '8': Lang('AGOSTO'),
            '9': Lang('SEPTIEMBRE'),
            '10': Lang('OCTUBRE'),
            '11': Lang('NOVIEMBRE'),
            '12': Lang('DICIEMBRE'),
        }
    }
}


const CalificacionesDeficienciasStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'lleu': Lang('LEVE'),
            'important': Lang('IMPORTANTE'),
            'greu': Lang('GRAVE'),
            'molt_greu': Lang('MUY_GRAVE'),
        }
    }
}

const TiposIntervencionStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'manteniment': Lang('MANTENIMIENTO'),
            'rehabilitacio': Lang('REHABILITACION'),
            'millora': Lang('MEJORA'),
            // 'incidencia': Lang('INCIDENCIA'),
        }
    }
}

const TiposPlanFinancieraStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'ingresos': Lang('INGRESOS'),
            'gastos': Lang('GASTOS'),
            'tesoreria': Lang('TESORERIA'),
            // 'incidencia': Lang('INCIDENCIA'),
        }
    }
}


const TiposFirmantesStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'signant_llibre': Lang('TECNICO_REDACTOR'),
            'signant_promotor': Lang('PROMOTOR'),
        }
    }
}

const PeriodicidadUnidadStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'months': Lang('MES_ES'),
            'years': Lang('ANIO_S'),
        }
    }
}


const BooleanTextosStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'true': Lang('SI'),
            'false': Lang('NO'),
        }
    }
}


const CualificacionCAStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'apte': Lang('APTO'),
            'apte_provisionalment': Lang('APTO_PROVISIONALMENTE'),
            'apte_cautelarment': Lang('APTO_CAUTELARMENTE'),
        }
    }
}

const ComunitatStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'pais-basc': Lang('PAIS_VASCO'),
            'navarra': Lang('NAVARRA'),
            'territorio_nacional': Lang('TERRITORIO_NACIONAL'),
        }
    }
}

const TipoIngresosStaticServices = {
    
    GET: function () {

        const Lang = useLang()

        return {
            'fons_propis_actual': Lang('FONDOS_ECONOMICOS'),
            'subvencions_atorgades': Lang('SUBVENCIONES'),
            'import_financar': Lang('FINANCIACION_BANCARIA'),
        }
    }
}


export {
    IdiomasStaticServices,
    RolesAdminStaticServices,
    MetricasTiposHTMLStaticServices,
    CosteEconomicoMejoraStaticServices,
    DificultadMejoraStaticServices,
    MesesStaticServices,
    CalificacionesDeficienciasStaticServices,
    TiposIntervencionStaticServices,
    TiposPlanFinancieraStaticServices,
    TiposFirmantesStaticServices,
    PeriodicidadUnidadStaticServices,
    BooleanTextosStaticServices,
    CualificacionCAStaticServices,
    ComunitatStaticServices,
    TipoIngresosStaticServices,
}