import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLang } from "../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileExcel, faPlus, faSliders } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../hooks/useModalData";
import { ModalActividad } from "./ModalActividad";
import { convertParamToObject, getDataFile, isStatusSuccess } from "../../../../../../helpers/helpers";
import { ModalEscogerNuevaActividad } from "./ModalEscogerNuevaActividad";

export const TableActividades = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, readOnly, idedificio, fetchParamsMisSistemasGETData, fetchParamsAgentesGET,
    fetchParamsAgentesGETData, tiposIntervencionStaticServices, showFilter, setshowFilter, navLinksFases,
    fetchSuccess, fetchElementosConstructivosData, idCurrentFase, fetchParamsPublicGETData,
    handleSubmitDownloadExcel, fetchActividadesBlobGETData, resetfetchActividadesBlobGETData }: any) => {

    const Lang = useLang()

    const navigate = useNavigate();

    // --------------------- Modal Nuevo usuario ---------------------

    const [dataModal_NuevaActividad, handleShowModal_NuevaActividad, handleCloseModal_NuevaActividad] = useModalData()

    const [dataModal_EscogerNuevaActividad, handleShowModal_EscogerNuevaActividad,
        handleCloseModal_EscogerNuevaActividad
    ] = useModalData()


    let allActions: any = [

        {
            label: <span><FontAwesomeIcon icon={faSliders} /></span>,
            variant: 'outlined',
            color: 'secondary',
            action: () => setshowFilter(!showFilter),
        }
    ]

    if (!readOnly) {
        allActions.unshift(
            {
                label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_ACTIVIDAD')}</span>,
                variant: 'contained',
                action: () => handleShowModal_EscogerNuevaActividad({
                    action: 'CREATE',
                }),
            }
        )

        allActions.unshift(
            {
                label: <span><FontAwesomeIcon icon={faFileExcel} className='me-2' />{Lang('DESCARGAR_EXCEL')}</span>,
                variant: 'contained',
                action: () => handleSubmitDownloadExcel(),
                withoutID: true,
                tooltip: {
                    placement: 'top',
                    text: Lang('PARRAFO_USARAN_ELEMENTOS_SELECCIONADOS')
                },
                color: 'success'
            }
        )
    }

    let allActionsPerLine: any = []

    allActionsPerLine.push(
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (item: any) => navigate(`../actividad/${item.id}`),
        }
    )

    const columnsTable = {

        titol: {
            title: Lang('TITULO'),
            order: true,
        },
        descripcio: {
            title: Lang('DESCRIPCION'),
        },
        sistema_id: {
            title: Lang('SISTEMA'),
            adapter: convertParamToObject(fetchElementosConstructivosData?.data)
        },
        data_inici_calculada: {
            title: Lang('FECHA_INICIO'),
            className: 'text-center',
            classNameTitle: 'text-center',
        },
        durada_mesos: {
            title: Lang('DURACION_MESES'),
            className: 'text-center',
            classNameTitle: 'text-center',
        },

        tipus_intervencio: {
            title: Lang('TIPO_ACTIVIDAD'),
            adapter: tiposIntervencionStaticServices
        },
        estado_actividad: {
            title: Lang('ESTADO'),
            adapter: convertParamToObject(fetchParamsPublicGETData?.data?.['estado_actividad'] || [])
        }
    }

    // Acciones

    const classNameFunctionRow = (item: any) => {

        return item.estado_actividad === 'finalizada' ? 'tr-green' : ''
    }


    useEffect(() => {

        if (isStatusSuccess(fetchActividadesBlobGETData.status)) {

            // handleCloseModal_ConfirmGenerarVistaPrev()

            // handleShowModal_ShowBlobPDF()

            getDataFile(fetchActividadesBlobGETData, resetfetchActividadesBlobGETData, 'Actividades', '.xlsx')
        }

    }, [fetchActividadesBlobGETData]);

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-3'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('ACTIVIDADES')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                    classNameFunctionRow={classNameFunctionRow}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalActividad
                data={dataModal_NuevaActividad}
                handleClose={handleCloseModal_NuevaActividad}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                navLinksFases={navLinksFases}
                fetchSuccess={fetchSuccess}
                fetchElementosConstructivosData={fetchElementosConstructivosData}
                // idCurrentFase={idCurrentFase}
                tiposIntervencionStaticServices={tiposIntervencionStaticServices}
            />

            <ModalEscogerNuevaActividad
                data={dataModal_EscogerNuevaActividad}
                handleClose={handleCloseModal_EscogerNuevaActividad}
                handleShowModal_NuevaActividad={handleShowModal_NuevaActividad}
                idCurrentFase={idCurrentFase}

            />
        </>
    )
}
