export const AvisoLegalSeccion11 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Introducción: Con la información que le proporcionaremos a continuación el  COL.LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA (en adelante el  COL.LEGI), queremos usted conozca la política de privacidad aplicada respeto a sus datos personales.
      </p>
      <p>
        Cumplimiento de la normativa de protección de datos personales. El Col·legi cumple todos los requisitos establecidos por la normativa vigente en materia de protección de datos personales, i todos los datos bajo nuestra responsabilidad son tratados de conformidad con las exigencias legales.
      </p>
      <p>
        Medidas de seguridad: Se aplicarán las correspondientes medidas de seguridad, tanto técnicas como organizativas, que garanticen la confidencialidad de los datos.
      </p>
      <p>
        Responsable: COL·LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA.
      </p>
      <p>
        COL·LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA
      </p>
      <p>
        CIF	Q0875009C
      </p>
      <p>
        Dirección:	C. Bon Pastor, 5. 08021 Barcelona. Espanya
      </p>
      <p>
        Contacto:	informacio@cateb.cat o tel. 93 240 20 60
      </p>
      <p>
        Delegado de Protección de Dates: Institut Qualitas d’Assessoria BCN SL
      </p>
      <p>
        Contacto del Delegado de Protección de Datos: dpd@cateb.cat
      </p>
      <p>
        Finalidad: gestión de la relación entre el responsable y el interesado en cuanto a la gestión de e información referente a los servicios ofrecidos en esta web.
      </p>
      <p>
        Se comunica que ser realizarán análisis de perfiles y de uso a partir del historial de servicios y actividades del interesado con la finalidad de mejorar y dotar de valor añadido los servicios y actividades a disposición de los usuarios.
      </p>
      <p>
        Legitimación: consentimiento del interesado.
      </p>
      <p>
        Destinatarios: sus datos solo podrán ser comunicados a terceros para el cumplimiento de las obligaciones legales y contractuales que puedan existir o bien con el consentimiento expreso del interesado.
      </p>
      <p>
        Derechos de los afectados: En caso necesario, puede ejercer sus derechos de acceso, rectificación, supresión y limitación, portabilidad y oposición al trato de los datos, dirigiéndose por escrito o por correo electrónico y acompañando copia del documento oficial que lo identifique al  COL.LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA,  con domicilio en la calle  Bon Pastor, nº 5, Cp 08021, Barcelona, o enviando  un e-mail a informacio@cateb.cat, indicando en el sobre en el asunto del mensaje: Aviso legal y de privacidad. También tiene derecho a formular un reclamación ante la autoridad de control. Usted puede revocar su consentimiento en cualquier momento.
      </p>
      <p>
        Datos de terceros: si usted nos facilita datos personales de un tercero, tendrá, con carácter previo, que haberlo informado y solicitado su consentimiento sobre los extremos aquí expuestos.
      </p>
      <p>
        Los datos se conservarán durante todo el periodo de tiempo en que se mantenga su relación con nosotros y posteriormente durante todo el periodo de prescripción de las acciones de responsabilidad.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Introducció: Amb la informació que li proporcionarem a continuació el COL. LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA (en endavant el COL. LEGI), volem vostè la política de privacitat aplicada respecte a les seves dades personals.
      </p>
      <p>
        Compliment de la normativa de protecció de dades personals. El Col·legi compleix tots els requisits establerts per la normativa vigent en matèria de protecció de dades personals, i totes les dades sota la nostra responsabilitat són tractades de conformitat amb les exigències legals.
      </p>
      <p>
        Mesures de seguretat: S' aplicaran les corresponents mesures de seguretat, tant tècniques com organitzatives, que garanteixin la confidencialitat de les dades.
      </p>
      <p>
        Responsable: COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA.
      </p>
      <p>
        COL· LEGI DE L’ARQUITECTURA TÈCNICA DE BARCELONA
      </p>
      <p>
        CIF	Q0875009C
      </p>
      <p>
        Adreça:	C. Bon Pastor, 5. 08021 Barcelona. Espanya
      </p>
      <p>
        Contacte	Tel informacio@cateb.cat. 93 240 20,60
      </p>
      <p>
        Delegado de Protección de Dates: Institut Qualitas d’Assessoria BCN SL
      </p>
      <p>
        Contacte del Delegat de Protecció de Dades: dpd@cateb.cat
      </p>
      <p>
        Finalitat: gestió de la relació entre el responsable i l' interessat pel que fa a la gestió de la informació referent als serveis oferts en aquest web.
      </p>
      <p>
        Es comunica que es realitzaran anàlisis de perfils i d' ús a partir de l' historial de serveis i activitats de l' interessat amb la finalitat de millorar i dotar de valor afegit els serveis i activitats a disposició dels usuaris.
      </p>
      <p>
        Legitimació: consentiment de l' interessat.
      </p>
      <p>
        Destinataris: les seves dades només podran ser comunicades a tercers per al compliment de les obligacions legals i contractuals que puguin existir o bé amb el consentiment exprés de l' interessat.
      </p>
      <p>
        Drets dels afectats: En cas necessari, pot exercir els seus drets d' accés, rectificació, supressió i limitació, portabilitat i oposició al tracte de les dades, dirigint-se per escrit o per correu electrònic i acompanyant còpia del document oficial que l' identifiqui al COL. LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA, amb domicili al carrer Bon Pastor, nº 5, Cp 08021, Barcelona, o enviant un e-mail a informacio@cateb.cat, indicant en el sobre en l'assumpte del missatge: Avís legal i de privacitat. També té dret a formular una reclamació davant l' autoritat de control. Vostè pot revocar el seu consentiment en qualsevol moment.
      </p>
      <p>
        Dades de tercers: si vostè ens facilita dades personals d' un tercer, tindrà, amb caràcter previ, que haver-lo informat i demanat el seu consentiment sobre els extrems aquí exposats.
      </p>
      <p>
        Les dades es conservaran durant tot el període de temps en què es mantingui la seva relació amb nosaltres i posteriorment durant tot el període de prescripció de les accions de responsabilitat.
      </p>
    </div>
  )
}