import './NavBottom.scss'
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";

export const NavBottom = ({ navLinks, className = '', title }: any) => {

    return (
        <>
            <nav className={`NavBottom ${className}`}>

                {
                    title &&
                    <Typography variant='subtitle2' className='mb-0 fw-bold'>
                        {title}
                    </Typography>
                }

                <ul className='list-unstyled m-0 p-0 text-nowrap'>
                    {
                        navLinks?.map((item: any) => {
                            return (
                                <NavLink
                                    to={`./${item.link}`}
                                    className={({ isActive }) => isActive ? "NavBottom__li--active" : ""}
                                    key={item.link}
                                >
                                    {item.icon} {item.title}
                                </NavLink>
                            )
                        })
                    }
                </ul>
            </nav>
        </>
    );
};
