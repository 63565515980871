import { Grid } from "@mui/material";
import React from "react";

export const SeccionResumenDescarga = ({ lang }: any) => {
    return (
        <>
            {
                lang === 'ES' && <SeccionResumenDescargaES />
            }
            {
                lang === 'CA' && <SeccionResumenDescargaCA />
            }
        </>
    )
};

const SeccionResumenDescargaES = () => {

    return (
        <>
            <Grid item xs={12}>

                <h4>
                    Reducción estimada del consumo de energía final por vector energético total y por servicio:
                </h4>

            </Grid>

            <Grid item xs={12} lg={5}>
                <p className="mt-0">
                    Estos datos se pueden encontrar al archivo xml del Certificado de Eficiencia Energética (etiqueta EnergiaFinalVectores).
                </p>

                <p>
                    Se pueden visualizar con el "Visor CTE XML", programa alojado en el web del Código Técnico de la Edificación (CTE).
                </p>

                <p>
                    Para hacerlo, hay que subir el archivo xml del Certificado de Eficiencia Energética del edificio existente al siguiente enlace: <a href="https://visorxml.codigotecnico.org/certificate" className="link-primary text-decoration-underline" target="_blank" rel="noreferrer">https://visorxml.codigotecnico.org/certificate</a>.
                </p>

                <p>
                    A continuación, hay que pulsar el botón "Informe complementario" y "Obtener PDF".
                </p>

                <p>
                    Seguidamente, hay que subir el archivo xml del Certificado de Eficiencia Energética del edificio existente con las medidas de mejora, e igualmente se obtendrá el "Informe complementario" en formato pdf.
                </p>

                <p>
                    Así, la reducción estimada del consumo de energía final por vector energético total y por servicio queda aportada por comparación de los informes mencionados.
                </p>

            </Grid>
        </>
    )
}


const SeccionResumenDescargaCA = () => {

    return (
        <>
            <Grid item xs={12}>

                <h4>
                    Reducció estimada del consum d'energia final per vector energètic total i per servei:
                </h4>

            </Grid>

            <Grid item xs={12} lg={5}>
                <p className="mt-0">
                    Aquestes dades es poden trobar a l'arxiu xml del Certificat d'Eficiència Energètica (etiqueta EnergiaFinalVectors).
                </p>

                <p>
                    Es poden visualitzar amb el "Visor CTE XML", programa allotjat al web del Codi Tècnic de l'Edificació (CTE).
                </p>

                <p>
                    Per fer-ho, cal pujar l'arxiu xml del Certificat d'Eficiència Energètica de l'edifici existent al següent enllaç: <a href="https://visorxml.codigotecnico.org/certificate" className="link-primary text-decoration-underline" target="_blank" rel="noreferrer">https://visorxml.codigotecnico.org/certificate</a>.
                </p>

                <p>
                    A continuació, cal prémer el botó "Informe complementari" i "Obtenir PDF".
                </p>

                <p>
                    Seguidament, cal pujar l'arxiu xml del Certificat d'Eficiència Energètica de l'edifici existent amb les mesures de millora, i igualment s'obtindrà l'"Informe complementari" en format pdf.
                </p>

                <p>
                    Així, la reducció estimada del consum d'energia final per vector energètic total i per servei queda aportada per comparació dels informes esmentats.
                </p>

            </Grid>
        </>
    )
}