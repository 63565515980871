import ModalAdvanced from '../../components/ModalAdvanced/ModalAdvanced'
import { Grid, Typography } from '@mui/material'
import { useLang } from '../../language'
import { useNavigate } from 'react-router'
import { ButtonAdvanced } from '../../components/ButtonAdvanced/ButtonAdvanced'
import { ModalTecnicos } from './ModalTecnicos'
import { useModalData } from '../../hooks/useModalData'

export const ModalTarifa2 = ({ data, handleClose, handleShowModal_Registro = () => null  }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [dataModal_TecnicosEspecialistas, handleShowModal_TecnicosEspecialistas, handleCloseModal_TecnicosEspecialistas] = useModalData()

    return (
        <>
            {/* <ModalAdvanced data={data} handleClose={handleClose} size='md'> */}

            {/* <ModalAdvanced.Head> */}
            {/* <TagTopProducto title={Lang('PROPIETARIOS')} /> */}

            <Typography variant="h5" component="h2" className='fw-bold text-center mb-3 color-primary'>
                {Lang('LED_GESTION')}
            </Typography>
            {/* </ModalAdvanced.Head> */}

            <ModalAdvanced.Body>

                <Typography variant="subtitle1" component="h5" className='text-center m-0'>
                    {Lang('HERRAMIENTA_DIGITAL_GESTION')}.
                </Typography>

                <Grid container justifyContent='center' spacing={0} className='py-4'>

                    <Grid item md={12} className=''>

                        <div className="text-center">

                            <Typography variant="h6" component="h5" className='text-center fw-normal my-2'>
                                {Lang('TARIFA_GENERAL')}
                            </Typography>

                            <Typography className='text-center fw-bold' variant="h5">
                                6€ / {Lang('VIVIENDA')} / {Lang('ANIO')}
                            </Typography>
                            <small>
                                ({Lang('SIN_IVA')})
                            </small>
                        </div>

                    </Grid>

                    {/* <Grid item md={6} className=' text-center'>

                            <Typography variant="h6" component="h5" className='text-center fw-normal my-2'>
                                {Lang('TARIFA_CONVENIO')}
                            </Typography>

                            <Typography className='text-center fw-bold' variant="h5">
                                100€ / {Lang('LIBRO')} / {Lang('ANIO')}
                            </Typography>
                            <small>
                                ({Lang('SIN_IVA')})
                            </small>

                        </Grid> */}

                </Grid>

                <div>
                    <small>
                        <strong>{Lang('CONDICIONES')}:</strong>
                    </small>
                </div>
                <small>
                    <ul className='ps-4'>
                        <li>{Lang('P_ALTA_PRODUCTO_REPRESENTANTE_EDIFICIO')}</li>
                        <li className='mt-2'>{Lang('P_ENTIDADES_LOCAL_VIVIENDAS')}</li>
                    </ul>
                </small>

                <p className='my-1'>
                    <small>{Lang('P_CONSULTA_TECNICOS')} <span onClick={() =>
                        handleShowModal_TecnicosEspecialistas({})
                    } className='link-primary text-decoration-underline'>{Lang('EN_ESTE_ENLACE')}</span> </small>
                </p>

            </ModalAdvanced.Body>

            <ModalAdvanced.Footer className='mt-auto ms-auto flex-basis-0'>
                <div className="d-flex justify-content-end">
                    {/* <ButtonAdvanced
                            variant="outlined" type="button" className="" color='secondary'
                            handleSubmit={handleClose}
                            // handleSubmit={handleSubmitRestablecerContrasenia}
                            // loadings={[fetchAuthPOSTData.loading]}
                            value={Lang('SALIR')}
                        /> */}
                    <ButtonAdvanced
                        variant="contained" type="button" className=""
                        // handleSubmit={handleClose}
                        handleSubmit={handleShowModal_Registro}
                        // handleSubmit={handleSubmitRestablecerContrasenia}
                        // loadings={[fetchAuthPOSTData.loading]}
                        value={Lang('REGISTRARSE_Y_COMPRAR')}
                    />
                </div>
            </ModalAdvanced.Footer>

            {/* </ModalAdvanced> */}

            <ModalTecnicos
                data={dataModal_TecnicosEspecialistas}
                handleClose={handleCloseModal_TecnicosEspecialistas}
            />

        </>
    )
}