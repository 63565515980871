import { useNavigate } from "react-router";
import { useLang } from "../../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { ModalListGremios } from "../../../../../../../../components/ModalList/ModalListGremios";
import { useState } from "react";
import { ModalGremioActividad } from "./ModalGremioActividad";

export const TableGremiosActividad = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, readOnly, idEdificio, fetchPropiedadAgentesGET, fetchParamsAgentesGET,
    fetchParamsAgentesGETData, idCurrentProject, showFilter, fetchSuccess, idActividad }: any) => {

    const Lang = useLang()

    const navigate = useNavigate();


    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()


    let allActions: any = []

    if (!readOnly) {

        allActions.push(
            {
                label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('AGREGAR_GREMIO')}</span>,
                variant: 'contained',
                action: () => handleShowUsuario({
                    action: 'CREATE',
                }),
            }
        )
    }

    let allActionsPerLine: any = []

    if (!readOnly) {
        
        allActionsPerLine.push(
            {
                variant: 'outlined',
                label: <FontAwesomeIcon icon={faEdit} className='' />,
                action: (item: any) => {
                    handleShowUsuario({
                        ...item,
                        action: 'EDIT',
                    })
                },
                tooltip: {
                    text: Lang('EDITAR')
                },
            }
        )
    }

    const columnsTable = {

        nom_gremi: {
            title: Lang('NOMBRE') + ' / ' + Lang('RAZON_SOCIAL'),
        },
        nif: {
            title: 'NIF / CIF',
        },
        tipus_gremi: {
            title: Lang('TIPO_GREMIO'),
        },
        direccio: {
            title: Lang('DIRECCION'),
        },
        email: {
            title: Lang('EMAIL'),
        },
        telefono: {
            title: Lang('TELEFONO'),
        },
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-0'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('GREMIOS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>


            <ModalGremioActividad
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idEdificio={idEdificio}
                formValuesSearch={formValuesSearch}
                fetchSuccess={fetchSuccess}
                idCurrentProject={idCurrentProject}
                idActividad={idActividad}
            />

        </>
    )
}
