export const CondParSeccion5 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Aplicación LED libro del edificio:
      </p>
      <p>
        (1) Tarjeta de crédito o débito. Como modalidad de pago se ofrece pago mediante tarjeta de crédito o débito. Para efectuar el pago el cliente será dirigido a través de una conexión a la pasarela de pago donde deberá introducir los datos para realizar la compra.
      </p>
      <p>
        (2) Cargo en cuenta. Si usted es colegiado del CATEB deberá autorizar en el momento de la compra el cargo del importe del producto en la cuenta donde tiene domiciliados los recibos del CATEB.
      </p>
      <p>
        Aplicación  LED gestión del mantenimiento:
      </p>
      <p>
        (1) Cargo en cuenta.  Si usted es colegiado del CATEB deberá autorizar en el momento de la compra el cargo del importe del producto en la cuenta donde tiene domiciliados los recibos del CATEB.
      </p>
      <p>
        (2) Usted deberá autorizar en el momento de la compra el cargo del importe del producto en la cuenta siguiendo las indicaciones de la web y cumplimentando los formularios que sean necesarios (formulario SEPA).
      </p>
    </div>

  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Aplicació LED llibre de l' edifici:
      </p>
      <p>
        (1) Targeta de crèdit o dèbit. Com a modalitat de pagament s' ofereix pagament mitjançant targeta de crèdit o dèbit. Per efectuar el pagament el client serà dirigit a través d'una connexió a la passarel·la de pagament on haurà d'introduir les dades per realitzar la compra.
      </p>
      <p>
        (2) Càrrec en compte. Si vostè és col·legiat del CATEB haurà d'autoritzar en el moment de la compra el càrrec de l'import del producte en el compte on té domiciliats els rebuts del CATEB.
      </p>
      <p>
        Aplicació LED gestió del manteniment:
      </p>
      <p>
        (1)	Càrrec en compte.  Si vostè és col·legiat del CATEB haurà d'autoritzar en el moment de la compra el càrrec de l'import del producte en el compte on té domiciliats els rebuts del CATEB.
      </p>
      <p>
        (2) Vostè haurà d'autoritzar en el moment de la compra el càrrec de l'import del producte en el compte seguint les indicacions del web i emplenant els formularis que siguin necessaris (formulari SEPA).
      </p>
    </div>
  )
}