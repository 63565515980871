import { useAxios } from "../../../../../../../hooks/useAxios"
import { GremioAdapters, GremiosAdapters } from "./gremios.adapters"

const GremiosServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/gremis',
            type: 'json',
            adapter: {
                method: GremiosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_BY_USER: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/get-gremis',
            type: 'json',
            adapter: {
                method: GremiosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const GremioServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/gremio`,
            type: 'json',
            adapter: {
                method: GremioAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio_proyectos/gremio',
            type: 'json',
            adapter: {
                method: GremioAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/gremio',
            type: 'json',
            adapter: {
                method: GremioAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/gremio',
            type: 'json',
            adapter: {
                method: GremioAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    GremiosServices,
    GremioServices
}