import { Box, Tab, Tabs, Typography } from "@mui/material";
import { isArray } from "lodash";
import React, { useState } from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

interface ITabsAdvanced {
    children?: any;
    classNameTabsAdvanced?: string,
}


export const TabsAdvanced = ({ children, classNameTabsAdvanced = '' }: ITabsAdvanced) => {

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function addPropsToReactElement(element: any, props: any) {
        if (React.isValidElement(element)) {
            return React.cloneElement(element, props)
        }
        return element
    }

    return (
        <section className={`TabsAdvanced ${classNameTabsAdvanced}`}>
            <>
                {
                    children?.map((childElement: any, indexchildElement: any) =>
                        addPropsToReactElement(childElement, {
                            value, setValue, handleChange,
                            'key': indexchildElement
                        })
                    )
                }
            </>
        </section>
    )
};

const TabsAdvancedHead = ({ values = [], value, setValue, handleChange, centered = false }: any) => {

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered={centered} >
                    {
                        values?.map((item: any) => {

                            const obj: any = {}

                            if (item.disabled) obj.disabled = true

                            return (
                                <Tab key={item.id} label={item.name} id={`tab-${item.id}`} {...obj} />
                            )
                        })
                    }
                </Tabs>
            </Box>
        </>
    )
}

const TabsAdvancedBody = ({ children, value, setValue }: any) => {

    let childrenTemp = []

    if ( isArray(children) ) {

        childrenTemp = children

    } else {
        
        childrenTemp.push(children)
    }
    
    function addPropsToReactElement(element: any, props: any) {
        if (React.isValidElement(element)) {
            return React.cloneElement(element, props)
        }
        return element
    }

    return (
        <>
            {
                childrenTemp?.map((childElement: any, indexchildElement: any) =>
                    <CustomTabPanel value={value} index={indexchildElement} key={indexchildElement}>
                        {
                            addPropsToReactElement(childElement, {
                                value, setValue,
                                'key': indexchildElement
                            })
                        }
                    </CustomTabPanel>
                )
            }

        </>
    )
}

TabsAdvanced.Head = TabsAdvancedHead
TabsAdvanced.Body = TabsAdvancedBody
