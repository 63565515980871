import { useNavigate } from "react-router";
import { useLang } from "../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faFileImport, faPlus, faSliders } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { ModalImportarActividad } from "../ModalImportarActividad";
import { ModalMantCorrectivo } from "./ModalMantCorrectivo";
import { CalificacionesDeficienciasStaticServices } from "../../../../../../../controllers/statics/statics.services";
import { convertParamToObject } from "../../../../../../../helpers/helpers";

export const TableImportarCorrectivo = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, fetchFacturasGET, idedificio, fetchParamsMisSistemasGETData, fetchParamsAgentesGET,
    fetchParamsAgentesGETData, tiposIntervencionStaticServices, showFilter, setshowFilter, navLinksFases,
    fetchSuccess, fetchElementosConstructivosData, idCurrentFase }: any) => {

    const Lang = useLang()

    const navigate = useNavigate();

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // --------------------- Modal Nuevo usuario ---------------------

    const [dataModal_NuevaActividad, handleShowModal_NuevaActividad, handleCloseModal_NuevaActividad] = useModalData()

    const [dataModal_EscogerNuevaActividad, handleShowModal_EscogerNuevaActividad,
        handleCloseModal_EscogerNuevaActividad
    ] = useModalData()


    let allActions: any = [
        {
            label: <span><FontAwesomeIcon icon={faSliders} /></span>,
            variant: 'outlined',
            color: 'secondary',
            action: () => setshowFilter(!showFilter),
        }
    ]

    let allActionsPerLine: any = []

    allActionsPerLine.push(
        {
            variant: 'outlined',
            color: 'secondary',
            label: <FontAwesomeIcon icon={faEye} className='' />,
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('VER_DETALLE')
            },
        },
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faFileImport} className='' />,
            action: (item: any) => {
                handleShowModal_NuevaActividad({
                    ...item,
                    descripcio: item.actuacio,
                    sistema_id: item.elemento_constructivo_id,
                    tipus_intervencio: 'rehabilitacio',
                    origen_id: item.id,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('IMPORTAR')
            },
        },
    )
    
    const calificacionesDeficienciasStaticServices = CalificacionesDeficienciasStaticServices.GET()
    
    const columnsTable = {
        actuacio: {
            title: Lang('ACTUACION'),
        },
        // descripcio_curta: {
        //     title: Lang('DESCRIPCION'),
        // },
        qualificacio: {
            title: Lang('CALIFICACION'),
            adapter: calificacionesDeficienciasStaticServices
        },
        // elemento_constructivo_id: {
        //     title: Lang('SISTEMA'),
        //     adapter: convertParamToObject(fetchElementosConstructivosData?.data)
        // },
    }
    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-3'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('ACTUACIONES_DE_REHABILITACION')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalImportarActividad
                data={dataModal_NuevaActividad}
                handleClose={handleCloseModal_NuevaActividad}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                navLinksFases={navLinksFases}
                fetchSuccess={fetchSuccess}
                fetchElementosConstructivosData={fetchElementosConstructivosData}
                idCurrentFase={idCurrentFase}
            />

            <ModalMantCorrectivo
                data={showUsuario}
                handleClose={handleCloseUsuario}
            />

        </>
    )
}
