import { Pagination, Stack } from '@mui/material'
import './PaginationAdvanced.scss'

interface IPaginationAdvanced {
    total: any,
    handleFormChange: any,
    formValuesSearch: any,
    perPage: any,
}

export const PaginationAdvanced = ({ total = 0, handleFormChange, formValuesSearch, perPage = 1 }: IPaginationAdvanced) => {

    let totalPages = Math.ceil(total / perPage)

    const currentPage = Number(formValuesSearch.page)

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        
        handleFormChange({
            ...formValuesSearch,
            page: value
        });
    }

    return (
        <div className="d-flex justify-content-center py-0">
            <Stack spacing={2}>
                <Pagination count={totalPages} page={currentPage} onChange={handleChange} />
            </Stack>
        </div>
    )
}
