import React from "react";
import { Placeholder } from "../../Placeholder/Placeholder";
import { Grid } from "@mui/material";

export const LoaderModalFormPrimary = () => {

    return (
        <Grid container spacing={3} rowSpacing={3}>
            <Grid item xs={12} md={6} lg={9}>
                <Placeholder className='my-1' height='2rem' width='100%' />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <Placeholder className='my-1 delay-1' height='2rem' width='100%' />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <Placeholder className='my-1 delay-2' height='2rem' width='100%' />
            </Grid>

            <Grid item xs={12} md={6} lg={11}>
                <Placeholder className='my-1' height='2rem' width='100%' />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
                <Placeholder className='my-1 delay-1' height='2rem' width='100%' />
            </Grid>

            <Grid item xs={12} md={6} lg={5}>
                <Placeholder className='mt-1 delay-2' height='2rem' width='100%' />
            </Grid>

        </Grid>
    );
};
