import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router";
import { FasesServices } from "../../../../router/Fases/controllers/fases.services";
import { isStatusSuccess } from "../../../../../../helpers/helpers";
import { LayoutProgramacionGestion } from "./LayoutProgramacionGestion";
import { isEmpty } from "lodash";
import { Typography } from "@mui/material";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Actividad } from "../Actividades/Actividad/Actividad";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";
import { useLang } from "../../../../../../language";

export const LayoutFases = ({ id, text, textMain, idedificio, setDefaultLink, defaultLink,
    idCurrentProject, readOnly }: any) => {

    const Lang = useLang()

    const [navLinksFases, setnavLinksFases] = useState<any>(null);

    const [idCurrentFase, setidCurrentFase] = useState<any>();

    const [fetchFasesGET, fetchFasesGETData] = FasesServices.GET()

    useEffect(() => {

        fetchFasesGET({
            id: idCurrentProject,
        })

    }, []);

    useEffect(() => {

        if (isStatusSuccess(fetchFasesGETData.status)) {

            const data = fetchFasesGETData?.data || []

            setnavLinksFases(data?.map((item: any) => {
                return {
                    title: item.nom,
                    link: item.id
                }
            }))

            setidCurrentFase(data?.[0]?.id || '')
        }

        if (fetchFasesGETData?.error) {

            setnavLinksFases([])
        }

    }, [fetchFasesGETData]);

    const [loaderPhases, setloaderPhases] = useState(true);

    useEffect(() => {

        setloaderPhases(navLinksFases === null)

    }, [navLinksFases]);

    return (

        <>
            {
                loaderPhases ?
                    <LoaderFase />
                    :
                    <>
                        {
                            isEmpty(navLinksFases) ?
                                <div>
                                    <Typography variant="body1" component="h2" className="text-center py-5">
                                        <FontAwesomeIcon icon={faInfoCircle} className="fa-2x mb-3" />
                                        <span className="d-block">
                                            {Lang('PARRAFO_PERIODO_SIN_FASES_AGREGAR_CONTINUAR')}
                                        </span>
                                    </Typography>
                                </div>
                                :
                                <Routes>
                                    <Route
                                        path={`/*`}
                                        element={
                                            <LayoutProgramacionGestion
                                                idedificio={idedificio}
                                                defaultLink={defaultLink}
                                                setDefaultLink={setDefaultLink}
                                                navLinksFases={navLinksFases}
                                                idCurrentFase={idCurrentFase}
                                                setidCurrentFase={setidCurrentFase}
                                                readOnly={readOnly}
                                            />
                                        }
                                    />

                                    <Route
                                        path={`/actividad/:idActividad/*`}
                                        element={
                                            <Actividad
                                                navLinksFases={navLinksFases}
                                                idCurrentFase={idCurrentFase}
                                                idedificio={idedificio}
                                                idCurrentProject={idCurrentProject}
                                                readOnly={readOnly}
                                            />
                                        }
                                    />
                                </Routes>
                        }
                    </>
            }
        </>
    )
};

const LoaderFase = () => {
    return (
        <div>
            <Placeholder className='px-0 py-3 delay-1' height='3rem' />
            <Placeholder className='px-0 py-3 delay-2' height='3rem' />
            <Placeholder className='px-0 py-3 delay-3' height='3rem' />
        </div>
    )
}