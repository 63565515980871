import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLang } from "../../../language";

export const NavTop2 = ({ navLinks, componentLeft, titleStart }: any) => {

    const Lang = useLang()

    const [moduleActive, setmoduleActive] = useState<any>({});

    const handleClickSetModule = (link: string) => {

        const resObj = navLinks?.find((item: any) => item.link === link)

        setmoduleActive(resObj)
    }

    useEffect(() => {

        const linkActive = window.location.href?.split('/detalle/')?.[1]?.split('/')?.[0] || ''

        handleClickSetModule(linkActive)

    }, []);

    return (
        <>
            <header className='LayoutLibro__NavTop2 d-flex align-items-center justify-content-between'>

                <h3 className="LayoutLibro__NavTop2--title h3 mb-0 fw-bold ms-2 mt-0 ps-3">
                    {titleStart}
                </h3>
                {
                    !componentLeft ?
                        <ul className='LayoutLibro__NavTop2__nav list-unstyled d-flex mb-0 mt-auto pt-0 pe-3'>
                            {
                                navLinks?.map((item: any) => {
                                    return (
                                        <NavLink
                                            to={`./${item.link}`}
                                            className={({ isActive }) => isActive ? "LayoutLibro__NavTop2__nav--active" : ""}
                                            onClick={() => handleClickSetModule(item.link)}
                                            key={item.link}
                                        >
                                            {item.icon} {item.title}
                                        </NavLink>
                                    )
                                })
                            }
                        </ul>
                        :
                        <div className="pe-3">
                            {componentLeft}
                        </div>
                }

            </header>
        </>
    );
};
