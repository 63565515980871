import { useNavigate } from "react-router";

export const AvisoLegalSeccion6 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {

  const navigate = useNavigate()

  return (
    <div>
      <p>
        Todos los derechos sobre los contenidos publicados y ofrecidos por CATEB en el sitio web están protegidos por los derechos de propiedad intelectual e industrial. CATEB ha desarrollado o dispone de la licencias y autorizaciones necesarias sobre los contenidos del sitio web, los recursos, las herramientas, así como el código de programación y diseño de los productos y servicios digitales ofrecidos.
      </p>
      <p>
        Los artículos, contenidos, imágenes o logotipos del Colegio son titularidad de CATEB o de las empresas licenciatarias que han permitido su uso y publicación expresamente.
      </p>

      <p>
        Se prohíbe expresamente, salvo autorización por escrito y expresa de CATEB, la reproducción, transformación, distribución, comunicación pública, puesta a disposición al público, venta u otros usos distintos a los expresamente autorizados por CATEB en las presentes condiciones, en las  políticas y demás avisos publicados en su página web, así como en las condiciones particulares, en su caso, ya sea con fines comerciales o no, cuando conlleven la explotación de los contenidos, productos o servicios ofrecidos CATEB cuyos fines sean distintos a los previstos en estas condiciones.
      </p>
      <p>
        <span className="text-decoration-underline">
          6.1. Derechos de propiedad intelectual
        </span>
      </p>
      <p>
        El Usuario reconoce expresamente, y a todos los efectos, que la información, signos, imágenes, diseños, logotipos, animaciones, vídeos, textos, así como los demás elementos y contenidos, incluidos los servicios y aplicaciones informáticas de nuestro sitio web, están sujetos a derechos de propiedad industrial e intelectual de CATEB y/o de los terceros titulares que hayan autorizado debidamente su inclusión en la web, manifestándose expresamente que no se concederá  ningún tipo de derecho sobre ningún contenido de ningún tipo, salvo los que expresamente se reconozcan.
      </p>
      <p>
        <span className="text-decoration-underline">Consecuentemente, todos los derechos están reservados</span>, quedando expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización del titular
      </p>

      <p>
        Los usuarios:
      </p>
      <p>
        (1) deberán abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas del titular.
      </p>
      <p>
        (2) podrán visualizar los elementos del sitio web, imprimirlos, realizar copias o descargas de contenidos siempre que tales acciones estén dirigidas exclusivamente a su uso personal y privado.
      </p>
      <p>
        (3) No podrán realizar enlaces a este sitio web sin el consentimiento de su titular y, en su caso, solo se permitirán enlaces a la página principal.
      </p>
      <p>
        (4) No se permite el enlace a “páginas finales”, actos de “frame” o cualquier otra manipulación similar a esta página sin consentimiento expreso y por escrito por parte de CATEB salvo que se conceda autorización expresa.
      </p>
      <p>
        En caso de que el titular detecte un uso ilícito de cualquiera de los contenidos disponibles en su sitio web, emprenderá las acciones judiciales correspondientes de conformidad con el art. 140 de la Ley de Propiedad Intelectual y el art. 270 del Código Penal en caso de que los actos sean delictivos.
      </p>
      <p>
        6.2. Régimen de las aplicaciones informáticas Las aplicaciones de pago se regirán por lo estipulado en las <span className="link-primary" onClick={() => navigate('/condiciones-contratacion')}>Condiciones de contratación</span> .
      </p>

      <p>
        El titular de las aplicaciones informáticas y de todas las actualizaciones de mejora, rendimiento y de seguridad es CATEB, sus licenciatarios, empresas asociadas u otros proveedores que hayan podido proporcionar materiales para el desarrollo y diseño de las aplicaciones. De manera que CATEB y sus licenciatarios poseen todos los títulos y derechos de propiedad intelectual sobre las aplicaciones.
      </p>

      <p>
        Los derechos otorgados al usuario en las presentes condiciones son otorgados únicamente por licencia de uso expresa conforme se detalla a continuación.
      </p>

      <p>

        <span className="text-decoration-underline">6.2.1. Otorgamiento Licencia de Uso de las aplicaciones informáticas</span>
      </p>
      <p>
        Por medio del presente acuerdo de licencia CATEB le otorga al usuario una licencia de uso personal, no exclusiva, intransferible y no sublicenciable en relación con las aplicaciones o herramientas informáticas indicadas en la cláusula 6.2. de las presentes condiciones.
      </p>
      <p>
        La Licencia no le concede ninguna titularidad al usuario y será efectiva a partir de la fecha de aceptación de estos términos. La licencia de uso finalizará cuando el usuario decida desinstalar o dejar de usar las aplicaciones.
      </p>
      <p>
        <span className="text-decoration-underline">6.2.2. Condiciones de la licencia de uso</span>
      </p>
      <p>
        Las aplicaciones informáticas están protegidas por las leyes de propiedad intelectual, permitiéndose exclusivamente un uso personal y prohibiéndose los usos con fines comerciales.  De manera que el usuario no podrá hacer nada de lo mencionado a continuación con respecto a las aplicaciones o a cualquiera de sus partes:
      </p>
      <p>
        (a) utilizarlo con fines comerciales o promocionales;
      </p>
      <p>
        (b) copiarlo, reproducirlo, distribuirlo, exhibirlo o utilizarlo de una manera que no esté expresamente autorizada en las presentes condiciones;
      </p>
      <p>
        (c) venderlo, alquilarlo, licenciarlo, distribuirlo o transferirlo;

        (d) aplicar ingeniería inversa, derivar su código fuente, modificarlo, adaptarlo, traducirlo, descompilarlo o desensamblarlo, o realizar trabajos derivados basados en este; (e) eliminar, desactivar, eludir o modificar cualquier aviso o etiqueta de propiedad o tecnología de seguridad incluida en este; (f) utilizarlo para infringir o violar los derechos de terceros, incluidos, entre otros, cualquier derecho de propiedad intelectual, industrial o de privacidad; (g) obstaculizar, interferir o desactivar cualquier función de seguridad instalada.
      </p>
      <p>
        (h) utilizarlo, exportarlo o reexportarlo violando cualquier ley o regulación aplicable o (i) comportarse de una manera que sea perjudicial para el uso del Software por otros usuarios según lo previsto y a juicio exclusivo de CATEB, en base a la buena fe, la moral y el orden público, y, concretamente, introducir adwares, malwares o cualquier otro material que pudiera dañar las aplicaciones.
      </p>

    </div>
  )
}

const SeccionCA = () => {

  const navigate = useNavigate()

  return (
    <div>
      <p>
        Tots els drets sobre els continguts publicats i oferts per CATEB al lloc web estan protegits pels drets de propietat intel·lectual i industrial. CATEB ha desenvolupat o disposa de les llicències i autoritzacions necessàries sobre els continguts del lloc web, els recursos, les eines, així com el codi de programació i disseny dels productes i serveis digitals oferts.
      </p>
      <p>
        Els articles, continguts, imatges o logotips del Col·legi són titularitat de CATEB o de les empreses llicenciatàries que han permès el seu ús i publicació expressament.
      </p>

      <p>
        Es prohibeix expressament, llevat d' autorització per escrit i expressa de CATEB, la reproducció, transformació, distribució, comunicació pública, posada a disposició al públic, venda o altres usos diferents als expressament autoritzats per CATEB en les presents condicions, en les polítiques i altres avisos publicats a la seva pàgina web, així com a les condicions particulars, si s' escau,  ja sigui amb finalitats comercials o no, quan comportin l' explotació dels continguts, productes o serveis oferts CATEB les finalitats dels quals siguin diferents a les previstes en aquestes condicions.
      </p>
      <p>
        <span className="text-decoration-underline">
          6.1. Drets de propietat intel·lectual
        </span>
      </p>
      <p>
        L'Usuari reconeix expressament, i a tots els efectes, que la informació, signes, imatges, dissenys, logotips, animacions, vídeos, textos, així com els altres elements i continguts, inclosos els serveis i aplicacions informàtiques del nostre lloc web, estan subjectes a drets de propietat industrial i intel·lectual de CATEB i/o dels tercers titulars que hagin autoritzat degudament la seva inclusió al web,  manifestant-se expressament que no es concedirà cap tipus de dret sobre cap contingut de cap tipus, llevat dels que expressament es reconeguin.
      </p>
      <p>
        <span className="text-decoration-underline">Conseqüentment, tots els drets estan reservats</span>, quedant expressament prohibides la reproducció, la distribució i la comunicació pública, inclosa la seva modalitat de posada a disposició, de la totalitat o part dels continguts d' aquesta pàgina web, amb finalitats comercials, en qualsevol suport i per qualsevol mitjà tècnic, sense l' autorització del titular
      </p>

      <p>
        Els usuaris:
      </p>
      <p>
        (1) hauran d'abstenir-se de suprimir, alterar, eludir o manipular qualsevol dispositiu de protecció o sistema de seguretat que estigués instal·lat a les pàgines del titular.
      </p>
      <p>
        (2) podran visualitzar els elements del lloc web, imprimir-los, realitzar còpies o descàrregues de continguts sempre que aquestes accions estiguin dirigides exclusivament al seu ús personal i privat.
      </p>
      <p>
        (3) No podran realitzar enllaços a aquest lloc web sense el consentiment del seu titular i, si s'escau, només es permetran enllaços a la pàgina principal.
      </p>
      <p>
        (4) No es permet l'enllaç a "pàgines finals", actes de "frame" o qualsevol altra manipulació similar a aquesta pàgina sense consentiment exprés i per escrit per part de CATEB llevat que es concedeixi autorització expressa.
      </p>
      <p>
        En cas que el titular detecti un ús il·lícit de qualsevol dels continguts disponibles al seu lloc web, emprendrà les accions judicials corresponents de conformitat amb l'article 140 de la Llei de Propietat Intel·lectual i l'article 270 del Codi Penal en cas que els actes siguin delictius.
      </p>
      <p>
        6.2. Règim de les aplicacions informàtiques Les aplicacions de pagament es regiran per l'estipulat en les <span className="link-primary" onClick={() => navigate('/condiciones-contratacion')}>Condicions de Contractació</span> .
      </p>

      <p>
        El titular de les aplicacions informàtiques i de totes les actualitzacions de millora, rendiment i de seguretat és CATEB, els seus llicenciataris, empreses associades o altres proveïdors que hagin pogut proporcionar materials per al desenvolupament i disseny de les aplicacions. De manera que CATEB i els seus llicenciataris posseeixen tots els títols i drets de propietat intel·lectual sobre les aplicacions.
      </p>

      <p>
        Els drets atorgats a l' usuari en les presents condicions són atorgats únicament per llicència d' ús expressa conforme es detalla a continuació.
      </p>

      <p>

        <span className="text-decoration-underline">6.2.1. Atorgament Llicència d'Ús de les aplicacions informàtiques</span>
      </p>
      <p>
        Per mitjà del present acord de llicència CATEB li atorga a l' usuari una llicència d' ús personal, no exclusiva, intransferible i no sublicenciable en relació amb les aplicacions o eines informàtiques indicades en la clàusula 6.2. de les presents condicions.
      </p>
      <p>
        La Llicència no li concedeix cap titularitat a l' usuari i serà efectiva a partir de la data d' acceptació d' aquests termes. La llicència d'ús finalitzarà quan l'usuari decideixi desinstal·lar o deixar d'usar les aplicacions.      </p>
      <p>
        <span className="text-decoration-underline">6.2.2. Condicions de la llicència d'ús</span>
      </p>
      <p>
        Les aplicacions informàtiques estan protegides per les lleis de propietat intel·lectual, permetent-se exclusivament un ús personal i prohibint-se els usos amb finalitats comercials.  De manera que l' usuari no podrà fer res de l' esmentat a continuació respecte a les aplicacions o a qualsevol de les seves parts:
      </p>
      <p>
        (a) utilitzar-lo amb finalitats comercials o promocionals;
      </p>
      <p>
        (b) copiar-lo, reproduir-lo, distribuir-lo, exhibir-lo o utilitzar-lo d'una manera que no estigui expressament autoritzada en les presents condicions;
      </p>
      <p>
        (c) vendre'l, llogar-lo, llicenciar-lo, distribuir-lo o transferir-lo; 

        (d) aplicar enginyeria inversa, derivar el seu codi font, modificar-lo, adaptar-lo, traduir-lo, descompilar-lo o desensamblar-lo, o realitzar treballs derivats basats en aquest; (e) eliminar, desactivar, eludir o modificar qualsevol avís o etiqueta de propietat o tecnologia de seguretat inclosa en aquest; (f) utilitzar-lo per infringir o violar els drets de tercers, inclosos, entre d'altres, qualsevol dret de propietat intel·lectual, industrial o de privacitat; (g) obstaculitzar, interferir o desactivar qualsevol funció de seguretat instal·lada.
      </p>
      <p>
        (h) utilitzar-lo, exportar-lo o reexportar-lo violant qualsevol llei o regulació aplicable o (i) comportar-se d'una manera que sigui perjudicial per a l'ús del Programari per altres usuaris segons el previst i a judici exclusiu de CATEB, en base a la bona fe, la moral i l'ordre públic, i, concretament, introduir adwars, malwars o qualsevol altre material que pogués danyar les aplicacions.
      </p>

    </div>
  )
}