export const CondGenSeccion8 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <strong>8.1. Responsabilidad del Cliente y/o Usuario</strong>
      </p>
      <p>
        1. El Cliente deberá cumplir con las manifestaciones formales indicadas en la cláusula 3 de estas condiciones.
      </p>
      <p>
        2. El Cliente garantiza la veracidad y exactitud de los datos facilitados al rellenar los formularios de alta como usuario y de contratación, evitando la creación de perfiles o contrataciones que ocasionen perjuicios a CATEB como consecuencia de la incorrección de estos.
      </p>
      <p>
        3. El Cliente debe abonar el precio indicado por adelantado utilizando las formas de pago indicadas en las presentes condiciones.
      </p>
      <p>
        4. El Cliente debe hacer un uso diligente de los productos y servicios ofrecidos por CATEB obligándose a usar los productos y servicios ofrecidos en el sitio web de forma lícita y sin contravenir la legislación vigente ni lesionar los derechos e intereses de terceras personas. Queda expresamente prohibido realizar copias de manera total o parcial de las publicaciones adquiridas, así como cualquier otra conducta que lesione los derechos de propiedad intelectual de CATEB o terceros.
      </p>
      <p>
        5. Será responsable de cumplir con los requisitos mínimos técnicos de compatibilidad de los productos y servicios digitales.
      </p>
      <p>
        6. También será responsable de respetar las condiciones, forma o ejecución de los productos o servicios detallados en las condiciones particulares aplicables, en su caso.
      </p>
      <p>
        7. El Usuario también es responsable de hacer un buen uso de su perfil de Usuario, comprometiéndose a hacer un uso diligente y conservar las credenciales de acceso convenientemente para que no queden a disposición de terceros. En caso de pérdida, robo o posible acceso de un tercero no autorizado, el Cliente deberá comunicarlo inmediatamente a CATEB a fin de bloquear de inmediato el perfil de usuario comprometido.
      </p>
      <p>
        8. El Cliente indemnizará a CATEB por los gastos que tuviera que asumir a consecuencia de cualquier actuación cuya responsabilidad fuera atribuible al Cliente, incluidos los honorarios y gastos de los abogados, procuradores, peritos, tasas judiciales y cualesquiera otros procesales, incluso en el supuesto de una resolución judicial no definitiva.
      </p>
      <p>
        El incumplimiento de cualquiera de estas condiciones podrá dar lugar a la retirada o cancelación inmediata de los servicios por parte de CATEB sin que ello dé derecho al Usuario o Cliente a solicitar indemnización alguna.
      </p>
      <p>
        <strong>8.2. Responsabilidades de CATEB</strong>
      </p>
      <p>
        (1) Prestar el servicio en la forma y según las características ofrecidas en nuestro sitio web y lo dispuesto en estas condiciones de contratación en atención a los productos o servicios contratados por el Cliente.
      </p>
      <p>
        (2) Proteger la confidencialidad de los datos de sus Usuarios y/o Clientes según lo dispuesto en este texto y resto de políticas legales aplicables.
      </p>
      <p>
        (3) Cumplir con lo dispuesto en las condiciones particulares de cada producto o servicio contratado.
      </p>
      <p>
        <strong>8.3. Límites a la responsabilidad de CATEB</strong>
      </p>
      <p>
        A continuación, se detallan los supuestos en los que CATEB no asumirá responsabilidad, constituyendo una lista no cerrada, por lo que cualquier otra situación similar a las indicadas y que no esté contemplada, también será causa de exoneración de la responsabilidad de CATEB:
      </p>
      <p>
        (1) Mal uso o abuso de los productos o servicios ofrecidos en nuestro sitio web, así como los posibles daños o perjuicios causados a terceros.
      </p>
      <p>
        (2) De los daños o errores producidos por negligencia o mala fe del Usuario y/o Cliente.
      </p>
      <p>
        (3) El uso que realice el Usuario y/o Cliente de la información extraída a través de nuestros productos y servicios digitales.
      </p>
      <p>
        (4) De la oficialidad de la información extraída de los softwares en los informes o cualquier otro documento de características similares.
      </p>
      <p>
        CATEB se reserva el derecho a suspender el acceso sin previo aviso cuando se detecte cualquier situación en la que el Usuario y/o Cliente no haya actuado diligentemente y/o se haya aprovechado de las funcionalidades de la página web. La suspensión del servicio podrá tener carácter temporal o definitivo e CATEB no será responsable de los perjuicios que la suspensión pudiera ocasionarle al usuario/cliente.
      </p>
      <p>
        Asimismo, y de conformidad con la legislación vigente se hace constar que CATEB, aun cuando no sea responsable de los daños que se hubieran podido ocasionar, tanto al Usuario y/o Cliente como a terceros, colaborará y notificará a la autoridad competente este tipo de incidencias tan pronto tenga conocimiento fehaciente de que los daños ocasionados pueden constituir actividad ilícita.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <strong>8.1. Responsabilitat del Client i/o Usuari</strong>
      </p>
      <p>

        1. El Client haurà de complir amb les manifestacions formals indicades en la clàusula 3 d'aquestes condicions.
      </p>
      <p>

        2. El Client garanteix la veracitat i exactitud de les dades facilitades en emplenar els formularis d'alta com a usuari i de contractació, evitant la creació de perfils o contractacions que ocasionin perjudicis a CATEB com a conseqüència de la incorrecció d'aquests.
      </p>
      <p>

        3. El Client ha d'abonar el preu indicat per avançat utilitzant les formes de pagament indicades en les presents condicions.
      </p>
      <p>

        4. El Client ha de fer un ús diligent dels productes i serveis oferts per CATEB obligant-se a usar els productes i serveis oferts al lloc web de forma lícita i sense contravenir la legislació vigent ni lesionar els drets i interessos de terceres persones. Queda expressament prohibit realitzar còpies de manera total o parcial de les publicacions adquirides, així com qualsevol altra conducta que lesioni els drets de propietat intel·lectual de CATEB o tercers.
      </p>
      <p>

        5. Serà responsable de complir amb els requisits mínims tècnics de compatibilitat dels productes i serveis digitals.
      </p>
      <p>

        6. També serà responsable de respectar les condicions, forma o execució dels productes o serveis detallats en les condicions particulars aplicables, si s'escau.
      </p>
      <p>

        7. L'Usuari també és responsable de fer un bon ús del seu perfil d'Usuari, comprometent-se a fer un ús diligent i conservar les credencials d'accés convenientment perquè no quedin a disposició de tercers. En cas de pèrdua, robatori o possible accés d' un tercer no autoritzat, el Client haurà de comunicar-ho immediatament a CATEB a fi de bloquejar immediatament el perfil d' usuari compromès.
      </p>
      <p>

        8. El Client indemnitzarà CATEB per les despeses que hagués d'assumir a conseqüència de qualsevol actuació la responsabilitat de la qual fos atribuïble al Client, inclosos els honoraris i despeses dels advocats, procuradors, pèrits, taxes judicials i qualssevol altres processals, fins i tot en el supòsit d'una resolució judicial no definitiva.
      </p>
      <p>
        L'incompliment de qualsevol d'aquestes condicions podrà donar lloc a la retirada o cancel·lació immediata dels serveis per part de CATEB sense que això doni dret a l'Usuari o Client a sol·licitar cap indemnització.
      </p>
      <p>
        <strong>8.2. Responsabilitats de CATEB</strong>
      </p>
      <p>
        (1) Prestar el servei en la forma i segons les característiques ofertes en el nostre lloc web i el que disposa aquestes condicions de contractació en atenció als productes o serveis contractats pel Client.
      </p>
      <p>
        (2) Protegir la confidencialitat de les dades dels seus Usuaris i/o Clients segons el que disposa aquest text i resta de polítiques legals aplicables.
      </p>
      <p>
        (3) Complir amb el que disposen les condicions particulars de cada producte o servei contractat.
      </p>
      <p>
        <strong>8.3. Límits a la responsabilitat de CATEB</strong>
      </p>
      <p>
        A continuació, es detallen els supòsits en els quals CATEB no assumirà responsabilitat, constituint una llista no tancada, per la qual cosa qualsevol altra situació similar a les indicades i que no estigui contemplada, també serà causa d' exoneració de la responsabilitat de CATEB:
      </p>
      <p>
        (1) Mal ús o abús dels productes o serveis oferts al nostre lloc web, així com els possibles danys o perjudicis causats a tercers.
      </p>
      <p>
        (2) Dels danys o errors produïts per negligència o mala fe de l'Usuari i/o Client.
      </p>
      <p>
        (3) L'ús que realitzi l'Usuari i/o Client de la informació extreta a través dels nostres productes i serveis digitals.
      </p>
      <p>
        (4) De l'oficialitat de la informació extreta dels programaris en els informes o qualsevol altre document de característiques similars.
      </p>
      <p>
        CATEB es reserva el dret a suspendre l'accés sense previ avís quan es detecti qualsevol situació en què l'Usuari i/o Client no hagi actuat diligentment i/o s'hagi aprofitat de les funcionalitats de la pàgina web. La suspensió del servei podrà tenir caràcter temporal o definitiu i CATEB no serà responsable dels perjudicis que la suspensió pogués ocasionar-li a l'usuari/client.
      </p>
      <p>
        Així mateix, i de conformitat amb la legislació vigent es fa constar que CATEB, encara que no sigui responsable dels danys que s'haguessin pogut ocasionar, tant a l'Usuari i/o Client com a tercers, col·laborarà i notificarà a l'autoritat competent aquest tipus d'incidències tan aviat tingui coneixement fefaent que els danys ocasionats poden constituir activitat il·lícita.
      </p>
    </div>
  )
}