import { Container, Grid, Typography } from '@mui/material';
import './ListConvenios.scss'
import { useLang } from '../../../language';

export const ListConvenios = () => {

    const Lang = useLang()

    return (
        <>
            <div className="ListConvenios">

                <Container maxWidth="lg" className='my-auto'>

                    <Grid container justifyContent='center' spacing={4}>

                        <Grid item xs={12} className=''>

                            <Typography variant='h6' className='mb-4 fw-bold'>
                                {Lang('PARRAFO_TITULO_LISTADO_CONVENIOS')}:
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo2.webp`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col·legi de l'Arquitectura Tècnica de Barcelona
                            </Typography>

                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo1.webp`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col·legi Oficial d'Aparelladors i Arquitectes Tècnics de Mallorca
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo5.jpg`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col·legi Oficial d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Menorca
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo4.jpg`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de la Arquitectura Técnica de Navarra
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo18.jpg`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col·legi de l'Arquitectura Tècnica de Girona
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo3.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col·legi d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Tarragona
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo9.jpg`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col·legi de l’Arquitectura Tècnica de les Terres de l’Ebre
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo8.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Col.legi de l'Arquitectura Tècnica de Lleida
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo12.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de la Arquitectura Técnica de Pontevedra
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo7.jpg`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de la Arquitectura Técnica de A Coruña
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo10.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de la Arquitectura Técnica de Lugo
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo11.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de la Arquitectura Técnica de Ourense
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo14.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de León
                            </Typography>

                        </Grid>


                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo16.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Salamanca
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo19.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Zamora
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo22.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Ávila
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo15.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Lanzarote
                            </Typography>

                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo20.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores, Arquitectos Técnicos e Ingenieros de Edificación de Fuerteventura
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo21.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Gran Canaria
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo13.jpg`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Gipuzkoa
                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3} className='d-flex flex-column'>

                            <img src={`${process.env.PUBLIC_URL}/images/logos/logo17.png`} alt="logo" />

                            <Typography variant='subtitle2' className='py-4 text-center'>
                                Colegio Oficial de Aparejadores y Arquitectos Técnicos de Málaga
                            </Typography>

                        </Grid>

                    </Grid>

                </Container>
            </div>
        </>
    )
};
