import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../controllers/controllers"

const ActividadesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    descripcio: parseString(item.descripcio),
                    titol: parseString(item.titol),
                    sistema_id: parseString(item.sistema_id),
                    tipus_intervencio: parseString(item.tipus_intervencio),
                    data_inici_calculada: receiveFechaFormat2(receiveFecha(item.data_inici_calculada)),
                    durada_mesos: parseString(item.durada_mesos),
                    estado_actividad: parseString(item.estado_actividad) || 'pendiente',
                    fase_nom: parseString(item.fase_nom),
                    fase_id: parseString(item.fase_id),
                    referencia_padre_id: parseAny(item.referencia_padre_id),
                    periodicitat: parseString(item.periodicitat),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                    data_inici_intervencion: receiveFechaFormat2(receiveFecha(item.data_inici_intervencion)),
                    data_fi_activitat: receiveFechaFormat2(receiveFecha(item.data_fi_activitat)),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_BLOB: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseAny(item.data),
                    headers: parseString(item.headers),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_ALL_EDIFICIOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    created_at: parseString(),
                    label__created_at: receiveFechaFormat2(receiveFecha(item.created_at)),
                    updated_at: parseString(item.updated_at),
                    tipus_edifici: parseString(item.tipus_edifici),
                    tipus_producte: parseObject(item.tipus_producte),
                    label__tipus_producte: parseString(item.tipus_producte?.producte),
                    inmuebles: parseArray(item.inmuebles),
                    identificacion: parseArray(item.identificacion),
                    propiedad: parseArray(item.propiedad),
                    fotos: parseArray(item.fotos),
                    planos: parseArray(item.planos),
                    pagos: parseArray(item.pagos),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const ActividadAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    creat_per_id: parseNumber(item.creat_per_id),
                    created_at: parseString(item.created_at),
                    data_fi_calculada: parseString(item.data_fi_calculada),
                    data_inici_any: parseString(item.data_inici_any),
                    data_inici_calculada: parseString(item.data_inici_calculada),
                    data_inici_mes: parseString(item.data_inici_mes),
                    descripcio: parseString(item.descripcio),
                    durada_mesos: parseString(item.durada_mesos),
                    edifici_id: parseString(item.edifici_id),
                    import_altres: parseString(item.import_altres),
                    import_honoraris: parseString(item.import_honoraris),
                    import_obres: parseString(item.import_obres),
                    import_taxes: parseString(item.import_taxes),
                    import_total: parseString(item.import_total),
                    origen_id: parseString(item.origen_id),
                    origen_taula: parseString(item.origen_taula),
                    projecte_fase_id: parseNumber(item.projecte_fase_id),
                    sistema_id: parseString(item.sistema_id),
                    subsistema_id: parseString(item.subsistema_id),
                    tipus_intervencio: parseString(item.tipus_intervencio),
                    titol: parseString(item.titol),
                    updated_at: parseString(item.updated_at),
                    estado_actividad: parseString(item.estado_actividad) || 'pendiente',
                    referencia_padre_id: parseAny(item.referencia_padre_id),
                    periodicitat: parseString(item.periodicitat),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                    data_fi_activitat: parseString(item.data_fi_activitat),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_fase_id: parseNumber(item.projecte_fase_id),
                    tipus_intervencio: parseString(item.tipus_intervencio),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    sistema_id: parseNumber(item.sistema_id),
                    subsistema_id: parseString(item.subsistema_id),
                    import_obres: parseString(item.import_obres),
                    import_honoraris: parseString(item.import_honoraris),
                    import_taxes: parseString(item.import_taxes),
                    import_altres: parseString(item.import_altres),
                    import_total: parseString(item.import_total),
                    data_inici_any: parseNumber(item.data_inici_any),
                    data_inici_mes: parseNumber(item.data_inici_mes),
                    durada_mesos: parseNumber(item.durada_mesos),
                    origen_id: parseAny(item.origen_id, { removeNullable: true }),
                    periodicitat: parseString(item.periodicitat),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_fase_id: parseNumber(item.projecte_fase_id),
                    tipus_intervencio: parseString(item.tipus_intervencio),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    sistema_id: parseNumber(item.sistema_id),
                    subsistema_id: parseString(item.subsistema_id),
                    import_obres: parseString(item.import_obres),
                    import_honoraris: parseString(item.import_honoraris),
                    import_taxes: parseString(item.import_taxes),
                    import_altres: parseString(item.import_altres),
                    import_total: parseString(item.import_total),
                    data_inici_any: parseNumber(item.data_inici_any),
                    data_inici_mes: parseNumber(item.data_inici_mes),
                    durada_mesos: parseNumber(item.durada_mesos),
                    periodicitat: parseString(item.periodicitat),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_ESTADO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    estado_actividad: parseAny(item.estado_actividad),
                    data_fi_activitat: parseAny(item.data_fi_activitat),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_CLONAR: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_FASE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    fase_id: parseAny(item.fase_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}

export {
    ActividadesAdapters,
    ActividadAdapters,
}