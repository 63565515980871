import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import ReactRouterPrompt from "react-router-prompt";
import { ModalConfirm } from "../../../../../../components/Modals/ModalConfirm";
import { useLang } from "../../../../../../language";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { InputText } from "../../../../../../components/Inputs";
import { ButtonAdvanced } from "../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { LoaderModalFormPrimary } from "../../../../../../components/Loaders/LoaderModalFormPrimary";
import { MenuOtrosElementos } from "./MenuOtrosElementos/MenuOtrosElementos";
import { InputChecks } from "../../../../../../components/Inputs/InputChecks";

export const Metrica = ({ id, firstLoad, elementSelected, isBlockingNav, setisBlockingNav, fetchMiElemConst,
    idedificio, fetchMiElemConstData, handleFormChangeMetricas, formMetricas, selectedHistoric,
    fetchElemConstMetricaPOSTData, formChecks, rolesdefault, handleInputChecks, handleSubmitCheckYMetricas,
    fetchElemConstPATCHData, setelementSelected, setrolesdefault, handleSelect, isClickFromTree, setisClickFromTree }: any) => {

    const { id: idOriginal } = useParams()

    const Lang = useLang()

    const navigate = useNavigate()

    useEffect(() => {

        if (idOriginal) {

            if (firstLoad) {

                navigate('../')
            }

            if (idOriginal === 'otros') {

                setelementSelected({})

                setisBlockingNav(false)

            } else {

                setisBlockingNav(false)

                setelementSelected({})

                setrolesdefault([])

                fetchMiElemConst({
                    id: idedificio + '/edificio',
                    params: {
                        padre_id: idOriginal,
                        arbol: true,
                    }
                })
            }

            setisClickFromTree(false)
        }

    }, [idOriginal]);


    const handleOnChangeInputs = () => {

        setisBlockingNav(true)
    }

    return (
        <div>

            <ReactRouterPrompt when={isBlockingNav}>
                {({ isActive, onConfirm, onCancel }: any) => (
                    <ModalConfirm
                        data={{ value: true }}
                        textOnClickOk={Lang('CANCELAR')}
                        onClickOK={() => {
                            
                            let pultimo = selectedHistoric[selectedHistoric.length - 1] || '0'

                            if (isClickFromTree) {
                                
                                pultimo = selectedHistoric[selectedHistoric.length - 2] || '0'
                            }

                            handleSelect(null, pultimo)
                            onCancel()
                            setisBlockingNav(false)
                            setisClickFromTree(false)
                        }}
                        textOnClickKO={Lang('SALIR')}
                        onClickKO={() => {
                            onConfirm()
                        }}
                        variantClickOk='contained'
                        message={Lang('PARRAFO_SEGURO_SALIR_CAMBIOS_SIN_GUARDAR')}
                    />
                )}
            </ReactRouterPrompt>

            {
                !firstLoad &&
                <>
                    {
                        idOriginal !== 'otros' ?
                            <>
                                <Card>
                                    <CardContent>
                                        {
                                            !isEmpty(elementSelected) ?
                                                <>
                                                    {
                                                        !isEmpty(elementSelected.metricas) ?
                                                            <>
                                                                <Grid container spacing={2} columnSpacing={3} rowSpacing={0} className=''>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant='h6' className='m-0 p-0 fw-bolder'>
                                                                            {elementSelected.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    {
                                                                        elementSelected.metricas?.map((item3: any) => {

                                                                            return (
                                                                                <>
                                                                                    <Grid item xs={12} lg={6}>
                                                                                        {
                                                                                            item3.tipo_html === 'text' &&
                                                                                            <InputText
                                                                                                label={<span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item3.texto) }}></span>}
                                                                                                name={item3.codigo}
                                                                                                // value={formMetricas[item3.id]}
                                                                                                value={formMetricas[elementSelected.idOriginal]?.[item3.id]}
                                                                                                // value={item3.valor}
                                                                                                onChange={(e: any) => {
                                                                                                    handleFormChangeMetricas(elementSelected.id, item3.id, e)
                                                                                                    handleOnChangeInputs()
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }

                                                                    <Grid item xs={12} justifyContent='end' display='flex'>
                                                                        <ButtonAdvanced
                                                                            variant="contained" type="submit" className="ms-auto"
                                                                            handleSubmit={() => handleSubmitCheckYMetricas({
                                                                                soloMetrica: true,
                                                                            })}
                                                                            loadings={[fetchElemConstMetricaPOSTData.loading]}
                                                                            value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                                                        />
                                                                    </Grid>

                                                                </Grid>

                                                            </>
                                                            :
                                                            <>
                                                                <Typography variant='h6' className='m-0 p-0 fw-bolder'>
                                                                    {elementSelected.name}
                                                                </Typography>

                                                                <div className="mt-1">
                                                                    <Grid container spacing={2} columnSpacing={3} rowSpacing={0}>

                                                                        <Grid item xs={12}>
                                                                            <InputChecks
                                                                                name='metricas'
                                                                                values={formChecks.metricas}
                                                                                valuesDefault={rolesdefault?.map((item: any) => {
                                                                                    return {
                                                                                        id: String(item.idOriginal),
                                                                                        label: item.name
                                                                                    }
                                                                                }) || []}
                                                                                inlineClassName='justify-content-start'
                                                                                onChange={
                                                                                    (e: any) => {

                                                                                        handleInputChecks(e)
                                                                                        handleOnChangeInputs()
                                                                                    }
                                                                                }
                                                                                inline
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12}>

                                                                            {
                                                                                formChecks.metricas?.map((item: any) => {

                                                                                    const res = elementSelected?.children?.find((item2: any) => Number(item2.idOriginal) === Number(item)) || {}

                                                                                    if (isEmpty(res.metricas)) return null

                                                                                    return (
                                                                                        <Grid container spacing={2} columnSpacing={3} rowSpacing={0} className='mb-4' key={item}>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='h6' className='m-0 p-0 fw-bolder'>
                                                                                                    {res.name}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            {
                                                                                                res.metricas?.map((item3: any) => {

                                                                                                    return (
                                                                                                        <Grid item xs={12} lg={6} key={item3.id}>
                                                                                                            {
                                                                                                                item3.tipo_html === 'text' &&
                                                                                                                <InputText
                                                                                                                    label={<span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item3.texto) }}></span>}
                                                                                                                    name={item3.codigo}
                                                                                                                    value={formMetricas[item]?.[item3.id]}
                                                                                                                    onChange={(e: any) => {
                                                                                                                        handleFormChangeMetricas(item, item3.id, e)
                                                                                                                        handleOnChangeInputs()
                                                                                                                    }}
                                                                                                                />
                                                                                                            }
                                                                                                        </Grid>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </Grid>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </Grid>

                                                                        <Grid item xs={12} justifyContent='end' display='flex'>
                                                                            <ButtonAdvanced
                                                                                variant="contained" type="submit" className="ms-auto"
                                                                                handleSubmit={handleSubmitCheckYMetricas}
                                                                                loadings={[fetchElemConstMetricaPOSTData.loading, fetchElemConstPATCHData.loading]}
                                                                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                                                            />
                                                                        </Grid>

                                                                    </Grid>

                                                                </div>
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        fetchMiElemConstData.loading ?
                                                            <LoaderModalFormPrimary />
                                                            :
                                                            <div className="py-5 text-center">
                                                                {Lang('PARRAFO_NAVEGUE_ARBOL_SELECCIONE')}
                                                            </div>
                                                    }
                                                </>
                                        }
                                    </CardContent>
                                </Card>

                                {
                                    !isEmpty(elementSelected) && elementSelected.padre_id !== 533 &&
                                    <Grid item xs={12} className='mt-4'>
                                        <MenuOtrosElementos
                                            fetchDataInit={fetchMiElemConstData}
                                            elemento_constructivo_id={elementSelected.idOriginal}
                                            idEdificio={idedificio}
                                            id={id}
                                        />
                                    </Grid>
                                }

                            </>
                            :
                            <MenuOtrosElementos
                                elemento_constructivo_id={id}
                                idEdificio={idedificio}
                                id={id}
                                disabledCreate
                            />
                    }
                </>

            }
        </div>
    )
};
