import { parseAny, parseArray, parseNumber, parseObject, parseString, receiveCheck, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../../../controllers/controllers"

const DocumentacionAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    creat_per_id: parseString(item.creat_per_id),
                    edifici_id: parseString(item.edifici_id),
                    nom: parseString(item.nom),
                    tipus_document: parseString(item.tipus_document),
                    url_download: parseString(item.url_download),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_RESUMEN_COSTES: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nivel_principal: parseNumber(item.nivel_principal),
                    texto: parseString(item.texto),
                    total_coste: parseAny(item.total_coste),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
}

const DocumentoAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    tipus_document: parseString(item.tipus_document),
                    nom: parseString(item.nom),
                    ruta: parseString(item.ruta),
                    data_validesa: receiveFecha(item.data_validesa),
                    afegir_al_libre: receiveCheck(item.afegir_al_libre, 'afegir_al_libre'),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
  
    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nom: parseString(item.nom),
                    edifici_id: parseNumber(item.edifici_id),
                    tipus_document: parseString(item.tipus_document),
                    file: parseAny(item.file),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    tipus_document: parseString(item.tipus_document),
                    file: parseAny(item.file, {removeNullable: true}),
                    data_validesa: parseString(item.data_validesa),
                    afegir_al_libre: sendCheck(item.afegir_al_libre),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_POTENCIAL_MEJORAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_POTENCIAL_MEJORAS_BLOB: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseAny(item.data),
                    headers: parseString(item.headers),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    DocumentacionAdapters,
    DocumentoAdapters,
}