import React from "react";
import { ItemsDynamics } from "./ItemsDynamics";

import './index.css'
import { Grid } from "@mui/material";

const ContainerDragDrop = ({ children, blocked }: any) => {

    function addPropsToReactElement(element: any, props: any) {
        if (React.isValidElement(element)) {
            return React.cloneElement(element, props)
        }
        return element
    }

    const drop = (ev:any) => ev.preventDefault();

    const allowDrop = (ev:any) => ev.preventDefault();

    return (
        <div className='position-relative w-100'>
            <Grid container className="row InputImages__container" onDrop={drop} onDragOver={allowDrop} columnSpacing={3}>
                {
                    children?.map((childElement: any, indexchildElement: any) =>
                        addPropsToReactElement(childElement, { 
                            'key': indexchildElement,
                        } )
                    )
                }
            </Grid>
            {
                blocked && <div className='InputImages__Containerloader d-flex align-items-center justify-content-center'></div>
            }
        </div>
    )
}


ContainerDragDrop.ItemsDynamics = ItemsDynamics
// ContainerDragDrop.ItemsStatics = ItemsStatics

export default ContainerDragDrop;
