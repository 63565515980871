export const CondGenSeccion5 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <strong>5.1. Precios y gastos</strong>
      </p>

      <p>
        El precio (Precio de Venta al Público o PVP) se encuentra disponible en la descripción de cada producto, está expresado en euros e incluye el IVA y/o cualquier otro impuesto o tasa que resultase de aplicación.
      </p>

      <p>
        Verificado el pago, se procederá a la puesta a disposición de los productos o servicios conforme se detalla en la cláusula 3 de estas condiciones.
      </p>

      <p>
        <strong>5.2. Formas de pago</strong>
      </p>

      <p>
        Las formas de pago admitidas son las siguientes:
      </p>

      <p>
        <span className="text-decoration-underline">(1) Tarjeta bancaria</span>. El cargo se realiza en tiempo real, a través de la pasarela de pago segura Redsys y una vez se haya comprobado que los datos comunicados son correctos. Con el objetivo de dar la máxima seguridad al sistema de pago CATEB utiliza sistemas de pago seguro. En este sentido, los datos confidenciales son transmitidos directamente y de forma encriptada (SSL) a la entidad financiera correspondiente. El sistema de encriptación SSL que utilizamos (TLS 1.1 y TLS 1.2) confiere total seguridad a la transmisión de datos a través de la red. Sus datos gozan de total confidencialidad y protección. Los datos sobre las tarjetas de crédito no quedan registrados en ninguna base de datos nuestra. Están únicamente utilizados en el TPV (Terminal Punto de Venta) virtual de nuestra entidad financiera, a través de su Pasarela de Pago Seguro (Redsys). Las tarjetas de crédito estarán sujetas a comprobaciones y autorizaciones por parte de la entidad emisora de estas, pero si dicha entidad no autorizase el pago, el contrato no quedará formalizado hasta que CATEB reciba el pago.
      </p>
      <p>
        <span className="text-decoration-underline">(2) Cargo en cuenta</span>. Para aquellos usuarios que sean miembros del CATEB el pago podrá realizarse mediante cargo en cuenta bancaria que tengan autorizados los cargos del CATEB. En caso contrario, tendrá que domiciliar sus recibos siguiendo las indicaciones de la web y cumplimentado los formularios que se designen al efecto.
      </p>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <strong>5.1. Preus i despeses</strong>
      </p>

      <p>
        El preu (Preu de Venda al Públic o PVP) es troba disponible en la descripció de cada producte, està expressat en euros i inclou l'IVA i/o qualsevol altre impost o taxa que resultés d'aplicació.
      </p>

      <p>
        Verificat el pagament, es procedirà a la posada a disposició dels productes o serveis conforme es detalla en la clàusula 3 d' aquestes condicions.
      </p>

      <p>
        <strong>5.2. Formes de pagament</strong>
      </p>

      <p>
        Les formes de pagament admeses són les següents:
      </p>

      <p>
        <span className="text-decoration-underline">(1) Targeta bancària</span>. El càrrec es realitza en temps real, a través de la passarel·la de pagament segura Redsys i un cop s'hagi comprovat que les dades comunicades són correctes. Amb l' objectiu de donar la màxima seguretat al sistema de pagament CATEB utilitza sistemes de pagament segur. En aquest sentit, les dades confidencials són transmeses directament i de forma encriptada (SSL) a l'entitat financera corresponent. El sistema d'encriptació SSL que utilitzem (TLS 1.1 i TLS 1.2) confereix total seguretat a la transmissió de dades a través de la xarxa. Les seves dades gaudeixen de total confidencialitat i protecció. Les dades sobre les targetes de crèdit no queden registrades en cap base de dades nostra. Estan únicament utilitzats en el TPV (Terminal Punt de Venda) virtual de la nostra entitat financera, a través de la seva Passarel·la de Pagament Segur (Redsys). Les targetes de crèdit estaran subjectes a comprovacions i autoritzacions per part de l' entitat emissora d' aquestes, però si aquesta entitat no autoritzés el pagament, el contracte no quedarà formalitzat fins que CATEB rebi el pagament.
      </p>
      <p>
        <span className="text-decoration-underline">(2) Càrrec en compte</span>. Per a aquells usuaris que siguin membres del CATEB el pagament podrà realitzar-se mitjançant càrrec en compte bancari que tinguin autoritzats els càrrecs del CATEB. En cas contrari, haurà de domiciliar els seus rebuts seguint les indicacions de la web i complimentat els formularis que es designin a l' efecte.
      </p>

    </div>
  )
}