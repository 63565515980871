export const CondGenSeccion1 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <strong>1.1. Objeto</strong>
      </p>
      <p>
        Los presentes términos y condiciones regularán la relación entre del Col·legi de l’Arquitectura Tècnica de Barcelona (Cateb), titular de esta Web, y los Clientes que compren o contraten los productos y servicios ofrecidos en <a href="http://www.libroedificiodigital.es" target="_blank" rel="noreferrer" className="link-primary">www.libroedificiodigital.es</a>.
      </p>

      <p>
        Estas condiciones establecen las normas de contratación de los productos y servicios disponibles en <a href="http://www.libroedificiodigital.es" target="_blank" rel="noreferrer" className="link-primary">www.libroedificiodigital.es</a>, debiendo ser leídas y aceptadas por el Cliente previamente a la contratación.
      </p>

      <p>
        <strong>1.2. Definiciones Productos y Servicios</strong>
      </p>

      <p>
        Se refiere a todos los productos y servicios (incluidos los digitales) ofrecidos en nuestro sitio web. A modo enunciativo, pero no limitativo están incluidas: las licencias de uso de las aplicaciones de pago único o bajo suscripción.
      </p>

      <p>
        En cuanto a la definición de cada producto, se entenderá por: (1) “aplicaciones”, software disponible a través de nuestro sitio web que requiera previo pago de un precio por licencia de uso mediante pago único o suscripción.
      </p>

      <p>
        <strong>Usuario</strong>. Será la persona física o jurídica que navegue a través de nuestra web o la que se dé de alta con nuestro perfil de usuario.
      </p>

      <p>
        <strong>Cliente</strong>. Serán los usuarios que realicen compras o contrataciones de los productos y servicios disponibles en nuestro sitio web.
      </p>

      <p>
        <strong>Consumidores y usuarios</strong>. Adquirirán esta condición las personas físicas que actúen con un propósito ajeno a su actividad comercial, empresarial, oficio o profesión. También podrán adquirir la condición de consumidor y usuario las personas jurídicas y las entidades sin personalidad jurídica que actúen sin ánimo de lucro en un ámbito ajeno a una actividad comercial o empresarial.
      </p>

      <p>
        <strong>1.3. Destinatarios de nuestros productos y servicios</strong>
      </p>

      <p>
        Nuestros productos y servicios son de uso profesional, y están destinados a profesionales, empresas y/o agentes relacionados con las actividades de arquitectura y de construcción.
      </p>

      <p>
        En caso de que el comprador reúna las condiciones legales establecidas para tener la condición de consumidor y usuario, resultará de aplicación el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias. La definición de consumidor y usuario se encuentra en la cláusula 1.2. de las presentes condiciones.
      </p>

      <p>
        <strong>1.4. Modificación de las condiciones.</strong>
      </p>

      <p>
        El CATEB se reserva el derecho a modificar, total o parcialmente, estas condiciones generales, siendo de aplicación las nuevas que acuerde desde el momento de su publicación en la página. En cualquier caso, las citadas modificaciones no tendrán efectos retroactivos sobre los productos y servicios previamente adquiridos.
      </p>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <strong>1.1. Objecte</strong>
      </p>
      <p>
        Els presents termes i condicions regularan la relació entre del Col·legi de l'Arquitectura Tècnica de Barcelona (Cateb), titular d'aquesta Web, i els Clients que comprin o contractin els productes i serveis oferts en <a href="http://www.llibreedificidigital.cat" target="_blank" rel="noreferrer" className="link-primary">www.llibreedificidigital.cat</a>.
      </p>

      <p>
        Aquestes condicions estableixen les normes de contractació dels productes i serveis disponibles en <a href="http://www.llibreedificidigital.cat" target="_blank" rel="noreferrer" className="link-primary">www.llibreedificidigital.cat</a>, havent de ser llegides i acceptades pel Client prèviament a la contractació.
      </p>

      <p>
        <strong>1.2. Definicions Productes i Serveis</strong>
      </p>

      <p>
        Es refereix a tots els productes i serveis (inclosos els digitals) oferts al nostre lloc web. A títol enunciatiu, però no limitatiu estan incloses: les llicències d' ús de les aplicacions de pagament únic o sota subscripció.
      </p>

      <p>
        Quant a la definició de cada producte, s'entendrà per: (1) “Aplicacions”, programari disponible a través del nostre lloc web que requereixi previ pagament d'un preu per llicència d'ús mitjançant pagament únic o subscripció.
      </p>

      <p>
        <strong>Usuari</strong>. Serà la persona física o jurídica que navegui a través de la nostra web o la que es doni d'alta amb el nostre perfil d'usuari.
      </p>

      <p>
        <strong>Client</strong>. Seran els usuaris que realitzin compres o contractacions dels productes i serveis disponibles al nostre lloc web.
      </p>

      <p>
        <strong>Consumidors i usuaris</strong>. Adquiriran aquesta condició les persones físiques que actuïn amb un propòsit aliè a la seva activitat comercial, empresarial, ofici o professió. També podran adquirir la condició de consumidor i usuari les persones jurídiques i les entitats sense personalitat jurídica que actuïn sense ànim de lucre en un àmbit aliè a una activitat comercial o empresarial.
      </p>

      <p>
        <strong>1.3. Destinataris dels nostres productes i serveis</strong>
      </p>

      <p>
        Els nostres productes i serveis són d'ús professional, i estan destinats a professionals, empreses i/o agents relacionats amb les activitats d'arquitectura i de construcció.
      </p>

      <p>
        En cas que el comprador reuneixi les condicions legals establertes per tenir la condició de consumidor i usuari, resultarà d'aplicació el Reial Decret Legislatiu 1/2007, de 16 de novembre, pel qual s'aprova el text refós de la Llei General per a la Defensa dels Consumidors i Usuaris i altres lleis complementàries. La definició de consumidor i usuari es troba en la clàusula 1.2. de les presents condicions.
      </p>

      <p>
        <strong>1.4. Modificació de les condicions.</strong>
      </p>

      <p>
        El CATEB es reserva el dret a modificar, totalment o parcialment, aquestes condicions generals, essent d' aplicació les noves que acordi des del moment de la seva publicació a la pàgina. En qualsevol cas, les esmentades modificacions no tindran efectes retroactius sobre els productes i serveis prèviament adquirits.
      </p>

    </div>
  )
}