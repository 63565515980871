export const AvisoLegalSeccion2 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <strong>2.1. ¿Cómo funciona nuestro sitio web?</strong>
      </p>
      <p>
        Se podrá navegar por nuestro sitio web sin necesidad de estar registrado como usuario. Podrá conocer los productos y servicios que ofertamos y contactar con nosotros.
      </p>
      <p>
        Para realizar compras y acceder a algunos de nuestros productos se requerirá un registro previo como usuario en el que se le solicitará un documento identificativo, un correo electrónico y contraseña como credenciales de acceso a su perfil de usuario. El registro le permitirá acceder a determinadas aplicaciones informáticas de pago que precisan la identificación de un usuario para el correcto funcionamiento. Para registrarse será preciso que el usuario, con carácter previo, se lea, comprenda y acepte las presentes condiciones y la Política de Privacidad.
      </p>
      <p>
        Las contrataciones de productos o servicios se regirán por las estipulaciones recogidas en las Condiciones generales y particulares de Contratación, que deberán ser leídas, comprendidas y aceptadas antes de realizar la contratación.
      </p>
      <p>
        En el resto de los casos, el usuario podrá navegar y utilizar libremente los recursos y contenidos ofrecidos en nuestro sitio web, respetando y cumpliendo con las obligaciones y limitaciones establecidas en las presentes condiciones.
      </p>
      <p>
        Estas Condiciones serán de aplicación juntamente con el resto de las condiciones que pudieran resultar de aplicación como la Política de Privacidad.
      </p>
      <p>
        <strong>2.2. Tipos de usuario de nuestro sitio web</strong>
      </p>
      <p className="text-decoration-underline">
        Usuarios.
      </p>
      <p>
        Serán aquellos usuarios que accedan y hagan uso de nuestro sitio web sin realizar compras ni registrarse.
      </p>
      <p className="text-decoration-underline">
        Usuarios registrados.
      </p>
      <p>
        <p>
          Serán aquellos usuarios que se hayan dado de alta en nuestro sitio web mediante la creación de un perfil de usuario.
        </p>
        <p>
          El registro en la web www.libroedificiodigital.es, es el paso previo  para adquirir los productos ofrecidos en la Web, en las condiciones que regulan cada producto que tendrán que ser aceptadas de forma expresa en el momento de su adquisición.
        </p>
        <p>
          Este registro no otorga ningún derecho sobre la web ni sobre sus productos salvo que se adquiera alguno de ellos de forma expresa.
        </p>
        <p>
          El registro es personal y no puede darse de alta a terceras personas, ni compartir las claves de acceso a los productos.
        </p>
        <p>
          El registro, sin compra, podrá ser eliminado por el Cateb, en cualquier momento sin previo aviso.
        </p>
      </p>
      <p className="text-decoration-underline">
        Usuarios/Cliente.
      </p>
      <p>
        Lo constituirán las personas que hayan realizado alguna compra o han sido dados de alta en nuestro sitio web, debiendo haber leído, comprendido y aceptado previamente las presentes condiciones, las Condiciones generales de contratación y la Política de privacidad.
      </p>
      <p>
        <strong>2.3. Seguridad de nuestro sitio web</strong>
      </p>
      <p>
        Nuestro sitio web utiliza el protocolo seguro de transferencia de hipertexto (Hypertext Transfer Protocol Secure, HTTPS) garantizando la confidencialidad de las comunicaciones.
      </p>
      <p>
        El protocolo HTTPS provee protección y garantía en el envío de información a través de internet, de manera que los datos y la información enviada desde nuestro sitio web al servidor no puede ser leída ni interceptada por terceros, ya que la información viaja cifrada.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <strong>2.1.¿Com el nostre lloc web?</strong>
      </p>
      <p>
        Es podrà navegar pel nostre lloc web sense necessitat d'estar registrat com a usuari. Podrà conèixer els productes i serveis que oferim i contactar amb nosaltres.
      </p>
      <p>
        Per realitzar compres i accedir a alguns dels nostres productes es requerirà un registre previ com a usuari en el qual se li sol·licitarà un document identificatiu, un correu electrònic i contrasenya com a credencials d'accés al seu perfil d'usuari. El registre li permetrà accedir a determinades aplicacions informàtiques de pagament que precisen la identificació d' un usuari per al correcte funcionament. Per registrar-se caldrà que l' usuari, amb caràcter previ, es llegeixi, comprengui i accepti les presents condicions i la Política de Privacitat.
      </p>
      <p>
        Les contractacions de productes o serveis es regiran per les estipulacions recollides en les Condicions generals i particulars de Contractació, que hauran de ser llegides, compreses i acceptades abans de realitzar la contractació.
      </p>
      <p>
        En la resta dels casos, l'usuari podrà navegar i utilitzar lliurement els recursos i continguts oferts en el nostre lloc web, respectant i complint amb les obligacions i limitacions establertes en les presents condicions.
      </p>
      <p>
        Aquestes Condicions seran d' aplicació juntament amb la resta de les condicions que poguessin resultar d' aplicació com la Política de Privacitat.
      </p>
      <p>
        <strong>2.2.Tipus d' usuari del nostre lloc web</strong>
      </p>
      <p className="text-decoration-underline">
        Usuaris.
      </p>
      <p>
        Seran aquells usuaris que accedeixin i facin ús del nostre lloc web sense realitzar compres ni registrar-se.
      </p>
      <p className="text-decoration-underline">
        Usuaris registrats.
      </p>
      <p>
        <p>
          Seran aquells usuaris que s'hagin donat d'alta al nostre lloc web mitjançant la creació d'un perfil d'usuari.
        </p>
        <p>
          El registre al web www.libroedificiodigital.es, és el pas previ per adquirir els productes oferts a la Web, en les condicions que regulen cada producte que hauran de ser acceptades de forma expressa en el moment de la seva adquisició.
        </p>
        <p>
          Aquest registre no atorga cap dret sobre la web ni sobre els seus productes llevat que s'adquireixi algun d'ells de forma expressa.
        </p>
        <p>
          El registre és personal i no es pot donar d' alta a terceres persones, ni compartir les claus d' accés als productes.
        </p>
        <p>
          El registre, sense compra, podrà ser eliminat pel Cateb, en qualsevol moment sense previ avís.
        </p>
      </p>
      <p className="text-decoration-underline">
        Usuaris/Client.
      </p>
      <p>
        El constituiran les persones que hagin realitzat alguna compra o han estat donats d'alta al nostre lloc web, havent d'haver llegit, comprès i acceptat prèviament les presents condicions, les Condicions generals de contractació i la Política de privacitat.
      </p>
      <p>
        <strong>2.3. Seguretat del nostre lloc web</strong>
      </p>
      <p>
        El nostre lloc web utilitza el protocol segur de transferència d'hipertext (Hypertext Transfer Protocol Secure, HTTPS) garantint la confidencialitat de les comunicacions.
      </p>
      <p>
        El protocol HTTPS proveeix protecció i garantia en l'enviament d'informació a través d'internet, de manera que les dades i la informació enviada des del nostre lloc web al servidor no pot ser llegida ni interceptada per tercers, ja que la informació viatja xifrada.
      </p>
    </div>
  )
}