export const AvisoLegalSeccion7 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El uso de la Web es gratuito, pero si se quiere adquirir alguno de sus productos, el precio vendrá marcado en cada uno de ellos.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        L'ús de la Web és gratuït, però si es vol adquirir algun dels seus productes, el preu vindrà marcat en cadascun d'ells.
      </p>
    </div>
  )
}