import { useState } from 'react';
import { ModalCambioContrasenia } from '../ModalCambioContrasenia';

interface IBody {
    children: React.ReactNode;
    setshowNavLeft?: (boolean: boolean) => boolean,
    showNavLeft?: boolean,
    label?: string
}

const Body = ({ children, setshowNavLeft = (() => false), showNavLeft, label = '' }: IBody) => {

    // Modal Cambiar credenciales

    const [showCambiarPassword, setShowCambiarPassword] = useState({
        value: false,
        payload: null
    });

    const handleCloseCambiarPassword = () => setShowCambiarPassword({
        value: false,
        payload: null
    })

    const handleShowCambiarPassword = (payload: any) => setShowCambiarPassword({
        value: true,
        payload
    });

    return (
        <section className="LayoutLibro__contentright w-100 d-flex flex-column flex-grow-1 position-relative">
            
            <main className='LayoutLibro__contentmain flex-grow-1 w-100 justify-content-end position-relative'>
                {children}
            </main>

            <ModalCambioContrasenia
                data={showCambiarPassword}
                handleClose={handleCloseCambiarPassword}
            />
        </section>
    )
}

export default Body