import { isEmpty } from 'lodash';
import React, { lazy } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RootState } from '../store';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import Login from '../pages/Login/Login';

import LibroRouter from '../modules/libro/router/LibroRouter';
import { LoaderLayoutLibro } from '../components/Layouts/LayoutLibro/LoaderLayoutLibro/LoaderLayoutLibro';
import { LoaderLayoutUsuario } from '../components/Layouts/LayoutUsuario/LoaderLayoutUsuario/LoaderLayoutUsuario';
import Index from '../pages/Index/Index';
import { isUsuarioNormal } from '../helpers/helpers';
import AvisoLegal from '../pages/AvisoLegal/AvisoLegal';
import Contacto from '../pages/Contacto/Contacto';
import ProgramacionGestion from '../modules/programacion-gestion/router/ProgramacionGestion';
import CondicionesContratacion from '../pages/CondicionesContratacion/CondicionesContratacion';

const UsuarioRouter = lazy(() => import('../modules/usuario/router/UsuarioRouter').then((module: any) => (
    { default: module.default }
)));

export const ModulesRouter = () => {

    const { auth } = useSelector((state: RootState) => state.auth)

    const isLogged = !isEmpty(auth)

    const isUserNormal = isUsuarioNormal(auth?.user || {})

    return (
        <Routes>

            {
                !isLogged ?
                    <Route path='/' element={<PublicRoute isAuthenticated={isLogged} />}>

                        <Route index element={<Index />} />

                        <Route path='login' element={<Login />} />

                    </Route>
                    :
                    <Route path='/*' element={<PrivateRoute isAuthenticated={isLogged} isAllowedRol={true} />}>
                        
                        {
                            isUserNormal ?
                                <>
                                    <Route
                                        path='*'
                                        element={
                                            <React.Suspense fallback={<LoaderLayoutUsuario />}>
                                                <UsuarioRouter />
                                            </React.Suspense>
                                        }
                                    />

                                    <Route
                                        path="edificios/:id/detalle/*"
                                        element={
                                            <React.Suspense fallback={<LoaderLayoutLibro />}>
                                                <LibroRouter />
                                            </React.Suspense>
                                        }
                                    />
                                </>
                                :
                                <>
                                    <Route
                                        path='*'
                                        element={
                                            <React.Suspense fallback={<LoaderLayoutUsuario />}>
                                                <UsuarioRouter />
                                            </React.Suspense>
                                        }
                                    />

                                    <Route
                                        path="edificios/:id/detalle/*"
                                        element={
                                            <React.Suspense fallback={<LoaderLayoutLibro />}>
                                                <LibroRouter />
                                            </React.Suspense>
                                        }
                                    />

                                    <Route
                                        path="edificios/:id/planificacion/*"
                                        element={
                                            <React.Suspense fallback={<LoaderLayoutLibro />}>
                                                <ProgramacionGestion />
                                            </React.Suspense>
                                        }
                                    />
                                </>
                        }
                    </Route>
            }

            <Route path='contactanos' element={<Contacto />} />

            <Route path='aviso-legal' element={<AvisoLegal />} />

            <Route path='condiciones-contratacion' element={<CondicionesContratacion />} />

            <Route path="*" element={<Navigate to="/" replace />} />

        </Routes>
    )
}