import { parseAny, parseArray, parseNumber, parseObject, parseString, receiveCheck, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../../../../controllers/controllers"

const FicheroITEAdapters = {
    
    GET_DEFICIENCIAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    created_at: parseString(item.created_at),
                    descripcio: parseString(item.descripcio),
                    fotos: parseArray(item.fotos),
                    gravetat_codi: parseString(item.gravetat_codi),
                    gravetat_des: parseString(item.gravetat_des),
                    gravetat_mestre_codi: parseString(item.gravetat_mestre_codi),
                    gravetat_mestre_des: parseString(item.gravetat_mestre_des),
                    ite_id: parseString(item.ite_id),
                    observacio: parseString(item.observacio),
                    tipus_deficiencia_codi: parseString(item.tipus_deficiencia_codi),
                    tipus_deficiencia_des: parseString(item.tipus_deficiencia_des),
                    tipus_deficiencia_mestre_codi: parseString(item.tipus_deficiencia_mestre_codi),
                    tipus_deficiencia_mestre_des: parseString(item.tipus_deficiencia_mestre_des),
                    updated_at: parseString(item.updated_at),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_RESUMEN: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },


    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    descripcio: parseString(item.descripcio),
                    periodicitat: parseNumber(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    document_referencia: parseString(item.document_referencia),
                    responsable: parseString(item.responsable),
                    obligatorietat: receiveCheck(item.obligatorietat, 'obligatorietat'),
                    manual_habitatge: receiveCheck(item.manual_habitatge, 'manual_habitatge'),
                    data_inici_any: parseNumber(item.data_inici_any),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    file: parseAny(item.file),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_AGREGAR_A_DEFICIENCIAS: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    elemento_contructivo_id: parseNumber(item.elemento_contructivo_id),
                    deficiencias: parseArray(item.deficiencias),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_AGREGAR_DEFICIENCIA: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    elemento_contructivo_id: parseNumber(item.elemento_contructivo_id),
                    deficiencia_id: parseNumber(item.deficiencia_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    descripcio: parseString(item.descripcio),
                    periodicitat: parseNumber(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    document_referencia: parseString(item.document_referencia),
                    responsable: parseString(item.responsable),
                    obligatorietat: sendCheck(item.obligatorietat),
                    manual_habitatge: sendCheck(item.manual_habitatge),
                    data_inici_any: parseNumber(item.data_inici_any),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}

export {
    FicheroITEAdapters,
}