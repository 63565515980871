import { useEffect, useState } from 'react';
import './MenuList.scss'

export const MenuList = ({ navLinks, active, setActive }: any) => {

    return <>
        <div className='MenuList'>
            <ul className='list-unstyled p-0 m-0 position-static'>
                {
                    navLinks?.map((item: any) => {
                        return (
                            <li
                                className={`MenuList__li ${active === item.id ? "MenuList__li--active" : ""}`}
                                key={item.id}
                                onClick={() => setActive(item.id)}
                            >
                                {item.title}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </>
};
