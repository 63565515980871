import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Index.css'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'

export const ImageCard = ({ item, onClick, onClickEdit, onClickDelete, name, description, urlImage, heightRem = 14 }: any) => {

  const [imgEl, setimgEl] = useState<any>(null);


  useEffect(() => {

    if (typeof item?.name == 'string') {

      const reader = new FileReader();

      reader.onload = (e: any) => {
        setimgEl(e.target.result)
      }
      reader.readAsDataURL(item);

    }

  }, [item]);


  return (
    <article className='ImageCard' >
      <span className='ImageCard__options'>
      {
        onClickEdit && <Button variant='outlined' size='small' className='ImageCard__option' onClick={onClickEdit}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      }
      {
        onClickDelete && <Button variant='outlined' size='small' className='ImageCard__option' onClick={onClickDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      }
      </span>
      
      <div onClick={() => onClick ? onClick() : null} className={onClick ? 'pointer' : ''} style={{ height: heightRem + 'rem' }}>
        {
          (imgEl || urlImage) ?
            <img src={imgEl || urlImage} alt="" />
            :
            <div className='ImageCard__fondo'></div>
        }

        <div className='ImageCard__content d-flex flex-column justify-content-end'>
          {
            name && <h5 className='fw-bold mb-0'>{name}</h5>
          }
          {
            description && <small className='mt-1'>{description}</small>
          }
        </div>
      </div>
    </article>
  )
}
