import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { findGetParameter, removeParam } from '../../../helpers/helpers'

export interface LanguageState {
  language: string
}

const initialState: LanguageState = {
  language: setInitialLanguage(),
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
      localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE', action.payload)
    }
  },
})

function setInitialLanguage() {

  const initialLanguage = String(findGetParameter('locale') || '').toUpperCase() || localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE') || process.env.REACT_APP_LANGUAGE_DEFAULT || ''
  
  localStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE + '_LANGUAGE', initialLanguage)

  window.history.pushState({}, '' , removeParam("locale", window.location.href))

  return initialLanguage
}

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions

// export default counterSlice.reducer