import { useEffect, useState } from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material"
import { useLang } from "../../../../../../language";
import { InputTextarea } from "../../../../../../components/Inputs/InputTextarea";
import { ButtonAdvanced } from "../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { ModalInfo } from "../../../../../../components/Modals/ModalInfo";
import { useModalData } from "../../../../../../hooks/useModalData";
import { MaestrosServices } from "../../../../../../controllers/maestros/maestros.services";
import { isStatusSuccess } from "../../../../../../helpers/helpers";
import { DocumentacionServices } from "./controllers/documentacion.services";
import { InputFileService } from "./InputFileService";
import { isEmpty } from "lodash";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";
import { PotencialMejoraServices } from "../controllers/potencial-mejora.services";
import { LoaderModalFormPrimary } from "../../../../../../components/Loaders/LoaderModalFormPrimary";
import { useForm } from "../../../../../../hooks/useForm";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";
import { RowDocPotencialMejorasEdificio } from "./RowDocLibroEdificio";
import { TabsAdvanced } from "../../../../../../components/TabsAdvanced/TabsAdvanced";

import { ResumenCostes } from "./ResumenCostes/ResumenCostes";
import { SeccionResumenDescarga } from "./SeccionResumenDescarga";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

export const ResumenDescarga = ({ idedificio }: any) => {

    const Lang = useLang()

    const [dataModal_InfoError, handleShowModal_InfoError, handleCloseModal_InfoError] = useModalData()

    const [formPotencialMejora, handleInputPotencialMejora, handleFormPotencialMejora] = useForm({
        descripcion: '',
    })

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [fetchDocumentacionGET, fetchDocumentacionGETData] = DocumentacionServices.GET()

    const [fetchPotencialMejoraGET, fetchPotencialMejoraGETData] = PotencialMejoraServices.GET(idedificio)

    const [fetchPotencialMejoraPATCH, fetchPotencialMejoraPATCHData] = PotencialMejoraServices.PATCH(idedificio)

    const [loaderAll, setloaderAll] = useState(false);

    useEffect(() => {

        setloaderAll(true)

        fetchDocumentacionGET({
            params: {
                idedifici: idedificio,
            }
        })

        fetchParamsPublicGET({
            body: [
                {
                    parametroPadre: "tipus_document_milloras",
                },
            ]
        })

        fetchPotencialMejoraGET()

    }, [])

    useEffect(() => {

        if (isStatusSuccess(fetchPotencialMejoraGETData.status) && isStatusSuccess(fetchDocumentacionGETData.status)) {

            handleFormPotencialMejora({
                ...fetchPotencialMejoraGETData.data,
            })

            setloaderAll(false)
        }

    }, [fetchPotencialMejoraGETData, fetchDocumentacionGETData]);

    const handleOnBlurAdvanced = (e: any) => {

        // const nuevoValor = e.target.value

        // if (valueDefault !== nuevoValor) {

        fetchPotencialMejoraPATCH({
            body: {
                descripcion: formPotencialMejora.descripcion
            }
        })
        // }
    }
    const { language } = useSelector((state: RootState) => state.language)

    const label__MaxSizeMB = '10 MB'

    return (
        <>
            <Grid container spacing={3} rowSpacing={3} justifyContent='center' className="mb-4">

                <Grid item xs={12} md={11} lg={10}>

                    <Card>
                        <CardContent>

                            <TabsAdvanced>
                                <TabsAdvanced.Head
                                    // centered={true}
                                    values={[
                                        {
                                            id: 'datos-adicionales',
                                            name: Lang('DATOS_ADICIONALES'),
                                        },
                                        {
                                            id: 'resumen-costes',
                                            name: Lang('RESUMEN_COSTES'),
                                        },
                                    ]}
                                />
                                <TabsAdvanced.Body>
                                    <section className="pt-3">
                                        {
                                            loaderAll ?
                                                <Grid container columnSpacing={3} rowSpacing={1}>
                                                    <Grid item xs={12}>
                                                        <LoaderModalFormPrimary />
                                                    </Grid>
                                                </Grid>
                                                :
                                                <>
                                                    <Grid container columnSpacing={3} rowSpacing={1}>

                                                        <SeccionResumenDescarga lang={language} />

                                                        <Grid item xs={12} lg={7}>

                                                            <Grid container spacing={3} rowSpacing={3}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1" component="h4">
                                                                        {Lang('DOCUMENTACION')} ({Lang('MAX')}. {label__MaxSizeMB}):
                                                                    </Typography>
                                                                </Grid>

                                                                {
                                                                    isEmpty(fetchParamsPublicGETData?.data) ?
                                                                        <TableAdvancedLoader />
                                                                        :
                                                                        <>
                                                                            {
                                                                                fetchDocumentacionGETData?.data && fetchParamsPublicGETData?.data?.['tipus_document_milloras']?.map((item: any, index: number) => {

                                                                                    const document = fetchDocumentacionGETData?.data?.find((item2: any) => item2.tipus_document === item.id) || {}

                                                                                    return (
                                                                                        <Grid item xs={12} key={index}>

                                                                                            <InputFileService
                                                                                                id={document?.id}
                                                                                                label={item.name}
                                                                                                path={document?.url_download || ''}
                                                                                                name={item.id}
                                                                                                idedificio={idedificio}
                                                                                                fetchSuccess={() => {
                                                                                                    fetchDocumentacionGET({
                                                                                                        params: {
                                                                                                            idedifici: idedificio,
                                                                                                        }
                                                                                                    })
                                                                                                }}
                                                                                            // onChange={handleInputDocumento}
                                                                                            // readOnly={data?.action === 'EDIT'}
                                                                                            />

                                                                                        </Grid>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                }

                                                                <Grid item xs={12}>
                                                                    <h4 className="mb-0">
                                                                        <span>{Lang('TEXTO_PRELIMINAR')}</span> <FontAwesomeIcon icon={faInfoCircle} className="ms-2 cursor-pointer" style={{ 'fontSize': '1.1rem' }} onClick={handleShowModal_InfoError} />
                                                                    </h4>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <InputTextarea
                                                                        label={`${Lang('TEXTO_PRELIMINAR_PLAN')}: *`}
                                                                        name='descripcion'
                                                                        value={formPotencialMejora.descripcion}
                                                                        onChange={handleInputPotencialMejora}
                                                                    // onBlur={handleOnBlurAdvanced}
                                                                    // onFocus={handleOnFocusValueDefault}
                                                                    />

                                                                </Grid>

                                                                <Grid item xs={12} className="d-flex">
                                                                    <ButtonAdvanced
                                                                        variant="outlined" type="submit" size='small' className="ms-auto"
                                                                        handleSubmit={handleOnBlurAdvanced} loadings={[fetchPotencialMejoraPATCHData.loading]}
                                                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                                                    />
                                                                </Grid>

                                                            </Grid>

                                                        </Grid>

                                                    </Grid>

                                                    <ModalStatusServer fetchData={fetchPotencialMejoraPATCHData} />

                                                </>
                                        }
                                    </section>
                                    <section className="pt-3">

                                        <ResumenCostes idedificio={idedificio} />

                                    </section>
                                </TabsAdvanced.Body>
                            </TabsAdvanced>

                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                className="mt-4"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <div className="border-top d-flex justify-content-end pt-3">

                                            <RowDocPotencialMejorasEdificio
                                                idEdificio={idedificio}
                                            />

                                        </div>
                                    </Grid>

                                </Grid>
                            </Box>

                        </CardContent>
                    </Card>

                </Grid>
            </Grid>

            <ModalInfo
                data={dataModal_InfoError}
                handleClose={handleCloseModal_InfoError}
                size = 'lg'
                message={
                    <>
                        {/* <p>
                            {Lang('P_EJEMPLO_TEXTO_PRELIMINAR')}
                        </p> */}
                        <div className="text-start" dangerouslySetInnerHTML={{ __html: Lang('P_EJEMPLO_TEXTO_PRE') }} />

                    </>
                }
            />

        </>
    );
};

const TableAdvancedLoader = () => {
    return (
        <>
            <Grid container columnSpacing={0} rowSpacing={0} className="mt-3">

                <Grid item xs={12}>
                    <Placeholder className='px-4 py-1' height='1rem' />
                </Grid>
                <Grid item xs={12}>
                    <Placeholder className='px-4 py-1 delay-1' height='2rem' />
                </Grid>

                <Grid item xs={12} className="mt-3">
                    <Placeholder className='px-4 py-1' height='1rem' />
                </Grid>
                <Grid item xs={12}>
                    <Placeholder className='px-4 py-1 delay-1' height='2rem' />
                </Grid>
            </Grid>
        </>
    )
}