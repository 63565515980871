import { Grid, Card, CardContent } from "@mui/material";
import { useState, useEffect } from 'react'
import { TableProyectos } from "./TableProyectos";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { useLang } from "../../../../language";
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../helpers/helpers";
import { ProyectosServices } from "./controllers/proyectos.services";
import { useForm } from "../../../../hooks/useForm";

export const Proyectos = ({ idedificio, id, setproyectoSelected }: any) => {

    const Lang = useLang()


    const [fetchMiElemConst, fetchMiElemConstData] = ProyectosServices.GET()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            order: '',
            edificioid: idedificio,
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edificioid: params.edificioid || allValuesSearch.edificioid,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edificioid')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMiElemConst({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'edificioid')

        fetchMiElemConst({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


    return (
        <Grid container spacing={2} columnSpacing={3}>

            <Grid item xs={12} className="mb-0 mt-3">
                <TableProyectos
                    data={fetchMiElemConstData.data?.items}
                    // data={elementSelected?.operations}
                    // loading={false}
                    loading={fetchMiElemConstData.loading}
                    total={fetchMiElemConstData.data?.total}
                    perPage={fetchMiElemConstData.data?.per_page}

                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    handleInputChangeSearch={handleInputChangeSearch}

                    idedificio={idedificio}
                    fetchSuccess={() => {
                        fetchMiElemConst({
                            params: {
                                ...getValuesParams(formValuesSearch)
                            }
                        })
                    }}
                    id={id}
                    setproyectoSelected={setproyectoSelected}
                />
            </Grid>

        </Grid>
    );
};
