export const CondGenSeccion6 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        De conformidad con aquello dispuesto en el RDL 1/2007 de 16 de noviembre, por el que se aprueba el “Texto Refundido de los Consumidores y Usuarios y otras leyes complementarias”, el cliente, dispondrá de un período de dos años de garantía en los productos para manifestar su falta de conformidad, excepto cuando la falta de conformidad sea incompatible con la naturaleza del producto o la índole de la falta de conformidad.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        De conformitat amb allò disposat en el RDL 1/2007 de 16 de novembre, pel qual s'aprova el "Text Refós dels Consumidors i Usuaris i altres lleis complementàries", el client, disposarà d'un període de dos anys de garantia en els productes per manifestar la seva falta de conformitat, excepte quan la falta de conformitat sigui incompatible amb la naturalesa del producte o l'índole de la falta de conformitat.
      </p>
    </div>
  )
}