export const CondGenSeccion9 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El periodo de vigencia del contrato se inicia con la compra del producto y finaliza en el momento del cierre del producto o la finalización del periodo de suscripción.
      </p>
      <p>
        En todo caso, el CATEB se reserva el derecho a suspender el servicio con carácter definitivo, con un aviso previo de 2 meses, durante los cuales el cliente podrá descargar su información.
      </p>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        El període de vigència del contracte s' inicia amb la compra del producte i finalitza en el moment del tancament del producte o la finalització del període de subscripció.
      </p>
      <p>
        En tot cas, el CATEB es reserva el dret a suspendre el servei amb caràcter definitiu, amb un avís previ de 2 mesos, durant els quals el client podrà descarregar la seva informació.
      </p>
    </div>
  )
}