import { Button, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { useLang } from '../../language';
import ModalAdvanced from '../ModalAdvanced/ModalAdvanced';
import { isStatusSuccess } from '../../helpers/helpers';

// import './Modal.scss'

export const ModalStatusServer = ({ fetchData, onlySuccess, onlyError, message2 = '', activeLoading, customData,
    disableStatus }: any) => {
    
    const [showStatus, setShowStatus] = useState(false);

    const [status, setStatus] = useState<any>({
        status: '',
        message: ''
    });

    const codeFormError = 422

    const handleCloseStatus = () => {
        setShowStatus(false)
    };

    const handleShowStatus = () => setShowStatus(true);

    const Lang = useLang()

    useEffect(() => {

        if (fetchData.loading && activeLoading) {

            setStatus({
                status: '',
                message: <>
                    <p>{<CircularProgress color="primary" />}</p>
                    {/* <p>{fetchData.error?.data?.errors}</p> */}
                </>
            })
            handleShowStatus()

        } else {

            if (onlyError) {

                if (fetchData.error && fetchData.error?.code !== codeFormError) {
                    setStatus({
                        status: customData?.[fetchData.status]?.['status'] || 'Error',
                        message: <>
                            <p>{customData?.[fetchData.status]?.['message'] || fetchData.error?.message}</p>
                            {/* <p>{fetchData.error?.data?.errors}</p> */}
                        </>
                    })
                    handleShowStatus()
                }

            } else if (onlySuccess) {

                if (isStatusSuccess(fetchData.status)) {
                    setStatus({
                        status: customData?.[fetchData.status]?.['status'] || Lang('OPERACION_EXITOSA'),
                        message: customData?.[fetchData.status]?.['message'] || fetchData.data?.message || Lang('PARRAFO_OPERACION_EXITO')
                    })
                    handleShowStatus()
                }

            } else {

                if (fetchData.error && fetchData.error?.code !== codeFormError) {
                    setStatus({
                        status: customData?.[fetchData.status]?.['status'] || 'Error',
                        message: <>
                            <p>{customData?.[fetchData.status]?.['message'] || fetchData.error?.message}</p>
                            {/* <p>{fetchData.error?.data?.errors}</p> */}
                        </>
                    })
                    handleShowStatus()
                }

                if (fetchData.data) {

                    setStatus({
                        status: customData?.[fetchData.status]?.['status'] || Lang('OPERACION_EXITOSA'),
                        message: customData?.[fetchData.status]?.['message'] || fetchData.data?.message || Lang('PARRAFO_OPERACION_EXITO')
                    })
                    handleShowStatus()
                }
            }
        }

    }, [fetchData])

    return (
        <div>
            <ModalAdvanced data={{ value: showStatus }} handleClose={handleCloseStatus}>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {disableStatus ? '' : status.status}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    <div className='text-center' style={{ lineHeight: '1.5' }}>
                        {status.message}
                    </div>
                </ModalAdvanced.Body>

                <ModalAdvanced.Footer className='border-0 d-flex justify-content-between mt-2'>
                    <div></div>
                    {
                        !fetchData.loading &&
                        <Button variant="outlined" onClick={handleCloseStatus} className='ms-auto'>
                            {Lang('SALIR')}
                        </Button>
                    }
                </ModalAdvanced.Footer>


            </ModalAdvanced>

        </div>
    )
}
