import { useNavigate } from "react-router";
import '../PagesDefault.scss'
import { BreadcrumbAdvanced } from "../../components/BreadcrumbAdvanced/BreadcrumbAdvanced";
import { Container, Typography } from "@mui/material";
import { useLang } from "../../language";
import { AccordionAdvanced } from "../../components/AccordionAdvanced/AccordionAdvanced";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { LayoutPage } from "../../components/Layouts/LayoutPage/LayoutPage";
import './AvisoLegal.scss'
import { FormAvisoLegal } from "./FormAvisoLegal";
import { useEffect } from "react";

const AvisoLegal = () => {

    const navigate = useNavigate()


    const Lang = useLang()

    const BreadcrumbAdvancedItems = [
        {
            title: Lang('INICIO'),
            link: '/',
        },
        {
            title: Lang('AVISO_LEGAL_CONDICIONES_USO_WEB'),
            link: '/',
        }
    ]

    useEffect(() => {
      
        window.scrollTo(0, 0)
        
    }, []);
    


    return (
        <LayoutPage>
            <Container maxWidth="md" className='my-auto AvisoLegal'>

                <BreadcrumbAdvanced
                    items={BreadcrumbAdvancedItems}
                    className='mb-4'
                />

                <FormAvisoLegal />

            </Container>
        </LayoutPage>
    );
};

export default AvisoLegal;
