import { useState, useEffect } from 'react';

import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import './DropdownAdvanced.scss'

export const DropdownAdvanced = ({ objectList, className = '', variant, size, onClickOpen, lastDivider,
    classNameContent = '', children, onClose }: any) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {

        setAnchorEl(null);

    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    let objectProps: any = {}

    if (size) {
        objectProps.size = size
    }

    useEffect(() => {

        if (open) {

            if (onClickOpen) {
                onClickOpen()
            }
        }

    }, [open]);

    useEffect(() => {
      
        if (onClose) {
            handleClose()
        }
        
    }, [onClose]);
    

    return (
        <>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className='DropdownAdvanced__title'>
                <Typography className='px-3 pointer LayoutPrimary__Navtop--user' onClick={handleClick}>{objectList.title} <FontAwesomeIcon icon={faChevronDown} className='ml-1' /> </Typography>
            </Box> */}
            <Button
                variant={variant || 'text'} className={`px-3 ${className}`}
                onClick={handleClick} {...objectProps}>
                {/* <Typography className='pointer LayoutPrimary__Navtop--user' >  </Typography> */}
                <span>{objectList.title} <FontAwesomeIcon icon={faChevronDown} className='ml-1' /></span>
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleOpen}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                className={classNameContent}
            >
                {
                    objectList?.list?.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                {
                                    (lastDivider && (index === objectList?.list?.length - 1)) && <Divider />
                                }
                                <MenuItem onClick={item.action} key={index}>
                                    {item.content}
                                </MenuItem>
                            </div>
                        )
                    })
                }
                <div onClick={(e) => e.stopPropagation()}>
                    {
                        children
                    }
                </div>
            </Menu>
        </>
    );
};
