import { useForm } from "../../../hooks/useForm"
import { useValidator } from "../../../hooks/useValidator"
import { useLang } from "../../../language"
import { InputText } from "../../Inputs"

import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from '@mui/material';
import { validExiste, validIsEqual } from "../../../helpers/validations"

export const ModalCambioContrasenia = ({ showCambiarPassword, handleClose, data }: any) => {

    const Lang = useLang()

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };

    const [validLogin, handleValidationLogin] = useValidator({
        password: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        password_confirmation: [
            { functionValidMultipleKeys: (form: any) => validIsEqual(form.password, form.password_confirmation), msgError: Lang('VALID_CONFIRMAR_CLAVE'), isOk: true },
        ],
    })

    const [formLogin, handleInputLogin, handleFormLogin, handleResetFormLogin] = useForm({
        password: '',
        password_confirmation: '',
    })

    return (

        <>
            <Modal
                open={data.value}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={data.value}>
                    <Grid container spacing={1} sx={style} className='z-depth-4'>

                        <Grid item xs={12}>
                            <section className='ModalHeader border-0 text-center'>
                                <Typography variant="h6" component="h2">
                                    {Lang('CAMBIAR_CONTRASENIA')}
                                </Typography>

                            </section>
                        </Grid>

                        <Grid item xs={12}>
                            <section className='ModalBody text-center d-flex flex-column'>

                                <div className="row justify-content-center py-3">
                                    <Box sx={{ width: '100%' }}>

                                        <Box
                                            component="form"
                                            noValidate
                                            autoComplete="off"
                                        >

                                            <InputText
                                                label={Lang('CONTRASENIA_ACTUAL')}
                                                type='password'
                                                name='password'
                                                value={formLogin.password}
                                                onChange={handleInputLogin}
                                                localValidation={validLogin.password}
                                                className='mt-2 mb-3 w-100'
                                            />

                                            <InputText
                                                label={Lang('CONFIRMAR_CLAVE')}
                                                type='password'
                                                name='password_confirmation'
                                                value={formLogin.password_confirmation}
                                                onChange={handleInputLogin}
                                                localValidation={validLogin.password_confirmation}
                                                className='mt-2 mb-3 w-100'
                                            />
                                        </Box>

                                    </Box>
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={12}>
                            <section className='ModalBody text-center d-flex flex-column'>
                                <Button variant="contained">
                                    {Lang('SALIR')}
                                </Button>
                            </section>
                        </Grid>

                    </Grid>
                </Fade>

            </Modal>
        </>
    )
}