import '../PagesDefault.scss'
import { BreadcrumbAdvanced } from "../../components/BreadcrumbAdvanced/BreadcrumbAdvanced";
import { Container } from "@mui/material";
import { useLang } from "../../language";
import { LayoutPage } from "../../components/Layouts/LayoutPage/LayoutPage";
import './CondicionesContratacion.scss'
import { FormCondicionesContratacion } from './FormCondicionesContratacion';
import { useEffect } from 'react';

const CondicionesContratacion = () => {

    const Lang = useLang()

    const BreadcrumbAdvancedItems = [
        {
            title: Lang('INICIO'),
            link: '/',
        },
        {
            title: Lang('CONDICIONES_CONTRATACION'),
            link: '/',
        }
    ]

    
    useEffect(() => {
      
        window.scrollTo(0, 0)
        
    }, []);

    return (
        <LayoutPage>
            <Container maxWidth="md" className='my-auto AvisoLegal'>

                <BreadcrumbAdvanced
                    items={BreadcrumbAdvancedItems}
                    className='mb-5'
                />

                <FormCondicionesContratacion />

            </Container>
        </LayoutPage>
    );
};

export default CondicionesContratacion;
