import { useState } from "react";
import { Grid } from "@mui/material";
import { TableIngresosProyecto } from "./TableIngresosProyecto";
import { TipoIngresosStaticServices } from "../../../../../../../controllers/statics/statics.services";

export const IngresosProyecto = ({ readOnly, idEdificio, fetchEdificioGETData, idCurrentProject, 
    data, fetchPlanificacionFinancieraGET }: any) => {

    const tipoIngresosStaticServices = TipoIngresosStaticServices.GET()

    const [showFilter, setshowFilter] = useState(false);

    return (
        <>
            <div className="mt-0">
                {/* <Container maxWidth="lg" className='mt-3 mb-auto py-2'> */}

                    <Grid container>

                        <Grid item xs={12} className='py-0 py-lg-0'>

                            <section>
                                <TableIngresosProyecto
                                    data={data}
                                    loading={false}
                                    total={null}
                                    perPage={null}

                                    idEdificio={idEdificio}
                                    fetchEdificioGETData={fetchEdificioGETData}

                                    setshowFilter={setshowFilter}
                                    showFilter={showFilter}
                                    idCurrentProject={idCurrentProject}
                                    readOnly={readOnly}
                                    tipoIngresosStaticServices={tipoIngresosStaticServices}
                                    fetchPlanificacionFinancieraGET={fetchPlanificacionFinancieraGET}
                                />
                            </section>

                        </Grid>

                    </Grid>

                {/* </Container> */}
            </div>

        </>
    );
};
