import { Tooltip, Typography } from '@mui/material'
import React from 'react'
import { ButtonAdvanced } from '../../ButtonAdvanced/ButtonAdvanced'
import { DropdownAdvanced } from '../../DropdownAdvanced/DropdownAdvanced'

interface ITableAdvancedHead {
    checkAll?: boolean,
    incluidos?: Array<any>,
    title?: any,
    allActions?: any,
    classNameTitle?: string,
}

export const TableAdvancedHead = ({ checkAll, incluidos = [], title, allActions, classNameTitle = '' }: ITableAdvancedHead) => {
    return (
        <div className="d-flex flex-wrap justify-content-between align-items-start mb-3">

            <Typography variant='h6' className={`m-0 p-0 fw-bolder ${classNameTitle}`}>
                {title}
            </Typography>

            <div className='d-flex flex-wrap align-items-center TableAdvancedHead__AllActions'>
                {
                    allActions?.map((item: any, index: any) => {
                        return (
                            <WrapperTooltip tooltip={item.tooltip} key={index} >
                                {
                                    item.multipleActions ? (
                                        <>
                                            <DropdownAdvanced
                                                className='border-0 ms-3'
                                                variant={item.variant || 'contained'}
                                                size='small'
                                                objectList={
                                                    {
                                                        title: item.label,
                                                        list: item.multipleActions
                                                    }
                                                }
                                            />
                                        </>
                                        // <DropdownButton
                                        //     key={`lvl2-${index}`}
                                        //     align="end"
                                        //     title={item.label}
                                        //     id="dropdown-menu-align-end"
                                        //     className='border-0 ms-3'
                                        //     variant={item.variant ? item.variant : 'success'}
                                        //     size="sm"
                                        //     disabled={item.disabled}
                                        // >
                                        //     {
                                        //         item.multipleActions.map((item2: any) => {
                                        //             return (
                                        //                 <Dropdown.Item eventKey="1" onClick={item2.action}>{item2.icon} {item2.label}</Dropdown.Item>
                                        //             )
                                        //         })
                                        //     }
                                        // </DropdownButton>
                                    ) : (
                                        <ButtonAdvanced key={index} handleSubmit={item.action} size='small' value={item.label} color={item.color || ''}
                                            className='ms-3' variant={item.variant ? item.variant : 'contained'} disabled={item.disabled} />
                                    )
                                }
                            </WrapperTooltip>
                        )
                    })
                }
            </div>

        </div>
    )
}

const WrapperTooltip = ({ children, tooltip }: any) => {

    return (
        <>

            {
                tooltip ?
                    <Tooltip title={tooltip.text} placement={tooltip.placement || 'top'}>
                        <span className="d-inline-block">
                            {children}
                        </span>
                    </Tooltip>
                    :
                    children
            }

        </>
    )
}