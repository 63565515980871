
import { Placeholder } from '../../../Placeholder/Placeholder'
import './LoaderLayoutLibro.css'

export const LoaderLayoutLibro = () => {
  return (
    <>
      <section className="LayoutLibro__contentright w-100 d-flex flex-column flex-grow-1">

        <main className='LayoutLibro__contentmain flex-grow-1 w-100'>

          <Placeholder className='my-3 delay-1' height='100px' width='100%' />
          <Placeholder className='my-3 delay-2' height='200px' width='100%' />

        </main>
      </section>
    </>
  )
}
