import { openLinkByClick } from "../../../helpers/helpers";

export const CondGenSeccion7 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El cliente dispondrá de la facultad de desistimiento de su compra, notificándolo al CATEB en el plazo de 14 días naturales desde el acceso al contenido digital, excepto en los casos previstos al “RDL 1/2007, de 16 de noviembre de la Ley General para la Defensa de los Consumidoras y Usuarios y otras leyes complementarias”.
      </p>
      <p>
        <span className="link-primary" onClick={() => openLinkByClick(`${process.env.PUBLIC_URL}/files/ES/Formulario de desistimiento LED 032024.docx`)}>
          Modelo de formulario de desistimiento.
        </span>
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        El client disposarà de la facultat de desistiment de la seva compra, notant-ho al CATEB en el termini de 14 dies naturals des de l'accés al contingut digital, excepte en els casos previstos al "RDL 1/2007, de 16 de novembre de la Llei General per a la Defensa dels Consumidores i Usuaris i altres lleis complementàries".
      </p>
      <p>
        <span className="link-primary" onClick={() => openLinkByClick(`${process.env.PUBLIC_URL}/files/CA/Formulari de desistiment LED 032024.docx`)}>
          Model de formulari de desistiment
        </span>
      </p>
    </div>
  )
}