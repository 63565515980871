import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveCheck, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../controllers/controllers"

const ImportarActividadesAdapters = {
    
    GET_PREVENTIVOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    data_inici_any: parseNumber(item.data_inici_any),
                    descripcio: parseString(item.descripcio),
                    document_referencia: parseString(item.document_referencia),
                    edifici_id: parseNumber(item.edifici_id),
                    elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    manual_habitatge: parseBoolean(item.manual_habitatge),
                    obligatorietat: parseBoolean(item.obligatorietat),
                    periodicitat: parseString(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    responsable: parseString(item.responsable),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_CORRECTIVOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    edifici_id: parseString(item.edifici_id),
                    descripcio_curta: parseString(item.descripcio_curta),
                    descripcio: parseString(item.descripcio),
                    observacions: parseString(item.observacions),
                    qualificacio: parseString(item.qualificacio),
                    elemento_constructivo_id: parseString(item.elemento_constructivo_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
    GET_MEJORAS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    beneficio: parseAny(item.beneficio),
                    comentario: parseString(item.comentario),
                    complejidad: parseString(item.complejidad),
                    complejidad_texto: parseString(item.complejidad_texto),
                    coste: parseString(item.coste),
                    coste_maximo: parseString(item.coste_maximo),
                    coste_minimo: parseString(item.coste_minimo),
                    costo_texto: parseString(item.costo_texto),
                    creat_per_id: parseString(item.creat_per_id),
                    descripcion_plan: parseString(item.descripcion_plan),
                    duracion_plan: parseString(item.duracion_plan),
                    edifici_id: parseString(item.edifici_id),
                    edifici_millora_plan_id: parseString(item.edifici_millora_plan_id),
                    elemento: parseString(item.elemento),
                    elemento_constructivo: parseString(item.elemento_constructivo),
                    elemento_texto: parseString(item.elemento_texto),
                    exigencia: parseString(item.exigencia),
                    exigencia_texto: parseString(item.exigencia_texto),
                    fase_id: parseString(item.fase_id),
                    medida: parseString(item.medida),
                    descripcio: parseString(item.medida),
                    nivel_principal: parseString(item.nivel_principal),
                    nivel_principal_texto: parseString(item.nivel_principal_texto),
                    orden: parseString(item.orden),
                    reduccion_cepnren: parseString(item.reduccion_cepnren),
                    seleccionado: parseString(item.seleccionado),
                    total_coste_maximo_plan: parseString(item.total_coste_maximo_plan),
                    total_coste_minimo_plan: parseString(item.total_coste_minimo_plan),
                    total_reduccion_consumo_epnr_plan: parseString(item.total_reduccion_consumo_epnr_plan),
                    urgente: parseAny(item.urgente),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const ImportarActividadAdapters = {

    GET_PREVENTIVO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    descripcio: parseString(item.descripcio),
                    periodicitat: parseNumber(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    document_referencia: parseString(item.document_referencia),
                    responsable: parseString(item.responsable),
                    obligatorietat: receiveCheck(item.obligatorietat, 'obligatorietat'),
                    manual_habitatge: receiveCheck(item.manual_habitatge, 'manual_habitatge'),
                    data_inici_any: parseNumber(item.data_inici_any),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_CORRECTIVO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    edifici_id: parseNumber(item.edifici_id),
                    descripcio_curta: parseString(item.descripcio_curta),
                    descripcio: parseString(item.descripcio),
                    observacions: parseString(item.observacions),
                    qualificacio: parseString(item.qualificacio),
                    elemento_constructivo_id: parseString(item.elemento_constructivo_id),
                    actuacio: parseString(item.actuacio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_MEJORA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    beneficio: parseAny(item.beneficio),
                    comentario: parseString(item.comentario),
                    complejidad: parseString(item.complejidad),
                    complejidad_texto: parseString(item.complejidad_texto),
                    coste: parseString(item.coste),
                    coste_maximo: parseString(item.coste_maximo),
                    coste_minimo: parseString(item.coste_minimo),
                    costo_texto: parseString(item.costo_texto),
                    creat_per_id: parseString(item.creat_per_id),
                    descripcion_plan: parseString(item.descripcion_plan),
                    duracion_plan: parseString(item.duracion_plan),
                    edifici_id: parseString(item.edifici_id),
                    edifici_millora_plan_id: parseString(item.edifici_millora_plan_id),
                    elemento: parseString(item.elemento),
                    elemento_constructivo: parseString(item.elemento_constructivo),
                    elemento_texto: parseString(item.elemento_texto),
                    exigencia: parseString(item.exigencia),
                    exigencia_texto: parseString(item.exigencia_texto),
                    fase_id: parseString(item.fase_id),
                    medida: parseString(item.medida),
                    descripcio: parseString(item.medida),
                    nivel_principal: parseString(item.nivel_principal),
                    nivel_principal_texto: parseString(item.nivel_principal_texto),
                    orden: parseString(item.orden),
                    reduccion_cepnren: parseString(item.reduccion_cepnren),
                    seleccionado: parseString(item.seleccionado),
                    total_coste_maximo_plan: parseString(item.total_coste_maximo_plan),
                    total_coste_minimo_plan: parseString(item.total_coste_minimo_plan),
                    total_reduccion_consumo_epnr_plan: parseString(item.total_reduccion_consumo_epnr_plan),
                    urgente: parseAny(item.urgente),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_fase_id: parseNumber(item.projecte_fase_id),
                    tipus_intervencio: parseString(item.tipus_intervencio),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    sistema_id: parseNumber(item.sistema_id),
                    subsistema_id: parseString(item.subsistema_id),
                    import_obres: parseString(item.import_obres),
                    import_honoraris: parseString(item.import_honoraris),
                    import_taxes: parseString(item.import_taxes),
                    import_altres: parseString(item.import_altres),
                    import_total: parseString(item.import_total),
                    data_inici_any: parseNumber(item.data_inici_any, { removeNullable: true }),
                    data_inici_mes: parseNumber(item.data_inici_mes, { removeNullable: true }),
                    durada_mesos: parseNumber(item.durada_mesos, { removeNullable: true }),
                    origen_id: parseAny(item.origen_id),
                    periodicitat: parseString(item.periodicitat),
                    periodicitat_unidad: parseString(item.periodicitat_unidad),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    ImportarActividadesAdapters,
    ImportarActividadAdapters,
}