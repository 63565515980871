import { Button, Typography } from "@mui/material";
import React from "react";
import { AccordionAdvanced } from "../../components/AccordionAdvanced/AccordionAdvanced";
import { useLang } from "../../language";

import { AvisoLegalSeccion1 } from "./AvisoLegalSeccion1";
import { AvisoLegalSeccion2 } from "./AvisoLegalSeccion2";
import { AvisoLegalSeccion3 } from "./AvisoLegalSeccion3";
import { AvisoLegalSeccion4 } from "./AvisoLegalSeccion4";
import { AvisoLegalSeccion5 } from "./AvisoLegalSeccion5";
import { AvisoLegalSeccion6 } from "./AvisoLegalSeccion6";
import { AvisoLegalSeccion7 } from "./AvisoLegalSeccion7";
import { AvisoLegalSeccion8 } from "./AvisoLegalSeccion8";
import { AvisoLegalSeccion9 } from "./AvisoLegalSeccion9";
import { AvisoLegalSeccion10 } from "./AvisoLegalSeccion10";
import { AvisoLegalSeccion11 } from "./AvisoLegalSeccion11";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openLinkByClick } from "../../helpers/helpers";

export const FormAvisoLegal = ({ index }: any) => {

    const Lang = useLang()

    const { language } = useSelector((state: RootState) => state.language)

    const list = [
        {
            title: `1. ${Lang('AVISO_LEGAL')}`,
            content: <AvisoLegalSeccion1 lang={language} />
        },
        {
            title: `2. ${Lang('FUNCIONAMIENTO_NUESTRO_SITIO_WEB')}`,
            content: <AvisoLegalSeccion2 lang={language} />
        },
        {
            title: `3. ${Lang('CONDICIONES_USO_SITIO_WEB_TODOS_USUARIOS')}`,
            content: <AvisoLegalSeccion3 lang={language} />
        },
        {
            title: `4. ${Lang('REGIMEN_RESPONSABILIDADES')}`,
            content: <AvisoLegalSeccion4 lang={language} />
        },
        {
            title: `5. ${Lang('POLITICA_CONTENIDOS')}`,
            content: <AvisoLegalSeccion5 lang={language} />
        },
        {
            title: `6. ${Lang('DERECHOS_PROPIEDAD_INTELECTUAL')}`,
            content: <AvisoLegalSeccion6 lang={language} />
        },
        {
            title: `7. ${Lang('PRECIOS_PRODUCTOS_SERVICIOS')}`,
            content: <AvisoLegalSeccion7 lang={language} />
        },
        {
            title: `8. ${Lang('DURACION_FINALIZACION')}`,
            content: <AvisoLegalSeccion8 lang={language} />
        },
        {
            title: `9. ${Lang('POLITICA_COOKIES')}`,
            content: <AvisoLegalSeccion9 lang={language} />
        },
        {
            title: `10. ${Lang('JURISDICCION_COMPETENCIA')}`,
            content: <AvisoLegalSeccion10 lang={language} />
        },
        {
            title: `11. ${Lang('PROTECCION_DATOS')}`,
            content: <AvisoLegalSeccion11 lang={language} />
        },
    ]

    const handleDownloadFile = () => {

        let url = ''

        if (language === 'ES') {

            url = `${process.env.PUBLIC_URL}/files/ES/Aviso legal y condiciones de uso Web LED 032024.docx`

        } else if (language === 'CA') {

            url = `${process.env.PUBLIC_URL}/files/CA/Avis legal i condicions ús Web LED 032024 catala.docx`
        }

        openLinkByClick(url)
    }

    return (
        <>
            <Typography variant='h5' className='fw-bolder me-2 text-center mb-3 pb-3' color=''>
                {Lang('AVISO_LEGAL_CONDICIONES_USO_WEB')}
            </Typography>

            <div className="mb-3">
                <AccordionAdvanced
                    list={list}
                    indexAccordion={index}
                />
            </div>

            <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                <Button variant="contained" color="primary" onClick={handleDownloadFile}>
                    <FontAwesomeIcon icon={faFile} className="me-2" /> {Lang('DESCARGAR')}
                </Button>
            </div>
        </>
    )
};
