import React, { useEffect } from "react";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { Box, Grid, Typography } from "@mui/material";
import { useLang } from "../../../language";
import { InputText } from "../../../components/Inputs";
import { validCorrectEmail, validExiste } from "../../../helpers/validations";
import { useValidator } from "../../../hooks/useValidator";
import { useForm } from "../../../hooks/useForm";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";
import { AuthServices } from "../../../controllers/auth/auth.services";
import { InputSelect } from "../../../components/Inputs/InputSelect";
import { ModalStatusServer } from "../../../components/Modals/ModalStatusServer";

export const ModalRecuperarCodigo = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const [formRecuperarCodigo, handleInputRecuperarCodigo, handleFormRecuperarCodigo, resetFormRecuperarCodigo] = useForm()

    const [fetchMisEdificiosGET, fetchMisEdificiosGETData] = AuthServices.GET_EDIFICIOS()

    const [fetchRecuperarCodigoPOST, fetchRecuperarCodigoPOSTData] = AuthServices.POST_RECUPERAR_CODIGO()

    const [validLogin, handleValidationLogin, resetvalidLogin] = useValidator({
        nif: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        id: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const handleSubmitRestablecerContrasenia = () => {

        if (handleValidationLogin(formRecuperarCodigo)) {

            fetchRecuperarCodigoPOST({
                id: formRecuperarCodigo.id
            })
        }

    }

    useEffect(() => {

        if (formRecuperarCodigo.nif?.length > 7) {
            fetchMisEdificiosGET({
                body: {
                    nif: formRecuperarCodigo.nif
                }
            })
        }

    }, [formRecuperarCodigo.nif]);

    useEffect(() => {

        if (data.value) {

            fetchMisEdificiosGET()

        } else {
            resetFormRecuperarCodigo()
            resetvalidLogin()
        }

    }, [data.value]);


    return (
        <>

            <ModalAdvanced data={data} handleClose={handleClose}>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {Lang('RESTABLECER_CODIGO')}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <Typography variant="body1" component="h2" className="mb-2">
                        Para reestablecer su código de acceso al edificio, es necesario que nos proporcione su NIF y seleccionar el edificio.
                    </Typography>

                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container>

                            <Grid item xs={12}>
                                <InputText
                                    label={Lang('NIF') + ': *'}
                                    name='nif'
                                    value={formRecuperarCodigo.nif}
                                    onChange={(e: any) => handleInputRecuperarCodigo({
                                        target: {
                                            name: 'nif',
                                            value: (e.target.value).toUpperCase()
                                        }
                                    })}
                                    localValidation={validLogin.nif}
                                    className='mt-2 mb-3 w-100'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputSelect
                                    label={Lang('NUM_EDIFICIO') + ': *'}
                                    name='id'
                                    value={formRecuperarCodigo.id}
                                    values={fetchMisEdificiosGETData?.data?.data?.map((item: any) => {
                                        return {
                                            id: item.id,
                                            name: `${item.nom}. Ref Catastral: ${item.ref_cadastral}`
                                        }
                                    }) || []}
                                    onChange={handleInputRecuperarCodigo}
                                    localValidation={validLogin.id}
                                    className='mt-2 mb-3 w-100'
                                />
                            </Grid>
                        </Grid>

                    </Box>

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="contained" type="submit" className="mt-3 w-100"
                        handleSubmit={handleSubmitRestablecerContrasenia}
                        loadings={[fetchRecuperarCodigoPOSTData.loading]}
                        value={Lang('RESTABLECER_CODIGO')}
                    />
                </ModalAdvanced.Footer>


            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchRecuperarCodigoPOSTData} />

        </>
    );
};
