export const CondParSeccion1 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El objeto de este contrato es la contratación “on line”, por parte de profesionales del sector de la edificación, de la aplicación de redacción y gestión digital del Libro del Edificio (LED), regulado por la Ley 38/1999, de 5 de noviembre, de Ordenación de la Edificación.
      </p>
      <p>
        La contratación de este producto permite la redacción y gestión del Libro del Edificio de edificios unifamiliares y plurifamiliares de obra nueva, edificios terciarios y el Libro del Edificio por edificios existentes, según lo dispuesto al Decreto 67/2015 y adaptado al RD 853/2021, por el cual se regulan los programas de ayuda en materia de rehabilitación residencial y vivienda social del plan de Recuperación, Transformación y Resiliencia de los Fondos “Next Generation”.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        L'objecte d'aquest contracte és la contractació "on line", per part de professionals del sector de l'edificació, de l'aplicació de redacció i gestió digital del Llibre de l'Edifici (LED), regulat per la Llei 38/1999, de 5 de novembre, d'Ordenació de l'Edificació.
      </p>
      <p>
        La contractació d'aquest producte permet la redacció i gestió del Llibre de l'Edifici d'edificis unifamiliars i plurifamiliars d'obra nova, edificis terciaris i el Llibre de l'Edifici per edificis existents, segons el que disposa el Decret 67/2015 i adaptat al RD 853/2021, pel qual es regulen els programes d'ajuda en matèria de rehabilitació residencial i habitatge social del pla de Recuperació,  Transformació i Resiliència dels Fons "Next Generation".
      </p>
    </div>
  )
}