import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveFecha, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../controllers/controllers"

const ElementosConstructivosParamAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.elemento_constructivo_id),
                    texto: parseString(item.texto),
                    name: parseString(item.texto),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}


const PlanificacionFinancieraAdapters = {

    GET_INFORMACION_PREVIA: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // planificacion: parseArray(item.planificacion)?.map((item: any) => {
                    //     return {
                    //         ...item,
                    //         fons_fecha: receiveFecha(item.fons_fecha),
                    //         import_fecha: receiveFecha(item.import_fecha),
                    //         subvencions_fecha: receiveFecha(item.subvencions_fecha),
                    //     }
                    // }),
                    total_proyecto: parseNumber(item.total_proyecto),
                    // total_ingresos: parseNumber(item.total_ingresos),
                    pendiente_financiar: parseNumber(item.pendiente_financiar),
                    // total_ingresos_derramas_finalizadas: parseNumber(item.total_ingresos_derramas_finalizadas),
                    proyecto: parseObject(item.proyecto),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_RESUMEN_FINANCIERO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                return renderSchema(item)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_CALENDARIO_FINANCIERO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                return renderSchema(item)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_RESUMEN_FINANCIERO_BLOB: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseAny(item.data),
                    headers: parseString(item.headers),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    ElementosConstructivosParamAdapters,
    PlanificacionFinancieraAdapters,
}