const VALIDATIONS = {
    VALID_EXISTE_CAMPO_DEFAULT: 'El campo es obligatorio.',
    VALID_EXISTE_NUMERO_COLEGIADO: 'El número de colegiado es obligatorio.',
    VALID_EXISTE_CLAVE: 'La clave es obligatoria.',
    VALID_CONFIRMAR_CLAVE: 'Las claves no son las mismas.',
    VALID_EXISTE_NOMBRE_SOCIEDAD: 'El nombre de la sociedad es obligatorio.',
    VALID_CORRECT_EMAIL: 'Por favor, introduzca una dirección de correo válida.',
    VALID_CORRECT_MOVIL: 'El número de móvil sólo puede contener dígitos (sin espacios, puntos,...)',
    VALID_CORRECT_YEAR: `Por favor, ingrese un numero de año correcto.`,
    VALID_CORRECT_DNI: `Campo incorrecto`,
}

export default VALIDATIONS;