import { useAxios } from "../../../../../../../../hooks/useAxios"
import { FicheroITEAdapters } from "./ficheroite.adapters"

const FicheroITEServices = {

    GET_DEFICIENCIAS: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio/ite/deficiencias`,
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.GET_DEFICIENCIAS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_RESUMEN: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio/ite/resumen`,
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.GET_RESUMEN,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/operaciones/edificio-operaciones`,
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio/ite/load_zip',
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.POST,
                types: {
                    send: 'multipart',
                    receive: 'default',
                }
            },
        })
    },

    POST_AGREGAR_A_DEFICIENCIAS: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio/ite/deficiencias',
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.POST_AGREGAR_A_DEFICIENCIAS,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_AGREGAR_DEFICIENCIA: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio/ite/deficiencia',
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.POST_AGREGAR_DEFICIENCIA,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: FicheroITEAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    FicheroITEServices
}