const GENERALS = {
    CATALAN: 'Catalán',
    ESPANIOL: 'Español',
    CASTELLANO: 'Castellano',
    INICIAR_SESION: 'Iniciar sesión',
    USUARIO: 'Usuario',
    CONTRASENIA: 'Contraseña',
    LIBRO_DEL_EDIFICIO: `Libro del edificio`,
    COLEGIADOS: 'Colegiados',
    OTROS_USUARIOS: 'Otros usuarios',
    INICIA_SESION: 'Inicia sesión',
    REGISTRATE: 'Registrate',
    SALIR: 'Salir',
    REGISTRARME: 'Registrarme',
    INICIO: 'Inicio',
    LIBROS: 'Libros',
    USUARIOS: 'Usuarios',
    PLANTILLAS: 'Plantillas',
    PARAMETROS: 'Parámetros',
    MIS_EDIFICIOS: 'Mis edificios',
    CONFIGURACION: 'Configuración',
    NUEVO_EDIFICIO: 'Nuevo edificio',
    NOMBRE: 'Nombre',
    APELLIDOS: 'Apellidos',
    NIF: 'NIF',
    TIPO_DE_USUARIO: 'Tipo de usuario',
    INDICA_CUAL: 'Indica cual',
    COMUNIDAD_AUTONOMA: 'Comunidad autónoma',
    CORREO_ELECTRONICO: 'Correo electrónico',
    CAMBIO_DE_CONTRASENIA: 'Cambio de contraseña',
    CONFIRMACION_DE_CONTRASENIA: 'Confirmación de contraseña',
    CONTRASENIA_ACTUAL: 'Contraseña actual',
    CANCELAR_LA_CUENTA: 'Cancelar la cuenta',
    GUARDAR: 'Guardar',
    MIS_DATOS: 'Mis datos',
    SOPORTE: 'Soporte',
    AGENTES: 'Agentes',
    DATOS_ADMINISTRATIVOS_JURIDICOS: 'Datos administrativos y jurídicos',
    DESCRIPCION_CONSTRUCTIVA: 'Descripción constructiva',
    POTENCIAL_MEJORA_PLAN_ACTUACIONES: 'Potencial de mejora y Plan de actuaciones',
    OPERACIONES: 'Operaciones',
    DOCUMENTOS: 'Documentos',
    IDENTIFICACION_EDIFICIO: 'Identificación del edificio',
    FOTOGRAFIAS_EMPLAZAMIENTO: 'Fotografías y emplazamiento',
    PROPIEDAD: 'Propiedad',
    EMPRESA_O_PROFESIONAL: 'Empresa o profesional',
    IDENTIFICACION: 'Identificación',
    PROGRAMA_DE_REHABILITACION: 'Programa de rehabilitación',
    LIBRO_DE_MANTENIMIENTO: 'Libro de mantenimiento',
    CREAR: 'Crear',
    NOMBRE_EDIFICIO: 'Nombre del edificio',
    TIPO_EDIFICIO: 'Tipo de edificio',
    REFERENCIA_CATASTRAL: 'Referencia catastral',
    NOTIFICACIONES: 'Notificaciones',
    HISTORICO: 'Histórico',
    NUEVO_USUARIO: 'Nuevo usuario',
    TIPO_DE_IDENTIFICACION: 'Tipo de identificación',
    TIPO_DE_VIA: 'Tipo de vía',
    DIRECCION: 'Dirección',
    CODIGO_POSTAL: 'Código postal',
    POBLACION: 'Población',
    PROVINCIA: 'Provincia',
    TELEFONO: 'Teléfono',
    RAZON_SOCIAL: 'Razón social',
    NUMERO: 'Número',
    LIBRO_DEL_EDIFICIO_DIGITAL: 'Libro del edificio digital',
    PRODUCTOS: 'Productos',
    ACCESO_GENERAL: 'Acceso general',
    ACCESO_EDIFICIO: 'Acceso edificio',
    OLVIDASTE_TU_CONTRASENIA: '¿Olvidaste tu contraseña?',
    OLVIDASTE_TU_CODIGO_DE_ACCESO: '¿Olvidaste tu código de acceso?',
    NUM_EDIFICIO: 'Núm. edificio',
    CODIGO_ACCESO: 'Código acceso',
    AREA_DE_ACCESO: 'Área de acceso',
    RECUPERAR_CONTRASENIA: 'Recuperar contraseña',
    REESTABLECER_CONTRASENIA: 'Restablecer contraseña',
    RESTABLECER_CODIGO: 'Restablecer código',
    EDIFICIOS: 'Edificios',
    ADMINISTRADORES: 'Administradores',
    OPERACION_EXITOSA: 'Operación exitosa',
    OPERACION_FALLIDA: 'Operación fallida',
    EMAIL: 'Email',
    BUSCAR: 'Buscar',
    LIMPIAR: 'Limpiar',
    BUSCADOR: 'Buscador',
    LISTA_PROPIEDAD_AGENTES: 'Lista de Propiedad y Agentes',
    ACCESO_ADMINISTRACION: 'Acceso administración',
    NUEVO_AGENTE: 'Nuevo Agente',
    EDITAR_AGENTE: 'Editar Agente',
    TIPO_AGENTE: 'Tipo Agente',
    TITULACION: 'Titulación',
    WEB: 'Web',
    TRABAJO: 'Trabajo',
    OBRA: 'Obra',
    AMBITO_DE_COLABORACION: 'Ámbito de colaboración',
    PROYECTO_PARCIAL: 'Proyecto parcial',
    SERVICIO: 'Servicio',
    FOTOGRAFIAS_FACHADA: 'Fotografías de fachada',
    PLANO_EMPLAZAMIENTO: 'Plano de emplazamiento',
    MANTENER_ORIGINAL: 'Mantener original',
    RECORTAR_IMAGEN: 'Recortar imagen',
    NUEVA_IMAGEN: 'Nueva imagen',
    INFORME_INSPECCION_TECNICA_EDIFICIO: 'Informe de la Inspección / Evaluación Técnica del Edificio',
    CERTIFICADO_EFICIENCIA_ENERGETICA_ESTADO_INICIAL: 'Certificado eficiencia energética edificio',
    LICENCIAS: 'Licencias',
    DECLARACION_OBRA_NUEVA: 'Declaración de obra nueva',
    REGIMEN_PROPIEDAD: 'Régimen de propiedad',
    REGIMEN_ESPECIAL: 'Régimen especial',
    CARGAS_REALES: 'Cargas reales',
    ENTIDADES_JURIDICAS: 'Entidades jurídicas',
    GARANTIAS_PROMOTOR: 'Garantías del promotor',
    GARANTIAS_INSTALACIONES: 'Garantías de las instalaciones',
    GARANTIAS_CONSTRUCTOR: 'Garantías del constructor',
    NUEVO_INFORME: 'Nuevo informe',
    REFERENCIA: 'Referencia',
    FECHA: 'Fecha',
    DESCRIPCION: 'Descripción',
    CREAR_INFORME: 'Crear informe',
    EDITAR_INFORME: 'Editar informe',
    ELIMINAR: 'Eliminar',
    NUEVO_CERTIFICADO: 'Nuevo certificado',
    CREAR_CERTIFICADO: 'Crear certificado',
    EDITAR_CERTIFICADO: 'Editar certificado',
    NUMERO_REGISTRO: 'Número de registro',
    FECHA_FINALIZACION_VIGILANCIA: 'Fecha de finalización de la vigencia',
    CALIFICACION_ENERGETICA: 'Calificación energética',
    CONSUMO_ENERGIA_PRIMARIA: 'Consumo de energía primaria (kWh/a)',
    EMISIONES_CO2: 'Emisiones de CO2 (kg/m2a)',
    CLASE: 'Clase',
    NUEVA_LICENCIA: 'Nueva licencia',
    CREAR_LICENCIA: 'Crear licencia',
    EDITAR_LICENCIA: 'Editar licencia',
    NUEVA_DECLARACION: 'Nueva declaración',
    CREAR_DECLARACION: 'Crear declaración',
    EDITAR_DECLARACION: 'Editar declaración',
    NOTARIO: 'Notario',
    NUMERO_PROTOCOLO: 'Número de protocolo',
    NUEVO_REGIMEN: 'Nuevo régimen',
    CREAR_REGIMEN: 'Crear régimen',
    EDITAR_REGIMEN: 'Editar régimen',
    TIPO: 'Tipo',
    ESCRITURA: 'Escritura',
    REFERENCIA_DOCUMENTO_ACREDITATIVO: 'Referencia del documento acreditativo',
    NUEVA_CARGA: 'Nueva carga',
    CREAR_CARGA: 'Crear carga',
    EDITAR_CARGA: 'Editar carga',
    NUEVA_ENTIDAD: 'Nueva entidad',
    CREAR_ENTIDAD: 'Crear entidad',
    EDITAR_ENTIDAD: 'Editar entidad',
    SUPERFICIE_M2: 'Superficie (m2)',
    DESTINACION: 'Destinación',
    CUOTA_PORCENTAJE: 'Cuota (%)',
    FECHA_VENCIMIENTO_GARANTIA: 'Fecha de vencimiento de la garantía',
    TIPO_GARANTIA: 'Tipo de garantía',
    REFERENCIA_POLIZA_OTRO_DOCUMENTO: 'Referencia de la póliza u otro documento acreditativo del otorgamiento de la garantía',
    NUEVA_GARANTIA: 'Nueva garantía',
    CREAR_GARANTIA: 'Crear garantía',
    EDITAR_GARANTIA: 'Editar garantía',
    NOMBRE_EMPRESA_GARANTE: 'Nombre de la empresa garante',
    INSTALACION: 'Instalación',
    NOMBRE_VIA: 'Nombre de la vía',
    BLOQUE: 'Bloque',
    ANIO_FIN_CONSTRUCCION: 'Año fin construcción',
    FUENTE_ANIO_CONSTRUCCION: 'Fuente año construcción',
    OBSERVACIONES: 'Observaciones',
    REGIMEN_JURIDICO: 'Régimen jurídico de la propiedad',
    DATOS_PROPIEDAD_PRESIDENTE_COMUNIDAD: 'Datos de la propiedad o comunidad de propietarios',
    DATOS_PROPIEDAD: 'Datos de la propiedad',
    DATOS_REPRESENTANTE: 'Datos representante',
    MEJORAR_PLAN: 'Mejorar plan',
    CREAR_NUEVO_EDIFICIO: 'Crear nuevo edificio',
    TIPO_PRODUCTO: 'Tipo producto',
    DATOS_EDIFICIO: 'Datos del edificio',
    FACTURACION: 'Facturación',
    PAGO: 'Pago',
    LISTA_DOCUMENTOS: 'Lista de documentos',
    NUEVO_DOCUMENTO: 'Nuevo documento',
    EDITAR_DOCUMENTO: 'Editar documento',
    SELECCIONAR_ARCHIVO: 'Seleccionar archivo',
    SIN_ARCHIVOS_SELECCIONADOS: 'Sin archivos seleccionados',
    TIPO_DOCUMENTO: 'Tipo documento',
    ELIMINAR_ARCHIVO: 'Eliminar archivo',
    DESCARGAR: 'Descargar',
    DOCUMENTO: 'Documento',
    PAGAR: 'Pagar',
    PAGAR_PRODUCTO: 'Pagar producto',
    PENDIENTE_PAGO: 'Pendiente de pago',
    CANCELAR: 'Cancelar',
    CONFIRMACION: 'Confirmación',
    LISTA_USUARIOS: 'Lista de Usuarios',
    NUESTROS_PRODUCTOS: 'Nuestros productos',
    AVISO_LEGAL: 'Aviso legal',
    AVISO_LEGAL_CONDICIONES_USO: 'Aviso legal y condiciones de uso',
    CONTACTANOS: 'Contáctanos',
    ENVIAR: 'Enviar',
    ASUNTO: 'Asunto',
    MENSAJE: 'Mensaje',
    CONTACTO: 'Contacto',
    TARIFAS: 'Tarifas',
    AGREGAR_USUARIO: 'Agregar usuario',
    BUSCAR_NIF: 'Buscar NIF',
    DATOS_GENERALES: 'Datos generales',
    ASIGNAR_ROLES: 'Asignar perfiles',
    CREAR_USUARIO: 'Crear Usuario',
    ROL: 'Perfil',
    ROLES: 'Perfiles',
    MODIFICAR_ROLES: 'Modificar perfiles',
    LISTA_INMUEBLES: 'Lista de Inmuebles',
    AGREGAR_INMUEBLE: 'Agregar inmueble',
    LOCALIZACION: 'Localización',
    REF_CATASTRAL: 'Ref. Catastral',
    COEFICIENTE: 'Coeficiente',
    SUPERFICIE: 'Superficie',
    ANIO_CONSTRUCCION: 'Año de construcción',
    ELIMINAR_USUARIO: 'Eliminar usuario',
    SI_ELIMINAR: 'Si, eliminar',
    MANTENIMIENTO_Y_MEJORAS: 'Mantenimiento y mejoras',
    FECHA_CREACION: 'Fecha de creación',
    ACEPTAR_Y_CONTINUAR: 'Aceptar y continuar',
    SI_RECHAZAR: 'Si, rechazar',
    RECHAZAR: 'Rechazar',
    VERIFICADO: 'Verificado',
    CREAR_EDIFICIO: 'Crear Edificio',
    ADMINISTRADOR: 'Administrador',
    ROLES_ASIGNADOS: 'Perfiles asignados',
    REGISTRAR_DATOS_FACTURACION: 'Registrar datos de facturación',
    COMARCA: 'Comarca',
    NOMBRE_CALLE: 'Nombre de la calle',
    EDIFICIO: 'Edificio',
    ESCALERA: 'Escalera',
    PISO: 'Piso',
    PUERTA: 'Puerta',
    CIUDAD: 'Ciudad',
    MIS_DATOS_PERSONALES: 'Mis datos personales',
    A_UNA_PERSONA: 'A una persona',
    A_UNA_EMPRESA: 'A una empresa',
    PAGADOR: 'Pagador',
    CIMENTACION: 'Cimentación',
    ESTRUCTURA: 'Estructura',
    CERRAMIENTOS_VERTICALES: 'Cerramientos verticales',
    CUBIERTA: 'Cubierta',
    PARTICIONES_ACABADOS: 'Particiones y acabados',
    SANEAMIENTO: 'Saneamiento',
    SUMINISTRO_AGUA: 'Suministro de agua',
    INSTALACION_ELECTRICA: 'Instalación eléctrica',
    INSTALACION_GAS: 'Instalación de gas',
    ACS_CALEFACCION_REFRIGERACION: 'ACS, calefacción y refrigeración',
    VENTILACION: 'Ventilación',
    PROTECCION_CONTRA_INCENDIOS: 'Protección contra incendios',
    ASCENSORES: 'Ascensores',
    INSTALACION_TELECOMUNICACIONES: 'Instalación de telecomunicaciones',
    INSTALACIONES_ESPECIALES: 'Instalaciones especiales',
    GESTIONAR_COMPONENTES: 'Gestionar componentes',
    COMPONENTES: 'Componentes',
    ELEMENTOS: 'Elementos',
    SESION_CADUCADA: 'La sesión ha finalizado',
    COMUNICACION: 'Comunicación',
    PLANIFICACION: 'Planificación',
    PROPIEDAD_AGENTES: 'Propiedad y Agentes',
    DOCUMENTACION: 'Documentación',
    ACTUALIZAR_DATOS: 'Actualizar datos',
    CONVENIO: 'Convenio',
    POLITICA_PRIVACIDAD: 'Política de privacidad',
    POLITICA_COOKIES: 'Política de cookies',
    CONDICIONES_CONTRATACION: 'Condiciones de contratación',
    PRECIO_REDUCIDO: 'Precio reducido',
    PRECIO_CONVENIO: 'Precio convenio',
    SELECCIONE_UN_PRODUCTO: 'Seleccione un producto',
    DESCRIPCION_POTENCIAL_MEJORA: 'Descripción del potencial de mejoras',
    REGISTRO_INCIDENCIAS_OPERACIONES: 'Registro de incidencias y operaciones',
    REPOSITORIO_DOCUMENTOS: 'Biblioteca de documentos',
    MANTENIMIENTO_PREVENTIVO: 'Mantenimiento preventivo',
    MANTENIMIENTO_CORRECTIVO: 'Mantenimiento correctivo',
    METRICAS: 'Métricas',
    AGREGAR_ELEMENTO: 'Agregar elemento',
    NUEVO_MANTENIMIENTO: 'Nuevo mantenimiento',
    EDITAR_MANTENIMIENTO: 'Editar mantenimiento',
    PERIODICIDAD: 'Periodicidad',
    PERIODICIDAD_EN_TEXTO: 'Periodicidad en texto',
    DOCUMENTO_REFERENCIA: 'Documento de referencia',
    RESPONSABLE: 'Responsable',
    MANTENIMIENTO: 'Mantenimiento',
    MANTENIMIENTOS: 'Mantenimientos',
    MANUAL_DE_VIVIENDA: 'Manual de la vivienda',
    OBLIGATORIO: 'Obligatorio',
    MANTENIMIENTOS_PREVENTIVOS: 'Mantenimientos preventivos',
    PROGRAMA_MANTENIMIENTO: 'Programa de mantenimiento',
    NUEVO_ELEMENTO: 'Nuevo elemento',
    EDITAR_ELEMENTO: 'Editar elemento',
    ANIO_INICIO: 'Año de inicio',
    ENVIAR_EMAIL: 'Enviar email',
    NECESARIO_SELECCIONAR_UNA_BUSQUEDA: `Es necesario seleccionar al menos 1 resultado de búsqueda`,
    CONTINUAR: 'Continuar',
    REMITENTE: 'Remitente',
    EMAIL_REMITENTE: 'Email del Remitente',
    PLANTILLA: 'Plantilla',
    LISTA_DESTINATARIOS: 'Lista de destinatarios',
    DESTINATARIOS: 'Destinatarios',
    SISTEMAS: 'Sistemas',
    OPERACIONES_MANTENIMIENTO: 'Operaciones de mantenimiento',
    NUEVA_OPERACION: 'Nueva operación',
    EDITAR_OPERACION: 'Editar operación',
    HERRAMIENTAS_SERVICIOS: 'Herramientas y servicios',
    OTROS_ELEMENTOS: 'Otros elementos',
    DESMARCAR_ELEMENTOS_ELIMINACION_ASOCIADOS: 'Al desmarcar algún elemento, se eliminarán todos los datos asociados',
    COMPLETAR: 'Completar',
    COMPLETADO: 'Completado',
    RECORDAR_NIF: 'Recordar NIF',
    ADJUNTAR_FICHEROS: 'Adjuntar ficheros',
    PARAMETROS_NORMATIVOS: 'Parámetros normativos',
    VALORACION_EVALUACION: 'Valoración y Evaluación',
    NUEVO_PARAMETRO_NORMATIVO: 'Nuevo parámetro normativo',
    EDITAR_PARAMETRO_NORMATIVO: 'Editar parámetro normativo',
    FOTOGRAFIA_PARAMETRO_NO_DETECTADO: 'Fotografia del parámetro normativo de referencia no detectado',
    PARAMETRO_NO_DETECTADO: 'Parámetro normativo de referencia no detectado',
    NUEVA_PROPUESTA_MEJORA: 'Nueva propuesta mejora',
    EDITAR_PROPUESTA_MEJORA: 'Editar propuesta mejora',
    PROPUESTAS_DE_MEJORA: 'Propuestas de mejora',
    PROPUESTA_DE_MEJORA: 'Propuesta de mejora',
    VENTAJAS: 'Ventajas',
    COSTE_ECONOMICO: 'Coste económico',
    DIFICULTAD_EJECUCION: 'Dificultad de ejecución',
    INTERVENCIONES: 'Intervenciones',
    PROPUESTA_MEDIDAS_MEJORA: 'PROPUESTAS de medidas de mejora',
    PROPUESTA_MEJORA: 'Propuesta de mejora',
    VENTAJAS_OBTENIDAS_MEJORA: 'VENTAJAS obtenidas con la mejora',
    COSTE_ECONOMICO_MEJORA: 'COSTE ECONÓMICO de la mejora',
    DIFICULTAD_EJECUCION_MEJORA: 'DIFICULTAD DE EJECUCIÓN de la mejora',
    VENTAJA_DE_PROPUESTA: 'Ventaja de la mejora',
    COSTE_ECONOMICO_DE_MEJORA: 'Coste económico de la mejora',
    JUSTIFICAR_DIFICULTAD_EJECUCION: 'Justificar la dificultad de ejecución (si es el caso)',
    VOLVER_A_PROPUESTAS: 'Volver a propuestas',
    EDITAR_CONDICIONES: 'Editar condiciones',
    NUEVA_INTERVENCION: 'Nueva intervención',
    EDITAR_INTERVENCION: 'Editar intervención',
    DEFICIENCIAS: 'Deficiencias',
    NUEVA_DEFICIENCIA: 'Nueva deficiencia',
    EDITAR_DEFICIENCIA: 'Editar deficiencia',
    IMPORTAR_ITE: 'Importar ITE',
    IMPORTE_DE_OBRAS: 'Importe de las obras',
    IMPORTE_DE_HONORARIOS: 'Importe de los honorarios',
    IMPORTE_DE_TASAS: 'Importe de las tasas',
    OTROS: 'Otros',
    MES_INICIO: 'Mes de inicio',
    ENERO: 'Enero',
    FEBRERO: 'Febrero',
    MARZO: 'Marzo',
    ABRIL: 'Abril',
    MAYO: 'Mayo',
    JUNIO: 'Junio',
    JULIO: 'Julio',
    AGOSTO: 'Agosto',
    SEPTIEMBRE: 'Septiembre',
    OCTUBRE: 'Octubre',
    NOVIEMBRE: 'Noviembre',
    DICIEMBRE: 'Diciembre',
    DURACION_MESES: 'Duración en meses',
    DEFICIENCIAS_IMPORTADAS: 'Deficiencias importadas',
    DEFICIENCIAS_FICHERO_ITE: 'Deficiencias fichero ITE',
    AGREGAR_A_DEFICIENCIAS: 'Agregar a deficiencias',
    SI_AGREGAR: 'Si, agregar',
    LEVE: 'Leve',
    IMPORTANTE: 'Importante',
    GRAVE: 'Grave',
    MUY_GRAVE: 'Muy grave',
    CALIFICACION: 'Calificación',
    IMAGENES: 'Imágenes',
    IMAGEN: 'Imágen',
    MEDIDAS: 'Medidas',
    NUEVA_MEDIDA: 'Nueva medida',
    EDITAR_MEDIDA: 'Editar medida',
    MEDIDA: 'Medida',
    BENEFICIO: 'Beneficio',
    COSTE_MINIMO: 'Coste mínimo',
    COSTE_MAXIMO: 'Coste máximo',
    COMPLEJIDAD: 'Complejidad',
    COMENTARIOS: 'Comentarios',
    COSTE: 'Coste',
    RELACION_MEDIDAS: 'Relación de medidas',
    FASE: 'Fase',
    REQUISITO: 'Requisito',
    EXIGENCIAS: 'Exigencias',
    DESCRIPCION_CORTA: 'Descripción corta',
    REEMPLAZAR_ITE: 'Reemplazar ITE',
    REGRESAR: 'Regresar',
    AGENTES_EDIFICACION: 'Agentes de la edificación',
    VIVIENDAS: 'Viviendas',
    USO: 'Uso',
    VIVIENDA: 'Vivienda',
    MANUAL_USO_MANTENIMIENTO: 'Manual de uso y Mantenimiento',
    PLANOS_DE_VIVIENDA: 'Planos de la vivienda',
    INCLUIR_EN_LIBRO: 'Incluir en libro',
    TIPO_PLANTILLA: 'Tipo de plantilla',
    EXCLUIR_DEFICIENCIAS_IMPORTADAS: 'Excluir deficiencias importadas',
    FECHA_INICIO: 'Fecha inicio',
    ESTADO: 'Estado',
    ADJUNTO: 'Adjunto',
    PROPIETARIOS: 'Propietarios',
    LISTA_PROPIETARIOS: 'Lista de propietarios',
    VALIDO_HASTA: 'Válido hasta',
    ACTUACION: 'Actuación',
    IMPORTAR: 'Importar',
    REDUCCION: 'Reducción',
    SELECCIONE_DEFICIENCIA_ITE_ACTUACION: `Seleccione una deficiencia del fichero ITE y asocie la actuación`,
    FECHA_ENVIADO: 'Fecha enviado',
    FECHA_FIN: 'Fecha fin',
    URGENTE: 'Urgente',
    POTENCIAL_MEJORAS: 'Potencial mejoras',
    ASIGNAR_FASES: 'Asignar fases',
    ELABORAR_PLAN: 'Elaborar plan',
    RESUMEN_DESCARGA: 'Resumen y descarga',
    CATASTRO: 'Catastro',
    ANIO_INICIO_CONSTRUCCION: 'Año inicio construcción',
    LEIDOS: 'Leídos',
    LEIDO: 'Leído',
    DESTINATARIO_NOMBRE: 'Destinatario nombre',
    DESTINATARIO_EMAIL: 'Destinatario email',
    PROGRAMACION_GESTION: 'Programación y Gestión',
    RESUMEN_GENERAL: 'Resumen general',
    VER_MAS: 'Ver más',
    PROYECTOS_FASES: 'Proyectos y fases',
    PROYECTO: 'Proyecto',
    PROYECTOS: 'Proyectos',
    NUEVO_PROYECTO: 'Nuevo proyecto',
    EDITAR_PROYECTO: 'Editar proyecto',
    NUEVA_FASE: 'Nueva fase',
    EDITAR_FASE: 'Editar fase',
    FASES: 'Fases',
    VOLVER_PROYECTOS: 'Volver a proyectos',
    ACTIVIDADES: 'Actividades',
    PROGRAMACION_COSTE: 'Programación y Coste',
    FINANCIACION: 'Financiación',
    NUEVA_ACTIVIDAD: 'Nueva actividad',
    EDITAR_ACTIVIDAD: 'Editar actividad',
    TIPO_ACTIVIDAD: 'Tipo actividad',
    TITULO: 'Título',
    SISTEMA: 'Sistema',
    REHABILITACION: 'Rehabilitación',
    MEJORA: 'Mejora',
    INCIDENCIA: 'Incidencia',
    FECHA_INICIO_DESDE: 'Fecha inicio desde',
    FECHA_INICIO_HASTA: 'Fecha inicio hasta',
    MEJORAS: 'Mejoras',
    OPERACION: 'Operación',
    IMPORTAR_A_ACTIVIDAD: 'Importar a Actividad',
    EXCLUIR_IMPORTADOS: 'Excluir importados',
    ANOTACIONES: 'Anotaciones',
    ACTIVIDAD: 'Actividad',
    NUEVA_ANOTACION: 'Nueva anotación',
    EDITAR_ANOTACION: 'Editar anotación',
    FECHA_SEGUIMIENTO: 'Fecha seguimiento',
    CALENDARIO: 'Calendario',
    GREMIOS: 'Gremios',
    IMAGENES_Y_DOCUMENTOS: 'Imágenes y Documentos',
    FASES_DE_MEJORA: 'Fases de Mejora',
    SI_DESMARCAR: 'Si, desmarcar',
    IR_A_ACTIVIDAD: 'Ir a actividad',
    DOCUMENTACION_TECNICA: 'Documentación técnica',
    DOCUMENTACION_ADMINISTRATIVA: 'Documentación administrativa',
    DOCUMENTACION_JURIDICA: 'Documentación jurídica',
    GENERADOR_DOCUMENTOS: 'Generador de documentos',
    GENERAR_DOCUMENTO: 'Generar documento',
    PLANIFICACION_FINANCIERA: 'Planificación financiera',
    FONDOS_PROPIOS: 'Fondos propios',
    SUBVENCIONES_SOLICITADAS: 'Subvenciones solicitadas',
    SUBVENCIONES_OTORGADAS: 'Subvenciones otorgadas',
    IMPORTE_FINANCIAR: 'Importe a financiar',
    FORMA_FINANCIACION: 'Forma de financiación',
    FECHA_APROBACION_FINANCIACION: 'Fecha de aprobación financiación',
    INFORMACION_PREVIA: `Información previa`,
    GASTOS: 'Gastos',
    NUEVO_GASTO: 'Nuevo gasto',
    EDITAR_GASTO: 'Editar gasto',
    CONCEPTO: 'Concepto',
    IMPORTE: 'Importe',
    MES: 'Mes',
    ANIO: 'Año',
    CREADO_POR: 'Creado por',
    DEFICIENCIA: 'Deficiencia',
    VISTA_PREVIA: 'Vista previa',
    RESUMEN_FINANCIERO: 'Resumen financiero',
    TOTAL_IMPORTE_DE_OBRAS: 'Total importe de obras',
    TOTAL_IMPORTE_DE_HONORARIOS: 'Total importe de honorarios',
    TOTAL_IMPORTE_DE_TASAS: 'Total importe de tasas',
    TOTAL_IMPORTE_OTROS: 'Total importe otros',
    TOTAL: 'Total',
    TOTAL_FASE: 'Total Fase',
    CALENDARIO_PLANIFICACION_FINANCIERA: 'Calendario planificación financiera',
    RESUMEN_FASE: 'Resumen fase',
    GREMIO: 'Gremio',
    NUEVO_GREMIO: 'Nuevo gremio',
    EDITAR_GREMIO: 'Editar gremio',
    TIPO_GREMIO: 'Tipo gremio',
    AGREGAR_GREMIO: 'Agregar gremio',
    LISTA_GREMIOS: 'Lista de gremios',
    CAMBIAR_A_FINALIZADO: 'Cambiar a finalizado',
    CAMBIAR_A_PENDIENTE: 'Cambiar a pendiente',
    INGRESOS: 'Ingresos',
    TESORERIA: 'Tesorería',
    FECHAS_CONSTRUCCION: 'Fechas de construcción',
    LICENCIA_MUNISIPAL_OBRAS: 'Licencia municipal de obras',
    FINALIZACION_OBRAS: 'Finalización de obras',
    NUMERO_EXPEDIENTE: 'Número de expediente',
    VISADO: 'Visado',
    RECEPCION_DEFINITIVA_OBRAS: `Recepción definitiva de obras`,
    LICENCIA_PRIMERA_OCUPACION: `Licencia de primera ocupación`,
    CALIFICACION_PROVISIONAL_VPO: `Calificación provisional VPO`,
    CALIFICACION_DEFINITIVA_VPO: `Calificación definitiva VPO`,
    ESCRITURAS: `Escrituras`,
    LICENCIA_ACTIVIDADES_PARQUING: `Licencia de actividades del parquing`,
    INCIDENCIAS: `Incidencias`,
    NUEVA_INCIDENCIA: 'Nueva incidencia',
    EDITAR_INCIDENCIA: 'Editar incidencia',
    TOMO: 'Tomo',
    FOLIO: 'Folio',
    LIBRO: 'Libro',
    NUMERO_FINCA: 'Número finca',
    REGISTRO: 'Registro',
    INSCRIPCION_GENERAL_DECLARACION_OBRA_NUEVA: 'Inscripción registral de la declaración de obra nueva',
    NUEVA_INSCRIPCION: 'Nueva inscripción',
    EDITAR_INSCRIPCION: 'Editar inscripción',
    PERIODO: 'Periodo',
    NUEVO_PERIODO: 'Nuevo periodo',
    EDITAR_PERIODO: 'Editar periodo',
    PERIODOS: 'Periodos',
    PERIODOS_Y_FASES: 'Periodos y fases',
    VOLVER_PERIODOS: 'Volver a periodos',
    DERRAMAS: 'Derramas',
    NUEVA_DERRAMA: 'Nueva derrama',
    EDITAR_DERRAMA: 'Editar derrama',
    CUOTA: 'Cuota',
    FECHA_BAJA: 'Fecha de baja',
    MESES: 'Meses',
    DAR_DE_BAJA: 'Dar de baja',
    FINALIZAR: 'Finalizar',
    IMPORTE_TOTAL: 'Importe total',
    FONDOS_ECONOMICOS: 'Fondos económicos',
    FONDOS_PROPIOS_ACTUALES: 'Fondos propios actuales',
    SUBVENCIONES: 'Subvenciones',
    FORMA_FINANCIAMIENTO: 'Forma de financiamiento',
    DESCARGAR_RESUMEN_FINANCIERO: 'Descargar resumen financiero',
    CAMBIAR_A: 'Cambiar a',
    IMPORTE_TOTAL_A_FINANCIAR: 'Importe total a financiar',
    IMPORTE_TOTAL_FINANCIADO: 'Importe total financiado',
    RESUMEN_TOTAL: 'Resumen total',
    TOTAL_PROYECTO: 'Total proyecto',
    TOTAL_INGRESOS: 'Total ingresos',
    PENDIENTE_FINANCIAR: 'Pendiente de financiar',
    TOTAL_PORCENTAJE_REDUCCION_CONSUMO_EPNR: 'Total % reducción consumo EPnr',
    TOTAL_COSTE_MINIMO: 'Total Coste mínimo',
    TOTAL_COSTE_MAXIMO: 'Total Coste máximo',
    ASOCIAR_FASES_AL_PERIODO: 'Agregar fases al periodo',
    ORDEN: 'Orden',
    ORDEN_DE_VISUALIZACION: 'Orden de visualización',
    LED_GESTION: 'LED Gestión del mantenimiento',
    LED_REDACCION: 'LED Libro del edificio',
    TEST_ENERGETICO: 'Test energético',
    TEST_ALUMINOSO: 'Test Aluminoso',
    TEST_RADON: 'Test Radón',
    PRUEBAS_DE_LABORATORIO: 'Pruebas de laboratorio',
    APARATOS_DE_MEDIDA: 'Aparatos de medida',
    IDENTIFICACION_MARCA_PROFESIONAL: 'Identificación marca personal',
    PROMOTOR_AGENTES: 'Promotor y Agentes',
    PENDIENTE: 'Pendiente',
    EDITAR: 'Editar',
    VER: 'Ver',
    VER_DETALLE: 'Ver detalle',
    SELECCIONAR: 'Seleccionar',
    VER_HISTORICO: 'Ver histórico',
    INSTRUCCIONES_USO_MANTENIMIENTO: 'Instrucciones de uso y mantenimiento',
    FIRMANTES: 'Firmantes',
    IMPORTAR_FIRMANTES: 'Importar firmantes',
    NUEVO_FIRMANTE: 'Nuevo firmante',
    NOMBRE_Y_APELLIDOS: 'Nombre y Apellidos',
    EDITAR_FIRMANTE: 'Editar firmante',
    FECHA_FIRMA: 'Fecha firma',
    NOMBRE_Y_APELLIDOS_REPRESENTANTE: 'Nombre y apellidos del representante',
    IMPORTANDO_FIRMANTES: 'Importando firmantes',
    FIRMANTE_LIBRO: 'Firmante libro',
    FIRMANTE_PROMOTOR: 'Firmante promotor',
    FICHAS_DE_CAMPO: 'Fichas de campo',
    AGREGAR_SISTEMAS: 'Agregar sistemas',
    PORTAL_GENERADOR_INFORMES_ITE: 'Portal generador informes ITE',
    RESUMEN_DE_MEDIDAS: 'Resumen de medidas',
    COMFIRMACION_CREACION_EDIFICIO: `Confirmación de creación del edificio`,
    SI_CREAR: 'Si, crear',
    CONDICIONES: 'Condiciones',
    EN_ESTE_ENLACE: 'en este enlace',
    PRECIO_GENERAL: 'Precio general',
    HAZ: 'Haz',
    CLICK_AQUI: 'click aquí',
    TIPO_CALLE_AVENIDA: 'Tipo de calle (Calle, Avenida...)',
    IMPORTE_TOTAL_PAGAR: 'Importe total a pagar',
    IVA_INCLUIDO: 'IVA incluido',
    SELECCIONE_UNA_VIA_PAGO: 'Seleccione una vía de pago',
    BUSCAR_EDIFICIO_NOMBRE_REFERENCIA: 'Buscar edificio por Nombre o Referencia catastral',
    PROFESION: 'Profesión',
    OTRA_PROFESION: 'Indicar profesión',
    NUMERO_DE_COLEGIADO: 'Número de colegiado',
    COLEGIO_PROFESIONAL: 'Colegio profesional',
    PAIS: 'País',
    AGREGAR_UNA_IMAGEN: 'Agregar una imagen',
    AGREGAR_A_ACTIVIDADES: 'Agregar a actividades',
    REGISTRARSE_Y_COMPRAR: 'Registrarse y comprar',
    RESUMEN: 'Resumen',
    REDUCCION_CONSUMO: 'reducción consumo',
    USE_ARRASTRE_ICONO: 'Use y arrastre el icono',
    PARA_CAMBIAR_ORDEN: 'para cambiar de orden',
    RESUMEN_FASES: 'Resumen de fases',
    ENLACES_EXTERNOS: 'Enlaces externos',
    REGRESAR_AL_EDIFICIO: 'Regresar al edificio',
    ESCRIBA_SU_NIF: 'Escriba su NIF',
    NUESTROS_MODULOS: 'Nuestros módulos',
    SIN_IVA: 'Sin IVA',
    TARIFA_GENERAL: 'Tarifa general',
    TARIFA_CONVENIO: 'Tarifa convenio',
    POTENCIAL_DE_MEJORAS: 'Potencial de mejoras',
    ACEPTAR: 'Aceptar',
    DATOS_ADICIONALES: 'Datos adicionales',
    RESUMEN_COSTES: 'Resumen costes',
    INCLUIR_MANUAL_VIVIENDA: 'Incluir en el Manual de la vivienda',
    PROMOTORES_AGENTES_EDIFICACION: 'Promotores y Agentes de la edificación',
    NUEVA_ACTUACION: 'Nueva actuación',
    EDITAR_ACTUACION: 'Editar actuación',
    ACTUACIONES: 'Actuaciones',
    ACEPTACION_PAGO_CUENTA: 'Aceptación de pago a cuenta',
    BAJA_SUSCRIPCION: 'Baja de suscripción',
    ELIMINAR_EDIFICIO: 'Eliminar edificio',
    IMPORTAR_SISTEMAS_OTRO_EDIFICIO: 'Importar Sistemas desde otro Edificio',
    SELECCIONA_UNA_OPCION: 'Selecciona una opción',
    LISTA_EDIFICIOS: 'Lista de edificios',
    ANIO_S: 'Año(s)',
    MES_ES: 'Mes(es)',
    UNIDAD: 'Unidad',
    INCLUIR_OPERACION: 'Incluir operación de mantenimiento',
    SI: 'Si',
    NO: 'No',
    CREAR_GREMIO: 'Crear gremio',
    SINCRONIZAR_CATASTRO: 'Sincronizar catastro',
    ACTIVO: 'Activo',
    EMPRESAS_COLABORADORAS: 'Empresas colaboradoras',
    MONITORIZACION: 'Monitorización',
    PROXIMAMENTE: 'Próximamente',
    DOMOTICA_INMOTICA: 'Domótica / Inmótica',
    ACTUACIONES_DE_REHABILITACION: 'Actuaciones de rehabilitación',
    ACEPTACION_INGRESO_EDIFICIO: 'Aceptación al ingreso del edificio',
    MEDIDAS_DE_MEJORA: 'Medidas de mejora',
    GREMIOS_ASIGNADOS: 'Gremios asignados',
    RESUMEN_FINANCIERO_GASTOS_FASES_PLANIFICACION_FINANCIERA: 'Resumen financiero (Gastos por fases / Planificación financiera)',
    FINANCIACION_BANCARIA: 'Financiación bancaria',
    SABER_MAS: 'Saber más',
    MAS_INFORMACION: 'Más información',
    VER_TARIFAS: 'Ver tarifas',
    GENERAR_ACTIVIDADES_PERIODICAS: 'Generar actividades periódicas',
    TOTAL_DERRAMAS_FINALIZADAS: 'Total derramas finalizadas',
    ENTIDAD_JURIDICA: 'Entidad jurídica',
    NUEVA_ENTIDAD_JURIDICA: 'Nueva entidad jurídica',
    EDITAR_ENTIDAD_JURIDICA: 'Editar entidad jurídica',
    CONTACTAR: 'Contactar',
    COMO_USUARIO_COMO_INVITADO: 'Como usuario registrado o como invitado',
    OCULTAR: 'Ocultar',
    CREAR_NUEVA_CUENTA: 'Crear una nueva cuenta',
    PROPIETARIO_EDIFICIO_VIRTUAL: 'Propietario edificio virtual',
    FINALIZADO: 'Finalizado',
    FECHA_FINALIZACION: 'Fecha finalización',
    FECHA_FINALIZACION_DESDE: 'Fecha finalización desde',
    FECHA_FINALIZACION_HASTA: 'Fecha finalización hasta',
    AVISO_LEGAL_POLITICA_PRIVACIDAD: 'Aviso legal y Política de privacidad',
    AVISO_LEGAL_CONDICIONES_USO_WEB: 'Aviso legal y condiciones de uso de la web',
    FUNCIONAMIENTO_NUESTRO_SITIO_WEB: 'Funcionamiento de nuestro sitio web',
    CONDICIONES_USO_SITIO_WEB_TODOS_USUARIOS: 'Condiciones de uso de nuestro sitio web para todos los usuarios web',
    REGIMEN_RESPONSABILIDADES: 'Régimen de responsabilidades',
    POLITICA_CONTENIDOS: 'Política de contenidos',
    DERECHOS_PROPIEDAD_INTELECTUAL: 'Derechos de Propiedad Intelectual',
    DURACION_FINALIZACION: 'Duración y finalización',
    PRECIOS_PRODUCTOS_SERVICIOS: 'Precios de los productos y servicios',
    JURISDICCION_COMPETENCIA: 'Jurisdicción y competencia',
    PROTECCION_DATOS: 'Protección de datos',
    CONDICIONES_GENERALES_PRODUCTOS_LED: 'Condiciones generales de contratación productos LED',
    CONDICIONES_PARTICULARES_PRODUCTOS_LED: 'Condiciones particulares de contratación productos LED',
    AMBITO_APLICACION_OBJETO: 'Ámbito de aplicación y objeto',
    IDIOMA: 'Idioma',
    PROCESO_CONTRATACION: 'Proceso de contratación',
    INFORMACION_PRODUCTOS_SERVICIOS_DIGITALES: 'Información sobre los Productos y Servicios digitales',
    CONDICIONES_ECONOMICAS: 'Condiciones económicas',
    GARANTIA_LEGAL: 'Garantía Legal',
    DESESTIMIENTO_DEVOLUCIONES_INCIDENCIAS: 'Desistimiento, devoluciones e incidencias',
    RESPONSABILIDADES: 'Responsabilidades',
    VIGENCIA_CONTRATO: 'Vigencia del contrato',
    PROPIEDAD_INTELECTUAL_INDUSTRIAL: 'Propiedad intelectual e industrial',
    ATENCION_CLIENTE_RECLAMACIONES: 'Atención al Cliente y Reclamaciones',
    HOJAS_RECLAMACION: 'Hojas de Reclamación',
    LEGISLACION_APLICABLE_JURISDICCION_COMPETENTE: 'Legislación Aplicable y Jurisdicción competente',
    OBJETO_CONTRATO: 'Objeto del contrato',
    CARACTERISTICAS_HERRAMIENTA_FUNCIONAMIENTO: 'Características de la herramienta y funcionamiento',
    PRECIO: 'Precio',
    PROCESO_COMPRA: 'Proceso de compra',
    FORMA_PAGO: 'Forma de pago',
    ATENCION_POSTVENTA: 'Atención postventa',
    DESISTIMIENTO: 'Desistimiento',
    DOCUMENTOS_EDIFICIO_PENDIENTES: 'Documentos de Edificio pendientes',
    MARCAR_COMO_LEIDO: 'Marcar como leído',
    DOCUMENTOS_INMUEBLE_PENDIENTES: 'Documentos de Inmueble pendientes',
    REAPERTURAR: 'Reaperturar',
    PROGRAMA_MANTENIMIENTO_REHABILITACION_RENOVACION: 'Programa de rehabilitación',
    PLANTILLA_DOCUMENTO_SEPA: 'Plantilla Documento SEPA',
    PROFESIONALES: 'Profesionales',
    TECNICOS_ESPECIALISTAS_EN_GESTION_MANTENIMIENTO: 'Técnicos especialistas en gestión del mantenimiento',
    MANUAL_DE_LA_VIVIENDA: 'Manual de la vivienda',
    REPRESENTANTE: 'Representante',
    ES_PROPIETARIO_REPRESENTANTE_LEGAL: '¿Es el propietario/a o representante legal de la propiedad del edificio?',
    DATOS_PROPIETARIO_REPRESENTANTE: 'Datos del propietario/a o Representante legal',
    DATOS_PROPIEDAD_REPRESENTANTE_LEGAL: 'Datos de la propiedad o representante legal de la propiedad',
    PERFIL_REPRESENTANTE_LEGAL: 'Perfil que desempeña en el edificio',
    ASIGNAR_ESPECIALISTA: 'Asignar especialista',
    NIF_ESPECIALISTA: 'NIF/NIE del especialista',
    PENDIENTE_DOCUMENTO_SEPA: 'Pendiente documento SEPA',
    COMUNICACIONES: 'Comunicaciones',
    DOCS_EDIFICIO: 'Docs Edificio',
    DOCS_INMUEBLES: 'Docs Inmuebles',
    USUARIO_INVITADO: 'Usuario invitado',
    TEXTO: 'Texto',
    NUMERICO: 'Numérico',
    COSTO: 'Costo',
    DIFICULTAD_EJECUCION_NULA: 'Dificultad de ejecución nula',
    DIFICULTAD_EJECUCION_BAJA: 'Dificultad de ejecución baja',
    DIFICULTAD_EJECUCION_MEDIA: 'Dificultad de ejecución media',
    DIFICULTAD_EJECUCION_ELEVADA: 'Dificultad de ejecución elevada',
    CONDICIONES_DE_CONTRATACION: 'condiciones de contratación',
    MANIFIESTO_LEIDO_Y_ACEPTO_LAS: 'Manifiesto que he leído y acepto las',
    PROTECCION_DATOS_CARACTER_PERSONAL: 'protección de datos de carácter personal',
    MANIFIESTO_LEIDO_Y_ACEPTO_INFORMACION_RELATIVA_A: 'Manifiesto que he leído y acepto la información relativa a la',
    MANIFIESTO_LEIDO_ACEPTO_QUE: 'Manifiesto que he leído y acepto que',
    SI_FACILITO_DATOS_TERCERAS_PERSONAS: 'si facilito los datos a terceras personas, tendré que informar y obtener su consentimiento previo en relación con los aspectos relacionados en el presente documento',
    CONDICIONES_REGISTRO: 'condiciones de registro',
    SUBIR_SEPA_FINALIZAR: 'Subir SEPA y finalizar',
    IMPORTE_UNIDAD_ENTIDAD_JURIDICA: 'Importe por unidad de Entidad jurídica',
    NUMERO_ENTIDADES_JURIDICAS: 'Número de Entidades jurídicas',
    EL_SIGUIENTE_ENLACE: `el siguiente enlace`,
    ESPECIALISTA_ASIGNADO_CORRECTAMENTE: 'El especialista se ha asignado correctamente.',
    NO_ENCONTRADO__NIF_ESPECIALISTA: 'No se ha encontrado el NIF del especialista en nuestro sistema.',
    PAGO_DOMICILIACION: 'Pago por domiciliación',
    TOTAL_FASES: 'Total Fases',
    TEXTO_PRELIMINAR: 'Texto preliminar',
    TEXTO_PRELIMINAR_PLAN: 'Texto preliminar del Plan',
    CREAR_CUENTA: 'Crear cuenta',
    NUEVA_PROPIEDAD: 'Nueva propiedad',
    CANTIDAD_TOTAL: 'Cantidad total',
    CANTIDAD_ENVIADOS: 'Cantidad enviados',
    HERRAMIENTA_DIGITAL_REDACCION: `HERRAMIENTA DIGITAL DE SOPORTE A LA REDACCIÓN DEL LIBRO DEL EDIFICIO`,
    HERRAMIENTA_DIGITAL_GESTION: `HERRAMIENTA DIGITAL DE GESTIÓN INTEGRAL DE EDIFICIOS`,
    PROPIETARIO_A: 'Propietario/a',
    REPRESENTANTE_LEGAL_PROPIEDAD: 'Representante legal propiedad',
    AUTORIZADO_A_PROPIEDAD: 'Autorizado/a propiedad',
    PRESIDENTE_A_PROPIEDAD: 'Presidente/a propiedad',
    ADMINISTRADOR_A_FINCAS: 'Administrador/a de fincas',
    ACCESO_USUARIOS: 'Acceso usuarios',
    DATOS_PERSONALES: 'Datos personales',
    DATOS_CONTACTO: 'Datos de contacto',
    SELECCION_ENTIDADES_JURIDICAS_EDIFICIO: `Selección de entidades jurídicas que conforman el edificio`,
    TRANSFERIR_EDIFICIO: 'Transferir edificio',
    NIF_NIE_NUEVO_PROPIETARIO: 'NIF / NIE del nuevo propietario del edificio virtual',
    TRANSFERENCIA_EXISTOSA: 'Se ha transferido el edificio',
    ACEPTAR_TRANSFERENCIA_EDIFICIO: 'Aceptar transferencia del edificio',
    IR_AL_EDIFICIO: 'Ir al edificio',
    DURACION_CONTRATO: 'Duración del contrato',
    DIVISIONES: 'Divisiones',
    COMUNICACIONES_PARA_USUARIO: 'Comunicaciones para el usuario',
    AVISO_VENCIMIENTO_DOCS_ASOCIADOS_EDIFICIOS: 'Aviso de vencimiento de documentos asociados a edificios',
    AVISO_VENCIMIENTO_DOCS_ASOCIADOS_VIVIENDAS: `Aviso de vencimiento de documentos asociados a viviendas`,
    CERTIFICADO_APTITUD: `Certificado de Aptitud`,
    NUMERO_EXPENDIENTE: `Número de expediente`,
    VIGENCIA_LIMITE_CERTIFICADO: `Vigencia límite del certificado`,
    CUALIFICACION_CERTIFICADO_APTITUD: `Cualificación del certificado de aptitud`,
    FECHA_PRIMERA_VERIFICACION_OBLIGATORIA: `Fecha de la primera verificación técnica obligatoria que se debe realizar`,
    FECHA_RECEPCION_INFORME_ITE: `Fecha de recepción del informe ITE por parte de la Propiedad`,
    PLAZO_MAXIMO_PROGRAMA_REHABILITACION: `Plazo máximo para aprobar el Programa de Rehabilitación`,
    APTO_CAUTELARMENTE: `Apto cautelarmente`,
    APTO_PROVISIONALMENTE: `Apto provisionalmente`,
    APTO: `Apto`,
    PROPIETARIO: `Propietario`,
    PROFESIONAL: `Profesional`,
    CODIGO_INFORME_ITE: 'Código del informe ITE',
    FECHA_EMISION_INFORME_ITE: `Fecha de emisión del informe ITE`,
    REDACTOR_ITE: `Redactor ITE`,
    NUMERO_COLEGIADO: `Número de colegiado`,
    AGREGAR_ESPECIALISTA: `Agregar especialista`,
    NECESARIO_AGREGAR_ESPECIALISTA: `Para poder llevar a cabo la gestión del mantenimiento es necesario agregar a un técnico especialista en gestión del mantenimiento en la pantalla`,
    CONTACTA_TECNICOS_ESPECIALISTAS_LOCALIZACION: `Contacta con técnicos especialistas en gestión del mantenimiento según la localización del edificio`,
    CUENTA_ENCONTRADA: `Cuenta encontrada`,
    CERRAR: 'Cerrar',
    CERRAR_SIN_SEPA: `Cerrar sin SEPA`,
    ACTIVIDADES_PROXIMAS: `Actividades próximas`,
    DESCARGAR_EXCEL: `Descargar Excel`,
    DESCARGANDO: `Descargando`,
    HISTORICO_PROPIEDADES: `Histórico de propiedades`,
    CATALUNIA: 'Cataluña',
    GALICIA: 'Galicia',
    ISLAS_BALEARES: 'Islas Baleares',
    PAIS_VASCO: 'País Vasco',
    NAVARRA: "Navarra",
    ABRIR: "Abrir",
    VER_MANT_PREVENTIVO: `Ver Mant. Preventivo`,
    VER_MANT_CORRECTIVO: `Ver Mant. Correctivo`,
    VER_MEJORA: `Ver Mejora`,
    FINALIZAR_ACTIVIDAD: `Finalizar actividad`,
    FECHA_FIN_DESDE: `Fecha fin desde`,
    FECHA_FIN_HASTA: `Fecha fin hasta`,
    ACTUALIZAR_DATOS_USUARIO: `Actualizar datos de usuario`,
    NUMERO_COLEGIADO_A: `Número de colegiado/da`,
    ESCRIBA_SU_NIF_NIE: `Escriba su NIF / NIE`,
    INCLUIR_EN_LIBRO_EDIFICIO: `Incluir en el Libro del edificio`,
    CASTILLA_Y_LEON: `Castilla y León`,
    AGREGAR_PROPIEDAD: `Agregar propiedad`,
    EDITAR_PROPIEDAD: `Editar propiedad`,
    PERSONA_JURIDICA: `Persona jurídica`,
    TERRITORIO_NACIONAL: 'Resto del territorio',
    EDIFICIO_SITUADO_EN: 'El edificio está situado en',
    PASAR_A_HISTORICO: `Pasar a histórico`,
    NOTA_INFO_REGISTRO: `El alta de un edificio se hace sincronizado automáticamente con los datos de la Sede electrónica de catastro de las distintas provincias y ciudades autónomas del territorio español, excepto País Vasco y Navarra que disponen de sus correspondientes catastros autonómicos o provinciales`,
    INCLUIR_EN_EL_MANUAL_DE_LA_VIVIENDA: `Incluir en el Manual de la vivienda`,
    CANARIAS: `Canarias`,
    VER_DESTINATARIOS: `Ver destinatarios`,
    ENVIADO_POR: `Enviado por`,
    SOLO_SE_ADMINTEN: 'Sólo se admiten documentos en formato',
    TOTAL_DESTINATARIOS: `Total destinatarios`,
    VER_MENSAJE: `Ver mensaje`,
    NUEVO_INGRESO: `Nuevo ingreso`,
    EDITAR_INGRESO: `Editar ingreso`,
    TIPO_INGRESO: `Tipo de ingreso`,
    INGRESO: `Ingreso`,
    FINALIZAR_DERRAMA: `Finalizar derrama`,
    PREGUNTAS_FRECUENTES: `Preguntas frecuentes`,
    COLABORACION_DE: `Con la colaboración de`,
    TECNICO_REDACTOR: `Técnico redactor`,
    GESTOR_MANTENIMIENTO: `Gestor de mantenimiento`,
    PROMOTOR: `Promotor`,
    ANDALUCIA: `Andalucía`,
    ALERTA_NOTIFICACIONES_1: `Las notificaciones llegan con`,
    ALERTA_NOTIFICACIONES_2: `días de antelación`,
    TRANSFERENCIA_EXITOSA: `Se ha transferido el edificio al nuevo propietario`,
    MAX: `Máx`,
    SE_HA_EXCEDIDO_PESO_PERMITIDO: `Se ha excedido el peso permitido`,
    NOTA: `Nota`,
    ALERTA_NOTIFICACIONES_EDIFICIO: `Las notificaciones de preaviso de caducidad de los documentos se hace con una antelación de 3 meses (90 días) siempre que se haya identificado esta fecha al cargar el archivo en la Biblioteca de documentos.`,
    ALERTA_NOTIFICACIONES_VIVIENDAS: `Las notificaciones de preaviso de caducidad de los documentos se hace con una antelación de 3 meses (90 días) siempre que se haya identificado esta fecha al cargar el archivo en la Biblioteca de documentos.`,
    ALERTA_NOTIFICACIONES_ACTIVIDADES: `Las notificaciones de preaviso de inicio de una actividad programada se hace con una antelación de 3 meses (90 días).`,
    ALERTA_DOCUMENTO_ADJUNTOS_GENERADOR_LIBRO: `En caso de adjuntar documentos de la biblioteca que han agotado su fecha de validez, éstos no se incluirán en el Libro del edificio. En caso de quererlos incluir se tendrá que actualizar su fecha de validez o eliminar la fecha en caso de no tenerlos que renovar (en este caso, también dejarán de aparecer las notificaciones de caducidad del documento).`,
    FICHA: `Ficha`,

}

export default GENERALS;