import { useAxios } from "../../../../../../../../hooks/useAxios"
import { FaseAdapters, FasesAdapters } from "./fases.adapters"

const MejoraFasesServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/potencial_mejora/edificio_mejora_fase',
            type: 'json',
            adapter: {
                method: FasesAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const MejoraFaseServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/edificio_mejora_fase`,
            type: 'json',
            adapter: {
                method: FaseAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/potencial_mejora/edificio_mejora_fase',
            type: 'json',
            adapter: {
                method: FaseAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora_fase',
            type: 'json',
            adapter: {
                method: FaseAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/potencial_mejora/edificio_mejora_fase',
            type: 'json',
            adapter: {
                method: FaseAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_ONBLUR: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora_fase',
            type: 'json',
            adapter: {
                method: FaseAdapters.PATCH_ONBLUR,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

}

export {
    MejoraFasesServices,
    MejoraFaseServices
}