import { useEffect, useState } from 'react'
import { FormControl, TextField, Typography } from '@mui/material'

export const InputTextarea = ({ label, readOnly, value, rows, onChange, name, placeholder, maxLength, readOnlyMU,
    className = '', localValidation = [], fetchData = {}, onFocus, onBlur }: any) => {

    const [validations, setvalidations] = useState({});

    useEffect(() => {

        setvalidations(validated(localValidation))

    }, [...(localValidation?.map((item: any) => item.isOk))]);

    useEffect(() => {

        if (fetchData?.error?.code === 422) {

            const errorsByName = fetchData?.error?.errors?.[name]

            if (errorsByName) {

                setvalidations(validatedServer(errorsByName))
            }
        }

    }, [fetchData]);

    const propsNative: any = {}
    const inputProps: any = {}

    if (maxLength) {
        inputProps.maxLength = maxLength
        propsNative.inputProps = inputProps
    }

    return (
        <FormControl fullWidth variant="standard">
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className="my-0 pb-1" component="span">
                            {value}
                        </Typography>
                    </> :
                    <>
                        <TextField
                            id="standard-multiline-static"
                            label={label}
                            multiline
                            rows={rows}
                            value={value}
                            name={name}
                            className={`${className} ${readOnlyMU ? 'pe-none' : ''}`}
                            onChange={onChange}
                            variant="standard"
                            placeholder={placeholder}
                            {...validations}
                            {...propsNative}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            InputProps={{
                                readOnly: readOnlyMU,
                            }}
                        />
                    </>
            }
        </FormControl>
    )
}

const validated = (localValidation: any) => {

    let localValidationPreFilter = localValidation?.filter((item: any) => item.isOk === false) || []

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{item.msgError}</span>
            }),
            error: true
        }
    }

    return res
}

const validatedServer = (errorsByName: any) => {

    let localValidationPreFilter = errorsByName

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{item}</span>
            }),
            error: true
        }
    }

    return res
}
