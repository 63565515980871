import React, { useState } from "react";
import { isEmpty } from "lodash";
import { MejoraFases } from "./Fases";
import ModalAdvanced from "../../../../../../components/ModalAdvanced/ModalAdvanced";
import { useLang } from "../../../../../../language";
import { Typography } from "@mui/material";

export const ModalMejoraFases = ({ data, handleClose, idedificio }: any) => {

    const Lang = useLang()

    const [proyectoSelected, setproyectoSelected] = useState<any>({});


    return (
        <ModalAdvanced data={data} handleClose={handleClose} size='675'>

            <ModalAdvanced.Head>

                <Typography variant="h6" component="h2">
                    {Lang('FASES')}
                </Typography>

            </ModalAdvanced.Head>

            <ModalAdvanced.Body className='p-0'>

                <MejoraFases
                    idedificio={idedificio}
                    proyectoSelected={proyectoSelected}
                    setproyectoSelected={setproyectoSelected}
                />
            </ModalAdvanced.Body>

        </ModalAdvanced>
    )
};
