import { useAxios } from "../../../../../../../../hooks/useAxios"
import { DerramaAdapters, DerramasAdapters } from "./derramas.adapters"

const DerramasServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/derramas',
            type: 'json',
            adapter: {
                method: DerramasAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const DerramaServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/derrama`,
            type: 'json',
            adapter: {
                method: DerramaAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio_proyectos/derrama',
            type: 'json',
            adapter: {
                method: DerramaAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/derrama',
            type: 'json',
            adapter: {
                method: DerramaAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/derrama',
            type: 'json',
            adapter: {
                method: DerramaAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    DerramasServices,
    DerramaServices
}