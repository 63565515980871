import { Grid } from "@mui/material";
import { TableDerramas } from "./TableDerramas";

export const Derramas = ({ data, idEdificio, fetchEdificioGETData, idCurrentProject, fetchPlanificacionFinancieraGET, 
    readOnly }: any) => {

    return (
        <>
            <div className="">

                <Grid container>

                    <Grid item xs={12} className='py-0 py-lg-0'>

                        <section>
                            <TableDerramas
                                data={data}
                                loading={false}
                                
                                idEdificio={idEdificio}
                                
                                fetchEdificioGETData={fetchEdificioGETData}
                                
                                fetchPlanificacionFinancieraGET={fetchPlanificacionFinancieraGET}

                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                            />
                        </section>

                    </Grid>

                </Grid>
            </div>

        </>
    );
};
