import { useAxios } from "../../../../../../../hooks/useAxios"
import { DocumentacionAdapters, DocumentoAdapters } from "./documentacion.adapters"

const DocumentacionServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/potencial_mejora/edificio_mejora_documentos',
            type: 'json',
            adapter: {
                method: DocumentacionAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_RESUMEN_COSTES: function (idEdificio: string) {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/costo_por_nivel_principal/${idEdificio}`,
            type: 'json',
            adapter: {
                method: DocumentacionAdapters.GET_RESUMEN_COSTES,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },
}

const DocumentoServices = {

    GET: function () {

        return useAxios({
            method: 'get',
            url: `/edificio_documentos`,
            type: 'json',
            adapter: {
                method: DocumentoAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'post',
            url: '/potencial_mejora/edificio_mejora_documento',
            type: 'json',
            adapter: {
                method: DocumentoAdapters.POST,
                types: {
                    send: 'multipart',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'post',
            url: '/edificio_documentos',
            type: 'json',
            adapter: {
                method: DocumentoAdapters.PATCH,
                types: {
                    send: 'multipart',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/potencial_mejora/edificio_mejora_documento',
            type: 'json',
            adapter: {
                method: DocumentoAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    GET_POTENCIAL_MEJORAS: function (idEdificio: string) {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/generar_pdf/${idEdificio}`,
            type: 'json',
            adapter: {
                method: DocumentoAdapters.GET_POTENCIAL_MEJORAS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_POTENCIAL_MEJORAS_BLOB: function (idEdificio: string) {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/generar_pdf/${idEdificio}`,
            type: 'blob',
            adapter: {
                method: DocumentoAdapters.GET_POTENCIAL_MEJORAS_BLOB,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'blob', // object, array, list (with pagination)
                }
            },
        })
    },
}

export {
    DocumentacionServices,
    DocumentoServices
}