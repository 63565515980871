import { parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../../../controllers/controllers"

const AnotacionesAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    projecte_id: parseNumber(item.projecte_id),
                    creat_per_nom: parseString(item.creat_per_nom),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    data_seguiment: parseString(item.data_seguiment),
                    label__data_seguiment: receiveFechaFormat2(receiveFecha(item.data_seguiment)),
                    fecha_finalizacion: parseString(item.fecha_finalizacion),
                    label__fecha_finalizacion: receiveFechaFormat2(receiveFecha(item.fecha_finalizacion)),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const AnotacionAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    projecte_id: parseNumber(item.projecte_id),
                    creat_per_nom: parseString(item.creat_per_nom),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    data_seguiment: receiveFecha(item.data_seguiment),
                    fecha_finalizacion: parseString(item.fecha_finalizacion),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
  
    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_id: parseNumber(item.projecte_id),
                    projecte_intervencion_id: parseNumber(item.projecte_intervencion_id),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    data_seguiment: parseString(item.data_seguiment),
                    fecha_finalizacion: parseString(item.fecha_finalizacion),

                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_id: parseNumber(item.projecte_id),
                    projecte_intervencion_id: parseNumber(item.projecte_intervencion_id),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                    data_seguiment: parseString(item.data_seguiment),
                    fechafinalizacion: parseString(item.fecha_finalizacion),

                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    AnotacionesAdapters,
    AnotacionAdapters,
}