import { useLang } from "../../../../../../language";
import { Navigate, Route, Routes } from "react-router-dom";
import { LayoutProgramacionGestionNavTop } from "./LayoutProgramacionGestionNavTop";
import { Actividades } from "../Actividades/Actividades";
import { IndexImportarActividades } from "../ImportarActividades";
import { ElementosConstructivosServices } from "../../../../../libro/pages/LibroEdificio/controllers/elemconst.services";
import { useEffect } from "react";
import { PlanificacionFinanciera } from "../PlanificacionFinanciera/PlanificacionFinanciera";
import { ResumenFinanciero } from "../ResumenFinanciero/ResumenFinanciero";

export const LayoutProgramacionGestion = ({ id, text, setidCurrentFase, idedificio, setDefaultLink, defaultLink,
    navLinksFases, idCurrentFase, readOnly }: any) => {

    const Lang = useLang()

    const navLinks = [
        {
            title: Lang('ACTIVIDADES'),
            link: 'actividades'
        },
        {
            title: Lang('PLANIFICACION_FINANCIERA'),
            link: 'planificacion-financiera'
        },
    ]

    const [fetchElementosConstructivos, fetchElementosConstructivosData] = ElementosConstructivosServices.GET()

    useEffect(() => {

        fetchElementosConstructivos()

    }, []);


    return (
        <>
            <div className="LayoutProgramacionGestion__NavTop d-flex">

                <LayoutProgramacionGestionNavTop
                    navLinks={navLinks}
                    navLinksFases={navLinksFases}
                    text={text}
                    id={id}
                    idedificio={idedificio}
                    idCurrentFase={idCurrentFase}
                    setidCurrentFase={setidCurrentFase}
                />

            </div>

            <div className="LayoutProgramacionGestion__Container">

                <Routes>

                    <Route
                        path={navLinks[0].link + '/*'}
                        element={
                            <Actividades
                                id={id}
                                idedificio={idedificio}
                                idCurrentFase={idCurrentFase}
                                navLinksFases={navLinksFases}
                                fetchElementosConstructivosData={fetchElementosConstructivosData}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path={navLinks[1].link + '/*'}
                        element={
                            <PlanificacionFinanciera
                                idCurrentFase={idCurrentFase}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="importar-actividad/*"
                        element={
                            <IndexImportarActividades
                                idedificio={idedificio}
                                idCurrentFase={idCurrentFase}
                                navLinksFases={navLinksFases}
                                fetchElementosConstructivosData={fetchElementosConstructivosData}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="resumen-financiero/*"
                        element={
                            <ResumenFinanciero
                                setidCurrentFase={setidCurrentFase}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={<Navigate to={navLinks[0].link} replace />}
                    />

                </Routes>
            </div>

        </>
    );
};
