import { Box, Grid } from '@mui/material'
import { InputText } from '../../../../../../../components/Inputs'
import { useLang } from '../../../../../../../language'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { convertStaticServiceToArray } from '../../../../../../../helpers/helpers'

export const FormSimple = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParamsMisSistemasGETData,
    tiposIntervencionStaticServices, fetchParamsPublicGETData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Box component="form" noValidate autoComplete="off">
                <Grid container columnSpacing={4}>

                    <Grid item xs={12} md={6} lg={4}>
                        <InputText
                            label={`${Lang('TITULO')} / ${Lang('DESCRIPCION')}:`}
                            name='magic'
                            value={formValuesSearch.magic}
                            onChange={handleInputChangeSearch}
                            className='mb-3 w-100'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <InputSelect
                            label={Lang('SISTEMA') + ':'}
                            name='sistemaid'
                            value={formValuesSearch.sistemaid}
                            values={fetchParamsMisSistemasGETData?.data || []}
                            onChange={handleInputChangeSearch}
                            // localValidation={validLogin.numedificio}
                            className='mb-3 '
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputSelect
                            label={Lang('ESTADO') + ':'}
                            name='estadoactividad'
                            value={formValuesSearch.estadoactividad}
                            values={fetchParamsPublicGETData?.data?.['estado_actividad']}
                            onChange={handleInputChangeSearch}
                            // localValidation={validLogin.numedificio}
                            className='mb-3 '
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputSelect
                            label={Lang('TIPO_ACTIVIDAD') + ':'}
                            name='tipusintervencio'
                            value={formValuesSearch.tipusintervencio}
                            values={convertStaticServiceToArray(tiposIntervencionStaticServices)}
                            onChange={handleInputChangeSearch}
                            // localValidation={validLogin.numedificio}
                            className='mb-3 '
                        />
                    </Grid>

                    <Grid item className='w-100 py-0'></Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputText
                            label={Lang('FECHA_INICIO_DESDE') + ':'}
                            name='data_inici_desde'
                            type='date'
                            value={formValuesSearch.data_inici_desde}
                            onChange={handleInputChangeSearch}
                            className='mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputText
                            label={Lang('FECHA_INICIO_HASTA') + ':'}
                            name='data_inici_hasta'
                            type='date'
                            value={formValuesSearch.data_inici_hasta}
                            onChange={handleInputChangeSearch}
                            className='mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputText
                            label={Lang('FECHA_FIN_DESDE') + ':'}
                            name='data_fi_activitat_desde'
                            type='date'
                            value={formValuesSearch.data_fi_activitat_desde}
                            onChange={handleInputChangeSearch}
                            className='mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputText
                            label={Lang('FECHA_FIN_HASTA') + ':'}
                            name='data_fi_activitat_hasta'
                            type='date'
                            value={formValuesSearch.data_fi_activitat_hasta}
                            onChange={handleInputChangeSearch}
                            className='mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                </Grid>

            </Box>
        </>
    )
}
