import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { InputText } from "../../../../components/Inputs";
import { useValidator } from "../../../../hooks/useValidator";
import { useForm } from "../../../../hooks/useForm";
import { validExiste, validNIFNIE } from "../../../../helpers/validations";
import { useLang } from "../../../../language";
import { ButtonAdvanced } from "../../../../components/ButtonAdvanced/ButtonAdvanced";
import { agregarRol, isChecked, isStatusSuccess } from "../../../../helpers/helpers";
import jwt_decode from 'jwt-decode'
import { AuthServices } from "../../../../controllers/auth/auth.services";
import { useDispatch } from "react-redux";
import { logIn } from "../../../../store/slices/auth";
import { useModalData } from "../../../../hooks/useModalData";
import { ModalStatusServer } from "../../../../components/Modals/ModalStatusServer";
import { useLocation } from 'react-router-dom';
import { ModalRecuperarCodigo } from "../ModalRecuperarCodigo";
import { ModalAceptarEdificio } from "./ModalAceptarEdificio";
import queryString from 'query-string'
import { ModalInfo } from "../../../../components/Modals/ModalInfo";
import { InputChecks } from "../../../../components/Inputs/InputChecks";
import { isEmpty } from "lodash";

export const AccesoEdificio = () => {

    const Lang = useLang()
    const location = useLocation()

    const params = queryString.parse(location.search);

    const dispatch = useDispatch()

    const [fetchAuthAccesoEdificioPOST, fetchAuthAccesoEdificioPOSTData] = AuthServices.POST_LOGIN_EDIFICIO()

    let localNIF = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_NIF_CODE') || ''

    const [formAccesoEdificio, handleInputAccesoEdificio] = useForm({
        nif: params?.nif || localNIF || '',
        code: params?.code || '',
        rememmberNIF: !isEmpty(localNIF) ? ['rememmberNIF'] : [],
    })

    const [validAccesoEdificio, handleValidationAccesoEdificio] = useValidator({
        nif: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
            { functionValid: validNIFNIE, msgError: Lang('VALID_CORRECT_DNI'), isOk: true },
        ],
        code: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CLAVE'), isOk: true },
        ],
    })

    const handleSubmitLogin = (e?: any) => {

        if (e) e.preventDefault()


        if (handleValidationAccesoEdificio(formAccesoEdificio)) {

            localStorage.setItem(
                process.env.REACT_APP_MAIN_VARIABLE + '_NIF_CODE' || '',
                isChecked(formAccesoEdificio.rememmberNIF) ? (formAccesoEdificio.nif || '') : ''
            )

            fetchAuthAccesoEdificioPOST({
                body: formAccesoEdificio
            })
        }
    }

    const [dataModal_ActualizarUsuario, handleShow_ActualizarUsuario, handleClose_ActualizarUsuario] = useModalData()

    useEffect(() => {

        if (isStatusSuccess(fetchAuthAccesoEdificioPOSTData.status)) {

            const token = fetchAuthAccesoEdificioPOSTData?.data?.token || ''

            const data: any = jwt_decode(token)

            if (fetchAuthAccesoEdificioPOSTData?.data?.activ === true) {

                dispatch(logIn({
                    user: data,
                    token: token,
                    perfil: data.perfil || ''
                }))

            } else if (fetchAuthAccesoEdificioPOSTData?.data?.activ === false) {

                handleShowModal_InfoError()

            } else {

                const edificio = fetchAuthAccesoEdificioPOSTData?.data?.edificio

                let rolesAll = fetchAuthAccesoEdificioPOSTData?.data?.roles.map((item: any) => {
                    return {
                        id: item.role_llibre_id,
                        name: item?.role_idioma?.nom
                    }
                })

                if (String(data.user?.user_id) === String(edificio.user_id)) {

                    rolesAll = agregarRol(rolesAll, {
                        id: 10,
                        name: Lang('PROPIETARIO_EDIFICIO_VIRTUAL')
                    })
                }

                handleShow_ActualizarUsuario({
                    tokenParam: token,
                    edificio: fetchAuthAccesoEdificioPOSTData?.data?.edificio,
                    roles: rolesAll,
                })
            }
        }

    }, [fetchAuthAccesoEdificioPOSTData]);

    // Modal recordar contraseña

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // ----------------- Enviar por defecto el acceso

    useEffect(() => {

        if (params.nif && params.code) {

            handleSubmitLogin()
        }

    }, []);

    const [dataModal_InfoError, handleShowModal_InfoError, handleCloseModal_InfoError] = useModalData()


    return (
        <>

            <Typography variant='body1' className='mt-3 mb-2 z-index-10 position-relative fw-normal'>
                {Lang('P_PARA_INVITADO_EDIFICIO')}
            </Typography>

            <Box sx={{ width: '100%' }}>

                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <InputText
                        label={Lang('NIF')}
                        name='nif'
                        value={formAccesoEdificio.nif}
                        onChange={(e: any) => handleInputAccesoEdificio({
                            target: {
                                name: 'nif',
                                value: (e.target.value).toUpperCase()
                            }
                        })}
                        localValidation={validAccesoEdificio.nif}
                        className='mt-2 mb-3 w-100'
                        fetchData={fetchAuthAccesoEdificioPOSTData}
                    />

                    <InputText
                        label={Lang('CODIGO_ACCESO')}
                        name='code'
                        value={formAccesoEdificio.code}
                        onChange={handleInputAccesoEdificio}
                        localValidation={validAccesoEdificio.code}
                        className='mt-2 mb-3 w-100'
                        fetchData={fetchAuthAccesoEdificioPOSTData}
                    />

                    <Grid item xs={12} display='flex'>
                        <InputChecks
                            name='rememmberNIF'
                            values={formAccesoEdificio.rememmberNIF}
                            valuesDefault={[
                                {
                                    id: 'rememmberNIF',
                                    label: <small>{Lang('RECORDAR_NIF')}</small>
                                }
                            ]}
                            onChange={handleInputAccesoEdificio}
                            size='small'
                            inlineClassName='justify-content-end flex-row'
                            tabIndex={-1}
                        />
                    </Grid>

                </Box>

                <div className="col-12">
                    <ButtonAdvanced
                        variant="contained" type="submit" className="mt-2 w-100"
                        handleSubmit={handleSubmitLogin} loadings={[fetchAuthAccesoEdificioPOSTData.loading]}
                        value={Lang('INICIAR_SESION')}
                    />
                </div>

                <div className="col-12">
                    <ButtonAdvanced
                        variant="outline-primary" type="submit" className="me-2 w-100 mt-2"
                        handleSubmit={handleShowUsuario}
                        value={Lang('OLVIDASTE_TU_CODIGO_DE_ACCESO')}
                    />
                </div>
            </Box>

            <ModalRecuperarCodigo
                data={showUsuario}
                handleClose={handleCloseUsuario}
            />

            <ModalStatusServer fetchData={fetchAuthAccesoEdificioPOSTData} onlyError />

            <ModalAceptarEdificio
                data={dataModal_ActualizarUsuario}
                handleClose={handleClose_ActualizarUsuario}
                redirectKO='/edificios'
            />

            <ModalInfo
                data={dataModal_InfoError}
                handleClose={handleCloseModal_InfoError}
                message={Lang('PARRAFO_HA_RECHAZADO_ACCESO_EDIFICIO_CONTACTE')}
            />
        </>
    );
};
