import { Typography } from "@mui/material";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { useLang } from "../../../language";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";

export const ModalCondicionesEdificio = ({ data, handleClose, handleInputRegistro }: any) => {

    const Lang = useLang()

    const handleClickAceptar = () => {
        handleInputRegistro({
            target: {
                name: 'politica_privacitat_acceptada_en',
                value: ['politica_privacitat_acceptada_en']
            }
        })
        handleClose()
    }

    const handleClickRechazar = () => {
        handleInputRegistro({
            target: {
                name: 'politica_privacitat_acceptada_en',
                value: []
            }
        })
        handleClose()

    }

    return (
        <>

            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        Aviso legal y política de privacidad del Edificio
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    
                    <div className="legaltext my-0 mx-0 py-0 px-0">
                        
                    </div>

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <div className="d-flex justify-content-between">
                        <ButtonAdvanced
                            variant="outlined" type="submit" className=""
                            handleSubmit={handleClickRechazar}
                            // loadings={[fetchAuthPOSTData.loading]}
                            value={Lang('RECHAZAR')}
                        />
                        <ButtonAdvanced
                            variant="contained" type="submit" className=""
                            handleSubmit={handleClickAceptar}
                            // loadings={[fetchAuthPOSTData.loading]}
                            value={Lang('ACEPTAR')}
                        />
                    </div>
                </ModalAdvanced.Footer>


            </ModalAdvanced>
        </>
    );
};
