import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { PlanificacionFinanciera } from "./PlanificacionFinanciera";
import { ResumenFinanciero } from "../ResumenFinanciero/ResumenFinanciero";
import { NavBottom } from "../../../../layout/NavBottom";
import { useLang } from "../../../../../../language";

export const IndexPlanificacionFinanciera = ({ idCurrentProject, readOnly }: any) => {

    const Lang = useLang()

    let navLinks = [
        {
            link: 'informacion-previa',
            title: Lang('INGRESOS'),
        },
        {
            link: 'resumen-financiero',
            title: Lang('RESUMEN_FINANCIERO_GASTOS_FASES_PLANIFICACION_FINANCIERA'),
        },
    ]

    return (
        <div style={{ 'marginLeft': '0', 'marginRight': '0', 'marginBottom': '0' }}>

            <div style={{ 'borderBottom': '1px solid #ddd' }} className="px-3 px-lg-4">

                <NavBottom
                    navLinks={navLinks}
                    className='pb-0'
                />
            </div>
            <main className='pb-2 pt-3'>

                <Routes>
                    <Route
                        path={`/${navLinks[0].link}`}
                        element={
                            <PlanificacionFinanciera
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path={`/${navLinks[1].link}`}
                        element={
                            <ResumenFinanciero 
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="/*"
                        element={<Navigate to={`${navLinks[0].link}`} replace />}
                    />


                </Routes>

            </main>
        </div>
    )
};
