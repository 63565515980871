import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Input, InputLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { cloneDeep, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import './Input.css'

export const InputChecks = ({ label, readOnly, name, values, valuesDefault, onChange, localValidation = [],
    inline = false, inlineClassName = 'justify-content-center', size, tabIndex, className = '',
    defaultChecked, disabled, onChangeUnControlled, ClassNameFormControlLabel = '' }: any) => {


    const [validations, setvalidations] = useState<any>({});

    useEffect(() => {

        setvalidations(validated(localValidation))

    }, [...(localValidation?.map((item: any) => item.isOk))]);

    const handleChangeChecks = (e: any) => {
        
        if (onChangeUnControlled) {

            onChangeUnControlled(e.target.checked)

        } else {

            let listChecksTemp = cloneDeep(values)

            if (e.target.checked) {
    
                // Si no existe
                if (isEmpty(listChecksTemp.find((item: any) => item === e.target.name))) {
    
                    listChecksTemp.push(e.target.name)
                }
    
            } else {
    
                listChecksTemp = listChecksTemp.filter((item: any) => item !== e.target.name)
            }
    
            const target = {
                name,
                value: listChecksTemp,
            }
    
            onChange({ target })
        }

    }

    let objCheckBox: any = {}

    if (size) {
        objCheckBox.size = size
    }

    if (tabIndex) {
        objCheckBox.tabIndex = tabIndex
    }

    if (disabled) {
        objCheckBox.disabled = true
    }

    return (
        <FormControl fullWidth variant="standard" error={validations.error} className={className}>
            {
                readOnly ?
                    <>
                        <Typography variant='body2' className='mb-1' component="span">
                            {label}
                        </Typography>
                        <Typography variant='body1' className="my-0 pb-1" component="span">

                        </Typography>
                    </> :
                    <>
                        <FormLabel>{label}</FormLabel>
                        <FormGroup className={`${inline ? 'flex-row' : ''} ${inlineClassName}`}>
                            {
                                valuesDefault?.map((item: any) => {
                                    return (
                                        <FormControlLabel
                                            className={`${ClassNameFormControlLabel}`}
                                            control={
                                                defaultChecked === undefined ?
                                                <Checkbox
                                                    checked={!isEmpty(values?.find((item2: any) => item2 === item.id))}
                                                    name={item.id}
                                                    onChange={handleChangeChecks}
                                                    {...objCheckBox}
                                                />
                                                :
                                                <Checkbox
                                                    name={item.id}
                                                    onChange={handleChangeChecks}
                                                    defaultChecked={defaultChecked}
                                                    {...objCheckBox}
                                                />
                                            }
                                            label={item.label}
                                            key={item.id}
                                        />
                                    )
                                })
                            }
                        </FormGroup>

                        <FormHelperText>
                            {validations?.helperText || null}
                        </FormHelperText>
                    </>
            }
        </FormControl>
    )
}

const validated = (localValidation: any) => {

    let localValidationPreFilter = localValidation?.filter((item: any) => item.isOk === false) || []

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{item.msgError}</span>
            }),
            error: true
        }
    }

    return res
}
