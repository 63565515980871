import { Box, Grid } from '@mui/material'
import { InputText } from '../../../../../../../../components/Inputs'
import { useLang } from '../../../../../../../../language'
import { InputSelect } from '../../../../../../../../components/Inputs/InputSelect'
import { InputChecks } from '../../../../../../../../components/Inputs/InputChecks'

export const FormSimple = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParamsMisSistemasGETData,
    tiposIntervencionStaticServices }: any) => {

    const Lang = useLang()

    return (
        <>
            <Box component="form" noValidate autoComplete="off">
                <Grid container columnSpacing={4}>

                    <Grid item xs={12} md={6} lg={3}>
                        <InputText
                            label={`${Lang('DESCRIPCION')}:`}
                            name='magic'
                            value={formValuesSearch.magic}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <InputSelect
                            label={Lang('SISTEMA') + ':'}
                            name='elemento_constructivo_id'
                            value={formValuesSearch.elemento_constructivo_id}
                            values={fetchParamsMisSistemasGETData?.data || []}
                            onChange={handleInputChangeSearch}
                            // localValidation={validLogin.numedificio}
                            className='mt-2 mb-3 '
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {/* <InputSelect
                            label={Lang('EXCLUIR_DEFICIENCIAS_IMPORTADAS') + ':'}
                            name='excluir_incluido'
                            value={formValuesSearch.excluir_incluido}
                            values={[
                                { id: 'true', name: 'Si' },
                                { id: 'false', name: 'No' },
                            ]}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 '
                        /> */}

                        <InputChecks
                            label={Lang('OPERACIONES')}
                            name='excluir_importadas'
                            valuesDefault={[
                                { id: 'excluir_importadas', label: Lang('EXCLUIR_IMPORTADOS') }
                            ]}
                            values={formValuesSearch.excluir_importadas}
                            onChange={handleInputChangeSearch}
                        />


                    </Grid>
                    {/* <Grid item xs={12} md={4} lg={2}>
                        <InputText
                            label={Lang('FECHA_INICIO_DESDE') + ':'}
                            name='data_inici_desde'
                            type='date'
                            value={formValuesSearch.data_inici_desde}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={2}>
                        <InputText
                            label={Lang('FECHA_INICIO_HASTA') + ':'}
                            name='data_inici_hasta'
                            type='date'
                            value={formValuesSearch.data_inici_hasta}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100 labelFocusAlways'
                        />
                    </Grid> */}

                </Grid>

            </Box>
        </>
    )
}
