import { Button, Typography } from "@mui/material";
import React from "react";
import { useLang } from "../../language";
import { AccordionAdvanced } from "../../components/AccordionAdvanced/AccordionAdvanced";

import { CondGenSeccion1 } from "./generales/CondGenSeccion1";
import { CondGenSeccion2 } from './generales/CondGenSeccion2';
import { CondGenSeccion3 } from './generales/CondGenSeccion3';
import { CondGenSeccion4 } from './generales/CondGenSeccion4';
import { CondGenSeccion5 } from './generales/CondGenSeccion5';
import { CondGenSeccion6 } from './generales/CondGenSeccion6';
import { CondGenSeccion7 } from './generales/CondGenSeccion7';
import { CondGenSeccion8 } from './generales/CondGenSeccion8';
import { CondGenSeccion9 } from './generales/CondGenSeccion9';
import { CondGenSeccion10 } from './generales/CondGenSeccion10';
import { CondGenSeccion11 } from './generales/CondGenSeccion11';
import { CondGenSeccion12 } from './generales/CondGenSeccion12';
import { CondGenSeccion13 } from './generales/CondGenSeccion13';
import { CondParSeccion1 } from './particulares/CondParSeccion1';
import { CondParSeccion2 } from './particulares/CondParSeccion2';
import { CondParSeccion3 } from './particulares/CondParSeccion3';
import { CondParSeccion4 } from './particulares/CondParSeccion4';
import { CondParSeccion5 } from './particulares/CondParSeccion5';
import { CondParSeccion6 } from './particulares/CondParSeccion6';
import { CondParSeccion7 } from './particulares/CondParSeccion7';
import { CondParSeccion8 } from './particulares/CondParSeccion8';
import { CondParSeccion9 } from './particulares/CondParSeccion9';
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { CondParSeccion61 } from "./particulares/CondParSeccion61";
import { openLinkByClick } from "../../helpers/helpers";

export const FormCondicionesContratacion = ({ index1, index2 }: any) => {

    const Lang = useLang()

    const { language } = useSelector((state: RootState) => state.language)

    const listGenerales = [
        {
            title: `1. ${Lang('AMBITO_APLICACION_OBJETO')}`,
            content: <CondGenSeccion1 lang={language} />
        },
        {
            title: `2. ${Lang('IDIOMA')}`,
            content: <CondGenSeccion2 lang={language} />
        },
        {
            title: `3. ${Lang('PROCESO_CONTRATACION')}`,
            content: <CondGenSeccion3 lang={language} />
        },
        {
            title: `4. ${Lang('INFORMACION_PRODUCTOS_SERVICIOS_DIGITALES')}`,
            content: <CondGenSeccion4 lang={language} />
        },
        {
            title: `5. ${Lang('CONDICIONES_ECONOMICAS')}`,
            content: <CondGenSeccion5 lang={language} />
        },
        {
            title: `6. ${Lang('GARANTIA_LEGAL')}`,
            content: <CondGenSeccion6 lang={language} />
        },
        {
            title: `7. ${Lang('DESESTIMIENTO_DEVOLUCIONES_INCIDENCIAS')}`,
            content: <CondGenSeccion7 lang={language} />
        },
        {
            title: `8. ${Lang('RESPONSABILIDADES')}`,
            content: <CondGenSeccion8 lang={language} />
        },
        {
            title: `9. ${Lang('VIGENCIA_CONTRATO')}`,
            content: <CondGenSeccion9 lang={language} />
        },
        {
            title: `10. ${Lang('PROPIEDAD_INTELECTUAL_INDUSTRIAL')}`,
            content: <CondGenSeccion10 lang={language} />
        },
        {
            title: `11. ${Lang('ATENCION_CLIENTE_RECLAMACIONES')}`,
            content: <CondGenSeccion11 lang={language} />
        },
        {
            title: `12. ${Lang('HOJAS_RECLAMACION')}`,
            content: <CondGenSeccion12 lang={language} />
        },
        {
            title: `13. ${Lang('LEGISLACION_APLICABLE_JURISDICCION_COMPETENTE')}`,
            content: <CondGenSeccion13 lang={language} />
        },
    ]


    const listParticulares = [
        {
            title: `1. ${Lang('OBJETO_CONTRATO')}`,
            content: <CondParSeccion1 lang={language} />
        },
        {
            title: `2. ${Lang('CARACTERISTICAS_HERRAMIENTA_FUNCIONAMIENTO')}`,
            content: <CondParSeccion2 lang={language} />
        },
        {
            title: `3. ${Lang('PRECIO')}`,
            content: <CondParSeccion3 lang={language} />
        },
        {
            title: `4. ${Lang('PROCESO_COMPRA')}`,
            content: <CondParSeccion4 lang={language} />
        },
        {
            title: `5. ${Lang('FORMA_PAGO')}`,
            content: <CondParSeccion5 lang={language} />
        },
        {
            title: `6. ${Lang('DURACION_CONTRATO')}`,
            content: <CondParSeccion61 lang={language} />
        },
        {
            title: `7. ${Lang('ATENCION_POSTVENTA')}`,
            content: <CondParSeccion6 lang={language} />
        },
        {
            title: `8. ${Lang('DESISTIMIENTO')}`,
            content: <CondParSeccion7 lang={language} />
        },
        {
            title: `9. ${Lang('RESPONSABILIDADES')}`,
            content: <CondParSeccion8 lang={language} />
        },
        {
            title: `10. ${Lang('PROTECCION_DATOS')}`,
            content: <CondParSeccion9 lang={language} />
        },
    ]

    const handleDownloadFile = () => {

        let url = ''

        if (language === 'ES') {

            url = `${process.env.PUBLIC_URL}/files/ES/Condiciones generales y particulares compra LED 032024.docx`

        } else if (language === 'CA') {

            url = `${process.env.PUBLIC_URL}/files/CA/Condicions Generals i Particulars LED032024 Catala.docx`
        }

        openLinkByClick(url)
    }

    return (
        <>
            <Typography variant='h5' className='fw-bolder me-2 text-center mb-5 pb-3' color=''>
                {Lang('CONDICIONES_CONTRATACION')}
            </Typography>

            <div className="mb-4 pb-2 ">

                <h3 className="h3">
                    {Lang('CONDICIONES_GENERALES_PRODUCTOS_LED')}
                </h3>

                <AccordionAdvanced
                    list={listGenerales}
                    indexAccordion={index1}
                    id='condiciones-generales'
                />
            </div>

            <div className="mb-3 ">

                <h3 className="h3">
                    {Lang('CONDICIONES_PARTICULARES_PRODUCTOS_LED')}
                </h3>

                <AccordionAdvanced
                    list={listParticulares}
                    indexAccordion={index2}
                    id='condiciones-particulares'
                />
            </div>

            <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                <Button variant="contained" color="primary" onClick={handleDownloadFile}>
                    <FontAwesomeIcon icon={faFile} className="me-2" /> {Lang('DESCARGAR')}
                </Button>
            </div>
        </>
    )
};
