import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useForm } from "../../../../../../../../hooks/useForm"
import { useValidator } from "../../../../../../../../hooks/useValidator"
import { isStatusSuccess } from "../../../../../../../../helpers/helpers"
import { Box, FormHelperText, Grid, Typography } from "@mui/material"
import { InputFile } from "../../../../../../../../components/Inputs/InputFile"
import ModalAdvanced from "../../../../../../../../components/ModalAdvanced/ModalAdvanced"
import { ButtonAdvanced } from "../../../../../../../../components/ButtonAdvanced/ButtonAdvanced"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { ModalStatusServer } from "../../../../../../../../components/Modals/ModalStatusServer"
import { useLang } from "../../../../../../../../language"
import { FicheroITEServices } from "../controllers/ficheroite.services"
import { InputTextarea } from "../../../../../../../../components/Inputs/InputTextarea"
import { validExiste } from "../../../../../../../../helpers/validations"

export const ModalImportarDeficiencia = ({ data, handleClose, fetchParamsAgentesGETData, idedificio, fetchSuccess,
    formValuesSearch, fetchParamsDocumentosGETData, elemento_contructivo_id }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()


    const [fetchDeficienciaITEPOST, fetchDeficienciaITEPOSTData] = FicheroITEServices.POST_AGREGAR_DEFICIENCIA()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        actuacio: '',
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        actuacio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // tipus_document: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchDeficienciaITEPOST({
                body: {
                    ...formDocumento,
                    edifici_id: idedificio,
                    elemento_contructivo_id,
                    deficiencia_id: formDocumento.id,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchDeficienciaITEPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchDeficienciaITEPOSTData]);

    useEffect(() => {

        if (data.value) {

            handleFormDocumento({
                ...data
            })

        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='lg'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('IMPORTAR_ITE')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={3} rowSpacing={3}>

                            <Grid item xs={12}>

                                <InputTextarea
                                    label={Lang('DESCRIPCION')}
                                    name='descripcio'
                                    value={formDocumento.descripcio}
                                    onChange={handleInputDocumento}
                                    // fetchData={data?.action === 'CREATE' ? fetchDeficienciaITEPOSTData : fetchMantPrevPATCHData}
                                    // localValidation={validDocumento.descripcio}
                                    readOnly
                                />

                            </Grid>

                            <Grid item xs={12}>

                                <InputTextarea
                                    label={Lang('ACTUACION') + ': *'}
                                    name='actuacio'
                                    value={formDocumento.actuacio}
                                    onChange={handleInputDocumento}
                                    // fetchData={data?.action === 'CREATE' ? fetchDeficienciaITEPOSTData : fetchMantPrevPATCHData}
                                    localValidation={validDocumento.actuacio}
                                />

                            </Grid>

                        </Grid>
                    </Box>
                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="contained" type="button" className="mt-3 ms-auto"
                        handleSubmit={handleSubmitDocumentoPOST}
                        loadings={[fetchDeficienciaITEPOSTData.loading]}
                        value={Lang('IMPORTAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                    />
                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchDeficienciaITEPOSTData} />
        </>
    )
}