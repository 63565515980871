import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useModalData } from "../../../../../../../hooks/useModalData";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useLang } from "../../../../../../../language";
import { ModalMantPreventivo } from "./ModalMantPreventivo";

export const TableMantPreventivo = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
     handleInputChangeSearch, idedificio, fetchSuccess, fetchParamsAgentesGETData, 
     fetchParamsDocumentosGETData, id }: any) => {

    const Lang = useLang()
    
    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const allActions = [
        {
            label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_OPERACION')}</span>,
            variant: 'contained',
            action: () => handleShowUsuario({
                action: 'CREATE',
            }),
        }
    ]

    const allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('EDITAR')
            }
        }
    ]

    const columnsTable = {
        descripcio: {
            title: Lang('DESCRIPCION'),
        },
        periodicitat_text: {
            title: Lang('PERIODICIDAD'),
        },
        document_referencia: {
            title: Lang('DOCUMENTO_REFERENCIA'),
        },
        responsable: {
            title: Lang('RESPONSABLE'),
        }
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('OPERACIONES_MANTENIMIENTO')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalMantPreventivo 
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchSuccess={fetchSuccess}
                fetchParamsDocumentosGETData={fetchParamsDocumentosGETData}
                id={id}
            />
        </>
    )
}
