import React from "react";
import logo from '../../assets/images/logo.png'

import { useNavigate } from "react-router";
import { DropdownLanguage } from "../../components/Layouts/DropdownLanguage";
import '../PagesDefault.scss'
import { BreadcrumbAdvanced } from "../../components/BreadcrumbAdvanced/BreadcrumbAdvanced";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { useLang } from "../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { AccordionAdvanced } from "../../components/AccordionAdvanced/AccordionAdvanced";
import { InputText } from "../../components/Inputs";
import { useValidator } from "../../hooks/useValidator";
import { useForm } from "../../hooks/useForm";
import { validCorrectEmail, validExiste } from "../../helpers/validations";
import { ButtonAdvanced } from "../../components/ButtonAdvanced/ButtonAdvanced";
import { InputTextarea } from "../../components/Inputs/InputTextarea";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Contacto = () => {

    const navigate = useNavigate()

    const Lang = useLang()

    const BreadcrumbAdvancedItems = [
        {
            title: Lang('INICIO'),
            link: '/',
        },
        {
            title: Lang('CONTACTANOS'),
            link: '/',
        }
    ]

    const [formContactar, handleInputContactar, handleFormContactar, resetFormContactar] = useForm({
    
    })

    const [validNuevoEdificio, handleValidNuevoEdificio, resetvalidNuevoEdificio] = useValidator({
        nom: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        assumpte: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        missatge: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        email: [
            { functionValid: validCorrectEmail, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const { language } = useSelector((state: RootState) => state.language)

    return (
        <section className="PageDefault PageAvisoLegal">
            <nav className="PageDefault__NavTop d-flex align-items-center">

                <div className='px-3 d-flex justify-content-between w-100 align-items-center'>

                    <img src={logo} alt="logo" className='cursor-pointer d-block d-lg-none' onClick={() => navigate('/')} />
                    <img src={`${process.env.PUBLIC_URL}/images/Logo_LED_${language}.png`} alt="logo" className='cursor-pointer d-none d-lg-block' onClick={() => navigate('/')} />

                    <div className='d-flex align-items-center'>

                        <DropdownLanguage />

                    </div>

                </div>

            </nav>
            <section className="PageDefault__contentbottom">
                <main className="PageDefault__contentmain">

                    <Container maxWidth="md" className='my-auto'>

                        <BreadcrumbAdvanced
                            items={BreadcrumbAdvancedItems}
                            className='mb-3'
                        />

                        <Typography variant='h5' className='fw-bolder me-2 text-center mb-3' color=''>
                            {Lang('CONTACTANOS')}
                        </Typography>

                        <div className="mb-5">

                            <Card>
                                <CardContent>

                                    <Grid container justifyContent='center' spacing={7} rowSpacing={5}>

                                        <Grid item xs={12} className=''>

                                            <Box
                                                component="form"
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <Grid container spacing={3} rowSpacing={3}>

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <InputText
                                                            label={Lang('NOMBRE') + ': *'}
                                                            name='nom'
                                                            value={formContactar.nom}
                                                            onChange={handleInputContactar}
                                                            localValidation={validNuevoEdificio.nom}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <InputText
                                                            label={Lang('EMAIL') + ': *'}
                                                            name='email'
                                                            value={formContactar.email}
                                                            onChange={handleInputContactar}
                                                            localValidation={validNuevoEdificio.email}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <InputText
                                                            label={Lang('ASUNTO') + ': *'}
                                                            name='assumpte'
                                                            value={formContactar.assumpte}
                                                            onChange={handleInputContactar}
                                                            localValidation={validNuevoEdificio.assumpte}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputTextarea
                                                            label={Lang('MENSAJE') + ':'}
                                                            name='missatge'
                                                            value={formContactar.missatge}
                                                            onChange={handleInputContactar}
                                                            localValidation={validNuevoEdificio.missatge}
                                                            className='w-100'
                                                        />
                                                    </Grid>


                                                    <Grid item xs={12} justifyContent='end' display='flex'>

                                                        <ButtonAdvanced
                                                            variant="contained" className="mt-3 ms-auto"
                                                            // handleSubmit={handlefetchNuevoEdificioPOST}
                                                            // loadings={[fetchNuevoEdificioPOSTData.loading]}
                                                            value={Lang('ENVIAR')} icon={<FontAwesomeIcon icon={faPaperPlane} className="me-2" />}
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Box>
                                        </Grid>

                                    </Grid>

                                </CardContent>
                            </Card>

                        </div>

                    </Container>

                </main>

            </section>
        </section>
    );
};

export default Contacto;
