import { parseNumber, parseString, renderReceiveAdapter, renderSchema } from "../controllers"

const MaestrosAdapters = {
    
    GET_PARAMETROS_PUBLICOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const dataTemp = item

                let resEnd: any = {}

                for (const key in dataTemp) {
                    resEnd[key] = Object.keys(dataTemp[key]).map((key2: any) => {
                        return {
                            id: key2,
                            name: dataTemp[key][key2],
                        }
                    })
                }

                return renderSchema( resEnd )
            },

            adapter: function (data: any, type: string) {
                
                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
    GET_COLEGIOS_PROFESIONALES_PUBLICOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}
                
                const data = {
                    id: parseString(item.slug),
                    name: parseString(item.nombre),
                    comunitat: parseString(item.comunitat),
                    tipo: parseString(item.tipo),
                    slug: parseString(item.slug),
                    codigo: parseString(item.codigo),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
    GET_ROLES_EDIFICIO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}
                
                const data = {
                    id: parseNumber(item.id),
                    name: parseString(item.name),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_PARAMETROS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const dataTemp = item

                let resEnd: any = {}

                for (const key in dataTemp) {
                    resEnd[key] = Object.keys(dataTemp[key]).map((key2: any) => {
                        return {
                            id: key2,
                            name: dataTemp[key][key2],
                        }
                    })
                }

                return renderSchema( resEnd )
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    MaestrosAdapters,
}