import { useLang } from "../../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faSliders } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { ModalAnotacion } from "./ModalAnotacion";

export const TableAnotaciones = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, readOnly, idEdificio, fetchPropiedadAgentesGET, fetchParamsAgentesGET,
    fetchParamsAgentesGETData, fetchSuccess, showFilter, setshowFilter, idCurrentProject, idActividad }: any) => {

    const Lang = useLang()    

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()


    let allActions: any = [
        {
            label: <span><FontAwesomeIcon icon={faSliders} /></span>,
            variant: 'outlined',
            color: 'secondary',
            action: () => setshowFilter(!showFilter),
        }
    ]

    if (!readOnly) {

        allActions.unshift(
            {
                label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_INCIDENCIA')}</span>,
                variant: 'contained',
                action: () => handleShowUsuario({
                    action: 'CREATE',
                    idActividad
                }),
            }
        )
    }

    let allActionsPerLine: any = []

    allActionsPerLine.push(
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                    idActividad
                })
            },
            tooltip: {
                text: Lang('EDITAR')
            },
        }
    )
    
    const columnsTable = {

        titol: {
            title: Lang('TITULO'),
        },
        // descripcio: {
        //     title: Lang('DESCRIPCION'),
        // },
        label__data_seguiment: {
            title: Lang('FECHA_SEGUIMIENTO'),
            className: 'text-center',
            classNameTitle: 'text-center',
        },
        creat_per_nom: {
            title: Lang('CREADO_POR'),
        },
        label__fecha_finalizacion: {
            title: Lang('FECHA_FINALIZACION'),
            className: 'text-center',
            classNameTitle: 'text-center',
        }
    }

    // Acciones

    const classNameFunctionRow = (item: any) => {

        return item.fecha_finalizacion ? 'tr-red' : ''
    }

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-0'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('INCIDENCIAS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                    classNameFunctionRow={classNameFunctionRow}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalAnotacion
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idEdificio={idEdificio}
                formValuesSearch={formValuesSearch}
                fetchPropiedadAgentesGET={fetchPropiedadAgentesGET}
                idCurrentProject={idCurrentProject}
                idActividad={idActividad}
                readOnly={readOnly}
                fetchSuccess={fetchSuccess}
                
            />
        </>
    )
}
