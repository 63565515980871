import { Button, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react";

interface IButtonAdvanced {
    variant?: string,
    type?: string,
    className?: string,
    handleSubmit?: any,
    loadings?: Array<any>,
    fetchDataLoading?: Array<any>,
    value?: any,
    size?: string,
    icon?: any,
    iconEnd?: any,
    disabled?: boolean,
    color?: string
}

export const ButtonAdvanced = ({ variant, type, className = '', handleSubmit, fetchDataLoading = [], loadings = [],
    value, size, icon = '', disabled = false, color, iconEnd }: IButtonAdvanced) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const isLoading = loadings?.filter((item) => item === true).length > 0

        setIsLoading(isLoading)

    }, [loadings]);

    const propsCss: any = {}

    if (size) {
        propsCss.size = size
    }
    if (color) {
        propsCss.color = color
    }

    return (
        <>
            <Button
                variant={variant} type={type} {...propsCss}
                className={className} onClick={handleSubmit} disabled={isLoading || disabled}>
                {
                    isLoading ?
                        <CircularProgress size={18} className="me-2" style={{ color: 'inherit' }} />
                        : ''
                } {icon} {value} {iconEnd}
            </Button>
        </>
    )
}
