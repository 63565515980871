import { useEffect, useState } from "react";
import { useLang } from "../../../../../../../../language";
import { Grid } from "@mui/material";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../../hooks/useForm";
import queryString from 'query-string';
import { useLocation } from "react-router";
import { TableGremiosActividad } from "./TableGremiosActividad";
import { GremiosActividadServices } from "./controllers/gremios-actividades.services";

export const GremiosActividad = ({ readOnly, idEdificio, fetchEdificioGETData, idCurrentProject, idActividad }: any) => {

    const Lang = useLang()

    const [fetchPropiedadAgentesGET, fetchPropiedadAgentesGETData] = GremiosActividadServices.GET()

    // --------------------- Modal Nuevo edificio ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            edifici_id: idEdificio,
            order: '',
            tipo_agente: '',
            // projecteid: idCurrentProject,
            projecte_intervencio_id: idActividad,
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        tipo_agente: params.tipo_agente || allValuesSearch.tipo_agente,
        projecte_intervencio_id: params.projecte_intervencio_id || allValuesSearch.projecte_intervencio_id,
        // projecteid: allValuesSearch.projecteid,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecte_intervencio_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchPropiedadAgentesGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecte_intervencio_id')

        fetchPropiedadAgentesGET({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const [showFilter, setshowFilter] = useState(false);

    return (
        <>
            <div className="">
                {/* <Container maxWidth="lg" className='mt-3 mb-auto py-2'> */}

                    <Grid container>

                        <Grid item xs={12} className='py-0 py-lg-0'>

                            <section>
                                <TableGremiosActividad
                                    data={fetchPropiedadAgentesGETData.data?.items}
                                    loading={fetchPropiedadAgentesGETData.loading}
                                    total={fetchPropiedadAgentesGETData.data?.total}
                                    perPage={fetchPropiedadAgentesGETData.data?.per_page}

                                    formValuesSearch={formValuesSearch}
                                    handleFormChange={handleFormChange}
                                    handleInputChangeSearch={handleInputChangeSearch}

                                    idEdificio={idEdificio}
                                    fetchPropiedadAgentesGET={fetchPropiedadAgentesGET}
                                    // fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                                    fetchEdificioGETData={fetchEdificioGETData}
                                    fetchSuccess={(e: any) => handleSubmitSearch(e, formValuesSearch)}

                                    setshowFilter={setshowFilter}
                                    showFilter={showFilter}
                                    idCurrentProject={idCurrentProject}
                                    idActividad={idActividad}
                                    readOnly={readOnly}
                                />
                            </section>

                        </Grid>

                    </Grid>

                {/* </Container> */}
            </div>

        </>
    );
};
