import { useLang } from "../../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { ModalDocumento } from "./ModalDocumento";
import { openLinkByClick } from "../../../../../../../../helpers/helpers";

export const TableDocumentacion = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, readOnly, idEdificio, fetchDocumentacionGET, idAnotacion,
    fetchParamsAgentesGETData, idActividad, idCurrentProject }: any) => {

    const Lang = useLang()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    let allActions: any = []

    if (!readOnly) {

        allActions.push(
            {
                label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVO_DOCUMENTO')}</span>,
                variant: 'contained',
                action: () => handleShowUsuario({
                    action: 'CREATE',
                    idAnotacion,
                    idActividad,
                }),
            }
        )
    }

    let allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <span><FontAwesomeIcon icon={faDownload} className='me-1' /> {Lang('DESCARGAR')}</span>,
            action: (item: any) => openLinkByClick(item.url),
            className: 'd-block',
        },
    ]

    if (!readOnly) {

        allActionsPerLine.push(
            {
                variant: 'outlined',
                label: <FontAwesomeIcon icon={faEdit} className='' />,
                action: (item: any) => {
                    handleShowUsuario({
                        ...item,
                        action: 'EDIT',
                        idAnotacion,
                        idActividad,
                    })
                },
                tooltip: {
                    text: Lang('EDITAR')
                },
                className: 'd-block ms-3',
            }
        )
    }

    const columnsTable = {
        titol: {
            title: Lang('TITULO'),
        },
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-0'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('LISTA_DOCUMENTOS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalDocumento
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idEdificio={idEdificio}
                formValuesSearch={formValuesSearch}
                fetchDocumentacionGET={fetchDocumentacionGET}
                idCurrentProject={idCurrentProject}
            />
        </>
    )
}
