export const AvisoLegalSeccion1 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El prestador de servicios y titular de este sitio web es el <strong>COLEGIO DE ARQUITECTURA TÉCNICA DE BARCELONA (CATEB en adelante)</strong>, con NIF Q0875009C, con domicilio en C. Bon Pastor 5 – CP: 08021 Barcelona, encontrándose inscrito   en el registro de Colegios Profesionales de la Generalitat de Catalunya con número de inscripción ATiAP. Si quieres contactar con nosotros puedes hacerlo mediante el teléfono 93 240 20 60 o correo electrónico escribiendo a <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>
      <p>
        Este sitio web tiene por objeto promocionar y ofrecer servicios relacionados con el ámbito de actuación de CATEB a través de la puesta a disposición de aplicaciones informáticas, software y formación a través de nuestra aula virtual.
      </p>
      <p>
        El idioma de las presentes condiciones es el español, por lo que, en caso de discrepancia en la interpretación de las condiciones, el español será el idioma de prevalencia. Cualquier traducción que se realice sobre las mismas no afectará a la prevalencia indicada.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        El prestador de serveis i titular d'aquest lloc web és el <strong>COL·LEGI D'ARQUITECTURA TÈCNICA DE BARCELONA (CATEB en endavant)</strong>, amb NIF Q0875009C, amb domicili a C. Bon Pastor 5 – CP: 08021 Barcelona, trobant-se inscrit al registre de Col·legis Professionals de la Generalitat de Catalunya amb número d'inscripció ATiAP. Si vols contactar amb nosaltres pots fer-ho mitjançant el telèfon 93 240 20 60 o correu electrònic escrivint a <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>
      <p>
        Aquest lloc web té per objecte promocionar i oferir serveis relacionats amb l'àmbit d'actuació de CATEB a través de la posada a disposició d'aplicacions informàtiques, programari i formació a través de la nostra aula virtual.
      </p>
      <p>
        L'idioma de les presents condicions és el català, per la qual cosa, en cas de discrepància en la interpretació de les condicions, l' espanyol serà l' idioma de prevalença. Qualsevol traducció que es realitzi sobre aquestes no afectarà la prevalença indicada.
      </p>
    </div>
  )
}