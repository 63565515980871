import { Grid, Card, CardContent, Button } from "@mui/material";
import { useState, useEffect } from 'react'
import { TableFases } from "./TableFases";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { useLang } from "../../../../../../../language";
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../hooks/useForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { MejoraFasesServices } from "./controllers/fases.services";

export const MejoraFases = ({ idedificio, id, proyectoSelected, setproyectoSelected }: any) => {

    const Lang = useLang()

    const [fetchMejoraFases, fetchMejoraFasesData] = MejoraFasesServices.GET()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            order: '',
            edifici_id: idedificio,
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMejoraFases({
                id: proyectoSelected?.id,
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'edifici_id')

        fetchMejoraFases({
            id: proyectoSelected?.id,
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


    return (
        <Grid container spacing={2} columnSpacing={3}>

            <Grid item xs={12} className="mb-0 mt-3">
                <TableFases
                    data={fetchMejoraFasesData.data?.items}
                    // data={elementSelected?.operations}
                    // loading={false}
                    loading={fetchMejoraFasesData.loading}
                    total={fetchMejoraFasesData.data?.total}
                    perPage={fetchMejoraFasesData.data?.per_page}

                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    handleInputChangeSearch={handleInputChangeSearch}

                    idedificio={idedificio}
                    fetchSuccess={() => {
                        fetchMejoraFases({
                            id: proyectoSelected?.id,
                            params: {
                                ...getValuesParams(formValuesSearch)
                            }
                        })
                    }}
                    // id={id}
                    proyectoSelected={proyectoSelected}
                />

            </Grid>

        </Grid>
    );
};
