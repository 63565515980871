import { useEffect } from "react";
import TableAdvanced from "../Tables/TableAdvanced/TableAdvanced";
import { useForm } from "../../hooks/useForm";
import { clearEmptyKeys, getValuesParams } from "../../helpers/helpers";
import { useLang } from "../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import ModalAdvanced from "../ModalAdvanced/ModalAdvanced";
import { Box, Grid, Typography } from "@mui/material";
import { InputText } from "../Inputs";
import { ButtonAdvanced } from "../ButtonAdvanced/ButtonAdvanced";
import { EdificiosServices } from "../../controllers/edificios/edificios.services";

export const ModalListEdificios = ({ data, handleClose, setItemSelect, itemSelect, params }: any) => {

    const Lang = useLang()

    const [fetchEdificiosGET, fetchEdificiosGETData, resetfetchEdificiosGETData] = EdificiosServices.GET_MIS_EDIFICIOS_PROPIETARIO()

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            order: '',
            ...params
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchEdificiosGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        if (data.value) {

            const urlParams = getValuesParams(formValuesSearch)

            fetchEdificiosGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faArrowRight} className='' />,
            action: (item: any) => {
                setItemSelect({
                    id: item.id,
                    item: item,
                    name: `${item.nom} - (${item.nif})`,
                })
                handleClose()
            },
            tooltip: {
                text: Lang('SELECCIONAR')
            },
        }
    ]

    const columnsTable = {

        nom: {
            title: Lang('NOMBRE') ,
        },
        tipus_edifici: {
            title: Lang('TIPO_EDIFICIO'),
        },
    }

    useEffect(() => {

        if (data.value) {

            const urlParams = getValuesParams(formValuesSearch)

            fetchEdificiosGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [data.value])

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='lg'>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2" className="text-start">
                        {Lang('SELECCIONA_UNA_OPCION')}:
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-0 p-0'>
                    <Box component="form" noValidate autoComplete="off" className="mb-3">
                        <Grid container spacing={4}>

                            <Grid item xs>
                                <InputText
                                    label={Lang('NOMBRE')}
                                    name='magic'
                                    value={formValuesSearch.magic}
                                    onChange={handleInputChangeSearch}
                                />
                            </Grid>

                            <Grid item xs='auto' alignItems='end' className='d-flex'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="ms-auto mt-auto" color="secondary" size='small'
                                    handleSubmit={(e: React.SyntheticEvent) => handleSubmitSearch(e, formValuesSearch)}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className="me-2" />}
                                />
                            </Grid>

                        </Grid>

                    </Box>
                    <TableAdvanced
                        classNameTableAdvanced='minByDropdown'
                        handleFormChange={handleFormChange}
                        formValuesSearch={formValuesSearch}
                        handleInputChange={handleInputChangeSearch}
                    >
                        <TableAdvanced.Head
                            title={Lang('LISTA_EDIFICIOS')}
                        />

                        <TableAdvanced.Body
                            data={fetchEdificiosGETData?.data?.items}
                            isLoading={fetchEdificiosGETData?.loading}
                            columnsTable={columnsTable}
                            allActionsPerLine={allActionsPerLine}
                        />

                        <TableAdvanced.Footer
                            total={fetchEdificiosGETData?.data?.total}
                            perPage={fetchEdificiosGETData?.data?.per_page}
                        />
                    </TableAdvanced>
                </ModalAdvanced.Body>
            </ModalAdvanced>

        </>

    );
};
