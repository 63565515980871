import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { clearEmptyKeys, getValuesParams, isFetchDataInitLoader, isStatusSuccess, parseMoneda, setUrlByParams } from "../../../../../../helpers/helpers";
import { useForm } from "../../../../../../hooks/useForm";
import { useLang } from "../../../../../../language";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid } from "@mui/material";

import './TableElaborarPlan.scss'
import { InputText } from "../../../../../../components/Inputs";
import { MejoraFasesServices } from "../PlanActuacion/Fases/controllers/fases.services";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";
import { isEmpty } from "lodash";
import { PotencialMejoraServices } from "../controllers/potencial-mejora.services";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";

export const TableElaborarPlanResumen = ({ idedificio }: any) => {

    const Lang = useLang()

    const [dataResumen, setdataResumen] = useState<any>([]);

    const [fetchMejoraFases, fetchMejoraFasesData] = MejoraFasesServices.GET()

    const [fetchPotencialMejoraGET, fetchPotencialMejoraGETData] = PotencialMejoraServices.GET(idedificio)

    const [fetchPotencialMejoraPATCH, fetchPotencialMejoraPATCHData] = PotencialMejoraServices.PATCH(idedificio)

    const [fetchPotencialMejoraDurPATCH, fetchPotencialMejoraDurPATCHData] = PotencialMejoraServices.PATCH_DURACION_FASES()

    useEffect(() => {

        fetchPotencialMejoraGET()

    }, []);

    useEffect(() => {

        if (isStatusSuccess(fetchMejoraFasesData.status)) {
            
            setdataResumen(fetchMejoraFasesData?.data?.items)
        }

    }, [fetchMejoraFasesData]);

    const [formFase, handleInputFase, handleFormFase, resetFormFase] = useForm({
        fase: '',
    })

    useEffect(() => {

        if (isStatusSuccess(fetchPotencialMejoraGETData.status)) {

            handleFormFase({
                ...fetchPotencialMejoraGETData?.data
            })
        }

    }, [fetchPotencialMejoraGETData]);

    // Buscador de agentes

    const location = useLocation()

    const params: any = {};

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            order: '',
            // padre_id: id,
            edifici_id: idedificio,

        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        // padre_id: params.padre_id || allValuesSearch.padre_id,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'padre_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMejoraFases({
                // id: idedificio,
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'padre_id')

        fetchMejoraFases({
            // id: idedificio,
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const [valueDefault, setvalueDefault] = useState<any>('');

    const handleOnBlurAdvanced = (e: any) => {

        const nuevoValor = e.target.value

        if (valueDefault !== nuevoValor) {

            fetchPotencialMejoraPATCH({
                body: {
                    [e.target.name]: nuevoValor
                }
            })
        }

    }

    const handleOnBlurAdvancedDuracion = (e: any, fase: any) => {

        const nuevoValor = e.target.value

        if (valueDefault !== nuevoValor) {

            fetchPotencialMejoraDurPATCH({
                id: fase + '/edificio/' + idedificio + '/actualizar',
                body: {
                    duracion: nuevoValor
                }
            })
        }

    }

    const handleOnFocusValueDefault = (e: any) => {
        setvalueDefault(e.target.value)
    }

    return (
        <>
            <Typography variant="h6" component="h2" className="text-center mb-3">
                {Lang('RESUMEN_FASES')}
            </Typography>

            <Grid container spacing={3} rowSpacing={3} justifyContent='center'>

                <Grid item xs={12} md={8} lg={8}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{Lang('FASE')}</TableCell>
                                    <TableCell>{Lang('TOTAL_PORCENTAJE_REDUCCION_CONSUMO_EPNR')}</TableCell>
                                    <TableCell>{Lang('TOTAL_COSTE_MINIMO')}</TableCell>
                                    <TableCell>{Lang('TOTAL_COSTE_MAXIMO')}</TableCell>
                                    <TableCell>{Lang('DURACION_MESES')}</TableCell>
                                    {/* <TableCell>Inicio de obra</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    isFetchDataInitLoader(fetchMejoraFasesData) ?
                                        <TableAdvancedLoader />
                                        :
                                        <>
                                            {
                                                dataResumen?.map((item: any, index: number) => {
                                                    return (
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                                            <TableCell className="text-nowrap">{Lang('FASE')} {item.fase}</TableCell>
                                                            <TableCell className="text-nowrap"> {item.total_reduccion_consumo_epnr} %</TableCell>
                                                            <TableCell className="text-nowrap"> {parseMoneda(item.total_coste_minimo)}</TableCell>
                                                            <TableCell className="text-nowrap"> {parseMoneda(item.total_coste_maximo)}</TableCell>
                                                            <TableCell className="text-nowrap" style={{maxWidth: '100px'}}>
                                                                <InputText
                                                                    name='duracion'
                                                                    type='number'
                                                                    defaultValue={item.duracion}
                                                                    // onChange={handleInputFase}
                                                                    onBlur={(e: any) => handleOnBlurAdvancedDuracion(e, item.id)}
                                                                    onFocus={handleOnFocusValueDefault}
                                                                    className='labelFocusAlways'
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }

                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                <TableCell className="text-nowrap fw-bold">{Lang('TOTAL_FASES')}</TableCell>
                                                <TableCell>
                                                    <InputText
                                                        name='total_reduccion_consumo_epnr'
                                                        type='number'
                                                        value={formFase.total_reduccion_consumo_epnr}
                                                        onChange={handleInputFase}
                                                        onBlur={handleOnBlurAdvanced}
                                                        onFocus={handleOnFocusValueDefault}
                                                        className='labelFocusAlways'
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <InputText
                                                        name='total_coste_minimo'
                                                        type='number'
                                                        value={parseMoneda(formFase.total_coste_minimo)}
                                                        onChange={handleInputFase}
                                                        onBlur={handleOnBlurAdvanced}
                                                        onFocus={handleOnFocusValueDefault}
                                                        className='labelFocusAlways'
                                                        readOnly={true}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <InputText
                                                        name='total_coste_maximo'
                                                        type='number'
                                                        value={parseMoneda(formFase.total_coste_maximo)}
                                                        onChange={handleInputFase}
                                                        onBlur={handleOnBlurAdvanced}
                                                        onFocus={handleOnFocusValueDefault}
                                                        className='labelFocusAlways'
                                                        readOnly={true}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <InputText
                                                        name='duracion'
                                                        type='number'
                                                        value={formFase.duracion}
                                                        onChange={handleInputFase}
                                                        onBlur={handleOnBlurAdvanced}
                                                        onFocus={handleOnFocusValueDefault}
                                                        className='labelFocusAlways'
                                                    />
                                                </TableCell>
                                            </TableRow>

                                        </>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <ModalStatusServer fetchData={fetchPotencialMejoraPATCHData} onlyError />

            <ModalStatusServer fetchData={fetchPotencialMejoraDurPATCHData} onlyError />
        </>
    )
};

const TableAdvancedLoader = () => {

    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-2' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-3' height='1.1rem' />
                </TableCell>
            </TableRow>
        </>
    )
}
