import { useAxios } from "../../../../../../../hooks/useAxios"
import { MantenimientoPreventivoAdapters, MantenimientosPreventivosAdapters, MejorasNivelPrincipalAdapters } from "./mantpreventivo.adapters"

const MantenimientosPreventivosServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: MantenimientosPreventivosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_BY_EDIFICIO: function () {

        return useAxios({
            method: 'GET',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: MantenimientosPreventivosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },
}

const MantenimientoPreventivoServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/operaciones/edificio-operaciones`,
            type: 'json',
            adapter: {
                method: MantenimientoPreventivoAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: MantenimientoPreventivoAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: MantenimientoPreventivoAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: MantenimientoPreventivoAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

const MejorasNivelPrincipalServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/potencial_mejora/niveles_principales',
            type: 'json',
            adapter: {
                method: MejorasNivelPrincipalAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },
}

const MejorasResumenFasesServices = {
    
    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/{id}/edifici_plan_millora',
            type: 'json',
            adapter: {
                method: MantenimientoPreventivoAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}


export {
    MantenimientosPreventivosServices,
    MantenimientoPreventivoServices,
    MejorasNivelPrincipalServices,
    MejorasResumenFasesServices
}