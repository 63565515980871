import { useEffect, useState } from 'react'
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useLang } from "../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ModalOtrosElementos } from "./ModalOtrosElementos";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { ElementosConstructivosServices } from "../../../controllers/elemconst.services";

export const MenuOtrosElementos = ({ elemento_constructivo_id, idEdificio, disabledCreate, fetchDataInit, 
    handleSubmitCheckYMetricas, id }: any) => {

    const Lang = useLang()

    let allActions: any = []

    if (!disabledCreate) {
        allActions.push(
            {
                label: <span><FontAwesomeIcon icon={faPlus} /> {Lang('NUEVO_ELEMENTO')}</span>,
                action: (item: any) => {
                    handledataModal_OtroElemento({
                        action: 'CREATE',
                        elemento_constructivo_id: elemento_constructivo_id,
                    })
                },
                variant: 'contained',
                color: 'primary',
            }
        )
    }

    let allActionsPerLine: any = [
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faEdit} className='' />,
            action: (item: any) => {
                handledataModal_OtroElemento({
                    ...item,
                    action: 'EDIT',
                    elemento_constructivo_id: elemento_constructivo_id,
                })
            },
            tooltip: {
                text: Lang('EDITAR')
            },
        }
    ]

    const columnsTable = {

        nom_element: {
            title: Lang('NOMBRE'),
        },
    }

    const [dataModal_OtroElemento, handledataModal_OtroElemento, handleCloseModal_OtroElemento] = useModalData()

    const [fetchMisElemsConst, fetchMisElemsConstData] = ElementosConstructivosServices.GET_BY_EDIFICIO()

    const [existDataInit, setexistDataInit] = useState(!!fetchDataInit);


    useEffect(() => {

        if (!existDataInit) {

            fetchMisElemsConst({
                id: idEdificio + '/edificio',
                params: {
                    padre_id: elemento_constructivo_id,
                    arbol: true
                }
            })
        }

    }, [existDataInit]);


    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mb-4'
            >
                <TableAdvanced.Head
                    title={Lang('OTROS_ELEMENTOS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={existDataInit ? fetchDataInit?.data?.[0]?.otros_elementos : fetchMisElemsConstData?.data?.[0]?.otros_elementos}
                    isLoading={existDataInit ? false : fetchMisElemsConstData?.loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                {/* <TableAdvanced.Footer
                            total={data?.length}
                            perPage={15}
                        /> */}
            </TableAdvanced>

            <ModalOtrosElementos
                data={dataModal_OtroElemento}
                handleClose={handleCloseModal_OtroElemento}
                idEdificio={idEdificio}
                fetchSuccess={() => {
                    setexistDataInit(false)
                    fetchMisElemsConst({
                        id: idEdificio + '/edificio',
                        params: {
                            padre_id: elemento_constructivo_id,
                            arbol: true
                        }
                    })
                }}
                id={id}
            />
        </>
    )
};
