import { faCaretDown, faCaretUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

interface IButtonOrderBy {
    formValuesSearch?: any,
    handleInputChange?: any,
    label?: string,
    id?: any,
    className?: string,
}

const ButtonOrderBy = ({ formValuesSearch, label, id, className, handleInputChange }: IButtonOrderBy) => {

    let labelButton = null

    if (formValuesSearch?.order === `${id} asc`) {

        labelButton = <FontAwesomeIcon icon={faCaretUp} className='me-2' />

    } else if (formValuesSearch?.order === `${id} desc`) {

        labelButton = <FontAwesomeIcon icon={faCaretDown} className='me-2' />

    } else {

        labelButton = <FontAwesomeIcon icon={faSort} className='me-2' />
    }

    return (
        <Typography component='div' variant='body2'
            className={`bg-transparent 
            border-0 text-start w-100 text-nowrap cursor-pointer fw-bold
            ${className ? className : ''} 
            ${(formValuesSearch?.order === `${id} asc` || formValuesSearch?.order === `${id} desc`) ? 'table-orderby-active' : ''}`}

            onClick={() => {

                if (formValuesSearch?.order === `${id} asc`) {

                    handleInputChange({
                        target: {
                            name: 'order',
                            value: `${id} desc`
                        }
                    })
                } else if (formValuesSearch?.order === `${id} desc`) {

                    handleInputChange({
                        target: {
                            name: 'order',
                            value: ''
                        }
                    })

                } else {
                    
                    handleInputChange({
                        target: {
                            name: 'order',
                            value: `${id} asc`
                        }
                    })
                }
            }}
        >
            <span>{labelButton}</span> {` ${label}`}
        </Typography>
    )
}

export default ButtonOrderBy