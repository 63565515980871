import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router";
import { useLang } from "../../../../../../../language";
import { NavLeftSecondary } from "../../../../../layout/NavLeftSecondary";
import { Card, CardContent, Chip, Grid, Stack } from "@mui/material";
import { DatosGenerales } from "./DatosGenerales/DatosGenerales";
import { BreadcrumbAdvanced } from "../../../../../../../components/BreadcrumbAdvanced/BreadcrumbAdvanced";
import { Anotaciones } from "./Anotaciones/Anotaciones";
import { AnotacionFotos } from "./AnotacionesImagenes/AnotacionFotos";
import { Documentacion } from "./Documentacion/Documentacion";
import { GremiosActividad } from "./Gremios/GremiosActividad";
import { Periodicidades } from "./Periodicidades/Periodicidades";
import { ActividadServices } from "../controllers/actividades.services";
import { isStatusSuccess } from "../../../../../../../helpers/helpers";
import { red } from '@mui/material/colors';
import { TiposIntervencionStaticServices } from "../../../../../../../controllers/statics/statics.services";

export const Actividad = ({ navLinksFases, idCurrentFase, idedificio, idCurrentProject, readOnly }: any) => {

    const { idActividad } = useParams()

    const Lang = useLang()

    const [activeNav, setactiveNav] = useState({});

    const pathRedirectActividades = `../../actividades/${idCurrentFase}`

    const BreadcrumbAdvancedItems = [
        {
            title: Lang('ACTIVIDADES'),
            link: pathRedirectActividades,
        },
        {
            title: Lang('ACTIVIDAD'),
            link: '/',
        }
    ]

    const [navLinks, setnavLinks] = useState([
        {
            title: Lang('DATOS_GENERALES'),
            link: 'datos-generales'
        },
        {
            title: Lang('GREMIOS_ASIGNADOS'),
            link: 'gremios'
        },
        {
            title: Lang('DOCUMENTOS'),
            link: 'documentos'
        },

        {
            title: Lang('IMAGENES'),
            link: 'imagenes'
        },
        {
            title: Lang('INCIDENCIAS'),
            link: 'incidencias',
        },
    ])


    const [fetchActividadGET, fetchActividadGETData] = ActividadServices.GET()

    useEffect(() => {

        if (idActividad) {

            fetchActividadGET({
                id: idActividad + '/mostrar'
            })
        }

    }, [idActividad]);

    useEffect(() => {

        if (isStatusSuccess(fetchActividadGETData.status) && fetchActividadGETData?.data?.referencia_padre_id) {

            if (!navLinks?.find((item: any) => item.link === 'periodicidades')) {

                setnavLinks(
                    [
                        ...navLinks,
                        {
                            title: 'Periodicidades',
                            link: 'periodicidades'
                        }
                    ]
                )
            }
        }

    }, [fetchActividadGETData]);

    const tiposIntervencionStaticServices: any = TiposIntervencionStaticServices.GET()


    const colorChipTipoIntervencion = (tipus_intervencio?: any) => {

        let color: any = ''

        if (tipus_intervencio === 'manteniment') {
            
            color = 'info'

        } else if(tipus_intervencio === 'rehabilitacio'){

            color = 'warning'

        } else if(tipus_intervencio === 'millora'){

            color = 'success'

        } else {

            color = 'primary'
        }

        return (
            <>
                {
                    tipus_intervencio &&
                    <Stack direction="row" spacing={1} className="ms-3">
                        <Chip label={tiposIntervencionStaticServices[tipus_intervencio]} color={color} />
                    </Stack>
                }
            </>

        )
    }


    return (
        <Grid container spacing={3} className="pt-3">

            <Grid item xs={12}>
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                    <BreadcrumbAdvanced
                        items={BreadcrumbAdvancedItems}
                        pathHome={pathRedirectActividades}
                    />

                    {
                        colorChipTipoIntervencion(fetchActividadGETData?.data?.tipus_intervencio)
                    }

                </div>

            </Grid>

            <Grid item xs={12} md={3} lg={3} xl={3} className='PageIndex__CTA--text py-5 py-lg-0 mt-3'>

                <NavLeftSecondary
                    navLinks={navLinks}
                    setactiveNav={setactiveNav}
                />

            </Grid>

            <Grid item xs={12} md={9} lg={9} xl={9} className='py-5 py-lg-0 mt-3'>

                <Routes>

                    <Route
                        path="datos-generales"
                        element={
                            <DatosGenerales
                                navLinksFases={navLinksFases}
                                idCurrentFase={idCurrentFase}
                                idActividad={idActividad}
                                idedificio={idedificio}
                                readOnly={readOnly}
                                fetchActividadGET={fetchActividadGET}
                                fetchActividadGETData={fetchActividadGETData}
                            />
                        }
                    />

                    <Route
                        path="incidencias"
                        element={
                            <Anotaciones
                                idEdificio={idedificio}
                                idActividad={idActividad}
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="imagenes"
                        element={
                            <Card>
                                <CardContent>
                                    <AnotacionFotos
                                        // idAnotacion={data?.id}
                                        idActividad={idActividad}
                                        idCurrentProject={idCurrentProject}
                                        readOnly={readOnly}
                                    />
                                </CardContent>
                            </Card>
                        }
                    />

                    <Route
                        path="documentos"
                        element={
                            <Documentacion
                                idActividad={idActividad}
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="gremios"
                        element={
                            <GremiosActividad
                                idActividad={idActividad}
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                            />
                        }
                    />

                    <Route
                        path="periodicidades"
                        element={
                            <Periodicidades
                                idActividad={idActividad}
                                idCurrentProject={idCurrentProject}
                                readOnly={readOnly}
                                idEdificio={idedificio}
                                navLinksFases={navLinksFases}
                                referencia_padre_id={fetchActividadGETData?.data?.referencia_padre_id}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={<Navigate to='datos-generales' replace />}
                    />
                </Routes>
            </Grid>

        </Grid>
    )
};
