import { Typography } from "@mui/material";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { useLang } from "../../../language";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";
import { FormCondicionesContratacion } from "../../CondicionesContratacion/FormCondicionesContratacion";

export const ModalCondicionesContratacion = ({ data, handleClose, handleInput, name = '' }: any) => {

    const Lang = useLang()

    const handleClickAceptar = () => {
        handleInput({
            target: {
                name: name,
                value: [name]
            }
        })
        handleClose()
    }

    const handleClickRechazar = () => {
        handleInput({
            target: {
                name: name,
                value: []
            }
        })
        handleClose()

    }

    return (
        <>

            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <FormCondicionesContratacion 
                        index1={data.index1Accordion || ''}
                        index2={data.index2Accordion || ''}
                    />

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <div className="d-flex justify-content-between">
                        <ButtonAdvanced
                            variant="outlined" type="submit" className=""
                            handleSubmit={handleClickRechazar}
                            value={Lang('RECHAZAR')}
                        />
                        <ButtonAdvanced
                            variant="contained" type="submit" className=""
                            handleSubmit={handleClickAceptar}
                            value={Lang('ACEPTAR')}
                        />
                    </div>
                </ModalAdvanced.Footer>


            </ModalAdvanced>
        </>
    );
};
