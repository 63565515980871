export const CondGenSeccion13 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Este contrato se rige por la Ley Española. Para los casos que la normativa prevea la posibilidad de que las partes se sometan a un fuero, se someten a los Jueces y Tribunales de la Ciudad de Barcelona (España), con renuncia expresa de cualquier otro al que pudiera corresponderles.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Aquest contracte es regeix per la Llei Espanyola. Per als casos que la normativa prevegi la possibilitat que les parts se sotmetin a un fur, se sotmeten als Jutges i Tribunals de la Ciutat de Barcelona (Espanya), amb renúncia expressa de qualsevol altre al qual pogués correspondre'ls.
      </p>
    </div>
  )
}