
import './theme/scss/global.scss'
import './theme/scss/overwrite.scss'
import './assets/scss/utils-bootstrap.scss'
import './assets/scss/utils.scss'

import 'animate.css';

import { ModulesRouter } from './routes/ModulesRouter'
import { useSelector } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material';

import { RootState } from './store';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useEffect } from 'react';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<ModulesRouter />} />
  ),
  {
    basename: process.env.REACT_APP_SUBDOMAIN
  }
);

function App() {

  const { language } = useSelector((state: RootState) => state.language)

  // Or Create your Own theme:
  const theme = createTheme({
    palette: {
      primary: {
        main: '#E94B4C'
      },
      secondary: {
        main: '#333'
      },
      // action: {
      //   disabledBackground: '#e58f8f',
      //   disabled: '#fff'
      // }
    },
    typography: {
      fontFamily: [
        'Aeonik',
        'sans-serif',
      ].join(','),
    }
  });


  useEffect(() => {

    if (String(language || '').toLowerCase() === 'es') {

      document.title = 'Libro del edificio'
      
    } else {
      
      document.title = `Llibre de l'edifici`
    }

  }, []);


  return (
    <ThemeProvider theme={theme}>

      {/* <BrowserRouter basename={process.env.REACT_APP_SUBDOMAIN}>

        <ModulesRouter />

      </BrowserRouter> */}

      <RouterProvider router={router} />

    </ThemeProvider >

  );
}

export default App;
