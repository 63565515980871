import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { MantenimientoPreventivoServices } from "./controllers/mantpreventivo.services"
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { InputChecks } from "../../../../../../../components/Inputs/InputChecks"
import { PeriodicidadUnidadStaticServices } from "../../../../../../../controllers/statics/statics.services"
import { validExiste } from "../../../../../../../helpers/validations"
import { MaestrosServices } from "../../../../../../../controllers/maestros/maestros.services"

export const ModalMantPreventivo = ({ data, handleClose, idedificio, fetchSuccess, id }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const periodicidadUnidadStaticServices = PeriodicidadUnidadStaticServices.GET()
    
    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [fetchMantPrevGET, fetchMantPrevGETData] = MantenimientoPreventivoServices.GET()

    const [fetchMantPrevPOST, fetchMantPrevPOSTData] = MantenimientoPreventivoServices.POST()

    const [fetchMantPrevPATCH, fetchMantPrevPATCHData] = MantenimientoPreventivoServices.PATCH()

    const [fetchMantPrevDELETE, fetchMantPrevDELETEData] = MantenimientoPreventivoServices.DELETE()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
        periodicitat_unidad: '',
    })

    const [validOperacion, handleValidOperacion, resetvalidOperacion] = useValidator({
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        document_referencia: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        responsable: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat_unidad: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat_text:[
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevGETData.status)) {

            handleFormDocumento({
                ...fetchMantPrevGETData.data
            })
        }

    }, [fetchMantPrevGETData]);


    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidOperacion(formDocumento)) {

            fetchMantPrevPOST({
                id: idedificio,
                body: {
                    ...formDocumento,
                    elemento_constructivo_id: id
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidOperacion(formDocumento)) {

            fetchMantPrevPATCH({
                id: data?.id + '/actualizar',
                body: {
                    ...formDocumento,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchMantPrevDELETE({
            id: data?.id + '/eliminar',
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevDELETEData]);


    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "periodicitat_unidad",
                    },
                ]
            })

            if (isNumber(data?.id)) {

                fetchMantPrevGET({
                    id: data?.id + '/mostrar'
                })
            }

        } else {
            resetFormDocumento()
            resetvalidOperacion()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_OPERACION') : Lang('EDITAR_OPERACION')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchMantPrevGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ': *'}
                                            name='descripcio'
                                            value={formDocumento.descripcio}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validOperacion.descripcio}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={5} lg={6} display='flex' alignItems='end'>
                                        <InputText
                                            label={Lang('PERIODICIDAD_EN_TEXTO') + ': *'}
                                            name='periodicitat_text'
                                            value={formDocumento.periodicitat_text}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validOperacion.periodicitat_text}
                                        />
                                        <Tooltip title="Texto a incluir en el libro del edificio" placement="top">
                                            <FontAwesomeIcon icon={faQuestionCircle} className="mb-2 ms-2" />
                                        </Tooltip>
                                    </Grid>


                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('PERIODICIDAD') + ': *'}
                                            name='periodicitat'
                                            type='number'
                                            value={formDocumento.periodicitat}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validOperacion.periodicitat}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputSelect
                                            label={Lang('UNIDAD') + ': *'}
                                            name='periodicitat_unidad'
                                            value={formDocumento.periodicitat_unidad}
                                            values={fetchParamsPublicGETData?.data?.['periodicitat_unidad'] || []}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            onChange={handleInputDocumento}
                                            localValidation={validOperacion.periodicitat_unidad}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('ANIO_INICIO') + ': *'}
                                            name='data_inici_any'
                                            type='number'
                                            value={formDocumento.data_inici_any}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validOperacion.data_inici_any}
                                        />
                                    </Grid> */}

                                    <Grid width='100%'></Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                        <InputText
                                            label={Lang('DOCUMENTO_REFERENCIA') + ': *'}
                                            name='document_referencia'
                                            value={formDocumento.document_referencia}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validOperacion.document_referencia}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <InputText
                                            label={Lang('RESPONSABLE') + ': *'}
                                            name='responsable'
                                            value={formDocumento.responsable}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validOperacion.responsable}
                                        />
                                    </Grid>

                                    {/* <Box width="100%"/> */}

                                    {/* <Grid item xs={12} md={6} lg={4}>
                                        <InputChecks
                                            name='obligatorietat'
                                            values={formDocumento.obligatorietat}
                                            valuesDefault={[
                                                {
                                                    id: 'obligatorietat',
                                                    label: Lang('OBLIGATORIO')
                                                }
                                            ]}
                                            onChange={handleInputDocumento}
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputChecks
                                            name='manual_habitatge'
                                            values={formDocumento.manual_habitatge}
                                            valuesDefault={[
                                                {
                                                    id: 'manual_habitatge',
                                                    label: Lang('INCLUIR_MANUAL_VIVIENDA')
                                                }
                                            ]}
                                            onChange={handleInputDocumento}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchMantPrevPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchMantPrevDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchMantPrevPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchMantPrevPOSTData} />

            <ModalStatusServer fetchData={fetchMantPrevPATCHData} />

            <ModalStatusServer fetchData={fetchMantPrevDELETEData} />
        </>
    )
}