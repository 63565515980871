
const VALIDATIONS = {
    VALID_EXISTE_CAMPO_DEFAULT: 'El camp és obligatori.',
    VALID_EXISTE_NUMERO_COLEGIADO: 'El número de col·legiat és obligatori.',
    VALID_EXISTE_CLAVE: 'La clau és obligatòria.',
    VALID_CONFIRMAR_CLAVE: 'Les claus no són les mateixes.',
    VALID_EXISTE_NOMBRE_SOCIEDAD: 'El nom de la societat és obligatori.',
    VALID_CORRECT_EMAIL: 'Si us plau, entreu una adreça de correu vàlida.',
    VALID_CORRECT_MOVIL: 'El número de mòbil només pot contenir dígits (sense espais, punt, etc.)',
    VALID_CORRECT_YEAR: `Si us plau, introduïu un número d'any correcte.`,
    VALID_CORRECT_DNI: `Camp incorrecte`,

}

export default VALIDATIONS;