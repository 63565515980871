import { useEffect } from "react"
import { useLang } from '../../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../../helpers/validations'
import { InputText } from '../../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Alert, Box, Grid, Typography } from '@mui/material'
import { getDateInput, isStatusSuccess } from '../../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AnotacionServices } from './controllers/anotaciones.services'
import { InputTextarea } from '../../../../../../../../components/Inputs/InputTextarea'
import { TabsAdvanced } from '../../../../../../../../components/TabsAdvanced/TabsAdvanced'
import { AnotacionFotos } from '../AnotacionesImagenes/AnotacionFotos'
import { Documentacion } from '../Documentacion/Documentacion'

export const ModalAnotacion = ({ data, handleClose, readOnly, idEdificio, fetchPropiedadAgentesGET,
    formValuesSearch, idCurrentProject, idActividad, fetchSuccess }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchAnotacionGET, fetchAnotacionGETData, resetfetchAnotacionGETData] = AnotacionServices.GET()

    const [fetchAnotacionPOST, fetchAnotacionPOSTData, resetfetchAnotacionPOSTData] = AnotacionServices.POST()

    const [fetchAnotacionPATCH, fetchAnotacionPATCHData, resetfetchAnotacionPATCHData] = AnotacionServices.PATCH()

    const [fetchAnotacionFinalizarPATCH, fetchAnotacionFinalizarPATCHData, resetfetchAnotacionFinalizarPATCHData] = AnotacionServices.PATCH()

    const [fetchAnotacionDELETE, fetchAnotacionDELETEData, resetfetchAnotacionDELETEData] = AnotacionServices.DELETE()

    const [formAnotacion, handleInputRelacion, handleFormAnotacion, resetFormAnotacion] = useForm({
        codi_postal: '',
        numero: '',
        adreca: '',
        provincia: '',
        poblacio: '',
        tipo: ''
    })

    const [validAnotacion, handleValidAnotacion, resetvalidAnotacion] = useValidator({
        titol: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data_seguiment: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // nif: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionGETData.status)) {

            handleFormAnotacion({
                ...fetchAnotacionGETData.data
            })
        }

    }, [fetchAnotacionGETData]);


    // ------------ POST

    const handleSubmitNuevoAgente = () => {

        if (handleValidAnotacion(formAnotacion)) {

            fetchAnotacionPOST({
                id: formAnotacion.tipo,
                body: {
                    ...formAnotacion,
                    projecte_id: idCurrentProject,
                    projecte_intervencion_id: data?.idActividad,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchAnotacionPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitEditarAgente = () => {

        if (handleValidAnotacion(formAnotacion)) {

            fetchAnotacionPATCH({
                id: data?.id + '/actualizar',
                body: {
                    ...formAnotacion,
                    projecte_id: idCurrentProject,
                    projecte_intervencion_id: data?.idActividad,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchAnotacionPATCHData]);


    // ------------ PATCH Finalizar

    const handleSubmitIncidenciaPATCH = (value: boolean) => {

        if (handleValidAnotacion(formAnotacion)) {

            fetchAnotacionFinalizarPATCH({
                id: data?.id + '/actualizar',
                body: {
                    ...formAnotacion,
                    fecha_finalizacion: value ? getDateInput() : '',
                    projecte_id: idCurrentProject,
                    projecte_intervencion_id: data?.idActividad,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionFinalizarPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchAnotacionFinalizarPATCHData]);

    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchAnotacionDELETE({
            id: data?.id + '/eliminar',
            body: {
                ...formAnotacion,
                edifici_id: idEdificio
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchAnotacionDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {
                fetchAnotacionGET({
                    id: data?.id + '/mostrar'
                })
            }

        } else {
            resetFormAnotacion()
            resetvalidAnotacion()
            resetfetchAnotacionPOSTData()
            resetfetchAnotacionGETData()
            resetfetchAnotacionPATCHData()
            resetfetchAnotacionDELETEData()
            resetfetchAnotacionFinalizarPATCHData()
        }

    }, [data.value]);


    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_INCIDENCIA') : Lang('EDITAR_INCIDENCIA')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchAnotacionGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={Lang('TITULO') + ': *'}
                                            name='titol'
                                            value={formAnotacion.titol}
                                            onChange={handleInputRelacion}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validAnotacion.titol}
                                            readOnly={readOnly || fetchAnotacionGETData.data?.fecha_finalizacion}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ':'}
                                            name='descripcio'
                                            value={formAnotacion.descripcio}
                                            onChange={handleInputRelacion}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validAnotacion.descripcio}
                                            readOnly={readOnly || fetchAnotacionGETData.data?.fecha_finalizacion}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputText
                                            label={Lang('FECHA_SEGUIMIENTO') + ': *'}
                                            name='data_seguiment'
                                            type='date'
                                            value={formAnotacion.data_seguiment}
                                            onChange={handleInputRelacion}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validAnotacion.data_seguiment}
                                            className='labelFocusAlways'
                                            readOnly={readOnly || fetchAnotacionGETData.data?.fecha_finalizacion}
                                        />
                                    </Grid>

                                    {
                                        formAnotacion.fecha_finalizacion &&
                                        <Grid item xs={12} md={6} lg={4}>
                                            <InputText
                                                label={Lang('FECHA_FINALIZACION') + ': *'}
                                                name='fecha_finalizacion'
                                                type='date'
                                                value={formAnotacion.fecha_finalizacion}
                                                className='labelFocusAlways'
                                                readOnly={true}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12}>

                                        {
                                            formAnotacion.id ?

                                                <TabsAdvanced>
                                                    <TabsAdvanced.Head
                                                        // centered={true}
                                                        values={[
                                                            {
                                                                id: 'imagenes',
                                                                name: Lang('IMAGENES'),
                                                            },
                                                            {
                                                                id: 'documentos',
                                                                name: Lang('DOCUMENTOS'),
                                                            },
                                                        ]}
                                                    />
                                                    <TabsAdvanced.Body>
                                                        <section className="pt-3">

                                                            <Grid container spacing={3} rowSpacing={3}>

                                                                <Grid item xs={12}>
                                                                    <AnotacionFotos
                                                                        idAnotacion={data?.id}
                                                                        idActividad={idActividad}
                                                                        idCurrentProject={idCurrentProject}
                                                                        readOnly={readOnly || fetchAnotacionGETData.data?.fecha_finalizacion}
                                                                    />
                                                                </Grid>


                                                            </Grid>
                                                        </section>
                                                        <section className="pt-3">

                                                            <Grid container spacing={3} rowSpacing={3}>
                                                                <Grid item xs={12}>

                                                                    <Documentacion
                                                                        idAnotacion={data?.id}
                                                                        idActividad={idActividad}
                                                                        idCurrentProject={idCurrentProject}
                                                                        readOnly={readOnly || fetchAnotacionGETData.data?.fecha_finalizacion}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        </section>
                                                    </TabsAdvanced.Body>

                                                </TabsAdvanced>
                                                :
                                                <>
                                                    <Typography variant='body1' className='mb-0 pb-2 mt-0 fw-normal'>
                                                        {Lang('IMAGENES_Y_DOCUMENTOS')}:
                                                    </Typography>
                                                    <Alert severity="info" className="mb-3 d-flex align-items-center">
                                                        <span>
                                                            {Lang('PARRAFO_ADJUNTAR_CREAR_ANOTACION')}
                                                        </span>
                                                    </Alert>
                                                </>

                                        }
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        !readOnly &&
                        <>
                            {
                                data?.action === 'CREATE' ?
                                    <ButtonAdvanced
                                        variant="contained" type="button" className="mt-3 ms-auto"
                                        handleSubmit={handleSubmitNuevoAgente}
                                        loadings={[fetchAnotacionPOSTData.loading]}
                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                    />
                                    :
                                    <div className='d-flex justify-content-between'>
                                        <ButtonAdvanced
                                            variant="outlined" type="button" className="mt-3"
                                            handleSubmit={handleSubmitEliminarAgente}
                                            loadings={[fetchAnotacionDELETEData.loading]}
                                            value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                            disabled={!formAnotacion.id}
                                        />

                                        {
                                            !formAnotacion.fecha_finalizacion &&
                                            <div>
                                                <ButtonAdvanced
                                                    variant="outlined" color='success' type="button" className="mt-3 ms-auto me-3"
                                                    handleSubmit={() => handleSubmitIncidenciaPATCH(true)}
                                                    loadings={[fetchAnotacionFinalizarPATCHData.loading]}
                                                    value={Lang('CERRAR')} icon={<FontAwesomeIcon icon={faCheck} className='me-2' />}
                                                    disabled={!formAnotacion.id}
                                                />
                                                <ButtonAdvanced
                                                    variant="contained" type="button" className="mt-3 ms-auto"
                                                    handleSubmit={handleSubmitEditarAgente}
                                                    loadings={[fetchAnotacionPATCHData.loading]}
                                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                                    disabled={!formAnotacion.id}
                                                />
                                            </div>
                                        }
                                        {
                                            formAnotacion.fecha_finalizacion &&
                                            <ButtonAdvanced
                                                variant="outlined" color='primary' type="button" className="mt-3 ms-auto"
                                                handleSubmit={() => handleSubmitIncidenciaPATCH(false)}
                                                loadings={[fetchAnotacionFinalizarPATCHData.loading]}
                                                value={Lang('ABRIR')} icon={<FontAwesomeIcon icon={faCheck} className='me-2' />}
                                                disabled={!formAnotacion.id}
                                            />
                                        }
                                    </div>
                            }
                        </>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchAnotacionPOSTData} />

            <ModalStatusServer fetchData={fetchAnotacionPATCHData} />

            {/* <ModalStatusServer fetchData={fetchAnotacionFinalizarPATCHData} /> */}

            <ModalStatusServer fetchData={fetchAnotacionDELETEData} />
        </>
    )
}