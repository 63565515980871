import { faBuilding, faGlobe, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLang } from "../../../language"
import { useNavigate } from "react-router"
import { DropdownAdvanced } from "../../DropdownAdvanced/DropdownAdvanced"
import { changeLanguage } from "../../../store/slices/language"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"

export const DropdownLanguage = ({ auth, logOut, handleShowCambiarPassword }: any) => {

    const Lang = useLang()

    const { language } = useSelector((state: RootState) => state.language)

    const dispatch = useDispatch()

    const objectList = {

        title: <><FontAwesomeIcon icon={faGlobe} className='me-1' /> <span className='me-1'>{language}</span></>,
        list: [
            {
                content: Lang('ESPANIOL'),
                action: () => {
                    dispatch(changeLanguage('ES'))
                    window.location.reload()
                },
            },
            {
                content: Lang('CATALAN'),
                action: () => {
                    dispatch(changeLanguage('CA'))
                    window.location.reload()
                },
            }
        ]
    
    }

    return (
        <DropdownAdvanced
            objectList={objectList}
        />
    )
}