import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { convertStaticServiceToArray, getValuesParams, isChecked, isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputFile } from '../../../../../../../components/Inputs/InputFile'
import { MenuOtroElementoServices } from "./controllers/menuotroselem.services"
import { InputChecks } from "../../../../../../../components/Inputs/InputChecks"
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { MantenimientoPreventivoServices } from "../../MantenimientoPreventivo/Operaciones/controllers/mantpreventivo.services"
import { PeriodicidadUnidadStaticServices } from "../../../../../../../controllers/statics/statics.services"
import { MaestrosServices } from "../../../../../../../controllers/maestros/maestros.services"

export const ModalOtrosElementos = ({ data, handleClose, elemento_constructivo_id, idEdificio,
    formValuesSearch, id, fetchSuccess }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchOtroElementoGET, fetchOtroElementoGETData] = MenuOtroElementoServices.GET()

    const [fetchOtroElementoPOST, fetchOtroElementoPOSTData] = MenuOtroElementoServices.POST()

    const [fetchOtroElementoPATCH, fetchOtroElementoPATCHData] = MenuOtroElementoServices.PATCH()

    const [fetchOtroElementoDELETE, fetchOtroElementoDELETEData] = MenuOtroElementoServices.DELETE()

    const [fetchMantPrevPOST, fetchMantPrevPOSTData] = MantenimientoPreventivoServices.POST()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [formOtroElemento, handleInputDocumento, handleFormOtroElemento, resetFormOtroElemento] = useForm({
        tipus_document: '',
        file: '',
        incluir_operacion: [],
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        nom_element: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchOtroElementoGETData.status)) {

            handleFormOtroElemento({
                ...fetchOtroElementoGETData.data
            })
        }

    }, [fetchOtroElementoGETData]);


    // ------------ POST Documento

    const handleSubmitDocumentoPOST = () => {

        if (isChecked(formOtroElemento.incluir_operacion)) {

            if (handleValidDocumento(formOtroElemento) && handleValidMantPrev(formMantPrev)) {

                fetchOtroElementoPOST({
                    id: idEdificio + '/create',
                    body: {
                        ...formOtroElemento,
                        elemento_constructivo_id: data.elemento_constructivo_id
                    }
                })

                fetchMantPrevPOST({
                    id: idEdificio,
                    body: {
                        ...formMantPrev,
                        elemento_constructivo_id: id
                    }
                })
            }

        } else {

            if (handleValidDocumento(formOtroElemento)) {

                fetchOtroElementoPOST({
                    id: idEdificio + '/create',
                    body: {
                        ...formOtroElemento,
                        elemento_constructivo_id: data.elemento_constructivo_id
                    }
                })
            }
        }
    }

    useEffect(() => {

        if (isChecked(formOtroElemento.incluir_operacion)) {

            if (isStatusSuccess(fetchOtroElementoPOSTData.status) && isStatusSuccess(fetchMantPrevPOSTData.status)) {

                fetchSuccess()
                handleClose()
            }

        } else {

            if (isStatusSuccess(fetchOtroElementoPOSTData.status)) {

                fetchSuccess()
                handleClose()
            }
        }

    }, [fetchOtroElementoPOSTData, fetchMantPrevPOSTData, formOtroElemento.incluir_operacion]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidDocumento(formOtroElemento)) {

            fetchOtroElementoPATCH({
                id: data.id + '/update',
                body: {
                    ...formOtroElemento,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchOtroElementoPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchOtroElementoPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchOtroElementoDELETE({
            id: data.id + '/delete',
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchOtroElementoDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchOtroElementoDELETEData]);


    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "periodicitat_unidad",
                    },
                ]
            })

            if (isNumber(data?.id)) {

                fetchOtroElementoGET({
                    id: data?.id + '/show'
                })
            }

        } else {
            resetFormOtroElemento()
            resetvalidDocumento()
        }

    }, [data.value]);



    const [formMantPrev, handleInputMantPrev, handleFormMantPrev, resetFormMantPrev] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
        periodicitat_unidad: '',
        periodicitat: '',
    })

    const periodicidadUnidadStaticServices = PeriodicidadUnidadStaticServices.GET()

    const [validMantPrev, handleValidMantPrev, resetvalidMantPrev] = useValidator({
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        document_referencia: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        responsable: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat_unidad: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat_text:[
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVO_ELEMENTO') : Lang('EDITAR_ELEMENTO')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchOtroElementoGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputText
                                            label={Lang('NOMBRE') + ': *'}
                                            name='nom_element'
                                            value={formOtroElemento.nom_element}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchOtroElementoPOSTData : fetchOtroElementoPATCHData}
                                            localValidation={validDocumento.nom_element}
                                        />
                                    </Grid>

                                    {
                                        data?.action === 'CREATE' &&
                                        <>

                                            <Grid item xs={12}>
                                                <InputChecks
                                                    name='incluir_operacion'
                                                    values={formOtroElemento.incluir_operacion}
                                                    valuesDefault={[
                                                        {
                                                            id: 'incluir_operacion',
                                                            label: Lang('INCLUIR_OPERACION')
                                                        }
                                                    ]}
                                                    onChange={handleInputDocumento}
                                                />
                                            </Grid>

                                            {
                                                isChecked(formOtroElemento.incluir_operacion) &&
                                                <>

                                                    <Grid item xs={12}>

                                                        <Typography variant="h6" component="h2" className="text-center">
                                                            {data?.action === 'CREATE' ? Lang('NUEVA_OPERACION') : Lang('EDITAR_OPERACION')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <InputTextarea
                                                            label={Lang('DESCRIPCION') + ': *'}
                                                            name='descripcio'
                                                            value={formMantPrev.descripcio}
                                                            onChange={handleInputMantPrev}
                                                            fetchData={fetchMantPrevPOSTData}
                                                            localValidation={validMantPrev.descripcio}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={5} lg={6} display='flex' alignItems='end'>
                                                        <InputText
                                                            label={Lang('PERIODICIDAD_EN_TEXTO') + ': *'}
                                                            name='periodicitat_text'
                                                            value={formMantPrev.periodicitat_text}
                                                            onChange={handleInputMantPrev}
                                                            fetchData={fetchMantPrevPOSTData}
                                                            localValidation={validMantPrev.periodicitat_text}
                                                        />
                                                        <Tooltip title="Texto a incluir en el libro del edificio" placement="top">
                                                            <FontAwesomeIcon icon={faQuestionCircle} className="mb-2 ms-2" />
                                                        </Tooltip>
                                                    </Grid>


                                                    <Grid item xs={12} md={3} lg={3}>
                                                        <InputText
                                                            label={Lang('PERIODICIDAD') + ': *'}
                                                            name='periodicitat'
                                                            type='number'
                                                            value={formMantPrev.periodicitat}
                                                            onChange={handleInputMantPrev}
                                                            fetchData={fetchMantPrevPOSTData}
                                                            localValidation={validMantPrev.periodicitat}
                                                            
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={3} lg={3}>
                                                        <InputSelect
                                                            label={Lang('UNIDAD') + ': *'}
                                                            name='periodicitat_unidad'
                                                            value={formMantPrev.periodicitat_unidad}
                                                            values={fetchParamsPublicGETData?.data?.['periodicitat_unidad'] || []}
                                                            fetchData={fetchMantPrevPOSTData}
                                                            onChange={handleInputMantPrev}
                                                            localValidation={validMantPrev.periodicitat_unidad}
                                                            
                                                        />
                                                    </Grid>

                                                    <Grid width='100%'></Grid>

                                                    <Grid item xs={12} md={4} lg={3}>
                                                        <InputText
                                                            label={Lang('DOCUMENTO_REFERENCIA') + ': *'}
                                                            name='document_referencia'
                                                            value={formMantPrev.document_referencia}
                                                            onChange={handleInputMantPrev}
                                                            fetchData={fetchMantPrevPOSTData}
                                                            localValidation={validMantPrev.document_referencia}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <InputText
                                                            label={Lang('RESPONSABLE') + ': *'}
                                                            name='responsable'
                                                            value={formMantPrev.responsable}
                                                            onChange={handleInputMantPrev}
                                                            fetchData={fetchMantPrevPOSTData}
                                                            localValidation={validMantPrev.responsable}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <InputChecks
                                                            name='manual_habitatge'
                                                            values={formMantPrev.manual_habitatge}
                                                            valuesDefault={[
                                                                {
                                                                    id: 'manual_habitatge',
                                                                    label: Lang('INCLUIR_MANUAL_VIVIENDA')
                                                                }
                                                            ]}
                                                            onChange={handleInputMantPrev}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                        </>
                                    }



                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchOtroElementoPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchOtroElementoDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchOtroElementoPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchOtroElementoPOSTData} />

            <ModalStatusServer fetchData={fetchOtroElementoPATCHData} />

            <ModalStatusServer fetchData={fetchMantPrevPOSTData} onlyError />
        </>
    )
}