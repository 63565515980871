import { Table, TableContainer, TableBody, Button, Tooltip } from '@mui/material'
import { useLang } from '../../../language'
import { TableAdvancedLoader } from './TableAdvancedLoader'

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ButtonOrderBy from '../../ButtonOrderBy/ButtonOrderBy';
import { InputChecks } from '../../Inputs/InputChecks';

interface ITableAdvancedBody {
    data?: Array<any>,
    checkAll?: boolean,
    excluidos?: Array<number>,
    incluidos?: Array<number>,
    setExcluidos?: (data: any) => void,
    setIncluidos?: (data: any) => void,
    headers?: any,
    dataLineTable?: any,
    allActionsPerLine?: any,
    checkMultiples?: boolean,
    isLoading?: any,
    classNameRow?: any,
    classNameFunctionRow?: any,
    classNameTable?: any,
    columnsTable?: any,
    handleInputChange?: any,
    formValuesSearch?: any,
    setDataTableChecks?: any,
    dataTableChecks?: any,
    disableCheckAll?: any,
    handleCheckTable?: any,
    labelOnCheck?: any,
}

export const TableAdvancedBody = ({ data = [], handleInputChange, handleCheckTable,
    setExcluidos = (data: any) => [0], setIncluidos = (data: any) => [0], headers, dataLineTable, formValuesSearch,
    allActionsPerLine, checkMultiples = false, isLoading, classNameRow = '', classNameTable, columnsTable,
    classNameFunctionRow, setDataTableChecks, dataTableChecks, disableCheckAll, labelOnCheck }: ITableAdvancedBody) => {

    const Lang = useLang()

    const handleClickChangeCheck = (e: any, id: any) => {

        if (dataTableChecks.checkAll) {

            if (e.target.value?.length > 0) {

                setDataTableChecks({ ...dataTableChecks, excluidos: [...dataTableChecks.excluidos.filter((item: number) => item !== id)] })

            } else {

                setDataTableChecks({ ...dataTableChecks, excluidos: [...dataTableChecks.excluidos.filter((item: number) => item !== id), id] })
            }

        } else {

            if (e.target.value?.length > 0) {

                setDataTableChecks({ ...dataTableChecks, incluidos: [...dataTableChecks.incluidos.filter((item: number) => item !== id), id] })

            } else {

                setDataTableChecks({ ...dataTableChecks, incluidos: [...dataTableChecks.incluidos.filter((item: number) => item !== id)] })
            }
        }

        if (handleCheckTable) {

            handleCheckTable(id, e.target.value?.length > 0)
        }
    }

    const selectAllItems = () => {
        setDataTableChecks({
            ...dataTableChecks,
            incluidos: [],
            excluidos: [],
            checkAll: true,
            activeChecks: true,
        })
    }

    const unselectAllItems = () => {
        setDataTableChecks({
            ...dataTableChecks,
            incluidos: [],
            excluidos: [],
            checkAll: false,
            activeChecks: true,
        })
    }

    const handleChangeCheckAll = () => {

        if (!dataTableChecks?.checkAll) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    return (
        <TableContainer component={Paper}>

            <Table sx={{ minWidth: 700 }} aria-label="customized table" className={`fs-6 ${classNameTable || ''}`}>
                <TableHead>
                    <TableRow>
                        {
                            dataTableChecks?.activeChecks && <StyledTableCell valign='middle'>
                                <InputChecks
                                    name='checkAll'
                                    valuesDefault={[{ id: 'checkAll', name: '' }]}
                                    values={dataTableChecks?.checkAll ? ['checkAll'] : []}
                                    onChange={handleChangeCheckAll}
                                    className={`${disableCheckAll ? 'd-none' : ''}`}
                                />
                                { labelOnCheck }
                            </StyledTableCell>
                        }
                        {
                            Object.keys(columnsTable)?.map((key: any) => {
                                return (
                                    <StyledTableCell key={key}>
                                        {
                                            columnsTable[key].order ?
                                                <ButtonOrderBy
                                                    label={columnsTable[key]['title']}
                                                    id={key}
                                                    className={columnsTable[key]['classNameTitle'] || ''}
                                                    handleInputChange={handleInputChange}
                                                    formValuesSearch={formValuesSearch}
                                                />
                                                :
                                                <span className={`${columnsTable[key]['classNameTitle'] || ''} d-block`}>
                                                    {columnsTable[key]['title']}
                                                </span>
                                        }
                                    </StyledTableCell>
                                )
                            })
                        }
                        {
                            allActionsPerLine?.length > 0 &&
                            <StyledTableCell>
                                <span></span>
                            </StyledTableCell>
                        }

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data?.map((item, index) => {

                            return (
                                <StyledTableRow key={`lvl1-${index}`} className={`${classNameRow} ${classNameFunctionRow ? classNameFunctionRow(item) : ''}`}>
                                    {
                                        dataTableChecks?.activeChecks && <StyledTableCell valign='middle'>
                                            <InputChecks
                                                name={item[(dataTableChecks.idRow || 'id')]}
                                                values={
                                                    ((dataTableChecks.checkAll && !dataTableChecks.excluidos?.find((item2: any) => item2 === item[(dataTableChecks.idRow || 'id')])) ||
                                                        (!dataTableChecks.checkAll && dataTableChecks.incluidos?.find((item2: any) => item2 === item[(dataTableChecks.idRow || 'id')]))) ?
                                                        [item[(dataTableChecks.idRow || 'id')]]
                                                        :
                                                        []
                                                }
                                                onChange={(e: any) => handleClickChangeCheck(e, item[(dataTableChecks.idRow || 'id')])}
                                                valuesDefault={[{ id: item[(dataTableChecks.idRow || 'id')], name: '' }]}
                                            />
                                        </StyledTableCell>
                                    }

                                    {
                                        Object.keys(columnsTable)?.map((key: any) => {

                                            return (
                                                <StyledTableCell key={`lvl2-${key}`} valign='middle' className={columnsTable[key]['className'] ? columnsTable[key]['className'] : ''}>
                                                    {
                                                        columnsTable[key]?.['component'] ? (
                                                            <>
                                                                <div>
                                                                    {columnsTable[key]?.['component'](item[key])}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span>
                                                                    {
                                                                        columnsTable[key]?.['adapter'] ?
                                                                            columnsTable[key]?.['adapterFunction'] ?
                                                                                columnsTable[key]?.['adapterFunction'](item[key], item)
                                                                                :
                                                                                (columnsTable[key]['adapter'][item[key]])
                                                                            :
                                                                            (item[key])
                                                                    }
                                                                </span>
                                                            </>
                                                        )
                                                    }
                                                </StyledTableCell>
                                            )
                                        })
                                    }
                                    {
                                        allActionsPerLine?.length > 0 && (
                                            <StyledTableCell valign='middle' className={`text-center text-nowrap AllActionsPerLine shrink-td`}>
                                                <div className="d-flex align-items-center">
                                                    {
                                                        allActionsPerLine?.map((item2: any, index2: any) => {

                                                            return (
                                                                <div key={index2} className='AllActionsPerLine__ContainerButton'>
                                                                    {
                                                                        (!item2.onlyExistKey || item[item2.onlyExistKey]) &&
                                                                        <>
                                                                            {
                                                                                item2.multipleActions ? (
                                                                                    <></>
                                                                                    // <DropdownButton
                                                                                    //     align="end"
                                                                                    //     title={item2.label}
                                                                                    //     id="dropdown-menu-align-end"
                                                                                    //     className='border-0'
                                                                                    //     variant="primary"
                                                                                    //     size="sm"
                                                                                    //     key={index2}
                                                                                    // // disabled={!(checkAll || incluidos?.length > 0)}
                                                                                    // >
                                                                                    //     {
                                                                                    //         item2.multipleActions.map((item3: any) => {
                                                                                    //             return (
                                                                                    //                 <Dropdown.Item eventKey={index + '_' + index2} onClick={() => item3.action(item.id)}>{item3.icon} {item3.label}</Dropdown.Item>
                                                                                    //             )
                                                                                    //         })
                                                                                    //     }
                                                                                    // </DropdownButton>
                                                                                ) : (
                                                                                    <>
                                                                                        <Tooltip title={item2.tooltip?.text || ''} placement={item2.tooltip?.placement || 'top'}>

                                                                                            <Button
                                                                                                onClick={() => item2.action(item)}
                                                                                                className={item2.className ? item2.className : 'ms-2 py-2'}
                                                                                                variant={item2.variant ? item2.variant : 'contained'}
                                                                                                size={item2.size ? item2.size : 'small'}
                                                                                                color={item2.color ? item2.color : 'primary'}
                                                                                                // loadings={[String(item2.loading?.id) === String(item.id) && item2.loading?.value]}
                                                                                            >
                                                                                                {item2.label}
                                                                                            </Button>

                                                                                        </Tooltip>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </StyledTableCell>
                                        )
                                    }
                                </StyledTableRow>
                            )
                        })
                    }
                    {
                        !isLoading && data?.length === 0 && <StyledTableRow>
                            <td colSpan={20} className='text-center py-5'>{Lang('PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR')}</td>
                        </StyledTableRow>
                    }
                    {
                        isLoading && <TableAdvancedLoader />
                    }

                </TableBody>

                <tbody>

                </tbody>
            </Table>

        </TableContainer>
    )
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#ededed',
        color: '#333',
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));