import { useAxios } from "../../hooks/useAxios"
import { MaestrosAdapters } from "./maestros.adapters"

const MaestrosServices = {

    GET_PARAMETROS_PUBLICOS: function () {

        return useAxios({
            method: 'POST',
            url: '/maestros/parametros-publicos',
            type: 'json',
            adapter: {
                method: MaestrosAdapters.GET_PARAMETROS_PUBLICOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_COLEGIOS_PROFESIONALES_PUBLICOS: function () {

        return useAxios({
            method: 'GET',
            url: '/maestros/colegio-profesionales-publicos',
            type: 'json',
            adapter: {
                method: MaestrosAdapters.GET_COLEGIOS_PROFESIONALES_PUBLICOS,
                types: {
                    // send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_COLEGIOS_PROFESIONALES_PUBLICOS_COMUNIDADES: function () {

        return useAxios({
            method: 'GET',
            url: '/maestros/colegio-profesionales-publicos/comunidades-autonomas',
            type: 'json',
            adapter: {
                method: MaestrosAdapters.GET_PARAMETROS_PUBLICOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_ROLES_EDIFICIO: function () {

        return useAxios({
            method: 'get',
            url: '/maestros/roles_libros',
            type: 'json',
            adapter: {
                method: MaestrosAdapters.GET_ROLES_EDIFICIO,
                types: {
                    // send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },
}

export {
    MaestrosServices
}