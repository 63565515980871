export const CondParSeccion8 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El cliente, manifiesta que es mayor de 18 años, tiene capacidad y reúne las condiciones definidas en cada uno de los perfiles descritos en la cláusula 2 de las condiciones particulares.
      </p>
      <p>
        El cliente se obliga a realizar un uso lícito de los servicios, contravenir la legislación vigente, ni lesionar los derechos e intereses de terceras personas.
      </p>
      <p>
        El cliente garantiza la veracidad y exactitud de los datos facilitados al cumplimentar los formularios de contratación evitando producir perjuicios al Cateb con motivo de la incorrección de estos.
      </p>
      <p>
        Si en una Edificio determinado (y en la organización de la misma) no concurren las circunstancias técnicas o no le es aplicable la normativa española o bien por cualquier razón se requieren funcionalidades y niveles de servicios distintos superiores a los que ofrece el LED del Cateb a través de su Portal, estará prohibido utilizar los servicios del Portal respecto de este Edificio.
      </p>
      <p>
        El Cateb no tiene ninguna responsabilidad sobre la asignación de los agentes habilitados para el uso del libro, ni por la utilización fraudulenta de las claves de acceso a la información del/los libro/s, ni para ningún uso incorrecto de estos.
      </p>
      <p>
        El incumplimiento de cualquiera de las Condiciones de Contratación podrá dar lugar a la retirada o cancelación de los servicios por parte del Cateb, sin necesidad de preaviso al cliente y sin que esto de derecho a indemnización.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        El client, manifesta que és major de 18 anys, té capacitat i reuneix les condicions definides en cadascun dels perfils descrits en la clàusula 2 de les condicions particulars.
      </p>
      <p>
        El client s' obliga a realitzar un ús lícit dels serveis, contravenir la legislació vigent, ni lesionar els drets i interessos de terceres persones.
      </p>
      <p>
        El client garanteix la veracitat i exactitud de les dades facilitades en emplenar els formularis de contractació evitant produir perjudicis al Cateb amb motiu de la incorrecció d' aquests.
      </p>
      <p>
        Si en un Edifici determinat (i en l'organització de la mateixa) no concorren les circumstàncies tècniques o no li és aplicable la normativa espanyola o bé per qualsevol raó es requereixen funcionalitats i nivells de serveis diferents superiors als que ofereix el LED del Cateb a través del seu Portal, estarà prohibit utilitzar els serveis del Portal respecte d'aquest Edifici.
      </p>
      <p>
        El Cateb no té cap responsabilitat sobre l'assignació dels agents habilitats per a l'ús del llibre, ni per la utilització fraudulenta de les claus d'accés a la informació del/s llibre/s, ni per a cap ús incorrecte d'aquests.
      </p>
      <p>
        L'incompliment de qualsevol de les Condicions de Contractació podrà donar lloc a la retirada o cancel·lació dels serveis per part del Cateb, sense necessitat de preavís al client i sense que això doni dret a indemnització.
      </p>
    </div>
  )
}