import { useEffect, useState } from "react";
import { NavLeft } from "../../layout/NavLeft";

import { Route, Routes, useParams } from "react-router";
import { useLang } from "../../../../language";
import { DescripcionConstructiva } from "./DescripcionConstructiva/DatosAdminJuridicos";
import { Box, Button, Grid, Typography } from "@mui/material";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAdvanced from "../../../../components/ModalAdvanced/ModalAdvanced";
import { useModalData } from "../../../../hooks/useModalData";
import { ElementosConstructivosServices } from "./controllers/elemconst.services";
import { LoaderModalFormPrimary } from "../../../../components/Loaders/LoaderModalFormPrimary";
import { ButtonAdvanced } from "../../../../components/ButtonAdvanced/ButtonAdvanced";
import { useForm } from "../../../../hooks/useForm";
import { isFetchDataInitLoader, isStatusSuccess, partition } from "../../../../helpers/helpers";
import { LoaderFormPrimary } from "../../layout/LoaderFormPrimary";
import { InputChecks } from "../../../../components/Inputs/InputChecks";
import { ModalStatusServer } from "../../../../components/Modals/ModalStatusServer";

const LibroEdificio = ({ isObraNueva }: any) => {

  const { id } = useParams()

  const Lang = useLang()

  const [dataModal_elementos, handleShowModal_elementos, handleCloseModal_elementos] = useModalData()

  const [formElementosConst, handleInputElementosConst, handleFormElementosConst, handleResetFormElementosConst] = useForm({
    elemento: [],
  })

  const [fetchElementosConstructivos, fetchElementosConstructivosData] = ElementosConstructivosServices.GET()

  useEffect(() => {

    fetchElementosConstructivos()

  }, []);

  const [resultElems, setresultElems] = useState([[]]);

  useEffect(() => {

    if (isStatusSuccess(fetchElementosConstructivosData.status)) {

      let result = partition(fetchElementosConstructivosData?.data?.map((item: any) => {
        return {
          id: item.id,
          label: item.name
        }
      }), 3);

      setresultElems(result)
    }

  }, [fetchElementosConstructivosData]);

  const [navLinks, setnavLinks] = useState([]);

  const [fetchMisElemsConst, fetchMisElemsConstData] = ElementosConstructivosServices.GET_BY_EDIFICIO()

  const ffetchMisElemsConst = () => {

    fetchMisElemsConst({
      id: id + '/edificio',
      params: {
        mostrar_todos: false,
      }
    })
  }

  useEffect(() => {

    ffetchMisElemsConst()

  }, []);

  useEffect(() => {

    if (isStatusSuccess(fetchMisElemsConstData.status)) {

      const data = fetchMisElemsConstData?.data || []

      setnavLinks(data?.map((item: any) => {
        return {
          title: item.name,
          link: item.id
        }
      }))

      handleFormElementosConst({
        elemento: data?.map((item: any) => item.id)
      })

    }

  }, [fetchMisElemsConstData]);

  const [fetchMisElemsConstPATCH, fetchMisElemsConstPATCHData] = ElementosConstructivosServices.PATCH_ELEMENTOS_BY_EDIFICIO()

  const handleSubmitfetchMisElemsConstPATCH = () => {

    fetchMisElemsConstPATCH({
      id: id + '/edificio/0/actualizar-hijos',
      body: {
        lista_ids_hijos: formElementosConst.elemento?.map((item: string) => Number(item))
      }
    })

  }

  useEffect(() => {

    if (isStatusSuccess(fetchMisElemsConstPATCHData.status)) {

      ffetchMisElemsConst()

      handleCloseModal_elementos()
    }

  }, [fetchMisElemsConstPATCHData]);

  const [defaultLink, setDefaultLink] = useState('');

  return (
    <>
      {
        isFetchDataInitLoader(fetchElementosConstructivosData) ?
          <div className="w-100 m-4">
            <LoaderFormPrimary />
          </div>
          :
          <>

            <NavLeft
              id={id}
              navLinks={navLinks}
              buttonAdd={
                <Button variant="outlined" size="small" className="d-block mx-auto mt-4 mb-4" onClick={handleShowModal_elementos}>
                  <FontAwesomeIcon icon={faPlus} className='me-1' /> {Lang('AGREGAR_SISTEMAS')}
                </Button>
              }
              defaultLink={defaultLink}
            />

            <main className='Main'>

              <Routes>

                {/* <Route
            path="agentes/*"
            element={<Agentes />}
          /> */}

                <Route
                  path="/*"
                  element={
                    <DescripcionConstructiva
                      navLinks={navLinks}
                      idedificio={id}
                      defaultLink={defaultLink}
                      setDefaultLink={setDefaultLink}
                      isObraNueva={isObraNueva}
                      ffetchMisElemsConst={ffetchMisElemsConst}
                    />
                  }
                />


                {/* <Route
            path="*"
            element={<Navigate to='descripcion-constructiva' replace />}
          /> */}

              </Routes>

            </main>
          </>
      }


      <ModalAdvanced data={dataModal_elementos} handleClose={handleCloseModal_elementos} size='xl'>

        <ModalAdvanced.Head>

          <Typography variant="h6" component="h2">
            {Lang('AGREGAR_SISTEMAS')}
          </Typography>

        </ModalAdvanced.Head>

        <ModalAdvanced.Body>

          {
            fetchElementosConstructivosData?.loading ?
              <LoaderModalFormPrimary />
              :
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={3} rowSpacing={3}>

                  <Grid item xs={12} md={6} lg={4}>


                    <InputChecks
                      name='elemento'
                      valuesDefault={resultElems?.[0]}
                      values={formElementosConst.elemento}
                      onChange={handleInputElementosConst}
                    />

                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>

                    <InputChecks
                      name='elemento'
                      valuesDefault={resultElems?.[1]}
                      values={formElementosConst.elemento}
                      onChange={handleInputElementosConst}
                    />

                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>

                    <InputChecks
                      name='elemento'
                      valuesDefault={resultElems?.[2]}
                      values={formElementosConst.elemento}
                      onChange={handleInputElementosConst}
                    />
                  </Grid>

                </Grid>
              </Box>
          }

        </ModalAdvanced.Body>

        <ModalAdvanced.Footer>
          <div className="d-flex justify-content-between align-items-center">
            <span>
              {Lang('DESMARCAR_ELEMENTOS_ELIMINACION_ASOCIADOS')}
            </span>
            <ButtonAdvanced
              variant="contained" type="button" className="mt-0 ms-auto"
              handleSubmit={handleSubmitfetchMisElemsConstPATCH}
              loadings={[fetchMisElemsConstPATCHData.loading]}
              value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
            />
          </div>

        </ModalAdvanced.Footer>

      </ModalAdvanced>

      <ModalStatusServer fetchData={fetchMisElemsConstPATCHData} />
    </>
  );
};

export default LibroEdificio;
