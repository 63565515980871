import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../controllers/controllers"

const ProyectosAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    descripcio: parseString(item.descripcio),
                    edifici_id: parseNumber(item.edifici_id),
                    estat: parseString(item.estat),
                    nom: parseString(item.nom),
                    data_fi: parseString(item.data_fi),
                    data_inici: parseString(item.data_inici),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}


const ProyectoAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    descripcio: parseString(item.descripcio),
                    edifici_id: parseNumber(item.edifici_id),
                    estat: parseString(item.estat),
                    nom: parseString(item.nom),
                    data_fi: parseString(item.data_fi),
                    data_inici: parseString(item.data_inici),

                    fons_propis_actual: parseString(item.fons_propis_actual),
                    fons_fecha: parseString(item.fons_fecha),

                    subvencions_atorgades: parseString(item.subvencions_atorgades),
                    subvencions_fecha: parseString(item.subvencions_fecha),

                    import_financar: parseString(item.import_financar),
                    import_fecha: parseString(item.import_fecha),
                    format_financament: parseString(item.format_financament),

                    observacions_financament: parseString(item.observacions_financament),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    descripcio: parseString(item.descripcio),
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    data_fi: parseString(item.data_fi),
                    data_inici: parseString(item.data_inici),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    descripcio: parseString(item.descripcio),
                    edifici_id: parseNumber(item.edifici_id),
                    nom: parseString(item.nom),
                    data_fi: parseString(item.data_fi),
                    data_inici: parseString(item.data_inici),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_RESUMEN_FINANCIERO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    fons_propis_actual: parseString(item.fons_propis_actual, { removeNullable: true }),
                    fons_fecha: parseString(item.fons_fecha, { removeNullable: true }),

                    subvencions_atorgades: parseString(item.subvencions_atorgades, { removeNullable: true }),
                    subvencions_fecha: parseString(item.subvencions_fecha, { removeNullable: true }),

                    import_financar: parseString(item.import_financar, { removeNullable: true }),
                    import_fecha: parseString(item.import_fecha, { removeNullable: true }),
                    format_financament: parseString(item.format_financament, { removeNullable: true }),

                    observacions_financament: parseString(item.observacions_financament, { removeNullable: true }),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_BY_METRICAS: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    metricas: parseArray(item.metricas),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}


export {
    ProyectosAdapters,
    ProyectoAdapters,
}