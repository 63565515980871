import { useEffect, useState } from "react";
import { useLang } from "../../../../../../language";
import { Grid } from "@mui/material";
import { clearEmptyKeys, getValuesParams, setUrlByParams } from "../../../../../../helpers/helpers";
import { useForm } from "../../../../../../hooks/useForm";
import queryString from 'query-string';
import { useLocation } from "react-router";
import { TableActividades } from "./TableActividades";
import FilterAdvanced from "../../../../../../components/FilterAdvanced/FilterAdvanced";
import { FormSimple } from "./Filters/FormSimple";
import { ActividadesServices } from "./controllers/actividades.services";
import { ElementosConstructivosParamServices } from "../../controllers/elemconst.services";
import { TiposIntervencionStaticServices } from "../../../../../../controllers/statics/statics.services";
import { MaestrosServices } from "../../../../../../controllers/maestros/maestros.services";
import { ModalConfirm } from "../../../../../../components/Modals/ModalConfirm";
import { useModalData } from "../../../../../../hooks/useModalData";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";

export const Actividades = ({ auth, idedificio, fetchEdificioGETData, idCurrentFase, navLinksFases,
    fetchElementosConstructivosData, readOnly }: any) => {

    const Lang = useLang()

    const [fetchActividadesGET, fetchActividadesGETData] = ActividadesServices.GET(idedificio)

    const [fetchParamsMisSistemasGET, fetchParamsMisSistemasGETData] = ElementosConstructivosParamServices.GET(idedificio)

    const [dataModal_ConfirmDescargar, handleShowModal_ConfirmDescargar, handleCloseModal_ConfirmDescargar] = useModalData()

    const tiposIntervencionStaticServices = TiposIntervencionStaticServices.GET()

    useEffect(() => {

        fetchParamsMisSistemasGET()

    }, []);

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    useEffect(() => {

        fetchParamsPublicGET({
            body: [
                {
                    parametroPadre: "estado_actividad",
                },
            ]
        })

    }, []);

    // --------------------- Modal Nuevo edificio ---------------------

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            edifici_id: idedificio,
            order: '',
            tipusintervencio: '',
            faseid: '',
            data_inici_desde: '',
            data_inici_hasta: '',
            estadoactividad: '',
            sistemaid: '',
            data_fi_activitat_desde: '',
            data_fi_activitat_hasta: '',
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        tipusintervencio: params.tipusintervencio || allValuesSearch.tipusintervencio,
        faseid: params.faseid || allValuesSearch.faseid,
        data_inici_desde: params.data_inici_desde || allValuesSearch.data_inici_desde,
        data_inici_hasta: params.data_inici_hasta || allValuesSearch.data_inici_hasta,
        estadoactividad: params.estadoactividad || allValuesSearch.estadoactividad,
        sistemaid: params.sistemaid || allValuesSearch.sistemaid,
        data_fi_activitat_desde: params.data_fi_activitat_desde || allValuesSearch.data_fi_activitat_desde,
        data_fi_activitat_hasta: params.data_fi_activitat_hasta || allValuesSearch.data_fi_activitat_hasta,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'tipusintervencio', 'faseid', 'edifici_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchActividadesGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        if (formValuesSearch.faseid) {

            const urlParams = getValuesParams(formValuesSearch)

            setUrlByParams(urlParams, 'limit', 'tipusintervencio', 'faseid', 'edifici_id')

            fetchActividadesGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit, formValuesSearch.faseid])

    useEffect(() => {

        handleFormChange({
            ...formValuesSearch,
            faseid: idCurrentFase,
        })

    }, [idCurrentFase]);


    const [showFilter, setshowFilter] = useState(false);

    const [fetchActividadesBlobGET, fetchActividadesBlobGETData, resetfetchActividadesBlobGETData] = ActividadesServices.GET_BLOB(idedificio)

    const handleSubmitDownloadExcel = () => {

        handleShowModal_ConfirmDescargar()

        const urlParams: any = getValuesParams(formValuesSearch)

        delete urlParams.page

        fetchActividadesBlobGET({
            params: {
                ...urlParams,
                to_excel: true
            }
        })
    }

    return (
        <>
            <div className="">
                <Grid container>
                    {
                        !idCurrentFase ?
                            <Grid item xs={12} className='py-5'>
                                <div className="text-center">
                                    Seleccione una fase para continuar
                                </div>
                            </Grid>
                            :
                            <Grid item xs={12} className='py-0 py-lg-0'>

                                {
                                    showFilter &&
                                    <div className="my-3 animate__animated animate__fadeIn animate__faster">

                                        <FilterAdvanced
                                            fetchData={fetchActividadesGETData}
                                            handleSubmitSearch={handleSubmitSearch}
                                            formValuesSearch={formValuesSearch}
                                            handleFormChange={handleFormChange}
                                            handleInputChangeSearch={handleInputChangeSearch}
                                            resetformValuesSearch={resetformValuesSearch}
                                            valuesDefault={valuesSearch.default}
                                            valuesSimple={valuesSearch.onlySimple}
                                            valuesExpanded={valuesSearch.onlyExpanded}
                                        >

                                            <FilterAdvanced.Simple label={Lang('BUSCADOR')}>

                                                <FormSimple
                                                    fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                                                    tiposIntervencionStaticServices={tiposIntervencionStaticServices}
                                                    fetchParamsPublicGETData={fetchParamsPublicGETData}
                                                />

                                            </FilterAdvanced.Simple>

                                            <FilterAdvanced.Expanded></FilterAdvanced.Expanded>

                                        </FilterAdvanced>
                                    </div>
                                }

                                <section className="mb-3">
                                    <TableActividades
                                        data={fetchActividadesGETData.data?.items}
                                        loading={fetchActividadesGETData.loading}
                                        total={fetchActividadesGETData.data?.total}
                                        perPage={fetchActividadesGETData.data?.per_page}

                                        formValuesSearch={formValuesSearch}
                                        handleFormChange={handleFormChange}
                                        handleInputChangeSearch={handleInputChangeSearch}

                                        idedificio={idedificio}
                                        fetchActividadesGET={fetchActividadesGET}
                                        fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                                        fetchEdificioGETData={fetchEdificioGETData}

                                        setshowFilter={setshowFilter}
                                        showFilter={showFilter}
                                        tiposIntervencionStaticServices={tiposIntervencionStaticServices}
                                        navLinksFases={navLinksFases}
                                        fetchSuccess={(e: any) => handleSubmitSearch(e, formValuesSearch)}
                                        fetchElementosConstructivosData={fetchElementosConstructivosData}
                                        idCurrentFase={idCurrentFase}
                                        fetchParamsPublicGETData={fetchParamsPublicGETData}
                                        readOnly={readOnly}
                                        handleSubmitDownloadExcel={handleSubmitDownloadExcel}
                                        fetchActividadesBlobGETData={fetchActividadesBlobGETData}
                                        resetfetchActividadesBlobGETData={resetfetchActividadesBlobGETData}
                                    />
                                </section>
                            </Grid>

                    }
                </Grid>
            </div>

            <ModalConfirm
                data={dataModal_ConfirmDescargar}
                handleClose={handleCloseModal_ConfirmDescargar}
                message={<p>{Lang('PARRAFO_GENERANDO_DOCUMENTO_ESPERE')}</p>}
                textOnClickOk={Lang('DESCARGANDO')}
                variantClickOk='contained'
                onClickOK={handleCloseModal_ConfirmDescargar}
                fetchData={fetchActividadesBlobGETData}
            />

            <ModalStatusServer fetchData={fetchActividadesBlobGETData} onlyError />
        </>
    );
};
