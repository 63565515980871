
import { isEmpty, isEqual } from "lodash"

export function validExiste(value: string) {

    return value !== undefined && value !== null && value !== ''
}

export function validIsEqual(value: string, value2: string) {
    
    return isEqual(value, value2)
}

export function validCorrectEmail(value: string = '') {

    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    return value.match(regExp) !== null
}

export function validCorrectMovil(value: string) {

    const regExp = "^[0-9]+$"

    return value.match(regExp) !== null
}

export function validAlMenosUnoMarcado(list: any) {

    return !isEmpty(list)
}

export function validCorrectYear(value: string = '') {

    return value?.length === 4
}

export function validCorrectDNI(value: string) {

    const regExpNIF = "^[0-9]{8}[A-Z]$"

    const regExpNIE = "^[XYZ][0-9]{7}[A-Z]$"

    return value.match(regExpNIF) !== null || value.match(regExpNIE) !== null
}

export function validNIFNIE(nifnie: string = '') {

    return validNIF(nifnie) || validNIE(nifnie)
}

export function validNIFNIECIF(nifniecif: string = '') {

    return validNIF(nifniecif) || validNIE(nifniecif) || validCIF(nifniecif)
}

export function validNIF(nif: string = '') {
    // Expresión regular para validar el formato del NIF
    var formatoValido = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  
    // Verificar el formato del NIF
    if (!formatoValido.test(nif)) {
        return false;
    }
  
    // Extraer el número y la letra del NIF
    var numero = parseInt(nif.slice(0, 8), 10);
    var letra = nif.slice(8).toUpperCase();
  
    // Array con las letras de control
    var letrasControl = 'TRWAGMYFPDXBNJZSQVHLCKE';
  
    // Calcular la letra de control
    var letraCalculada = letrasControl.charAt(numero % 23);
  
    // Verificar si la letra calculada coincide con la letra del NIF
    if (letraCalculada === letra) {
        return true;
    } else {
        return false;
    }
  }

  function validNIE(nie:string = '') {
    // ExpresiÃ³n regular para validar el formato del NIE
    var formatoValido = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

    // Verificar el formato del NIE
    if (!formatoValido.test(nie)) {
        return false;
    }

    // Reemplazar la primera letra por el nÃºmero correspondiente
    var letraInicial = nie.charAt(0).toUpperCase();
    var numeroInicial;
    switch (letraInicial) {
        case 'X':
            numeroInicial = 0;
            break;
        case 'Y':
            numeroInicial = 1;
            break;
        case 'Z':
            numeroInicial = 2;
            break;
        default:
            return false; // Letra inicial incorrecta
    }

    // Extraer el nÃºmero y la letra del NIE
    var numero = parseInt(numeroInicial + nie.slice(1, 8), 10);
    var letra = nie.slice(8).toUpperCase();

    // Array con las letras de control
    var letrasControl = 'TRWAGMYFPDXBNJZSQVHLCKE';

    // Calcular la letra de control
    var letraCalculada = letrasControl.charAt(numero % 23);

    // Verificar si la letra calculada coincide con la letra del NIE
    if (letraCalculada === letra) {
        return true;
    } else {
        return false;
    }
}

function validCIF(cif:string = '') {
    // Comprobar que el CIF tiene 9 caracteres
    if (cif.length !== 9) {
        return false;
    }

    // Extraer la primera letra y los nÃºmeros
    const letra = cif.charAt(0);
    const numeros = cif.substring(1, 8);
    const digitoControl = cif.charAt(8);

    // Comprobar que la primera letra es vÃ¡lida
    const letrasValidas = "ABCDEFGHJKLMNPQRSUVW";
    if (!letrasValidas.includes(letra)) {
        return false;
    }

    // Comprobar que los 7 caracteres siguientes son nÃºmeros
    if (!/^\d{7}$/.test(numeros)) {
        return false;
    }

    // Calcular el dÃ­gito de control
    const digitoCalculado = calcularDigitoControl(letra, numeros);

    // Comprobar que el dÃ­gito de control coincide
    return digitoCalculado === digitoControl;
}


function calcularDigitoControl(letra: any, numeros: any) {
    let sumaPar = 0;
    let sumaImpar = 0;

    for (let i = 0; i < numeros.length; i++) {
        const num = parseInt(numeros.charAt(i), 10);

        // Sumar los nÃºmeros en posiciones pares e impares
        if (i % 2 === 0) {
            let doble = num * 2;
            if (doble > 9) {
                doble -= 9;
            }
            sumaImpar += doble;
        } else {
            sumaPar += num;
        }
    }

    // Calcular la suma total
    const sumaTotal = sumaPar + sumaImpar;

    // Calcular el dÃ­gito de control
    const unidad = sumaTotal % 10;
    const digitoControl = unidad === 0 ? 0 : 10 - unidad;

    // Devolver el dÃ­gito de control como un carÃ¡cter
    const letrasControl = "JABCDEFGHI";
    if ("PQRSW".includes(letra)) {
        return letrasControl[digitoControl];
    } else if ("ABEH".includes(letra)) {
        return String(digitoControl);
    } else {
        return String(digitoControl);
    }
}
