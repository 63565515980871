import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { convertTextErrorServer } from '../../helpers/helpers';

export const InputSelect = ({ label, value, values = [], readOnly, onChange, name, className = '', localValidation = [],
    fetchData = {}, disabled = false, defaultValue, readOnlyMU }: any) => {


    const [validations, setvalidations] = useState<any>({});

    useEffect(() => {

        setvalidations(validated(localValidation))

    }, [...(localValidation?.map((item: any) => item.isOk))]);

    useEffect(() => {

        if (fetchData?.error?.code === 422) {

            const errorsByName = fetchData?.error?.errors?.[name]

            if (errorsByName) {

                setvalidations(validatedServer(errorsByName))
            }
        }

    }, [fetchData]);

    let objInputProps: any = {}

    if (readOnlyMU !== undefined) {
        objInputProps.readOnly = readOnlyMU
    }

    return (
        <FormControl fullWidth variant="standard" className={className}>
            {
                readOnly ? <>
                    <Typography variant='body2' className='mb-1' component="span">
                        {label}
                    </Typography>
                    <Typography variant='body1' className="my-0 pb-1" component="span">
                        {values?.find((item: any) => item.id === value)?.name || '-'}
                    </Typography>
                </> :
                    <>
                        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={value}
                            defaultValue={defaultValue}
                            name={name}
                            disabled={disabled}
                            onChange={onChange}
                            label="Age"
                            error={validations.error}
                            className={`${className} ${readOnlyMU ? 'pe-none' : ''}`}
                        >
                            <MenuItem value="" className='pt-3'>{' '}</MenuItem>
                            {
                                values.map((item: any) => {
                                    return (
                                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        {
                            validations.error &&
                            <FormHelperText error>
                                {
                                    validations?.helperText
                                }
                            </FormHelperText>
                        }
                    </>
            }

        </FormControl>
    )
}

const validated = (localValidation: any) => {

    let localValidationPreFilter = localValidation?.filter((item: any) => item.isOk === false) || []

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{item.msgError}</span>
            }),
            error: true
        }
    }

    return res
}

const validatedServer = (errorsByName: any) => {

    let localValidationPreFilter = errorsByName

    let res = {}

    if (localValidationPreFilter.length > 0) {
        res = {
            helperText: localValidationPreFilter?.map((item: any, index: number) => {
                return <span className='d-block' key={index}>{convertTextErrorServer(item)}</span>
            }),
            error: true
        }
    }

    return res
}