import './TagTopProducto.scss'

export const TagTopProducto = ({ title = '', top = '-35px', right = '0px' }: any) => {
    return (
        <div className="TagTopProducto">

            <span className="TagTopProducto__title text-uppercase" style={{'top': top, 'right': right}}>
                <small>{title}</small>
            </span>

        </div>
    )
};
