export const CondGenSeccion11 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Los Clientes podrán contactar y presentar sus reclamaciones llamando al teléfono de contacto 93 240 20 60 o enviando un email a <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>. El horario de atención al cliente será de Lunes a Viernes de 9:00 a 14:00.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Els Clients podran contactar i presentar les seves reclamacions trucant al telèfon de contacte 93 240 20 60 o enviant un email a. L' horari d' atenció al client serà de Dilluns a Divendres de 9: 00 a 14: 00.
      </p>
    </div>
  )
}