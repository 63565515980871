import { useEffect } from "react";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { Box, Typography } from "@mui/material";
import { useLang } from "../../../language";
import { InputText } from "../../../components/Inputs";
import { validExiste } from "../../../helpers/validations";
import { useValidator } from "../../../hooks/useValidator";
import { useForm } from "../../../hooks/useForm";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";
import { AuthServices } from "../../../controllers/auth/auth.services";
import { ModalStatusServer } from "../../../components/Modals/ModalStatusServer";

export const ModalRecuperarContrasenia = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const [fetchAuthRecuperarContraseniaPOST, fetchAuthRecuperarContraseniaPOSTData] = AuthServices.POST_RECUPERAR_CONTRASENIA()

    const [formRecuperarContrasenia, handleInputRecuperarContrasenia, handleFormRecuperarContrasenia, resetFormRecuperarContrasenia] = useForm()

    const [validRecuperarContrasenia, handleValidationRecuperarContrasenia, resetvalidRecuperarContrasenia] = useValidator({
        nif: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const handleSubmitRecuperarContrasenia = () => {

        if (handleValidationRecuperarContrasenia(formRecuperarContrasenia)) {

            fetchAuthRecuperarContraseniaPOST({
                body: formRecuperarContrasenia
            })
        }

    }

    useEffect(() => {

        if (data.value) {

        } else {
            resetFormRecuperarContrasenia()
            resetvalidRecuperarContrasenia()
        }

    }, [data.value]);


    return (
        <>

            <ModalAdvanced data={data} handleClose={handleClose}>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {Lang('REESTABLECER_CONTRASENIA')}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <Typography variant="body1" component="h2">
                        {Lang('P_PARA_REESTABLECER_CONTRASENIA')}
                    </Typography>

                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >

                        <InputText
                            label={Lang('NIF')}
                            name='nif'
                            value={formRecuperarContrasenia.nif}
                            onChange={(e: any) => handleInputRecuperarContrasenia({
                                target: {
                                    name: 'nif',
                                    value: (e.target.value).toUpperCase()
                                }
                            })}
                            localValidation={validRecuperarContrasenia.nif}
                            className='mt-2 w-100'
                            fetchData={fetchAuthRecuperarContraseniaPOSTData}
                        />

                    </Box>

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="contained" type="submit" className="mt-3 w-100"
                        handleSubmit={handleSubmitRecuperarContrasenia}
                        loadings={[fetchAuthRecuperarContraseniaPOSTData.loading]}
                        value={Lang('REESTABLECER_CONTRASENIA')}
                    />


                </ModalAdvanced.Footer>

                <Typography variant="body2" component="h2" className="mt-4 text-center">
                    {Lang('P_SI_ES_COLEGIADO_CATEB')} <a href="https://caateebcn.my.site.com/SiteLogin" target="_blank" rel="noreferrer">{Lang('EL_SIGUIENTE_ENLACE')}.</a> 
                </Typography>


            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchAuthRecuperarContraseniaPOSTData} />

        </>
    );
};
