import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useLang } from '../../../language';
import LayoutLibro from '../../../components/Layouts/LayoutLibro/LayoutLibro';
import { DropdownAdvanced } from '../../../components/DropdownAdvanced/DropdownAdvanced';
import { isAdministrador, isAdministradorFincas, isGestorMantenimiento, isJuntaComunidadPropietarios, isStatusSuccess } from '../../../helpers/helpers';
import { ProyectosServices } from './Proyectos/controllers/proyectos.services';
import { ModalProyectosFases } from './ModalProyectosFases';
import { useModalData } from '../../../hooks/useModalData';
import ProgGestion from '../pages/ProgGestion/ProgGestion';
import { EdificioServices } from '../../../controllers/edificios/edificios.services';
import { LoaderFormPrimary } from '../layout/LoaderFormPrimary';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export default function ProgramacionGestion() {

    const Lang = useLang()

    const { id }: any = useParams()

    const params: any  = useParams()
    
    const navigate = useNavigate()

    const [objectList, setobjectList] = useState<any>({});

    const [dataModal_Proyectos, handleshowModal_Proyectos, handleCloseModal_Proyectos] = useModalData()

    const [fetchProyectosGET, fetchProyectosGETData] = ProyectosServices.GET()

    const [fetchEdificioGET, fetchEdificioGETData] = EdificioServices.GET(id)

    useEffect(() => {

        if (id) {

            fetchProyectosGET({
                params: {
                    edificioid: id,
                    limit: 50
                }
            })
        }

    }, [id]);

    const handleChangeProject = (item: any, readOnly: boolean) => {

        localStorage.setItem((process.env.REACT_APP_MAIN_VARIABLE + '_ID_CURRENT_PROJECT') || '', JSON.stringify(item?.id || '{}'))

        navigate(`./${item?.id}`)

        handleUpdateDropdownProject(item?.id, readOnly)
    }

    useEffect(() => {

        if (isStatusSuccess(fetchProyectosGETData.status) && isStatusSuccess(fetchEdificioGETData.status)) {

            const idProyecto = params['*']?.split('/')?.[0]

            const readOnly = !(isGestorMantenimiento(fetchEdificioGETData?.data?.perfil_llibre) || isAdministrador(auth?.user?.user?.role))


            handleUpdateDropdownProject(idProyecto, readOnly)
        }

    }, [fetchProyectosGETData, params, fetchEdificioGETData]);

    const handleUpdateDropdownProject = (id: any, readOnly: boolean) => {

        const listProjects = fetchProyectosGETData.data?.items || []

        // const idCurrentProject = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_ID_CURRENT_PROJECT')
        
        const idCurrentProject = id

        const nameCurrentProject = listProjects?.find((item: any) =>
            String(item.id) === String(idCurrentProject))?.nom || listProjects?.[0]?.nom

        let objTemp = {
            title: <span className='me-1'>{nameCurrentProject}</span>,
            list: listProjects?.map((item: any) => {
                return {
                    content: item.nom,
                    action: () => handleChangeProject(item, readOnly)
                }
            })
        }

        if (!readOnly) {

            objTemp.list.push(
                {
                    content: <div className='text-center w-100 color-primary'>
                        <FontAwesomeIcon icon={faEdit} className='me-1' /> {Lang('PERIODOS_Y_FASES')}
                    </div>,
                    action: () => handleshowModal_Proyectos()
                }
            )
        }

        setobjectList(objTemp)
    }


    useEffect(() => {

        fetchEdificioGET()

    }, []);

    const { auth } = useSelector((state: RootState) => state.auth)

    useEffect(() => {

        if (isStatusSuccess(fetchEdificioGETData.status)) {

            const misRolesEdificio = fetchEdificioGETData?.data?.perfil_llibre

            if (!(isAdministrador(auth?.user?.user?.role) ||
                (isGestorMantenimiento(misRolesEdificio) || isAdministradorFincas(misRolesEdificio) || isJuntaComunidadPropietarios(misRolesEdificio)))) {

                navigate(`/edificios/${id}`)
            }
        }

    }, [fetchEdificioGETData]);


    return (
        <section className='LayoutLibro animate__animated animate__fadeIn'>

            {
                fetchEdificioGETData.loading ?
                    <div className="p-3">
                        <LoaderFormPrimary />
                    </div>
                    :
                    <LayoutLibro>

                        <LayoutLibro.NavTop auth={auth} />

                        <LayoutLibro.NavTop2
                            // navLinks={navLinksTop}
                            titleStart={
                                <>
                                    <FontAwesomeIcon icon={faCalendar} className='me-2' /> {Lang('PROGRAMACION_GESTION')}
                                </>
                            }
                            componentLeft={
                                <DropdownAdvanced
                                    objectList={objectList}
                                    lastDivider={isGestorMantenimiento(fetchEdificioGETData?.data?.perfil_llibre) || isAdministrador(auth?.user?.user?.role)}
                                />
                            }
                        />

                        <LayoutLibro.Body>

                            <Routes>
                                <Route
                                    path={`/*`}
                                    element={
                                        <ProgGestion
                                            // idCurrentProject={idCurrentProject}
                                            fetchProyectosGETData={fetchProyectosGETData}
                                            readOnly={!(isGestorMantenimiento(fetchEdificioGETData?.data?.perfil_llibre) || isAdministrador(auth?.user?.user?.role))}
                                        />
                                    }
                                />

                            </Routes>

                        </LayoutLibro.Body>

                        <LayoutLibro.Footer />

                    </LayoutLibro>
            }

            <ModalProyectosFases
                data={dataModal_Proyectos}
                handleClose={handleCloseModal_Proyectos}
                idedificio={id}
            />

        </section>
    )
}