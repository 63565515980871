export const CondParSeccion61 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        El periodo de duración del contrato para la aplicación del “LED redacción” de se inicia con la compra del producto que tendrá que redactarse y cerrarse dentro de los 12 meses inmediatamente posteriores a la compra. Una vez cerrado el libro i transcurridos los 6 meses previstos en la condición 2.2 para las modificaciones del libro, el contrato se dará por finalizado.
      </p>
      <p>
        En el caso de la aplicación de gestión la duración de la suscripción es anual prorrogable tácitamente en caso de que el pago sea domiciliado. En el caso de pago por tarjeta, la renovación del contrato tendrá que hacerse de forma expresa.
      </p>
      <p>
        En todo caso, el CATEB se reserva el derecho a suspender el servicio con carácter definitivo, con un aviso previo de 2 meses, durante los cuales el cliente podrá descargar su información.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        El període de vigència del contracte per l’aplicació “Led redacció” s' inicia amb la compra del producte que, caldrà redactar i  tancar dins dels 12 mesos posteriors a la compra. Un cop tancat el llibre i transcorreguts els 6 mesos previstos a la condició 2,.2 per fer les modificacions del llibre, el contracte es donarà per finalitzat.
      </p>
      <p>
        En el cas de l' aplicació de gestió i manteniment la durada de la subscripció és anual prorrogable tàcitament sempre que  el pagament sigui domiciliat. En el cas de pagament per targeta, la renovació del contracte haurà de fer-se de forma expressa.
      </p>
      <p>
        En tot cas, el CATEB es reserva el dret a suspendre el servei amb caràcter definitiu, amb un avís previ de 2 mesos, durant els quals el client podrà descarregar la seva informació.
      </p>
    </div>
  )
}