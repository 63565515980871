import { useEffect } from "react";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { clearEmptyKeys, getValuesParams, isFetchDataInitLoader, setUrlByParams } from "../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../hooks/useForm";
import queryString from 'query-string';
import { useLocation } from "react-router";
import { TableMedidas } from "./TableMedidas";
import { MedidasServices, PotencialMejoraItemAdminServices } from "./controllers/medidas.services";
import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ModalInfoAspecto } from "./ModalInfoAspecto";
import { LoaderModalFormPrimary } from "../../../../../../../components/Loaders/LoaderModalFormPrimary";
import { useLang } from "../../../../../../../language";

export const Medidas = ({ idEdificio, fetchParamsPublicGETData, idPM, isAhorroEnergia }: any) => {


    const [fetchMedidasGET, fetchMedidasGETData] = MedidasServices.GET()

    // --------------------- Modal Nuevo edificio ---------------------

    const [dataModal_InfoAspecto, handleShowModal_InfoAspecto, handleCloseModal_InfoAspecto] = useModalData()

    // Buscador de agentes

    const location = useLocation()

    const Lang = useLang()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '100',
            edifici_id: idEdificio,
            order: '',
            tipo_agente: '',
            elemento: '',
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        elemento: allValuesSearch.elemento,
        tipo_agente: params.tipo_agente || allValuesSearch.tipo_agente,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'elemento')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMedidasGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        if (formValuesSearch.elemento) {

            const urlParams = getValuesParams(formValuesSearch)

            setUrlByParams(urlParams, 'limit', 'edifici_id', 'elemento')

            fetchMedidasGET({
                params: {
                    ...urlParams
                }
            })

        }

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit, formValuesSearch.elemento])

    useEffect(() => {

        handleFormChange({
            ...formValuesSearch,
            elemento: idPM,
            page: 1,
        })

        fetchElemConstGET({
            id: idPM
        })

    }, [idPM]);

    const handleFetchSuccess = () => {
        handleSubmitSearch(null, formValuesSearch)
    }

    const { language } = useSelector((state: RootState) => state.language)

    const [fetchElemConstGET, fetchElemConstGETData] = PotencialMejoraItemAdminServices.GET()

    return (
        <>
            {
                (isFetchDataInitLoader(fetchElemConstGETData) || isFetchDataInitLoader(fetchMedidasGETData)) ?
                    <LoaderModalFormPrimary />
                    :
                    <>
                        <section>
                            {
                                fetchElemConstGETData?.data &&
                                <>
                                    <Typography variant='h6' className={`m-0 p-0 fw-bolder d-flex align-items-center`}>
                                        <span>
                                            {fetchElemConstGETData?.data?.textos?.[String(language).toLowerCase()]?.[0]?.texto}
                                        </span>
                                        {
                                            (
                                                fetchElemConstGETData?.data?.textos?.[String(language).toLowerCase()]?.[0]?.descripcion ||
                                                fetchElemConstGETData?.data?.fichas?.[String(language).toLowerCase()]?.[0] ||
                                                fetchElemConstGETData?.data?.links?.[String(language).toLowerCase()]?.[0]
                                            ) && 
                                            <Button color='secondary' variant="contained" size="small" className="ms-3" onClick={handleShowModal_InfoAspecto}>
                                                <FontAwesomeIcon icon={faFileAlt} className="me-2"/> {Lang('FICHA')} CTE
                                            </Button>
                                        }
                                    </Typography>

                                    <ModalInfoAspecto
                                        data={dataModal_InfoAspecto}
                                        handleClose={handleCloseModal_InfoAspecto}
                                        fetchElemConstGETData={fetchElemConstGETData}
                                        locale={String(language).toLowerCase()}
                                    />
                                </>
                            }

                        </section>

                        <section className="mb-5">
                            <TableMedidas
                                data={fetchMedidasGETData.data?.items}
                                loading={fetchMedidasGETData.loading}
                                total={fetchMedidasGETData.data?.total}
                                perPage={fetchMedidasGETData.data?.per_page}

                                formValuesSearch={formValuesSearch}
                                handleFormChange={handleFormChange}
                                handleInputChangeSearch={handleInputChangeSearch}

                                idEdificio={idEdificio}
                                fetchMedidasGET={fetchMedidasGET}
                                elemento={idPM}
                                fetchSuccess={handleFetchSuccess}
                                fetchParamsPublicGETData={fetchParamsPublicGETData}
                                isAhorroEnergia={isAhorroEnergia}
                            />

                        </section>
                    </>
            }
        </>
    );
};
