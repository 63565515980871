const LINKS = {
    LINK_TEST_ENERGETICO: 'http://www.testenergetico.es',
    LINK_MARKETCONS: 'http://www.marketcons.es',
    LINK_CONSTRULEX: 'http://www.construlex.es',
    LINK_CONSTRUDOC: 'http://www.construdoc.es',
    LINK_TEST_ALUMINOSIS: 'http://www.testaluminoso.es',
    LINK_TEST_RADON: 'http://www.testradon.es',

}

export default LINKS;