import { useState } from 'react'
import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useModalData } from '../../../../../../../hooks/useModalData'
import { isEmpty, orderBy } from 'lodash'
import axios from 'axios'
import { getDataFile, isFetchDataInitLoader } from '../../../../../../../helpers/helpers'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'

export const ModalInfoAspecto = ({ data = {}, handleClose, fetchElemConstGETData, locale }: any) => {

    const Lang = useLang()

    const [dataModal_IframePDF, handleShowModal_IframePDF, handleCloseModal_IframePDF] = useModalData()

    const [fetchDocumentoData, setFetchDocumentoData] = useState<any>({ data: null, loading: null, error: null, status: null });

    const handleClickLinkPDF = (e: any, url: string, type: string) => {

        e.preventDefault()

        setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

        let config: any = { responseType: 'blob' };

        axios.get(url, config).then((response: any) => {

            setFetchDocumentoData({
                loading: false,
                error: null,
                data: response,
                status: response,
            });
        });
    }

    useEffect(() => {

        if (dataModal_IframePDF.value) {

        } else {
            setFetchDocumentoData({ data: null, loading: true, error: null, status: null })
        }

    }, [dataModal_IframePDF.value]);

    useEffect(() => {

        if (fetchDocumentoData.data) {

            let ext = fetchDocumentoData.data?.headers?.['content-disposition']?.split('filename=')?.[1]?.split('.')

            let extension = ext[ext.length - 1]
            

            if (extension.indexOf('pdf') !== -1) {

                handleShowModal_IframePDF()

            } else {

                let filename = fetchDocumentoData.data?.headers?.['content-disposition']?.split('filename=')?.[1]

                getDataFile(fetchDocumentoData,
                    () => setFetchDocumentoData({ data: null, loading: true, error: null, status: null })
                    , filename, '.' + extension)
            }
        }

    }, [fetchDocumentoData]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='lg'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {fetchElemConstGETData?.data?.textos?.[locale]?.[0]?.texto}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <div className="mb-3">

                        <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(fetchElemConstGETData?.data?.textos?.[locale]?.[0]?.descripcion || '') }}></span>
                    </div>

                    <div>
                        {
                            !isEmpty(fetchElemConstGETData?.data?.fichas?.[locale]) &&
                            <>
                                {
                                    orderBy(fetchElemConstGETData?.data?.fichas?.[locale], ['titulo'], ['asc'])?.map((item: any) => {
                                        return (
                                            <a className="ms-2 cursor-pointer mb-3 d-block color-secondary text-decoration-none hover-text-decoration"
                                                onClick={(e) => handleClickLinkPDF(e, item.url, 'ficha')} href='.' target='_blank' rel="noreferrer">
                                                <FontAwesomeIcon icon={faFileAlt} className='me-2' /> {item.titulo}
                                            </a>
                                        )
                                    })
                                }
                            </>
                        }

                    </div>
                    {
                        !isEmpty(fetchElemConstGETData?.data?.links?.[locale]) &&
                        <div>
                            <div className="mb-3">
                                {Lang('ENLACES_EXTERNOS')}:
                            </div>
                            {
                                orderBy(fetchElemConstGETData?.data?.links?.[locale], ['titulo'], ['asc'])?.map((item: any) => {
                                    return (
                                        <a className="ms-2 cursor-pointer mb-3 d-block color-secondary text-decoration-none hover-text-decoration"
                                            href={item.link} target='_blank' rel="noreferrer">
                                            <FontAwesomeIcon icon={faExternalLink} className='me-2' /> {item.titulo}
                                        </a>
                                    )
                                })
                            }
                        </div>
                    }


                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>


                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalAdvanced data={dataModal_IframePDF} handleClose={handleCloseModal_IframePDF} size='lg'>

                <ModalAdvanced.Head>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        isFetchDataInitLoader(fetchDocumentoData) ?
                            <LoaderModalFormPrimary /> :

                            // fetchDocumentoData?.data?.data &&
                            <iframe id='blob' className="border-0 min-vh-85 w-100" src={window.URL.createObjectURL(fetchDocumentoData?.data?.data) + '#toolbar=0&navpanes=0&scrollbar=0'}></iframe>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>


                </ModalAdvanced.Footer>

            </ModalAdvanced>
        </>
    )
}