import { useEffect, useState } from 'react'
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { cloneDeep, isEmpty } from 'lodash';
import './InicioOnlyReadSistemas.scss'
import { ElementosConstructivosServices } from '../../../controllers/elemconst.services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary';
import { useLang } from '../../../../../../../language';

export const InicioOnlyReadSistemas = ({ id, idedificio, setfirstLoad }: any) => {

    const Lang = useLang()

    const [tree, settree] = useState<any>({});

    const [treeElement, settreeElement] = useState<any>(null);

    const [fetchMisElemsConst, fetchMisElemsConstData] = ElementosConstructivosServices.GET_BY_EDIFICIO()

    useEffect(() => {

        setfirstLoad(false)

        fetchMisElemsConst({
            id: idedificio + '/edificio',
            params: {
                padre_id: id,
                arbol: true,
                mostrar_todos: false,
            }
        })

    }, [id]);


    useEffect(() => {

        if (fetchMisElemsConstData?.data) {

            const treeRootTemp = cloneDeep(fetchMisElemsConstData)

            const treeRoot = treeRootTemp?.data?.find((item: any) => item.id === id)

            if (treeRoot) {

                handleRecorrerArbol(treeRoot, '')

                settree(treeRoot)
            }

        }
    }, [fetchMisElemsConstData]);

    const handleRecorrerArbol = (nodo: any, path: string) => {

        nodo.name = nodo.texto
        nodo.allowChildren = nodo.admite_hijos
        nodo.children = nodo.hijos
        nodo.idOriginal = nodo.id
        nodo.id = nodo.id + '|' + path
        
        if (Array.isArray(nodo.children)) {
            nodo.children?.map((item: any, index: number) => handleRecorrerArbol(item, path + '_' + index))
        }
    }

    const createTreeItem = (item: any) => {

        return (
            <div>
                {
                    item?.map((item2: any) => {

                        return (
                            <div className={`InicioOnlyReadSistemas__item mb-3 ms-2 ${item2.es_seleccionable ? 'd-block' : ''}`}>
                                <div className={`InicioOnlyReadSistemas__head mb-2 ${item2.padre_id === Number(id) ? 'border-bottom' : ''} `}>
                                    {
                                        (item2.es_seleccionable || item2.padre_id === 533) ?
                                            <>
                                                {
                                                    (item2.valor === '1' || item2.padre_id === 533) &&
                                                    <>

                                                        <div className='mx-3'>
                                                            <FontAwesomeIcon icon={faCheckSquare} className='me-2' /> {item2.texto}
                                                        </div>

                                                        {
                                                            item2.metricas?.map((item3: any) => {

                                                                return (
                                                                    <>
                                                                        {
                                                                            item3.valor &&
                                                                            <div className='ms-5 my-2'>
                                                                                <Typography variant='subtitle1' component='span' className={`m-0 p-0 ${item2.padre_id === Number(id) ? 'fw-bold mb-1' : ''}`}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item3.texto) }}></span>
                                                                                </Typography>:
                                                                                <span className='ms-2'>{item3.valor}</span>
                                                                            </div>
                                                                        }
                                                                    </>

                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <Typography variant='subtitle1' className={`m-0 p-0 ${item2.padre_id === Number(id) ? 'fw-bold mb-2' : ''}`}>
                                                {item2.texto}
                                            </Typography>
                                    }
                                </div>
                                <div className='InicioOnlyReadSistemas__body mb-1'>
                                    {createTreeItem(item2.children)}
                                </div>
                                {
                                    !isEmpty(item2.otros_elementos) &&
                                    <div className='ms-3'>
                                        <Typography variant='subtitle1' className={`m-0 p-0 ${item2.padre_id === Number(id) ? 'fw-bold mb-0' : ''}`}>
                                            {Lang('OTROS_ELEMENTOS')}
                                        </Typography>
                                        <ul className='mb-0 mt-1'>
                                            {
                                                item2.otros_elementos?.map((item3: any) => {
                                                    return (
                                                        <li className='mb-2'>{item3.nom_element}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    useEffect(() => {

        if (!isEmpty(tree)) {

            settreeElement(createTreeItem(tree.children))
        }

    }, [tree]);

    return (
        <Card>

            <CardContent>

                <Grid container spacing={2} columnSpacing={3} rowSpacing={0} className=''>
                    {

                        fetchMisElemsConstData.loading ?
                            <Grid item xs={12}>
                                <LoaderModalFormPrimary />
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='h6' className='m-0 p-0 fw-bolder'>
                                        {tree.texto}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className='InicioOnlyReadSistemas'>
                                        <div>{treeElement}</div>
                                        {
                                            !isEmpty(tree.otros_elementos) &&
                                            <div className='ms-1'>
                                                <Typography variant='subtitle1' className={`m-0 p-0 fw-bold`}>
                                                    {Lang('OTROS_ELEMENTOS')}
                                                </Typography>
                                                <ul className='mb-0 mt-1'>
                                                    {
                                                        tree.otros_elementos?.map((item3: any) => {
                                                            return (
                                                                <li className='mb-2'>{item3.nom_element}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </>
                    }

                </Grid>

            </CardContent>

        </Card>
    );
};
