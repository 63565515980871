import { useAxios } from "../../../../../hooks/useAxios"
import { ProyectoAdapters, ProyectosAdapters } from "./proyectos.adapters"

const ProyectosServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/proyectos',
            type: 'json',
            adapter: {
                method: ProyectosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const ProyectoServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/proyectos`,
            type: 'json',
            adapter: {
                method: ProyectoAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio_proyectos/proyectos',
            type: 'json',
            adapter: {
                method: ProyectoAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/proyectos',
            type: 'json',
            adapter: {
                method: ProyectoAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_RESUMEN_FINANCIERO: function (idProject: string) {

        return useAxios({
            method: 'PATCH',
            url: `/edificio_proyectos/proyectos/${idProject}`,
            type: 'json',
            adapter: {
                method: ProyectoAdapters.PATCH_RESUMEN_FINANCIERO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/proyectos',
            type: 'json',
            adapter: {
                method: ProyectoAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

}

export {
    ProyectosServices,
    ProyectoServices
}