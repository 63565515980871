export const CondGenSeccion4 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Las características de los productos, descripción técnica y funcionalidades se encuentran a disposición de los clientes en  <a href="http://www.libroedificiodigital.es" target="_blank" rel="noreferrer" className="link-primary">www.libroedificiodigital.es</a>.
      </p>
      <p>
        CATEB se reserva el derecho a realizar modificaciones en su catálogo de productos respetando en todo momento las normas que resulten de aplicación.
      </p>
      <p>
        CATEB pone a disposición del Usuario y/o Cliente los siguientes productos y servicios:
      </p>
      <ul>
        <li>
          Aplicaciones informáticas mediante pago único o suscripción.
        </li>
      </ul>

      <p>
        En nuestra web el Usuario y/o Cliente podrá encontrar las características y condiciones de cada producto y/o servicio en las que se le informará de las modalidades de acceso, coste, duración, contenidos, requisitos técnicos o compatibilidad con otros softwares y más información que puede resultar de interés para el Usuario y/o Cliente.
      </p>

      <p>
        Es posible que algunas de nuestras aplicaciones requieran autenticación para utilizarlas, correspondiéndose con las credenciales del alta como usuario. Adicionalmente, algunas de nuestras aplicaciones solicitan la introducción de otros datos personales -propios o de terceros- para prestar las funcionalidades requeridas. Usted tendrá que tener permiso de estos terceros para introducir sus datos.
      </p>
      <p>
        <strong>4.1. Requisitos técnicos de los productos y servicios digitales</strong>
      </p>
      <p>
        El Cliente deberá disponer de un programa informático compatible con la lectura y descarga de documentos con extensión .pdf (Adobe reader, Adobe profesional o similares), disponiendo de la información necesaria en las condiciones particulares y descripción de cada producto.
      </p>
      <p>
        El Cliente será responsable de disponer de los requisitos técnicos aquí expresados, no constituyendo causa de falta de conformidad.
      </p>
      <p>
        <strong>4.2. Actualizaciones de los productos digitales de pago</strong>
      </p>
      <p>
        CATEB podrá realizar actualizaciones de conformidad de sus productos digitales, incluidas las de seguridad, para mantener la conformidad de los productos, y las de contenido, incluyendo los nuevos contenidos normativos publicados. Las actualizaciones de conformidad y de contenidos permanecerán vigentes siempre que la aplicación informática esté publicada y en funcionamiento.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Les característiques dels productes, descripció tècnica i funcionalitats es troben a disposició dels clients en <a href="http://www.llibreedificidigital.cat" target="_blank" rel="noreferrer" className="link-primary">www.llibreedificidigital.cat</a>.
      </p>
      <p>
        CATEB es reserva el dret a realitzar modificacions en el seu catàleg de productes respectant en tot moment les normes que resultin d' aplicació.
      </p>
      <p>
        CATEB posa a disposició de l'Usuari i/o Client els següents productes i serveis:
      </p>
      <ul>
        <li>
          Aplicacions informàtiques mitjançant pagament únic o subscripció.
        </li>
      </ul>

      <p>
        A la nostra web l'Usuari i/o Client podrà trobar les característiques i condicions de cada producte i/o servei en les quals se l'informarà de les modalitats d'accés, cost, durada, continguts, requisits tècnics o compatibilitat amb altres programaris i més informació que pot resultar d'interès per a l'Usuari i/o Client.
      </p>

      <p>
        És possible que algunes de les nostres aplicacions requereixin autenticació per utilitzar-les, corresponent-se amb les credencials de l' alta com a usuari. Addicionalment, algunes de les nostres aplicacions demanen la introducció d'altres dades personals -pròpies o de tercers- per prestar les funcionalitats requerides. Vostè haurà de tenir permís d'aquests tercers per introduir les seves dades.
      </p>
      <p>
        <strong>4.1. Requisits tècnics dels productes i serveis digitals</strong>
      </p>
      <p>
        El Client haurà de disposar d'un programa informàtic compatible amb la lectura i descàrrega de documents amb extensió .pdf (Adobe reader, Adobe professional o similars), disposant de la informació necessària en les condicions particulars i descripció de cada producte.
      </p>
      <p>
        El Client serà responsable de disposar dels requisits tècnics aquí expressats, no constituint causa de falta de conformitat.
      </p>
      <p>
        <strong>4.2. Actualitzacions dels productes digitals de pagament</strong>
      </p>
      <p>
        CATEB podrà realitzar actualitzacions de conformitat dels seus productes digitals, incloses les de seguretat, per mantenir la conformitat dels productes, i les de contingut, incloent-hi els nous continguts normatius publicats. Les actualitzacions de conformitat i de continguts romandran vigents sempre que l' aplicació informàtica estigui publicada i en funcionament.
      </p>
    </div>
  )
}