import React, { useEffect, useState } from "react";
import { MedidasServices } from "../Medidas/controllers/medidas.services";
import { TableMedidas } from "../Medidas/TableMedidas";
import { clearEmptyKeys, getValuesParams, isStatusSuccess, setUrlByParams } from "../../../../../../../helpers/helpers";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useForm } from "../../../../../../../hooks/useForm";
// import { TableMedidasAll } from "../Medidas/TableMedidasAll";
import { Typography } from "@mui/material";
import { useLang } from "../../../../../../../language";
import { groupBy } from "lodash";

export const ReadOnlyPotencialMejoras = ({ idEdificio, fetchParamsPublicGETData, idPM, tree }: any) => {

    const [fetchMedidasGET, fetchMedidasGETData] = MedidasServices.GET()

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '800',
            order: '',
            edifici_id: idEdificio,
            elemento: '',
            idnivelprincipal: '',
            idfase: '',
            // seleccionado: 'true',
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        elemento: params.elemento || allValuesSearch.elemento,
        idnivelprincipal: params.idnivelprincipal || allValuesSearch.idnivelprincipal,
        idfase: params.idfase || allValuesSearch.idfase,
        // seleccionado: params.seleccionado || allValuesSearch.seleccionado,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'padre_id', 'seleccionado', 'edifici_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchMedidasGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'padre_id', 'seleccionado', 'edifici_id')

        fetchMedidasGET({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const Lang = useLang()


    const [datagrouped, setdatagrouped] = useState<any>({});

    useEffect(() => {

        if (isStatusSuccess(fetchMedidasGETData.status)) {

            const data = fetchMedidasGETData.data?.items

            let dataTemp: any = groupBy(data, 'nivel_principal')

            for (const key in dataTemp) {

                dataTemp[key] = groupBy(dataTemp[key], 'exigencia')

                for (const key2 in dataTemp[key]) {

                    dataTemp[key][key2] = groupBy(dataTemp[key][key2], 'elemento')
                }
            }

            setdatagrouped(dataTemp)
        }

    }, [fetchMedidasGETData]);

    return (
        <>
            <div className="text-center">
                <Typography variant='h6' className={`m-0 p-0 fw-bolder `}>
                    {Lang('RESUMEN_DE_MEDIDAS')}
                    <small className="d-block fw-normal fs-085">{Lang('PARRAFO_NAVEGUE_ARBOL_SELECCIONE')}</small>
                </Typography>
            </div>

            {
                // Object.keys(datagrouped)?.map((key: any) => {
                tree?.children?.map((i1: any) => i1.idOriginal)?.map((key: any, index: number) => {

                    if (!datagrouped[key]) return null

                    const keyChildren1 = Object.keys(datagrouped[key])[0]
                    const keyChildren2 = Object.keys(datagrouped[key][keyChildren1])[0]

                    return (
                        <div>
                            <h3 className="fw-bold border-bottom pb-2 mt-4">
                                {datagrouped[key][keyChildren1][keyChildren2]?.[0]?.nivel_principal_texto}
                            </h3>
                            {
                                Object.keys(datagrouped[key])?.map((key2: any) => {

                                    // if (!datagrouped[key][key2]) return null

                                    return (
                                        <div>
                                            {
                                                Object.keys(datagrouped[key][key2])?.map((key3: any) => {

                                                    // if (!datagrouped[key][key2][key3]) return null

                                                    return (
                                                        <>

                                                            <TableMedidas
                                                                data={datagrouped[key][key2][key3]}
                                                                loading={fetchMedidasGETData.loading}
                                                                total={fetchMedidasGETData.data?.total}
                                                                perPage={fetchMedidasGETData.data?.per_page}

                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                handleInputChangeSearch={handleInputChangeSearch}

                                                                idEdificio={idEdificio}
                                                                fetchMedidasGET={fetchMedidasGET}
                                                                elemento={idPM}
                                                                fetchSuccess={() => handleSubmitSearch(null, formValuesSearch)}
                                                                fetchParamsPublicGETData={fetchParamsPublicGETData}
                                                                isAhorroEnergia={Number(key) === 7}
                                                                title={
                                                                    <>
                                                                        <span className="fw-normal">
                                                                            {datagrouped[key][key2][key3]?.[0]?.exigencia_texto} /
                                                                        </span> <span>{datagrouped[key][key2][key3][0]?.elemento_texto}</span>

                                                                    </>
                                                                }
                                                                disabledCreate
                                                                disabledFooter

                                                            />
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }

        </>
    )
};
