import { useEffect, useState } from "react";
import { useLang } from "../../../../../../../language";
import { Grid } from "@mui/material";
import { clearEmptyKeys, getValuesParams, isChecked } from "../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../hooks/useForm";
import { TableMejora } from "./TableMejora";
import FilterAdvanced from "../../../../../../../components/FilterAdvanced/FilterAdvanced";
import { FormSimple } from "./Filters/FormSimple";
import { ImportarActividadesServices } from "../controllers/importar-actividades.services";
import { MejorasNivelPrincipalServices } from "../../../../../../libro/pages/LibroEdificio/PotencialMejoraPlanActuaciones/PlanActuacion/controllers/mantpreventivo.services";
import { MaestrosServices } from "../../../../../../../controllers/maestros/maestros.services";

export const ImportarMejora = ({ auth, idedificio, fetchEdificioGETData, idCurrentFase, navLinksFases,
    fetchParamsMisSistemasGETData, fetchElementosConstructivosData }: any) => {

    const Lang = useLang()

    const [fetchImportarMejorasGET, fetchImportarMejorasGETData] = ImportarActividadesServices.GET_MEJORAS()

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            edifici_id: idedificio,
            order: '',
            tipusintervencio: '',
            faseid: '',
            data_inici_desde: '',
            data_inici_hasta: '',
            elemento_constructivo_id: '',
            excluir_importadas: ['excluir_importadas'],
            urgente: '',
            complejidad: '',
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        ...allValuesSearch,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        urlParams.excluir_importadas = isChecked(urlParams.excluir_importadas)

        // setUrlByParams(urlParams, 'limit', 'tipusintervencio', 'faseid', 'edifici_id', 'elemento_constructivo_id', 'magic')

        if (urlParams.page !== 1) {

            handleFormChange({
                ...urlParams,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(urlParams)

            fetchImportarMejorasGET({
                // id: idedificio,
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams: any = getValuesParams(formValuesSearch)

        urlParams.excluir_importadas = isChecked(urlParams.excluir_importadas)

        // setUrlByParams(urlParams, 'limit', 'tipusintervencio', 'faseid', 'edifici_id', 'elemento_constructivo_id', 'magic')

        fetchImportarMejorasGET({
            // id: idedificio,
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const [showFilter, setshowFilter] = useState(true);

    const [fetchMejoraNivelPrincipal, fetchMejoraNivelPrincipalData] = MejorasNivelPrincipalServices.GET()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    useEffect(() => {

        fetchMejoraNivelPrincipal({
            id: idedificio,
        })

        fetchParamsPublicGET({
            body: [
                {
                    parametroPadre: "tipus_cost_milloras",
                },
                {
                    parametroPadre: "tipus_dificultat_milloras",
                },
            ]
        })

    }, []);


    return (
        <>
            <div className="">

                <Grid container>

                    <Grid item xs={12} className='py-0 py-lg-0'>

                        {
                            showFilter &&
                            <div className="my-3 animate__animated animate__fadeIn animate__faster">

                                <FilterAdvanced
                                    fetchData={fetchImportarMejorasGETData}
                                    handleSubmitSearch={handleSubmitSearch}
                                    formValuesSearch={formValuesSearch}
                                    handleFormChange={handleFormChange}
                                    handleInputChangeSearch={handleInputChangeSearch}
                                    resetformValuesSearch={resetformValuesSearch}
                                    valuesDefault={valuesSearch.default}
                                    valuesSimple={valuesSearch.onlySimple}
                                    valuesExpanded={valuesSearch.onlyExpanded}
                                >

                                    <FilterAdvanced.Simple label={Lang('BUSCADOR')}>

                                        <FormSimple
                                            fetchMejoraNivelPrincipalData={fetchMejoraNivelPrincipalData}
                                            fetchParamsPublicGETData={fetchParamsPublicGETData}
                                        />

                                    </FilterAdvanced.Simple>

                                    <FilterAdvanced.Expanded></FilterAdvanced.Expanded>

                                </FilterAdvanced>
                            </div>
                        }

                        <section className="mb-3">
                            <TableMejora
                                data={fetchImportarMejorasGETData.data?.items}
                                loading={fetchImportarMejorasGETData.loading}
                                total={fetchImportarMejorasGETData.data?.total}
                                perPage={fetchImportarMejorasGETData.data?.per_page}

                                formValuesSearch={formValuesSearch}
                                handleFormChange={handleFormChange}
                                handleInputChangeSearch={handleInputChangeSearch}

                                idedificio={idedificio}
                                fetchImportarMejorasGET={fetchImportarMejorasGET}
                                fetchEdificioGETData={fetchEdificioGETData}

                                setshowFilter={setshowFilter}
                                showFilter={showFilter}
                                navLinksFases={navLinksFases}
                                fetchSuccess={(e: any) => handleSubmitSearch(e, formValuesSearch)}
                                idCurrentFase={idCurrentFase}
                                fetchElementosConstructivosData={fetchElementosConstructivosData}
                                fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                            />
                        </section>

                    </Grid>

                </Grid>

            </div>

        </>
    );
};
