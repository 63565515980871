import { Button, Container, Grid, Typography } from "@mui/material";
import { Navigate, Route, Routes } from "react-router";
import { LayoutDescConst } from "./LayoutDescConst/LayoutDescConst";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLang } from "../../../../../language";
import { ImportarEdificio } from "./ImportarEdificio/ImportarEdificio";
import { useModalData } from "../../../../../hooks/useModalData";
import { ElementosConstructivosServices } from "../controllers/elemconst.services";
import { isStatusSuccess } from "../../../../../helpers/helpers";
import { useEffect } from "react";
import { LoaderModalFormPrimary } from "../../../../../components/Loaders/LoaderModalFormPrimary";
import { ModalStatusServer } from "../../../../../components/Modals/ModalStatusServer";

export const DescripcionConstructiva = ({ navLinks, idedificio, setDefaultLink, defaultLink, ffetchMisElemsConst,
    isObraNueva }: any) => {

    const Lang = useLang()

    const [dataModal_ImportarEdificio, handleShowModal_ImportarEdificio, handleCloseModal_ImportarEdificio] = useModalData()

    const [fetchImportarEdificioGET, fetchImportarEdificioGETData] = ElementosConstructivosServices.GET_IMPORTAR_EDIFICIO()

    useEffect(() => {

        if (isStatusSuccess(fetchImportarEdificioGETData.status)) {

            ffetchMisElemsConst()
        }

    }, [fetchImportarEdificioGETData]);

    return (
        <>
            <Container maxWidth={false} className='my-auto'>

                <Grid container spacing={3}>

                    <Grid item xs={12} className='py-0 py-lg-0 mt-3'>

                        {
                            isEmpty(navLinks) &&
                            <>
                                {
                                    fetchImportarEdificioGETData.loading ?
                                        <div className="p-3">
                                            <LoaderModalFormPrimary />
                                        </div>
                                        :
                                        <Button variant="outlined" onClick={handleShowModal_ImportarEdificio}
                                            color="secondary" className="d-block mx-auto my-5">
                                            <FontAwesomeIcon icon={faPlus} className="me-1" /> <span>{Lang('IMPORTAR_SISTEMAS_OTRO_EDIFICIO')}</span>
                                        </Button>
                                }
                                <Grid container spacing={3} justifyContent='center'>

                                    <Grid item xs={12} lg={9} className='py-0 py-lg-0 mt-3'>

                                        <Typography variant='body1' className='mb-0 pb-2 mt-0 fw-normal'>
                                            <strong>{Lang('IMPORTANTE')}</strong>: <span>{Lang('P_IDENTIFICAR_SISTEMAS_ELEMENTOS')}.</span>
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </>
                        }

                        <Routes>

                            {
                                navLinks?.map((item: any) => {
                                    return (
                                        <Route
                                            key={item.link}
                                            path={`${item.link}/*`}
                                            element={
                                                <LayoutDescConst
                                                    id={item.link}
                                                    text={item.title}
                                                    idedificio={idedificio}
                                                    setDefaultLink={setDefaultLink}
                                                    defaultLink={defaultLink}
                                                    isObraNueva={isObraNueva}
                                                />
                                            }
                                        />
                                    )
                                })
                            }

                            {
                                navLinks?.[0] &&
                                <Route
                                    path="*"
                                    element={<Navigate to={navLinks?.[0]?.link} replace />}
                                />
                            }

                        </Routes>
                    </Grid>

                </Grid>

            </Container>

            <ImportarEdificio
                dataModal_ImportarEdificio={dataModal_ImportarEdificio}
                handleCloseModal_ImportarEdificio={handleCloseModal_ImportarEdificio}
                fetchImportarEdificioGET={fetchImportarEdificioGET}
                idEdificio={idedificio}
                fetchImportarEdificioGETData={fetchImportarEdificioGETData}
            />

            <ModalStatusServer fetchData={fetchImportarEdificioGETData} />
        </>
    );
};
