import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { MaestrosServices } from "../../../../controllers/maestros/maestros.services";
import { useValidator } from "../../../../hooks/useValidator";
import { useLang } from "../../../../language";
import { validAlMenosUnoMarcado, validExiste, validIsEqual } from "../../../../helpers/validations";
import ModalAdvanced from "../../../../components/ModalAdvanced/ModalAdvanced";
import { InputText } from "../../../../components/Inputs";
import { ButtonAdvanced } from "../../../../components/ButtonAdvanced/ButtonAdvanced";
import { InputChecks } from "../../../../components/Inputs/InputChecks";
import { InputSelect } from "../../../../components/Inputs/InputSelect";
import { isStatusSuccess } from "../../../../helpers/helpers";
import { useForm } from "../../../../hooks/useForm";
import { AuthServices } from "../../../../controllers/auth/auth.services";
import { AuthAdapters } from "../../../../controllers/auth/auth.adapters";
import { useModalData } from "../../../../hooks/useModalData";
import { ModalStatusServer } from "../../../../components/Modals/ModalStatusServer";
import jwt_decode from 'jwt-decode'
import { logIn } from "../../../../store/slices/auth";
import { useDispatch } from "react-redux";
import { ModalAvisoLegal } from "../../ModalRegistroUsuario/ModalAvisoLegal";
import { InputRadio } from "../../../../components/Inputs/InputRadio";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const ModalActualizarUsuario = ({ data, handleClose }: any) => {

    const Lang = useLang()
    const dispatch = useDispatch()

    const [dataModalAvisoLegal, handleShowModalAvisoLegal, handleCloseModalAvisoLegal] = useModalData()

    const [dataModalAvisoLegal2, handleShowModalAvisoLegal2, handleCloseModalAvisoLegal2] = useModalData()

    // ------- 

    const [formCondiciones, handleInputChangeCondiciones, handleFormChangeCondiciones, resetformCondiciones] = useForm({
        condiciones_1: [],
        condiciones_2: [],
    });

    const [validCondiciones, handleValidCondiciones, resetvalidCondiciones] = useValidator({
        condiciones_1: [
            { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        condiciones_2: [
            { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })


    const [fetchActualizarUsuarioPATCH, fetchActualizarUsuarioPATCHData, resetfetchActualizarUsuarioPATCHData]
        = AuthServices.PATCH_USUARIO_NUEVO()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [fetchParamsPublicColegioProGET, fetchParamsPublicColegioProGETData] = MaestrosServices.GET_COLEGIOS_PROFESIONALES_PUBLICOS()

    const [fetchComunidadesAutGET, fetchComunidadesAutGETData] = MaestrosServices.GET_COLEGIOS_PROFESIONALES_PUBLICOS_COMUNIDADES()

    const [formRegistro, handleInputRegistro, handleFormRegistro, resetFormRegistro] = useForm({
        ...AuthAdapters.PATCH_USUARIO_NUEVO.send.schema({}),
        politica_privacitat_acceptada_en: [],
        nombre: ''
    })

    const [validRegistro, handleValidationRegistro, resetvalidRegistro] = useValidator({
        // professio: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // comunitat_autonoma: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // politica_privacitat_acceptada_en: [
        //     { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        tipo_usuario: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const [validRegistroProfesional, handleValidationRegistroProfesional, resetvalidRegistroProfesional] = useValidator({
        professio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        comunitat_autonoma: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        colegiado_externo_num_colegiado: [
            {
                functionValidMultipleKeys: (form: any) => {

                    return (validIsEqual(form.professio, '4') || validIsEqual(form.professio, '5')) ? validExiste(form.colegiado_externo_num_colegiado) : true
                }, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true
            }
        ]
    })

    useEffect(() => {

        handleFormRegistro({
            ...formRegistro,
            collegi_professional: ''
        })

        fetchParamsPublicColegioProGET({
            params: {
                comunitat: formRegistro.comunitat_autonoma,
                tipo: formRegistro.professio,
            }
        })

    }, [formRegistro.professio, formRegistro.comunitat_autonoma]);

    const handleSubmitRegistrate = () => {

        if (handleValidationRegistro(formRegistro) && handleValidCondiciones(formCondiciones) &&
            ((formRegistro.tipo_usuario !== 'profesional') || (formRegistro.tipo_usuario === 'profesional' && handleValidationRegistroProfesional(formRegistro)))) {

            fetchActualizarUsuarioPATCH({
                params: {
                    _method: 'PATCH',
                },
                body: formRegistro,
                tokenParam: data.tokenParam
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchActualizarUsuarioPATCHData.status)) {

            handleClose()

            const data2: any = jwt_decode(data.tokenParam)

            dispatch(logIn({
                user: data2,
                token: data.tokenParam,
                perfil: data2.perfil || ''
            }))

        }

    }, [fetchActualizarUsuarioPATCHData]);

    useEffect(() => {

        if (data.value) {

            handleFormRegistro({
                ...formRegistro,
                nombre: data?.nombre,
            })

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "comunidadautonoma",
                    },
                    {
                        parametroPadre: "profesion",
                    },
                ]
            })

        } else {
            resetvalidRegistro()
            // resetvalidNIF()
        }

    }, [data.value]);

    useEffect(() => {

        if (data.value) {

            fetchComunidadesAutGET({
                params: {
                    tipo: formRegistro.professio
                }
            })
        }

    }, [data.value, formRegistro.professio]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {Lang('ACTUALIZAR_DATOS_USUARIO')}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-0 p-0'>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className=""
                    >
                        <Grid container spacing={2} columnSpacing={3} rowSpacing={0}>

                            <Grid item xs={12} className="">
                                <Typography variant='subtitle1' className="fw-bold">
                                    {Lang('DATOS_PERSONALES')}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6} lg={7}>
                                <InputText
                                    label={Lang('NOMBRE') + ':'}
                                    name='nombre'
                                    value={formRegistro.nombre}
                                    onChange={handleInputRegistro}
                                    localValidation={validRegistro.nombre}
                                    fetchData={fetchActualizarUsuarioPATCHData}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} className="">
                                <Typography variant='subtitle1' className='mb-0 pb-0 mt-0 fw-normal fs-105'>
                                    <small>
                                        {Lang('TIPO_DE_USUARIO')}:
                                    </small>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className="pt-0">
                                <InputRadio
                                    onChange={handleInputRegistro}
                                    name='tipo_usuario'
                                    value={formRegistro.tipo_usuario}
                                    values={
                                        [
                                            {
                                                id: 'propietario', name: Lang('PROPIETARIO'),
                                            },
                                            {
                                                id: 'profesional', name: Lang('PROFESIONAL'),
                                            },
                                        ]
                                    }
                                    classNameGroup=''
                                    localValidation={validRegistro.tipo_usuario}

                                />
                                {
                                    !validRegistro.tipo_usuario?.[0]?.isOk &&
                                    <small className='d-block mt-2 color-error'>{validRegistro.tipo_usuario?.[0]?.msgError}</small>
                                }
                            </Grid>

                            {
                                formRegistro.tipo_usuario === 'profesional' &&
                                <>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <InputSelect
                                            label={Lang('PROFESION') + ': *'}
                                            name='professio'
                                            value={formRegistro.professio}
                                            values={fetchParamsPublicGETData?.data?.['profesion'] || []}
                                            onChange={handleInputRegistro}
                                            localValidation={validRegistroProfesional.professio}
                                            fetchData={fetchActualizarUsuarioPATCHData}
                                        />
                                    </Grid>

                                    {
                                        formRegistro.professio === '10' &&
                                        <Grid item xs={12} md={6} lg={3}>
                                            <InputText
                                                label={Lang('OTRA_PROFESION') + ':'}
                                                name='role_altres'
                                                value={formRegistro.role_altres}
                                                onChange={handleInputRegistro}
                                                localValidation={validRegistro.role_altres}
                                                fetchData={fetchActualizarUsuarioPATCHData}
                                            />
                                        </Grid>
                                    }

                                    {
                                        !isEmpty(formRegistro.professio) && String(formRegistro.professio) !== '0' &&
                                        <Grid item xs={12} md={6} lg={3}>
                                            <InputSelect
                                                label={Lang('COMUNIDAD_AUTONOMA') + ': *'}
                                                name='comunitat_autonoma'
                                                value={formRegistro.comunitat_autonoma}
                                                values={formRegistro.professio === '10' ? fetchParamsPublicGETData?.data?.['comunidadautonoma'] || [] : fetchComunidadesAutGETData?.data?.['comunidadautonoma'] || []}
                                                onChange={handleInputRegistro}
                                                localValidation={validRegistroProfesional.comunitat_autonoma}
                                                fetchData={fetchActualizarUsuarioPATCHData}
                                            />
                                        </Grid>
                                    }


                                    {
                                        !isEmpty(formRegistro.professio) && String(formRegistro.professio) !== '10' &&
                                        <>
                                            <Grid item xs={12} md={6} lg={3} display='flex' alignItems='end'>
                                                <InputText
                                                    label={Lang('NUMERO_COLEGIADO_A') + ':'}
                                                    name='colegiado_externo_num_colegiado'
                                                    value={formRegistro.colegiado_externo_num_colegiado}
                                                    onChange={handleInputRegistro}
                                                    localValidation={validRegistroProfesional.colegiado_externo_num_colegiado}
                                                    fetchData={fetchActualizarUsuarioPATCHData}
                                                />
                                                {
                                                    String(formRegistro.professio) === '4' &&
                                                    <Tooltip title={Lang('P_NUMERO_COLEGIADO_PRECIO_CONVENIO')} placement="top">
                                                        <FontAwesomeIcon icon={faInfoCircle} className="mb-2 ms-2" />
                                                    </Tooltip>
                                                }
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={8}>
                                                <InputSelect
                                                    label={Lang('COLEGIO_PROFESIONAL') + ':'}
                                                    name='collegi_professional'
                                                    value={formRegistro.collegi_professional}
                                                    values={fetchParamsPublicColegioProGETData?.data || []}
                                                    onChange={handleInputRegistro}
                                                    localValidation={validRegistro.collegi_professional}
                                                    fetchData={fetchActualizarUsuarioPATCHData}
                                                />
                                            </Grid>
                                        </>
                                    }
                                </>
                            }

                            <Grid item xs={12}>
                                <InputChecks
                                    name='condiciones_1'
                                    values={formCondiciones.condiciones_1}
                                    valuesDefault={[
                                        {
                                            id: 'condiciones_1',
                                            label: <small onClick={(e) => {
                                                e.preventDefault()
                                                handleShowModalAvisoLegal()
                                            }}>{Lang('MANIFIESTO_LEIDO_Y_ACEPTO_LAS')} <span className="color-primary">{Lang('CONDICIONES_REGISTRO')}</span>.</small>,
                                        }
                                    ]}
                                    onChange={handleInputChangeCondiciones}
                                    localValidation={validCondiciones.condiciones_1}
                                />
                            </Grid>

                            <Grid item xs={12} className='pt-0'>
                                <InputChecks
                                    name='condiciones_2'
                                    values={formCondiciones.condiciones_2}
                                    valuesDefault={[
                                        {
                                            id: 'condiciones_2',
                                            label: <small onClick={(e) => {
                                                e.preventDefault()
                                                handleShowModalAvisoLegal2({
                                                    indexAccordion: 10
                                                })
                                            }}>{Lang('MANIFIESTO_LEIDO_Y_ACEPTO_INFORMACION_RELATIVA_A')} <span className="color-primary">{Lang('PROTECCION_DATOS_CARACTER_PERSONAL')}</span>. </small>,
                                        }
                                    ]}
                                    onChange={handleInputChangeCondiciones}
                                    localValidation={validCondiciones.condiciones_2}
                                />
                            </Grid>

                            <Grid item xs={12} justifyContent='between' display='flex' className="mt-0">

                                <ButtonAdvanced
                                    variant="outlined" type="button" className="me-auto" color='secondary'
                                    handleSubmit={handleClose}
                                    value={Lang('SALIR')}
                                />

                                <ButtonAdvanced
                                    variant="contained" type="button" className="ms-auto"
                                    handleSubmit={handleSubmitRegistrate}
                                    loadings={[fetchActualizarUsuarioPATCHData.loading]}
                                    value={Lang('FINALIZAR')}
                                />
                            </Grid>

                        </Grid>

                    </Box>

                </ModalAdvanced.Body>

            </ModalAdvanced>

            <ModalAvisoLegal
                data={dataModalAvisoLegal}
                handleClose={handleCloseModalAvisoLegal}
                handleInput={handleInputChangeCondiciones}
                name='condiciones_1'
            />

            <ModalAvisoLegal
                data={dataModalAvisoLegal2}
                handleClose={handleCloseModalAvisoLegal2}
                handleInput={handleInputChangeCondiciones}
                name='condiciones_2'
            />

            <ModalStatusServer fetchData={fetchActualizarUsuarioPATCHData} />

        </>
    );
};
