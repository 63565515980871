import { useNavigate } from "react-router";
import { useLang } from "../../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faSync } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { ButtonAdvanced } from "../../../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { Card, CardContent, Typography } from "@mui/material";
import { isStatusSuccess } from "../../../../../../../../helpers/helpers";
import { useEffect } from "react";
import { TablePeriodicidadesEdit } from "./TablePeriodicidadesEdit";

export const TablePeriodicidades = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, readOnly, fetchActividadClonarPOST, fetchSuccess, fetchActividadClonarPOSTData,
    resetfetchActividadClonarPOSTData, navLinksFases }: any) => {

    const Lang = useLang()

    const navigate = useNavigate();


    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()


    let allActions: any = []

    // if (!readOnly) {

    //     allActions.push(
    //         {
    //             label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('AGREGAR_GREMIO')}</span>,
    //             variant: 'contained',
    //             action: () => handleShowUsuario({
    //                 action: 'CREATE',
    //             }),
    //         }
    //     )
    // }

    let allActionsPerLine: any = []

    if (!readOnly) {

        allActionsPerLine.push(
            {
                variant: 'outlined',
                label: <FontAwesomeIcon icon={faExternalLink} className='' />,
                action: (item: any) => navigate(`../../actividad/${item.id}`),
                tooltip: {
                    text: Lang('VER_DETALLE')
                },
            }
        )
    }

    const columnsTable = {

        titol: {
            title: Lang('TITULO'),
        },
        descripcio: {
            title: Lang('DESCRIPCION'),
        },
        data_inici_calculada: {
            title: Lang('FECHA_INICIO'),
        },
        fase_nom: {
            title: Lang('FASE'),
        },
    }


    const handleGenerarActividadesPeriodicas = () => {

        fetchActividadClonarPOST()
    }

    useEffect(() => {

        if (isStatusSuccess(fetchActividadClonarPOSTData.status)) {

            resetfetchActividadClonarPOSTData()
            fetchSuccess()
        }

    }, [fetchActividadClonarPOSTData]);


    return (
        <>
            {
                data?.length === 1 && formValuesSearch.page === 1 ?
                    <Card>
                        <CardContent className="d-flex justify-content-center flex-column align-items-center py-4">

                            <ButtonAdvanced
                                variant="contained" type="submit" size='small' className='me-1 mx-0'
                                handleSubmit={handleGenerarActividadesPeriodicas} loadings={[fetchActividadClonarPOSTData.loading]}
                                value={Lang('GENERAR_ACTIVIDADES_PERIODICAS')} icon={<FontAwesomeIcon icon={faSync} className="me-2" />}
                            />
                            <Typography variant='body1' className='mt-3' component="h5">
                                Se generarán las actividades subsecuentes hasta la fecha fin del proyecto.
                            </Typography>

                        </CardContent>
                    </Card>
                    :
                    <TablePeriodicidadesEdit
                        data={data}
                        perPage={perPage}
                        total={total}
                        navLinksFases={navLinksFases}
                        loading={loading}
                        handleFormChange={handleFormChange}
                        formValuesSearch={formValuesSearch}
                    />
                // <TableAdvanced
                //     classNameTableAdvanced='minByDropdown mt-0'
                //     handleFormChange={handleFormChange}
                //     formValuesSearch={formValuesSearch}
                //     handleInputChange={handleInputChangeSearch}
                // >
                //     <TableAdvanced.Head
                //         title={'Periodicidades'}
                //         allActions={allActions}
                //     />

                //     <TableAdvanced.Body
                //         data={data}
                //         isLoading={loading}
                //         columnsTable={columnsTable}
                //         allActionsPerLine={allActionsPerLine}
                //     />

                //     <TableAdvanced.Footer
                //         total={total}
                //         perPage={perPage}
                //     />
                // </TableAdvanced>

            }


        </>
    )
}
