import { faBarsStaggered, faBroom, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { ButtonAdvanced } from '../ButtonAdvanced/ButtonAdvanced';
import { useLang } from '../../language';
import { Card, CardContent, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import './FilterAdvanced.scss'

function addPropsToReactElement(element: any, props: any) {
    if (React.isValidElement(element)) {
        return React.cloneElement(element, props)
    }
    return element
}

interface IFilterAdvanced {
    children: any;
    expanded?: boolean,
    handleSubmitSearch?: any,
    fetchData?: any,
    formValuesSearch?: any,
    handleInputChangeSearch?: any,
    resetformValuesSearch?: any,
    handleFormChange?: any,
    valuesSimple?: any,
    valuesExpanded?: any,
    valuesDefault?: any
}

interface IValuesFilter {
    label?: string,
    showFilter?: boolean,
    setshowFilter?: (boolean: boolean) => boolean,
    children?: any;
    expanded?: boolean,
    handleSubmitSearch?: any,
    fetchData?: any,
    formValuesSearch?: any,
    handleInputChangeSearch?: any,
    resetformValuesSearch?: any,
    handleFormChange?: any,
    valuesSimple?: any,
    valuesDefault?: any
    valuesExpanded?: any,
}

const FilterAdvanced = ({ children, expanded = false, handleSubmitSearch, fetchData, formValuesSearch, handleInputChangeSearch,
    resetformValuesSearch, handleFormChange, valuesSimple, valuesExpanded, valuesDefault }: IFilterAdvanced) => {

    const [showFilter, setshowFilter] = useState(expanded)    

    return (
        <Card>
            <CardContent className="p-3">

                <>
                    {
                        children?.map((childElement: any) =>
                            addPropsToReactElement(childElement, {
                                showFilter, setshowFilter, expanded, handleSubmitSearch,
                                fetchData, formValuesSearch, handleInputChangeSearch,
                                resetformValuesSearch, handleFormChange, valuesSimple, valuesExpanded,
                                valuesDefault
                            })
                        )
                    }
                </>

            </CardContent>
        </Card>
    )
}


const FilterAdvancedHead = ({ label, showFilter, setshowFilter = (() => false), children, handleSubmitSearch, fetchData,
    formValuesSearch, handleInputChangeSearch, resetformValuesSearch, valuesSimple, valuesExpanded,
    handleFormChange }: IValuesFilter) => {

    const Lang = useLang()

    const handleMiddleSearch = () => {

        const valuesFinal = { ...formValuesSearch, ...valuesExpanded }

        handleFormChange(valuesFinal)
        handleSubmitSearch(null, valuesFinal)
    }

    return (
        <div className="">
            <div className='d-flex justify-content-between align-items-start '>
                {
                    label &&
                    <Typography variant='subtitle1' className='m-0 h5 d-none d-sm-block fw-bold'>
                        {label}
                    </Typography>
                }

                {
                    !isEmpty(valuesExpanded) && <div onClick={() => setshowFilter(!showFilter)} className='ms-3 p-1 color-secondary' id='btn_show_filter_advance'>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                    </div>
                }
            </div>
            <div className="container-fluid">
                <div className="row ms-auto ms-sm-0 justify-content-end">
                    <>
                        {
                            addPropsToReactElement(children, { showFilter, formValuesSearch, handleInputChangeSearch })
                        }
                    </>
                    {
                        !showFilter && <div className="col text-nowrap flex-grow-0">
                            <div className="d-flex flex-column align-items-end justify-content-end h-100">
                                <ButtonAdvanced
                                    variant="contained" type="submit" size='small'
                                    handleSubmit={handleMiddleSearch} loadings={[fetchData.loading]}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                />
                            </div>
                        </div>
                    }

                </div>
            </div>

        </div>
    )
}

const FilterAdvancedBody = ({ showFilter, children, handleSubmitSearch, fetchData, formValuesSearch,
    handleInputChangeSearch, resetformValuesSearch, handleFormChange, valuesSimple, valuesExpanded,
    valuesDefault }: IValuesFilter) => {

    const Lang = useLang()

    return (
        <div className='row'>
            {
                showFilter && <>
                    <div className="col-12 animate__animated animate__fadeIn animate__faster">
                        <div className="row">
                            {
                                addPropsToReactElement(children, {
                                    showFilter, formValuesSearch, handleInputChangeSearch,
                                    handleSubmitSearch, fetchData, resetformValuesSearch, handleFormChange, valuesSimple,
                                    valuesExpanded, valuesDefault
                                })
                            }
                        </div>
                    </div>
                    <div className="ms-auto col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                        <div className="d-flex align-items-end justify-content-end h-100">
                            <ButtonAdvanced
                                variant="outlined" type="submit" size='small' className="me-2"
                                // handleSubmit={handleResetClear} fetchDataLoading={[false]}
                                value={Lang('LIMPIAR')} icon={<FontAwesomeIcon icon={faBroom} className='me-1' />}
                            />
                            <ButtonAdvanced
                                variant="contained" type="submit" size='small' className=""
                                // handleSubmit={handleMiddleSearch} fetchDataLoading={[fetchData.loading]}
                                value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                            />
                        </div>

                    </div>
                </>
            }
        </div>
    )
}

FilterAdvanced.Simple = FilterAdvancedHead
FilterAdvanced.Expanded = FilterAdvancedBody

export default FilterAdvanced