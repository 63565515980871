import { Typography } from "@mui/material";
import { useLang } from "../../../../../../../../language";
import { FotosAnotacion } from "./FotosAnotacion";

export const AnotacionFotos = ({ idAnotacion, idCurrentProject, idActividad, readOnly }: any) => {

    const Lang = useLang()
    
    return (
        <>
            <Typography variant='body1' className='mb-0 pb-2 mt-0 fw-normal'>
                {Lang('IMAGENES')}:
            </Typography>

            <FotosAnotacion
                idAnotacion={idAnotacion}
                idCurrentProject={idCurrentProject}
                idActividad={idActividad}
                readOnly={readOnly}
            />
        </>
    )
};
