import React, { useEffect } from "react";
import { TabsAdvanced } from "../../../../../../components/TabsAdvanced/TabsAdvanced";
import { useLang } from "../../../../../../language";
import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { ImportarPreventivo } from "./Preventivo/ImportarPreventivo";
import { ElementosConstructivosParamServices } from "../../controllers/elemconst.services";
import { ImportarCorrectivo } from "./Correctivo/ImportarCorrectivo";
import { ElementosConstructivosServices } from "../../../../../libro/pages/LibroEdificio/controllers/elemconst.services";
import { ImportarMejora } from "./Mejoras/ImportarMejora";

export const IndexImportarActividades = ({ idedificio, navLinksFases, idCurrentFase, fetchElementosConstructivosData }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()
    const [fetchParamsMisSistemasGET, fetchParamsMisSistemasGETData] = ElementosConstructivosParamServices.GET(idedificio)

    useEffect(() => {

        fetchParamsMisSistemasGET()

    }, []);

    return (
        <>

            <Alert severity="info" className="my-3 d-flex align-items-center border"
                action={
                    <div className="me-1">
                        <Button
                            variant='outlined' color='secondary' size="small" className='me-3'
                            onClick={() => navigate('../')}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> {Lang('REGRESAR')}
                        </Button>
                    </div>
                }>
                <AlertTitle>{Lang('AGREGAR_A_ACTIVIDADES')}:</AlertTitle>
                <span>
                    {Lang('PARRAFO_SELECCIONE_ACTIVIDAD_IMPORTAR')}
                </span>
            </Alert>

            <TabsAdvanced>
                <TabsAdvanced.Head
                    // centered={true}
                    values={[
                        {
                            id: 'preventivo',
                            name: Lang('MANTENIMIENTO_PREVENTIVO'),
                        },
                        {
                            id: 'correctivo',
                            name: Lang('MANTENIMIENTO_CORRECTIVO'),
                        },
                        {
                            id: 'mejoras',
                            name: Lang('POTENCIAL_DE_MEJORAS'),
                        },

                    ]}
                />
                <TabsAdvanced.Body>
                    <section className="pt-3">

                        <Grid container spacing={3} rowSpacing={3}>

                            <Grid item xs={12}>

                                <ImportarPreventivo
                                    idedificio={idedificio}
                                    navLinksFases={navLinksFases}
                                    fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                                    idCurrentFase={idCurrentFase}
                                    fetchElementosConstructivosData={fetchElementosConstructivosData}
                                />

                            </Grid>

                        </Grid>
                    </section>
                    <section className="pt-2">

                        <Grid container spacing={3} rowSpacing={3}>
                            <Grid item xs={12}>

                                <ImportarCorrectivo
                                    idedificio={idedificio}
                                    navLinksFases={navLinksFases}
                                    fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                                    idCurrentFase={idCurrentFase}
                                    fetchElementosConstructivosData={fetchElementosConstructivosData}
                                />

                            </Grid>
                        </Grid>

                    </section>
                    <section className="pt-2">

                        <Grid container spacing={3} rowSpacing={3}>
                            <Grid item xs={12}>

                                <ImportarMejora
                                    idedificio={idedificio}
                                    navLinksFases={navLinksFases}
                                    fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                                    idCurrentFase={idCurrentFase}
                                    fetchElementosConstructivosData={fetchElementosConstructivosData}
                                />

                            </Grid>
                        </Grid>

                    </section>
                </TabsAdvanced.Body>

            </TabsAdvanced>
        </>
    )
};
