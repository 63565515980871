import { useEffect } from "react";
import { useLang } from "../../../../../../language";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { useForm } from "../../../../../../hooks/useForm";
import { ButtonAdvanced } from "../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Derramas } from "./Derramas/Derramas";
import { InputTextarea } from "../../../../../../components/Inputs/InputTextarea";
import { ProyectoServices } from "../../../../router/Proyectos/controllers/proyectos.services";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";
import { isFetchDataInitLoader, isStatusSuccess, parseMoneda } from "../../../../../../helpers/helpers";
import { PlanificacionFinancieraServices } from "../../controllers/elemconst.services";
import { LoaderModalFormPrimary } from "../../../../../../components/Loaders/LoaderModalFormPrimary";
import { IngresosProyecto } from "./Ingresos/IngresosProyecto";

export const PlanificacionFinanciera = ({ readOnly, idCurrentProject }: any) => {

    const Lang = useLang()

    const [fetchProyectoPATCH, fetchProyectoPATCHData] = ProyectoServices.PATCH_RESUMEN_FINANCIERO(idCurrentProject)

    const [fetchPlanificacionFinancieraGET, fetchPlanificacionFinancieraGETData] = PlanificacionFinancieraServices.GET_INFORMACION_PREVIA(idCurrentProject)


    const [formPlanFin, handleInputPlanFin, handleFormPlanFin, resetFormPlanFin] = useForm({

        observacions_financament: '',
    })

    const handleInformacionPrevPATCH = () => {

        fetchProyectoPATCH({
            body: formPlanFin
        })
    }

    useEffect(() => {

        fetchPlanificacionFinancieraGET()

    }, []);

    useEffect(() => {

        if (isStatusSuccess(fetchPlanificacionFinancieraGETData.status)) {

            handleFormPlanFin({
                ...fetchPlanificacionFinancieraGETData.data?.proyecto
            })
        }

    }, [fetchPlanificacionFinancieraGETData]);



    useEffect(() => {

        if (isStatusSuccess(fetchProyectoPATCHData.status)) {

            resetFormPlanFin()

            fetchPlanificacionFinancieraGET()
        }

    }, [fetchProyectoPATCHData]);

    return (
        <div className="">

            {
                isFetchDataInitLoader(fetchPlanificacionFinancieraGETData) ?
                    <Card>
                        <CardContent>
                            <LoaderModalFormPrimary />
                        </CardContent>
                    </Card>
                    :
                    <>
                        <div className="d-flex justify-content-end">
                            <div className="mt-0 mb-3 d-inline-flex flex-wrap justify-content-end border border-primary">
                                {/* <p className="ms-4">
                                <strong>{Lang('TOTAL_PROYECTO')}</strong>: {fetchPlanificacionFinancieraGETData.data?.total_proyecto} €
                            </p> */}
                                <p className="ms-3 my-2">
                                    <strong>{Lang('TOTAL_INGRESOS')}</strong>: {parseMoneda(fetchPlanificacionFinancieraGETData.data?.proyecto?.total_ingresos)}

                                </p>
                                <p className="mx-3 my-2">
                                    {/* <strong>Pendiente de financiar</strong>: {fetchPlanificacionFinancieraGETData.data?.pendiente_financiar} € */}
                                    <strong>{Lang('INGRESOS')} - {Lang('GASTOS')}</strong>: {parseMoneda(fetchPlanificacionFinancieraGETData.data?.pendiente_financiar)}

                                </p>
                            </div>
                        </div>
                        <Card>
                            <CardContent>
                                <Box
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                    className="mb-0"
                                >
                                    <Grid container rowSpacing={3} columnSpacing={5}>

                                        <Grid item xs={12}>

                                            <IngresosProyecto
                                                idCurrentProject={idCurrentProject}
                                                readOnly={readOnly}
                                                data={fetchPlanificacionFinancieraGETData?.data?.proyecto?.ingresos}
                                                fetchPlanificacionFinancieraGET={fetchPlanificacionFinancieraGET}
                                            />

                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <InputTextarea
                                                label={Lang('OBSERVACIONES') + ':'}
                                                name='observacions_financament'
                                                value={formPlanFin.observacions_financament}
                                                onChange={handleInputPlanFin}
                                                readOnly={readOnly}
                                            />
                                            <p className="mb-0">
                                                <small>
                                                    <strong>{Lang('PARRAFO_APROBACION_SISTEMA_FINANCIERO')}</strong>: ({Lang('PARRAFO_INDICAR_FECHA_JUNTA')})
                                                </small>
                                            </p>
                                        </Grid>

                                        {
                                            !readOnly &&
                                            <Grid item xs={12} className="pt-3">
                                                <div className="d-flex justify-content-end">
                                                    <ButtonAdvanced
                                                        variant="contained" type="button" className="ms-auto"
                                                        handleSubmit={handleInformacionPrevPATCH}
                                                        loadings={[fetchProyectoPATCHData.loading]}
                                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                                    />
                                                </div>
                                            </Grid>
                                        }

                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </>
            }

            <div className="mt-4 mb-4 pt-2">
                {
                    isFetchDataInitLoader(fetchPlanificacionFinancieraGETData) ?
                        <Card>
                            <CardContent>
                                <LoaderModalFormPrimary />
                            </CardContent>
                        </Card>
                        :
                        <Derramas
                            idCurrentProject={idCurrentProject}
                            data={fetchPlanificacionFinancieraGETData?.data?.proyecto?.derramas}
                            fetchPlanificacionFinancieraGET={fetchPlanificacionFinancieraGET}
                            readOnly={readOnly}
                        />
                }

            </div>

            <ModalStatusServer fetchData={fetchProyectoPATCHData} />

        </div>
    )
};
