import _, { isArray, isEmpty } from "lodash";

var REMOVE_FIELD_NULLABLE = 'REMOVE_FIELD_NULLABLE'

export function renderSendAdapter(data, type, functionSchema) {

    const dataTemp = data

    if (isEmpty(data)) console.error('No se encontró información en el adaptador')

    switch (type) {

        case 'object':

            let itemsObjectTemp = dataTemp

            let itemsObject = functionSchema(itemsObjectTemp)

            return {
                data: {
                    ...itemsObject
                }
            }

        case 'multipart':

            let itemsMultipartTemp = dataTemp

            let itemsMultipart = functionSchema(itemsMultipartTemp)

            let formData = new FormData();

            for (const key in itemsMultipart) {
                formData.append(key, itemsMultipart[key])
            }

            return {
                data: formData
            }

        default:

            break;
    }

}


export function renderReceiveAdapter(data, type, functionSchema) {

    const dataTemp = data

    if (isEmpty(data)) console.error('No se encontró información en el adaptador')

    switch (type) {

        case 'default':

            let itemsDefaultTemp = dataTemp?.data

            let itemsDefault = functionSchema(itemsDefaultTemp)

            return {
                data: {
                    ...itemsDefault
                }
            }

        case 'defaultArray':

            let itemsDefaultArrayTemp = dataTemp?.data

            let itemsDefaultArray = itemsDefaultArrayTemp?.map(item => (functionSchema(item)))

            return {
                data: itemsDefaultArray
            }

        case 'object':

            let itemsObjectTemp = dataTemp?.data?.data

            let itemsObject = functionSchema(itemsObjectTemp)

            return {
                data: {
                    ...itemsObject
                }
            }

        case 'array':

            const dataArrayTemp = dataTemp?.data?.data

            if (!Array.isArray(dataArrayTemp)) {
                console.error('La data no es de tipo:' + type);
                return {}
            }

            const itemsArray = dataArrayTemp?.map(item => (functionSchema(item)))

            return {
                data: [
                    ...itemsArray
                ]
            }

        case 'list':

            const dataListTemp = dataTemp?.data?.data

            if (!isArray(dataListTemp?.data)) {
                console.error('La data no es de tipo:' + type);
                return {}
            }

            const itemsList = dataListTemp.data?.map(item => (functionSchema(item)))

            return {
                data: {
                    items: itemsList,
                    total: dataListTemp?.total,
                    per_page: dataListTemp?.per_page,
                    current_page: dataListTemp?.current_page,
                }
            }

        // case 'listArray':

        //     const dataListArrayTemp = dataTemp?.data?.data

        //     if (!Array.isArray(dataListArrayTemp)) {
        //         console.error('La data no es de tipo:' + type);
        //         return {}
        //     }

        //     const itemslistArray = dataListArrayTemp?.map(item => (functionSchema(item)))

        //     return {
        //         data: [
        //             ...itemslistArray
        //         ]
        //     }
        
        case 'blob':

            let itemBlobTemp = dataTemp

            let itemBlob = functionSchema(itemBlobTemp)

            return {
                data: {
                    ...itemBlob
                }
            }

        default:
            break;
    }

}

// AfterAdapter

export function renderSchema(data) {

    const dataTemp = _.cloneDeep(data)

    for (const key in dataTemp) {
        if (dataTemp[key] === REMOVE_FIELD_NULLABLE) delete dataTemp[key]
    }

    return dataTemp
}

function AfterParse(value, options, defaultParse) {

    if (!options) options = {}

    if (options.acceptNullable && (value === undefined || value === null))
        return null

    if (options.acceptEmpty && (value === undefined || value === null))
        return ''

    if (options.removeNullable && (value === undefined || value === null))
        return REMOVE_FIELD_NULLABLE

    if (options.removeEmpty && (value === undefined || value === null || value === ''))
        return REMOVE_FIELD_NULLABLE

    return defaultParse
}

// Parsers

export function parseString(data, options) {

    return AfterParse(data, options, String(data || '') || '')
}

export function parseNumber(data, options) {

    return AfterParse(data, options, Number(data || 0) || 0)
}

export function parseBoolean(data, options) {

    return AfterParse(data, options, data === true || data === 'true')
}

export function parseArray(data, options) {

    return AfterParse(data, options, Array.isArray(data) ? data : [])
}

export function parseObject(data, options) {

    return AfterParse(data, options, typeof data === 'object' ? data : {})
}

export function parseFile(data, options) {

    return AfterParse(data, options, typeof data?.name == 'string' ? data : new File([""], ''))
}

export function parseAny(data, options) {

    return AfterParse(data, options, data)
}


export function sendCheck(value) {

    return value?.[0] ? true : false
}

export function receiveCheck(value, name) {

    return value ? [`${name}`] : []
}

export function parseCheck(value, name) {

    if (typeof value == "boolean") {

        return value ? [`${name}`] : []

    } else {

        return value?.[0] ? true : false
    }

}

export function receiveFecha(value) {

    if (value?.indexOf('T') !== -1) {
        return value?.split('T')?.[0] || ''

    } else {

        return value?.split(' ')?.[0] || ''
    }
}

export function receiveFechaFormat2(value) {

    // Recibiendo ya en formato yyyy-mm-dd

    return value.split('-').reverse().join('/')

}
