import { Grid, Typography } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { useLang } from "../../../../../../../../language";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { UploadCard } from "../../../../../../../../components/UploadCard/Index";
import { ModalCropImage } from "../../../../../../../../components/ModalCropImage/ModalCropImage";
import { ModalInfo } from "../../../../../../../../components/Modals/ModalInfo";
import { useParams } from "react-router";
import { ModalStatusServer } from "../../../../../../../../components/Modals/ModalStatusServer";
import { isStatusSuccess } from "../../../../../../../../helpers/helpers";
import { FotosDeficienciaServices } from "./controllers/fotosdeficiencia.services";
import ModalAdvanced from "../../../../../../../../components/ModalAdvanced/ModalAdvanced";
import { InputTextarea } from "../../../../../../../../components/Inputs/InputTextarea";
import { useValidator } from "../../../../../../../../hooks/useValidator";
import { useForm } from "../../../../../../../../hooks/useForm";
import { ButtonAdvanced } from "../../../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContainerDragDrop from "../../../../../../../../components/ContainerDragDrop";
import { ImageCard } from "../../../../../../../../components/ImageCard/Index";
import { cloneDeep } from "lodash";
import { Placeholder } from "../../../../../../../../components/Placeholder/Placeholder";
import { validExiste } from "../../../../../../../../helpers/validations";

export const ModalFotoDeficiencia = ({ data, handleClose, fetchSuccess, iddeficiencia }: any) => {

    const { id = '' } = useParams()

    const [InputFiles, setInputFiles] = useState<any>([])

    const Lang = useLang()

    // const label__MaxSizeMB = '3 MB'
    const MaxSizeMB = 3000000

    const inputFileRef = useRef<any>(null);

    // Modal nueva imagen
    const [dataModal_CropImage, handleShowModal_CropImage, handleCloseModal_CropImage] = useModalData()

    const [dataModal_InfoError, handleShowModal_InfoError, handleCloseModal_InfoError] = useModalData()


    const [formFotoDeficiencia, handleInputDocumento, handleFormFotoDeficiencia, resetFormFotoDeficiencia] = useForm({
        descripcio: '',
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // qualificacio: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    const [fetchFotoDeficienciaGET, fetchFotoDeficienciaGETData] = FotosDeficienciaServices.GET()

    useEffect(() => {

        if (isStatusSuccess(fetchFotoDeficienciaGETData.status)) {

            const dataTemp = cloneDeep(fetchFotoDeficienciaGETData.data)

            setInputFiles(
                [dataTemp]
            )

            handleFormFotoDeficiencia({
                ...dataTemp
            })
        }

    }, [fetchFotoDeficienciaGETData]);


    // ----------------- POST

    const [fetchSubirFotografiaPOST, fetchSubirFotografiaPOSTData] = FotosDeficienciaServices.POST()

    const handleSubirImagen = () => {

        if (handleValidDocumento(formFotoDeficiencia)) {

            fetchSubirFotografiaPOST({
                body: {
                    ...formFotoDeficiencia,
                    deficiencie_id: iddeficiencia,
                    imatge: InputFiles?.[0]
                }
            })

        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchSubirFotografiaPOSTData.status)) {

            setInputFiles([])
            handleClose()
            fetchSuccess()
        }

    }, [fetchSubirFotografiaPOSTData]);

    const handleChangeInputImage = (e: any) => {

        if (e?.target?.files?.length > 0) {

            if (e.target.files[0]?.size > MaxSizeMB) {

                handleShowModal_InfoError({})
                e.target.value = null

            } else {

                handleShowModal_CropImage({
                    file: e.target.files[0]
                })
            }
        }
    }


    // ----------------- PATCH

    const [fetchSubirFotografiaPATCH, fetchSubirFotografiaPATCHData] = FotosDeficienciaServices.PATCH()

    const handlefetchSubirFotografiaPATCH = () => {

        if (handleValidDocumento(formFotoDeficiencia)) {

            fetchSubirFotografiaPATCH({
                id: data?.id + '/update',
                body: {
                    ...formFotoDeficiencia,
                    deficiencie_id: iddeficiencia,
                    // imatge: InputFiles?.[0]
                }
            })
        }

    }

    useEffect(() => {

        if (isStatusSuccess(fetchSubirFotografiaPATCHData.status)) {

            setInputFiles([])
            handleClose()
            fetchSuccess()
        }

    }, [fetchSubirFotografiaPATCHData]);

    const [fetchSubirFotografiaDELETE, fetchSubirFotografiaDELETEData] = FotosDeficienciaServices.DELETE()

    const handleSubmitFotografiaDelete = () => {

        fetchSubirFotografiaDELETE({
            id: data?.id + '/destroy',
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchSubirFotografiaDELETEData.status)) {

            setInputFiles([])
            handleClose()

            fetchSuccess()
        }

    }, [fetchSubirFotografiaDELETEData]);



    useEffect(() => {

        if (data.value) {

            if (data?.id) {

                fetchFotoDeficienciaGET({
                    id: data?.id + '/edit'
                })
            }

        } else {
            resetFormFotoDeficiencia()
            resetvalidDocumento()
            setInputFiles([])
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose}>
                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2" justifyContent='start'>
                        {data?.action === 'CREATE' ? Lang('IMAGEN') : Lang('IMAGEN')}
                    </Typography>
                </ModalAdvanced.Head>
                <ModalAdvanced.Body>
                    {
                        fetchFotoDeficienciaGETData.loading ?

                            <LoaderFotoDeficiencia />
                            :
                            <Grid container spacing={3} rowSpacing={3}>

                                <Grid item xs={12} className="my-3">
                                    {
                                        InputFiles?.length > 0 ?
                                            <ContainerDragDrop.ItemsDynamics
                                                elements={InputFiles}
                                                // handleOrderElement={handleOrdenarImagenes}
                                                ComponentCard={ImageCard}
                                                setElements={setInputFiles}
                                                classNameContainer=''
                                                xs={12}
                                            >

                                                {
                                                    InputFiles?.map((item: any, index: number) => {
                                                        return (
                                                            <>
                                                                {
                                                                    item.fake ? <div key={index}></div> :
                                                                        <div key={index}>
                                                                            <ImageCard
                                                                                item={item}
                                                                                urlImage={item.ruta}
                                                                                // onClickDelete={() => handleSubmitFotografiaDelete(item.id)}
                                                                                heightRem={14}
                                                                            />
                                                                        </div>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }

                                            </ContainerDragDrop.ItemsDynamics>
                                            :
                                            <UploadCard
                                                height='15rem'
                                                label={Lang('NUEVA_IMAGEN')}
                                                onClick={() => inputFileRef.current.click()}
                                                className='m-3'
                                            />
                                    }

                                    <input className="d-none" type="file" onChange={handleChangeInputImage} ref={inputFileRef} />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputTextarea
                                        label={Lang('DESCRIPCION') + ': *'}
                                        name='descripcio'
                                        value={formFotoDeficiencia.descripcio}
                                        onChange={handleInputDocumento}
                                        // fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                        localValidation={validDocumento.descripcio}
                                    />
                                </Grid>
                            </Grid>

                    }

                </ModalAdvanced.Body>
                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubirImagen}
                                loadings={[fetchSubirFotografiaPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={handleSubmitFotografiaDelete}
                                    loadings={[fetchSubirFotografiaDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handlefetchSubirFotografiaPATCH}
                                    loadings={[fetchSubirFotografiaPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }
                </ModalAdvanced.Footer>
            </ModalAdvanced>


            <ModalInfo
                data={dataModal_InfoError}
                handleClose={handleCloseModal_InfoError}
                message={Lang('SE_HA_EXCEDIDO_PESO_PERMITIDO')}
            />

            <ModalCropImage
                show={dataModal_CropImage}
                handleClose={handleCloseModal_CropImage}
                height='500px'
                setInputFiles={setInputFiles}
                InputFiles={InputFiles}
                handleSubirImagen={handleCloseModal_CropImage}
                tipo='FOTO'
                escalas={[
                    { x: 10, y: 10, label: 'Cuadrado' }
                ]}
                fetchData={fetchSubirFotografiaPOSTData}
            />

            <ModalStatusServer fetchData={fetchSubirFotografiaPOSTData} onlyError />
            <ModalStatusServer fetchData={fetchSubirFotografiaDELETEData} onlyError />
        </>
    );
};



const LoaderFotoDeficiencia = () => {
    return (
        <>
            <Grid container columnSpacing={4}>
                <Grid item xs={12} className="my-3">

                    <Placeholder width="100%" height='11rem' />
                </Grid>
                <Grid item xs={12} className="my-3">

                    <Placeholder width="100%" height='4rem' className="delay-1" />
                </Grid>
            </Grid>
        </>
    )
}