import { useAxios } from "../../../../../../../../hooks/useAxios"
import { MedidaAdapters, MedidasAdapters, PotencialMejoraItemAdminAdapters } from "./medidas.adapters"

const MedidasServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/potencial_mejora/edificio_mejoras',
            type: 'json',
            adapter: {
                method: MedidasAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    PATCH_REORDENAR: function (idEdificio: string) {

        return useAxios({
            method: 'PATCH',
            url: `/potencial_mejora/reordenar_edificio_mejoras/${idEdificio}`,
            type: 'json',
            adapter: {
                method: MedidasAdapters.PATCH_REORDENAR,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

const MedidaServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/edificio_mejora`,
            type: 'json',
            adapter: {
                method: MedidaAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_SELECCIONADO: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.PATCH_SELECCIONADO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
    
    PATCH_URGENTE: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.PATCH_URGENTE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_FASE: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.PATCH_FASE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_ONBLUR_COSTE: function () {

        return useAxios({
            method: 'PATCH',
            url: '/potencial_mejora/edificio_mejora',
            type: 'json',
            adapter: {
                method: MedidaAdapters.PATCH_ONBLUR_COSTE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

const PotencialMejoraItemAdminServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora`,
            type: 'json',
            adapter: {
                method: PotencialMejoraItemAdminAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },
}

export {
    MedidasServices,
    MedidaServices,
    PotencialMejoraItemAdminServices
}