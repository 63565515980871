import { parseAny, parseFile, parseNumber, parseObject, parseString, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../../../controllers/controllers"

const AnotacionImagenesAdapters = {

    GET_ALL: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    creat_per_id: parseNumber(item.creat_per_id),
                    data_adjunt: parseString(item.data_adjunt),
                    nom: parseString(item.nom),
                    projecte_id: parseNumber(item.projecte_id),
                    projecte_intervencion_id: parseAny(item.projecte_intervencion_id),
                    projecte_seguiment_id: parseAny(item.projecte_seguiment_id),
                    imatge: parseAny(item.imatge),
                    tipus: parseString(item.tipus),
                    url: parseString(item.url),
                    titol: parseString(item.titol),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    creat_per_id: parseNumber(item.creat_per_id),
                    data_adjunt: parseString(item.data_adjunt),
                    nom: parseString(item.nom),
                    projecte_id: parseNumber(item.projecte_id),
                    projecte_intervencion_id: parseAny(item.projecte_intervencion_id),
                    projecte_seguiment_id: parseAny(item.projecte_seguiment_id),
                    imatge: parseAny(item.imatge),
                    tipus: parseString(item.tipus),
                    url: parseString(item.url),
                    titol: parseString(item.titol),
                    descripcio: parseString(item.descripcio),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_id: parseNumber(item.projecte_id),
                    projecte_seguiment_id: parseAny(item.projecte_seguiment_id, {removeNullable: true}),
                    projecte_intervencion_id: parseAny(item.projecte_intervencion_id, {removeNullable: true}),
                    titol: parseString(item.titol),
                    data_adjunt: parseString(item.data_adjunt),
                    tipus: parseString(item.tipus),
                    file: parseAny(item.file),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_id: parseNumber(item.projecte_id),
                    projecte_seguiment_id: parseAny(item.projecte_seguiment_id, {removeNullable: true}),
                    projecte_intervencion_id: parseAny(item.projecte_intervencion_id, {removeNullable: true}),
                    titol: parseString(item.titol),
                    // data_adjunt: parseString(item.data_adjunt),
                    // tipus: parseString(item.tipus),
                    // file: parseAny(item.file),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}

export {
    AnotacionImagenesAdapters,
}