import { useNavigate } from "react-router";

export const CondGenSeccion3 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {

  const navigate = useNavigate()

  return (
    <div>
      <p>
        <strong>3.1. Alta como usuario</strong>
      </p>
      <p>
        Para realizar cualquier compra o contratación en nuestro sitio web el Cliente deberá registrarse previamente creando una cuenta de usuario.
      </p>
      <p>
        Para el alta como usuario se solicitarán datos personales que se consideran necesarios y mínimos para que CATEB pueda tramitar las solicitudes de compra y/o contratación, respetando en todo momento las disposiciones establecidas en el Reglamento UE 2016/679 y en la Ley Orgánica 3/20218, de 5 de diciembre, de Protección de Datos Personales. Para más información sobre el tratamiento que realiza CATEB sobre tus datos personales, puedes consultar nuestra <span className="link-primary" onClick={() => navigate('/aviso-legal')}>Política de Privacidad</span>.
      </p>
      <p>
        <strong>3.2. Aceptación de las condiciones</strong>
      </p>
      <p>
        La compra de los productos o servicios ofrecidos en la web se realizará cumplimentando los formularios y procedimientos electrónicos previstos en la web. El proceso se iniciará con la elección del producto o servicio y el seguimiento de los pasos indicados en la web, que suponen la aceptación de las condiciones generales y particulares de la compra de los productos de la web.
      </p>
      <p>
        La confirmación de la contratación de los servicios se realizará a través de la activación del botón «Acepto las condiciones de contratación», que irá acompañada de una concisa información sobre el tratamiento de los datos personales que llevará a cabo CATEB. El botón de aceptación de las condiciones aparecerá una vez el usuario haya seleccionado los servicios en los que esté interesado y con anterioridad al pago o confirmación de su intención de contratar.
      </p>
      <p>
        El usuario deberá leerse las presentes condiciones antes de aceptarlas, quedando vinculado a su cumplimiento desde el momento de la aceptación.
      </p>
      <p>
        Las presentes condiciones estarán disponibles para el usuario de manera permanente en nuestra página web.
      </p>
      <p>
        Una vez realizada la compra, en un plazo no superior a 24 horas, el cliente recibirá un justificante de su pedido.
      </p>
      <p>
        La compra de los productos y servicios implican la autorización por parte del cliente de la emisión y el envío de la factura en soporte electrónico.
      </p>
      <p>
        <strong>3.3. Requisitos de contratación</strong>
      </p>
      <p>
        Para realizar cualquier compra en nuestro sitio web <a href="http://www.libroedificiodigital.es" target="_blank" rel="noreferrer" className="link-primary">www.libroedificiodigital.es</a> será necesario que el Cliente sea mayor de 18 años.
      </p>
      <p>
        La compra y contratación en nuestro sitio web requerirá la previa alta como usuario, conforme se indica en el apartado 3.1. de estas condiciones. El Cliente deberá introducir sus credenciales de acceso como método de identificación en las compras y contrataciones que realice. Las credenciales de acceso estarán constituidas por un documento identificativo y contraseña elegida por el Cliente.
      </p>
      <p>
        Una vez realizado el pedido y confirmada la recepción de pago por parte de CATEB, el Cliente recibirá un correo electrónico donde se le confirmará la recepción del pedido, comenzando en ese momento el plazo de entrega en caso de que resultase aplicable. Si en un plazo de 24 horas no ha recibido el email de confirmación, el Cliente deberá ponerse en contacto inmediatamente con CATEB llamando al teléfono de contacto 93 240 20 60 o enviando un email a <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>
      <p>
        Si durante el proceso de compra de cualquiera de nuestros productos o servicios ha introducido erróneamente algún dato, deberá ponerse en contacto inmediatamente con nosotros a través del teléfono de contacto 93 240 20 60 o enviando un email a <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>

      <p>
        <strong>3.4. Manifestaciones formales del Cliente</strong>
      </p>

      <p>
        Mediante la adquisición de los servicios el Usuario y/o Cliente, declara:
      </p>

      <ul>
        <li>
          Que es una persona mayor de edad y con capacidad para contratar.
        </li>
        <li>
          Que ha leído, comprendido y acepta las presentes Condiciones Generales de Contratación y demás normativa aplicable a la contratación de CATEB.
        </li>
      </ul>

    </div>
  )
}

const SeccionCA = () => {

  const navigate = useNavigate()

  return (
    <div>
      <p>
        <strong>3.1. Alta com a usuari</strong>
      </p>
      <p>
        Per realitzar qualsevol compra o contractació al nostre lloc web el Client haurà de registrar-se prèviament creant un compte d'usuari.
      </p>
      <p>
        Per a l'alta com a usuari se sol·licitaran dades personals que es consideren necessàries i mínimes perquè CATEB pugui tramitar les sol·licituds de compra i/o contractació, respectant en tot moment les disposicions establertes en el Reglament UE 2016/679 i en la Llei Orgànica 3/20218, de 5 de desembre, de Protecció de Dades Personals. Per a més informació sobre el tractament que realitza CATEB sobre les teves dades personals, pots consultar la nostra <span className="link-primary" onClick={() => navigate('/aviso-legal')}>Política de Privacitat</span>.
      </p>
      <p>
        <strong>3.2. Acceptació de les condicions</strong>
      </p>
      <p>
        La compra dels productes o serveis oferts al web es realitzarà emplenant els formularis i procediments electrònics previstos al web. El procés s'iniciarà amb l'elecció del producte o servei i el seguiment dels passos indicats a la web, que suposen l'acceptació de les condicions generals i particulars de la compra dels productes de la web.
      </p>
      <p>
        La confirmació de la contractació dels serveis es realitzarà a través de l'activació del botó «Accepto les condicions de contractació», que anirà acompanyada d'una concisa informació sobre el tractament de les dades personals que durà a terme CATEB. El botó d' acceptació de les condicions apareixerà una vegada l' usuari hagi seleccionat els serveis en els quals estigui interessat i amb anterioritat al pagament o confirmació de la seva intenció de contractar.
      </p>
      <p>
        L' usuari haurà de llegir-se les presents condicions abans d' acceptar-les, quedant vinculat al seu compliment des del moment de l' acceptació.
      </p>
      <p>
        Les presents condicions estaran disponibles per a l'usuari de manera permanent a la nostra pàgina web.
      </p>
      <p>
        Una vegada realitzada la compra, en un termini no superior a 24 hores, el client rebrà un justificant de la seva comanda.
      </p>
      <p>
        La compra dels productes i serveis impliquen l' autorització per part del client de l' emissió i l' enviament de la factura en suport electrònic.
      </p>
      <p>
        <strong>3.3. Requisits de contractació</strong>
      </p>
      <p>
        Per realitzar qualsevol compra al nostre lloc web <a href="http://www.llibreedificidigital.cat" target="_blank" rel="noreferrer" className="link-primary">www.llibreedificidigital.cat</a> caldrà que el Client sigui major de 18 anys.
      </p>
      <p>
        La compra i contractació al nostre lloc web requerirà la prèvia alta com a usuari, conforme s'indica a l'apartat 3.1. d' aquestes condicions. El Client haurà d' introduir les seves credencials d' accés com a mètode d' identificació en les compres i contractacions que realitzi. Les credencials d' accés estaran constituïdes per un document identificatiu i contrasenya escollida pel Client.
      </p>
      <p>
        Una vegada realitzada la comanda i confirmada la recepció de pagament per part de CATEB, el Client rebrà un correu electrònic on se li confirmarà la recepció de la comanda, començant en aquell moment el termini de lliurament en cas que resultés aplicable. Si en un termini de 24 hores no ha rebut l'email de confirmació, el Client haurà de posar-se en contacte immediatament amb CATEB trucant al telèfon de contacte 93 240 20 60 o enviant un email a consultoriatecnica@cateb.cat.
      </p>
      <p>
        Si durant el procés de compra de qualsevol dels nostres productes o serveis ha introduït erròniament alguna dada, s'haurà de posar en contacte immediatament amb nosaltres a través del telèfon de contacte 93 240 20 60 o enviant un email a <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>

      <p>
        <strong>3.4. Manifestacions formals del Client</strong>
      </p>

      <p>
        Mitjançant l'adquisició dels serveis l'Usuari i/o Client, declara:
      </p>

      <ul>
        <li>
          Que és una persona major d'edat i amb capacitat per contractar
        </li>
        <li>
          Que ha llegit, comprès i accepta les presents Condicions Generals de Contractació i la resta de normativa aplicable a la contractació de CATEB.
        </li>
      </ul>

    </div>
  )
}