import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useValidator } from "../../../../hooks/useValidator";
import { useLang } from "../../../../language";
import { validAlMenosUnoMarcado } from "../../../../helpers/validations";
import ModalAdvanced from "../../../../components/ModalAdvanced/ModalAdvanced";
import { InputText } from "../../../../components/Inputs";
import { ButtonAdvanced } from "../../../../components/ButtonAdvanced/ButtonAdvanced";
import { InputChecks } from "../../../../components/Inputs/InputChecks";
import { isStatusSuccess } from "../../../../helpers/helpers";
import { useForm } from "../../../../hooks/useForm";
import { AuthServices } from "../../../../controllers/auth/auth.services";
import { AuthAdapters } from "../../../../controllers/auth/auth.adapters";
import { useModalData } from "../../../../hooks/useModalData";
import { ModalStatusServer } from "../../../../components/Modals/ModalStatusServer";
import jwt_decode from 'jwt-decode'
import { logIn } from "../../../../store/slices/auth";
import { useDispatch } from "react-redux";
import { ModalCondicionesEdificio } from "../../ModalRegistroUsuario/ModalCondicionesEdificio";
import { ModalConfirm } from "../../../../components/Modals/ModalConfirm";
import { useNavigate } from "react-router";
import { ModalCondicionesContratacion } from "../../ModalRegistroUsuario/ModalCondicionesContratacion";

export const ModalAceptarEdificio = ({ data, handleClose, from = 'login', fetchEdificioGET }: any) => {

    const Lang = useLang()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    // --------------------- Modal Condiciones ---------------------

    const [dataModalCondiciones, handleShowModalCondiciones, handleCloseModalCondiciones] = useModalData()

    const [dataModalCondiciones2, handleShowModalCondiciones2, handleCloseModalCondiciones2] = useModalData()


    // ------- 

    const [formCondiciones, handleInputChangeCondiciones, handleFormChangeCondiciones, resetformCondiciones] = useForm({
        condiciones_1: [],
        condiciones_2: [],
    });

    const [validCondiciones, handleValidCondiciones, resetvalidCondiciones] = useValidator({
        condiciones_1: [
            { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        condiciones_2: [
            { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const [fetchAceptarEdificioPATCH, fetchAceptarEdificioPATCHData, resetfetchAceptarEdificioPATCHData]
        = AuthServices.PATCH_ACEPTAR_RECHAZAR_EDIFICIO()

    const [fetchRechazarEdificioPATCH, fetchRechazarEdificioPATCHData, resetfetchRechazarEdificioPATCHData]
        = AuthServices.PATCH_ACEPTAR_RECHAZAR_EDIFICIO()

    const [formRegistro, handleInputRegistro, handleFormRegistro, resetFormRegistro] = useForm({
        ...AuthAdapters.PATCH_USUARIO_NUEVO.send.schema({}),
        politica_privacitat_acceptada_en: [],
        nombre: '',
        roles: []
    })

    const handleSubmitAceptarEdificio = () => {

        if (handleValidCondiciones(formCondiciones)) {

            fetchAceptarEdificioPATCH({
                id: 'aprobar/' + formRegistro.id,
                params: {
                    _method: 'PATCH',
                },
                tokenParam: data.tokenParam
            })
        }
    }

    const handleSubmitRechazarEdificio = () => {

        fetchRechazarEdificioPATCH({
            id: 'rechazar/' + formRegistro.id,
            params: {
                _method: 'PATCH',
            },
            tokenParam: data.tokenParam
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAceptarEdificioPATCHData.status)) {

            handleClose()

            if (from === 'login') {

                const data2: any = jwt_decode(data.tokenParam)

                dispatch(logIn({
                    user: data2,
                    token: data.tokenParam,
                    perfil: data2.perfil || ''
                }))

            } else {

                fetchEdificioGET()
            }
        }

    }, [fetchAceptarEdificioPATCHData]);


    useEffect(() => {

        if (isStatusSuccess(fetchRechazarEdificioPATCHData.status)) {

            handleCloseModal_ConfirmRechazarEdificio()
            handleClose()

            if (from === 'edificio') {

                navigate('/edificios')
            }
        }

    }, [fetchRechazarEdificioPATCHData]);

    useEffect(() => {

        if (data.value) {

            handleFormRegistro({
                ...formRegistro,
                ...data?.edificio,
                roles: data?.roles,
            })

        } else {
            resetvalidCondiciones()
        }

    }, [data.value]);

    const [dataModal_ConfirmRechazarEdificio, handleShowModal_ConfirmRechazarEdificio, handleCloseModal_ConfirmRechazarEdificio]
        = useModalData()

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size={675}>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {Lang('ACEPTACION_INGRESO_EDIFICIO')}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-0 p-0'>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className="mt-2"
                    >
                        <Grid container spacing={2} columnSpacing={3} rowSpacing={0}>

                            <Grid item xs={12} md={12} lg={12}>
                                <InputText
                                    label={Lang('NOMBRE_EDIFICIO') + ':'}
                                    value={formRegistro.nom}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <InputText
                                    label={Lang('REF_CATASTRAL') + ':'}
                                    value={formRegistro.ref_cadastral}
                                    readOnly
                                />
                            </Grid>


                            <Grid item xs={12} md={6} lg={6}>
                                <InputText
                                    label={Lang('FECHA_CREACION') + ':'}
                                    value={(new Date(formRegistro.created_at))?.toLocaleDateString()}
                                    readOnly
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <Typography variant='body2' className='mb-1' component="span">
                                    {Lang('ROLES_ASIGNADOS')}:
                                </Typography>
                                <ul className="mb-0">
                                    {
                                        formRegistro.roles?.map((item: any) => {
                                            return (
                                                <li key={item.id} className="my-1">{item.name}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </Grid>

                            {/* <Grid item xs={12} className="mt-0">
                                <InputChecks
                                    name='politica_privacitat_acceptada_en'
                                    values={formRegistro.politica_privacitat_acceptada_en}
                                    valuesDefault={[
                                        {
                                            id: 'politica_privacitat_acceptada_en',
                                            label: <span onClick={(e) => {
                                                e.preventDefault()
                                                handleShowModalCondiciones(e)
                                            }}>{Lang('HAZ')} <span className="color-primary">{Lang('CLICK_AQUI')}</span> {Lang('PARRAFO_LEER_ACEPTAR_CONDICIONES')} </span>,
                                        }
                                    ]}
                                    onChange={handleInputRegistro}
                                    localValidation={validRegistro.politica_privacitat_acceptada_en}
                                    fetchData={fetchAceptarEdificioPATCHData}
                                />
                            </Grid> */}

                            <Grid item xs={12} className='mt-2'>
                                <InputChecks
                                    name='condiciones_1'
                                    values={formCondiciones.condiciones_1}
                                    valuesDefault={[
                                        {
                                            id: 'condiciones_1',
                                            label: <small onClick={(e) => {
                                                e.preventDefault()
                                                handleShowModalCondiciones({
                                                    // indexAccordion: 10
                                                })
                                            }}>{Lang('MANIFIESTO_LEIDO_Y_ACEPTO_LAS')} <span className="link-primary">{Lang('CONDICIONES_DE_CONTRATACION')}</span>.</small>,
                                        }
                                    ]}
                                    onChange={handleInputChangeCondiciones}
                                    localValidation={validCondiciones.condiciones_1}
                                />
                            </Grid>

                            <Grid item xs={12} className='mt-0 pt-0'>
                                <InputChecks
                                    name='condiciones_2'
                                    values={formCondiciones.condiciones_2}
                                    valuesDefault={[
                                        {
                                            id: 'condiciones_2',
                                            label: <small onClick={(e) => {
                                                e.preventDefault()
                                                handleShowModalCondiciones2({
                                                    index2Accordion: 9
                                                })
                                            }}>{Lang('MANIFIESTO_LEIDO_Y_ACEPTO_INFORMACION_RELATIVA_A')} <span className="link-primary">{Lang('PROTECCION_DATOS_CARACTER_PERSONAL')}</span>.</small>,
                                        }
                                    ]}
                                    onChange={handleInputChangeCondiciones}
                                    localValidation={validCondiciones.condiciones_2}
                                />
                            </Grid>


                            <Grid item xs={12} justifyContent='between' display='flex' className="mt-0">

                                <ButtonAdvanced
                                    variant="outlined" type="button" className="me-auto"
                                    handleSubmit={handleShowModal_ConfirmRechazarEdificio}
                                    value={Lang('RECHAZAR')}
                                />

                                <ButtonAdvanced
                                    variant="contained" type="button" className="ms-auto"
                                    handleSubmit={handleSubmitAceptarEdificio}
                                    loadings={[fetchAceptarEdificioPATCHData.loading]}
                                    value={Lang('ACEPTAR_Y_CONTINUAR')}
                                />
                            </Grid>

                        </Grid>

                    </Box>

                </ModalAdvanced.Body>

            </ModalAdvanced>

            <ModalCondicionesEdificio
                data={dataModalCondiciones}
                handleClose={handleCloseModalCondiciones}
                handleInputRegistro={handleInputRegistro}
            />

            <ModalStatusServer fetchData={fetchAceptarEdificioPATCHData} />

            <ModalStatusServer fetchData={fetchRechazarEdificioPATCHData} />

            <ModalConfirm
                data={dataModal_ConfirmRechazarEdificio}
                handleClose={handleCloseModal_ConfirmRechazarEdificio}
                message={<p>{Lang('PARRAFO_DESEA_RECHAZAR_ACCESO_EDIFICIO')}</p>}
                textOnClickOk={Lang('SI_RECHAZAR')}
                variantClickOk='contained'
                onClickOK={handleSubmitRechazarEdificio}
                fetchData={fetchRechazarEdificioPATCHData}
            />

            <ModalCondicionesContratacion
                data={dataModalCondiciones}
                handleClose={handleCloseModalCondiciones}
                handleInput={handleInputChangeCondiciones}
                name='condiciones_1'
            />

            <ModalCondicionesContratacion
                data={dataModalCondiciones2}
                handleClose={handleCloseModalCondiciones2}
                handleInput={handleInputChangeCondiciones}
                name='condiciones_2'
            />

        </>
    );
};
