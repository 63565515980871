import { Button } from "@mui/material";
import { useLang } from "../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSync } from "@fortawesome/free-solid-svg-icons";
import { ModalConfirm } from "../../../../../../components/Modals/ModalConfirm";
import { useModalData } from "../../../../../../hooks/useModalData";
import { ModalStatusServer } from "../../../../../../components/Modals/ModalStatusServer";
import { useEffect } from "react";
import { isStatusSuccess, openLinkByClick } from "../../../../../../helpers/helpers";
import { DocumentoServices } from "./controllers/documentacion.services";
import ModalAdvanced from "../../../../../../components/ModalAdvanced/ModalAdvanced";

export const RowDocPotencialMejorasEdificio = ({ idEdificio }: any) => {

    const Lang = useLang()

    const [dataModal_ConfirmGenerar, handleShowModal_ConfirmGenerar, handleCloseModal_ConfirmGenerar] = useModalData()

    const [dataModal_ConfirmGenerarVistaPrev, handleShowModal_ConfirmGenerarVistaPrev, handleCloseModal_ConfirmGenerarVistaPrev] = useModalData()

    const [fetchGenerarDocGET, fetchGenerarDocGETData] = DocumentoServices.GET_POTENCIAL_MEJORAS(idEdificio)

    const [fetchGenerarDocGETBlob, fetchGenerarDocGETBlobData, resetfetchGenerarDocGETBlobData] = DocumentoServices.GET_POTENCIAL_MEJORAS_BLOB(idEdificio)

    const handleSubmitGenerar = () => {

        fetchGenerarDocGET({
            params: {
                toDownload: false
            }
        })
    }

    const handleSubmitGenerarVistaPrevia = () => {

        fetchGenerarDocGETBlob({
            params: {
                toDownload: true
            }
        })
    }

    const [dataModal_ShowBlobPDF, handleShowModal_ShowBlobPDF, handleCloseModal_ShowBlobPDF] = useModalData()

    useEffect(() => {

        if (isStatusSuccess(fetchGenerarDocGETBlobData.status)) {

            handleCloseModal_ConfirmGenerarVistaPrev()

            handleShowModal_ShowBlobPDF()
        }

    }, [fetchGenerarDocGETBlobData]);

    useEffect(() => {

        if (!dataModal_ShowBlobPDF.value) {

            resetfetchGenerarDocGETBlobData()
        }

    }, [dataModal_ShowBlobPDF]);

    useEffect(() => {

        if (isStatusSuccess(fetchGenerarDocGETData.status)) {

            if (fetchGenerarDocGETData?.data?.data?.ruta) {

                openLinkByClick(fetchGenerarDocGETData?.data?.data?.ruta)
            }
        }

    }, [fetchGenerarDocGETData]);


    return (
        <>
            <Button variant="contained" color='success' className="me-3" onClick={() => {
                handleSubmitGenerarVistaPrevia()
                handleShowModal_ConfirmGenerarVistaPrev({
                    btnText: 'VISTA_PREVIA',
                })
            }}>
                <FontAwesomeIcon icon={faEye} className=" me-2" /> {Lang('VISTA_PREVIA')}
            </Button>

            <Button variant="contained" className="" onClick={() => {
                handleSubmitGenerar()
                handleShowModal_ConfirmGenerar({
                    btnText: 'GENERAR_DOCUMENTO',
                })
            }}>
                <FontAwesomeIcon icon={faSync} className=" me-2" /> {Lang('GENERAR_DOCUMENTO')}
            </Button>

            <ModalConfirm
                data={dataModal_ConfirmGenerar}
                handleClose={handleCloseModal_ConfirmGenerar}
                message={<p>{Lang('PARRAFO_GENERANDO_DOCUMENTO_ESPERE')}</p>}
                textOnClickOk={Lang(dataModal_ConfirmGenerar?.btnText)}
                variantClickOk='contained'
                onClickOK={handleSubmitGenerar}
                fetchData={fetchGenerarDocGETData}
            />

            <ModalConfirm
                data={dataModal_ConfirmGenerarVistaPrev}
                handleClose={handleCloseModal_ConfirmGenerarVistaPrev}
                message={<p>{Lang('PARRAFO_GENERANDO_DOCUMENTO_ESPERE')}</p>}
                textOnClickOk={Lang(dataModal_ConfirmGenerarVistaPrev?.btnText)}
                variantClickOk='contained'
                onClickOK={handleSubmitGenerar}
                fetchData={fetchGenerarDocGETBlobData}
            />

            <ModalStatusServer fetchData={fetchGenerarDocGETData} />

            <ModalStatusServer fetchData={fetchGenerarDocGETBlobData} onlyError />

            <ModalAdvanced data={dataModal_ShowBlobPDF} handleClose={handleCloseModal_ShowBlobPDF} size='xl'>
                <ModalAdvanced.Head></ModalAdvanced.Head>
                <ModalAdvanced.Body className='mb-3 p-0'>
                    <div className="">
                        {
                            fetchGenerarDocGETBlobData?.data?.data &&
                            <iframe id='blob' className="border-0 min-vh-85 w-100" src={window.URL.createObjectURL(fetchGenerarDocGETBlobData?.data?.data)}></iframe>
                        }
                    </div>
                </ModalAdvanced.Body>
            </ModalAdvanced>
        </>
    )
};
