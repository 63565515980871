import { useLang } from "../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { ModalIngresoProyecto } from "./ModalIngresoProyecto";
import { receiveFecha, receiveFechaFormat2 } from "../../../../../../../controllers/controllers";
import { parseMoneda } from "../../../../../../../helpers/helpers";

export const TableIngresosProyecto = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, idCurrentProject, fetchPlanificacionFinancieraGET, tipoIngresosStaticServices, 
    readOnly }: any) => {

    const Lang = useLang()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    let allActions: any = []

    let allActionsPerLine: any = []

    if (!readOnly) {

        allActions.push(
            {
                label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVO_INGRESO')}</span>,
                variant: 'contained',
                action: () => handleShowUsuario({
                    action: 'CREATE',
                }),
            }
        )

        allActionsPerLine.push(
            {
                variant: 'outlined',
                label: <FontAwesomeIcon icon={faEdit} className='' />,
                action: (item: any) => {
                    handleShowUsuario({
                        ...item,
                        action: 'EDIT',
                        item
                    })
                },
                tooltip: {
                    text: Lang('EDITAR')
                },
            }
        )
    }

    const columnsTable = {

        ingres: {
            title: Lang('INGRESO'),
            adapter: true,
            adapterFunction: (item: any = '') => parseMoneda(item) || '-'
        },
        tipus_projecte_ingressos: {
            title: Lang('TIPO_INGRESO'),
            adapter: tipoIngresosStaticServices,
        },
        data: {
            title: Lang('FECHA'),
            adapter: true,
            adapterFunction: (item: any = '') => receiveFechaFormat2(receiveFecha(item)) || '-'
        }
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-0'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('INGRESOS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                {/* <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                /> */}
            </TableAdvanced>

            <ModalIngresoProyecto
                data={showUsuario}
                handleClose={handleCloseUsuario}
                idCurrentProject={idCurrentProject}
                tipoIngresosStaticServices={tipoIngresosStaticServices}
                fetchPlanificacionFinancieraGET={fetchPlanificacionFinancieraGET}
            />
        </>
    )
}
