import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { InputChecks } from "../../../../../../../components/Inputs/InputChecks"
import { ImportarActividadServices } from "../controllers/importar-actividades.services"
import { InputSelect } from "../../../../../../../components/Inputs/InputSelect"
import { MaestrosServices } from "../../../../../../../controllers/maestros/maestros.services"

export const ModalMantPreventivo = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchMantPrevGET, fetchMantPrevGETData] = ImportarActividadServices.GET_PREVENTIVO()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        // nom: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // tipus_document: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevGETData.status)) {

            handleFormDocumento({
                ...fetchMantPrevGETData.data
            })
        }

    }, [fetchMantPrevGETData]);


    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "periodicitat_unidad",
                    },
                ]
            })

            if (isNumber(data?.id)) {

                fetchMantPrevGET({
                    id: data?.id + '/mostrar'
                })
            }

        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('OPERACION')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchMantPrevGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ': *'}
                                            name='descripcio'
                                            value={formDocumento.descripcio}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={5} lg={5}>
                                        <InputText
                                            label={Lang('PERIODICIDAD_EN_TEXTO') + ': *'}
                                            name='periodicitat_text'
                                            value={formDocumento.periodicitat_text}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('PERIODICIDAD') + ': *'}
                                            name='periodicitat'
                                            type='number'
                                            value={formDocumento.periodicitat}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputSelect
                                            label={Lang('UNIDAD') + ': *'}
                                            name='periodicitat_unidad'
                                            value={formDocumento.periodicitat_unidad}
                                            values={fetchParamsPublicGETData?.data?.['periodicitat_unidad'] || []}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid width='100%'></Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                        <InputText
                                            label={Lang('DOCUMENTO_REFERENCIA') + ': *'}
                                            name='document_referencia'
                                            value={formDocumento.document_referencia}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <InputText
                                            label={Lang('RESPONSABLE') + ': *'}
                                            name='responsable'
                                            value={formDocumento.responsable}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputChecks
                                            name='manual_habitatge'
                                            values={formDocumento.manual_habitatge}
                                            valuesDefault={[
                                                {
                                                    id: 'manual_habitatge',
                                                    label: Lang('INCLUIR_MANUAL_VIVIENDA')
                                                }
                                            ]}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="outlined" type="button" className="mt-3 ms-auto"
                        handleSubmit={handleClose}
                        value={Lang('SALIR')}
                    />
                </ModalAdvanced.Footer>

            </ModalAdvanced>
        </>
    )
}