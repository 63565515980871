import { useAxios } from "../../../../../../../hooks/useAxios"
import { ImportarActividadAdapters, ImportarActividadesAdapters } from "./importar-actividades.adapters"

const ImportarActividadesServices = {

    GET_PREVENTIVOS: function () {

        return useAxios({
            method: 'GET',
            url: '/operaciones/edificio-operaciones',
            type: 'json',
            adapter: {
                method: ImportarActividadesAdapters.GET_PREVENTIVOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    
    GET_CORRECTIVOS: function () {

        return useAxios({
            method: 'GET',
            url: '/deficiencias',
            type: 'json',
            adapter: {
                method: ImportarActividadesAdapters.GET_CORRECTIVOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_MEJORAS: function () {

        return useAxios({
            method: 'GET',
            url: '/potencial_mejora/edificio_mejoras',
            type: 'json',
            adapter: {
                method: ImportarActividadesAdapters.GET_MEJORAS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

}

const ImportarActividadServices = {

    GET_PREVENTIVO: function () {

        return useAxios({
            method: 'GET',
            url: `/operaciones/edificio-operaciones`,
            type: 'json',
            adapter: {
                method: ImportarActividadAdapters.GET_PREVENTIVO,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_CORRECTIVO: function () {

        return useAxios({
            method: 'GET',
            url: `/deficiencias`,
            type: 'json',
            adapter: {
                method: ImportarActividadAdapters.GET_CORRECTIVO,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_MEJORA: function () {

        return useAxios({
            method: 'GET',
            url: `/potencial_mejora/edificio_mejora`,
            type: 'json',
            adapter: {
                method: ImportarActividadAdapters.GET_MEJORA,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function (idedificio: string) {

        return useAxios({
            method: 'post',
            url: `/edificio_proyectos/intervenciones/${idedificio}/importacion`,
            type: 'json',
            adapter: {
                method: ImportarActividadAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    ImportarActividadesServices,
    ImportarActividadServices,
}