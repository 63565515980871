import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Placeholder } from "../../../../../../../../components/Placeholder/Placeholder";
import ContainerDragDrop from "../../../../../../../../components/ContainerDragDrop";
import { FotosDeficienciaServices } from "./controllers/fotosdeficiencia.services";
import { useLang } from "../../../../../../../../language";
import { ImageCard } from "../../../../../../../../components/ImageCard/Index";
import { UploadCard } from "../../../../../../../../components/UploadCard/Index";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { ModalFotoDeficiencia } from "./ModalFotoDeficiencia";
import { isStatusSuccess } from "../../../../../../../../helpers/helpers";

export const FotosDeficiencia = ({ iddeficiencia }: any) => {

  const [InputFiles, setInputFiles] = useState<any>([])

  const Lang = useLang()

  const [fetchFotografiasGET, fetchFotografiasGETData] = FotosDeficienciaServices.GET_ALL()

  const [dataModal_FotoDeficiencia, handleShowModal_FotoDeficiencia, handleCloseModal_FotoDeficiencia] = useModalData()

  const fetchSuccess = () => {

    setInputFiles([])

    fetchFotografiasGET({
      params: {
        deficiencie_id: iddeficiencia,
        limit: 100,
      }
    })
  }

  useEffect(() => {

    fetchSuccess()

  }, []);


  useEffect(() => {

    if (isStatusSuccess(fetchFotografiasGETData.status)) {

      setInputFiles(
        [...fetchFotografiasGETData.data?.items]
      )
    }

  }, [fetchFotografiasGETData]);

  return (
    <div>
      <Typography variant='body2' className='mb-1 pb-2 mt-0'>
        {Lang('PARRAFO_IMAGENES_FORMATO_JGP_PNG')}
      </Typography>

      <div className="row">
        <Grid container>

          {
            fetchFotografiasGETData.loading ?
              <LoaderFotosDeficiencia />
              :
              <ContainerDragDrop

                blocked={false}
              >
                {
                  InputFiles?.length > 0 &&
                  <ContainerDragDrop.ItemsDynamics
                    elements={InputFiles}
                    // handleOrderElement={handleOrdenarImagenes}
                    ComponentCard={ImageCard}
                    setElements={setInputFiles}
                    classNameContainer=''
                    xs={12} sm={6} md={6} lg={3}
                  >

                    {
                      InputFiles?.map((item: any, index: number) => {
                        return (
                          <>
                            {
                              item.fake ? <div key={index}></div> :
                                <div key={index}>
                                  <ImageCard
                                    item={item}
                                    urlImage={item.ruta}
                                    // onClickEdit={() => {
                                    //   handleShowModal_FotoDeficiencia({
                                    //     action: 'EDIT',
                                    //     id: item.id,
                                    //     item: item,
                                    //   })
                                    // }}
                                    heightRem={11}
                                  />
                                </div>
                            }
                          </>
                        )
                      })
                    }

                  </ContainerDragDrop.ItemsDynamics>
                }

                <Grid item xs={12} sm={6} md={6} lg={3} className="my-3">
                  {/* <UploadCard
                    height='11rem'
                    label={Lang('NUEVA_IMAGEN')}
                    onClick={() => {
                      handleShowModal_FotoDeficiencia({
                        action: 'CREATE'
                      })
                    }}
                    className='m-3'
                  /> */}
                </Grid>
              </ContainerDragDrop>
          }

        </Grid>

        <ModalFotoDeficiencia
          data={dataModal_FotoDeficiencia}
          handleClose={handleCloseModal_FotoDeficiencia}
          iddeficiencia={iddeficiencia}
          fetchSuccess={fetchSuccess}
          setInputFiles={setInputFiles}
          InputFiles={InputFiles}
        />
      </div>
    </div>
  )
};


const LoaderFotosDeficiencia = () => {
  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={3} className="my-3">

          <Placeholder width="100%" height='11rem' />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} className="my-3">

          <Placeholder width="100%" height='11rem' className="delay-1" />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} className="my-3">

          <Placeholder width="100%" height='11rem' className="delay-2" />
        </Grid>
      </Grid>
    </>
  )
}