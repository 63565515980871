import { useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { clearEmptyKeys, getValuesParams, isFetchDataInitLoader, setUrlByParams } from "../../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../../hooks/useForm";
import queryString from 'query-string';
import { useLocation } from "react-router";
import { TablePeriodicidades } from "./TablePeriodicidades";
import { ActividadServices, ActividadesServices } from "../../controllers/actividades.services";
import { LoaderModalFormPrimary } from "../../../../../../../../components/Loaders/LoaderModalFormPrimary";
import { ModalStatusServer } from "../../../../../../../../components/Modals/ModalStatusServer";

export const Periodicidades = ({ readOnly, idEdificio, fetchEdificioGETData, idCurrentProject, idActividad,
    navLinksFases, referencia_padre_id }: any) => {

    const [fetchActividadesGET, fetchActividadesGETData] = ActividadesServices.GET(idEdificio)

    // --------------------- Modal Nuevo edificio ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // Buscador de agentes

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            edifici_id: idEdificio,
            order: '',
            tipo_agente: '',
            // projecteid: idCurrentProject,
            projecte_intervencio_id: idActividad,
            referencia_padre_id
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        tipo_agente: params.tipo_agente || allValuesSearch.tipo_agente,
        projecte_intervencio_id: params.projecte_intervencio_id || allValuesSearch.projecte_intervencio_id,
        referencia_padre_id: params.referencia_padre_id || allValuesSearch.referencia_padre_id,
        // projecteid: allValuesSearch.projecteid,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecte_intervencio_id', 'referencia_padre_id')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchActividadesGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        if (formValuesSearch.referencia_padre_id) {

            const urlParams = getValuesParams(formValuesSearch)

            setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecte_intervencio_id', 'referencia_padre_id')

            fetchActividadesGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit, formValuesSearch.referencia_padre_id])

    useEffect(() => {

        if (referencia_padre_id) {

            handleFormChange({
                ...formValuesSearch,
                referencia_padre_id
            })
        }

    }, [referencia_padre_id]);


    const [showFilter, setshowFilter] = useState(false);

    const [fetchActividadClonarPOST, fetchActividadClonarPOSTData, resetfetchActividadClonarPOSTData] = ActividadServices.POST_CLONAR(idActividad)


    return (
        <>
            <div className="">
                {/* <Container maxWidth="lg" className='mt-3 mb-auto py-2'> */}

                <Grid container>

                    <Grid item xs={12} className='py-0 py-lg-0 mb-3'>

                        <section>
                            {
                                formValuesSearch.referencia_padre_id &&
                                <>
                                    {
                                        isFetchDataInitLoader(fetchActividadesGETData) ?
                                            <Card>
                                                <CardContent>
                                                    <LoaderModalFormPrimary />
                                                </CardContent>
                                            </Card>
                                            :
                                            <TablePeriodicidades
                                                data={fetchActividadesGETData.data?.items}
                                                loading={fetchActividadesGETData.loading}
                                                total={fetchActividadesGETData.data?.total}
                                                perPage={fetchActividadesGETData.data?.per_page}

                                                formValuesSearch={formValuesSearch}
                                                handleFormChange={handleFormChange}
                                                handleInputChangeSearch={handleInputChangeSearch}

                                                idEdificio={idEdificio}
                                                fetchActividadesGET={fetchActividadesGET}
                                                // fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                                                fetchEdificioGETData={fetchEdificioGETData}
                                                fetchSuccess={(e: any) => handleSubmitSearch(e, formValuesSearch)}

                                                setshowFilter={setshowFilter}
                                                showFilter={showFilter}
                                                idCurrentProject={idCurrentProject}
                                                idActividad={idActividad}
                                                readOnly={readOnly}
                                                navLinksFases={navLinksFases}
                                                fetchActividadClonarPOST={fetchActividadClonarPOST}
                                                fetchActividadClonarPOSTData={fetchActividadClonarPOSTData}
                                                resetfetchActividadClonarPOSTData={resetfetchActividadClonarPOSTData}
                                            />
                                    }
                                </>
                            }

                        </section>

                    </Grid>

                </Grid>

                {/* </Container> */}
            </div>

            <ModalStatusServer fetchData={fetchActividadClonarPOSTData} />

        </>
    );
};
