import { parseAny, parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../../controllers/controllers"

const DerramasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    projecte_id: parseNumber(item.projecte_id),
                    derrama: parseString(item.derrama),
                    importe_total_financiar: parseNumber(item.importe_total_financiar) + ' €',
                    fecha_inicio_derrama: receiveFechaFormat2(receiveFecha(item.fecha_inicio_derrama)),
                    fecha_fin_derrama: parseAny(item.fecha_fin_derrama),
                    estado: parseString(item.fecha_fin_derrama),
                    meses: parseNumber(item.meses),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const DerramaAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    projecte_id: parseNumber(item.projecte_id),
                    derrama: parseString(item.derrama),
                    importe_total_financiar: parseNumber(item.importe_total_financiar),
                    fecha_inicio_derrama: receiveFecha(item.fecha_inicio_derrama),
                    fecha_fin_derrama: receiveFecha(item.fecha_fin_derrama),
                    meses: parseNumber(item.meses),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    projecte_id: parseNumber(item.projecte_id),
                    derrama: parseString(item.derrama),
                    importe_total_financiar: parseNumber(item.importe_total_financiar),
                    fecha_inicio_derrama: parseString(item.fecha_inicio_derrama),
                    fecha_fin_derrama: parseString(item.fecha_fin_derrama),
                    meses: parseNumber(item.meses),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    derrama: parseString(item.derrama, { removeNullable: true }),
                    importe_total_financiar: parseNumber(item.importe_total_financiar, { removeNullable: true }),
                    // fecha_inicio_derrama: parseString(item.fecha_inicio_derrama, { removeNullable: true }),
                    fecha_fin_derrama: parseString(item.fecha_fin_derrama, { removeNullable: true }),
                    // meses: parseNumber(item.meses, { removeNullable: true }),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    DerramasAdapters,
    DerramaAdapters,
}