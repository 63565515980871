import { DropdownLanguage } from "../DropdownLanguage";
import logo from '../../../assets/images/logo.png'
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router";

export const LayoutPage = ({ children }: any) => {

    const { language } = useSelector((state: RootState) => state.language)

    const navigate = useNavigate()

    return (
        <section className="PageDefault PageAvisoLegal">
            <nav className="PageDefault__NavTop d-flex align-items-center">

                <div className='px-3 d-flex justify-content-between w-100 align-items-center'>

                    <img src={logo} alt="logo" className='cursor-pointer d-block d-lg-none' onClick={() => navigate('/')} />

                    <img src={`${process.env.PUBLIC_URL}/images/Logo_LED_${language}.png`} alt="logo" className='cursor-pointer d-none d-lg-block' onClick={() => navigate('/')} />

                    <div className='d-flex align-items-center'>

                        <DropdownLanguage />

                    </div>

                </div>
            </nav>
            <section className="PageDefault__contentbottom">
                <main className="PageDefault__contentmain">

                    {children}

                </main>

            </section>
        </section>
    );
};
