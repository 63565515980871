import { useAxios } from "../../../../../hooks/useAxios"
import { ElementoConstructivoAdapters, ElementosConstructivosAdapters } from "./elemconst.adapters"

const ElementosConstructivosServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/elementos_constructivos/elementos',
            type: 'json',
            adapter: {
                method: ElementosConstructivosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_BY_EDIFICIO: function () {

        return useAxios({
            method: 'GET',
            url: '/elementos_constructivos/elementos',
            type: 'json',
            adapter: {
                method: ElementosConstructivosAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },

    
    PATCH_ELEMENTOS_BY_EDIFICIO: function () {

        return useAxios({
            method: 'PATCH',
            url: '/elementos_constructivos/elementos',
            type: 'json',
            adapter: {
                method: ElementoConstructivoAdapters.PATCH_ELEMENTOS_BY_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    GET_IMPORTAR_EDIFICIO: function () {

        return useAxios({
            method: 'GET',
            url: '/elementos_constructivos/elementos',
            type: 'json',
            adapter: {
                method: ElementosConstructivosAdapters.GET_IMPORTAR_EDIFICIO,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'array', // object, array, list (with pagination)
                }
            },
        })
    },
}

const ElementoConstructivoServices = {

    GET: function (id: string) {

        return useAxios({
            method: 'get',
            url: `/${id}`,
            type: 'json',
            adapter: {
                method: ElementoConstructivoAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'post',
            url: '/',
            type: 'json',
            adapter: {
                method: ElementoConstructivoAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_BY_EDIFICIO: function () {

        return useAxios({
            method: 'PATCH',
            url: '/elementos_constructivos/elementos',
            type: 'json',
            adapter: {
                method: ElementoConstructivoAdapters.PATCH_BY_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_BY_METRICAS: function (id: string) {

        return useAxios({
            method: 'POST',
            url: `/elementos_constructivos/edificio/${id}/elemento/metricas`,
            type: 'json',
            adapter: {
                method: ElementoConstructivoAdapters.POST_BY_METRICAS,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

}

export {
    ElementosConstructivosServices,
    ElementoConstructivoServices
}