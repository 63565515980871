import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router";
import './NavLeft.scss'
import { NavLink } from "react-router-dom";
import { isEmpty } from "lodash";

export const NavLeft = ({ id, navLinks, buttonAdd = null, defaultLink }: any) => {

    const navigate = useNavigate()

    return (
        <>
            <nav className='NavigationPrimary d-flex flex-column position-relative start-0 overflow-visible'>

                <ul className='list-unstyled p-0 m-0 position-static'>
                    {
                        navLinks?.map((item: any) => {
                            return (
                                <NavLink
                                    to={`./${item.link}${defaultLink ? `/${defaultLink}` : ''}`}
                                    className={({ isActive }) => isActive ? "NavigationPrimary__li--active" : ""}
                                    key={item.link}
                                >
                                    {item.title}
                                </NavLink>
                            )
                        })
                    }
                </ul>

                {
                    (defaultLink === '' || defaultLink === 'sistemas') &&
                    <div className="flex-grow-1 d-flex justify-content-center">

                        <div className={`position-sticky ${isEmpty(navLinks) ? 'mt-0' : 'mt-auto'}`} style={{ bottom: '0px' }}>
                            {buttonAdd}
                        </div>
                    </div>
                }
                {/* <div onClick={() => navigate(`/edificios/${id}`)} className="NavigationPromary__volver mt-auto">
                    <FontAwesomeIcon icon={faChevronLeft} className='me-2' /> Regresar al edificio
                </div> */}
            </nav>
        </>
    );
};
