import React from "react";
import { Placeholder } from "../../../../components/Placeholder/Placeholder";

export const LoaderFormPrimary = () => {
    
    return (
        <div>
            <Placeholder className='' height='50px' width='100%' />
            <Placeholder className='my-3 delay-1' height='100px' width='100%' />
            <Placeholder className='my-3 delay-2' height='200px' width='100%' />
        </div>
    );
};
