import { Typography } from "@mui/material";

export const SeccionHeader = ({ lang }: any) => {

    return (
        <>
            {
                lang === 'ES' && <SeccionHeaderES />
            }
            {
                lang === 'CA' && <SeccionHeaderCA />
            }
        </>
    )
};

const SeccionHeaderES = () => {

    return (
        <>
            <div className="d-flex flex-column mb-1 pb-2">
                <div className="d-flex align-items-end">

                    <Typography variant='h4' className='fw-bold fs-1' color='white'>
                        LED Libro del Edificio Digital
                    </Typography>
                </div>
                <Typography variant='h5' className='fs-4 fw-semibold' color='white'>
                    smart buildings for smart cities
                </Typography>
            </div>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                La herramienta digital de gestión integral de los edificios en tiempo real que permite la interoperatividad entre usuarios.
            </Typography>

            <Typography variant='h6' className='mb-0 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Reduce costes, revaloriza el patrimonio y optimiza el ciclo de vida útil de los edificios.
            </Typography>

            <Typography variant='h6' className='mb-3 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                ¡Invierte menos para vivir mejor!
            </Typography>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Una revolución digital en la edificación.
            </Typography>
        </>
    )
}

const SeccionHeaderCA = () => {

    return (
        <>
            <div className="d-flex flex-column mb-1 pb-2">

                <div className="d-flex align-items-end">

                    <Typography variant='h4' className='fw-bold fs-1' color='white'>
                        LED Llibre de l'Edifici Digital
                    </Typography>
                </div>
                <Typography variant='h5' className='fs-4 fw-semibold' color='white'>
                    smart buildings for smart cities
                </Typography>
            </div>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                L'eina digital de gestió integral dels edificis a temps real que permet la interoperativitat entre usuaris.
            </Typography>

            <Typography variant='h6' className='mb-0 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Redueix costos, revalora el patrimoni i optimitza el cicle de vida útil dels edificis.
            </Typography>

            <Typography variant='h6' className='mb-3 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Inverteix menys per viure millor!
            </Typography>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Una revolució digital a l'edificació.
            </Typography>
        </>
    )
}