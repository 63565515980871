import { groupBy } from "lodash"
import { parseAny, parseArray, parseBoolean, parseCheck, parseNumber, parseObject, parseString, receiveCheck, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../../../../../../../../controllers/controllers"

const MedidasAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    beneficio: parseAny(item.beneficio),
                    comentario: parseString(item.comentario),
                    complejidad: parseString(item.complejidad),
                    complejidad_texto: parseString(item.complejidad_texto),
                    coste: parseString(item.coste),
                    coste_maximo: parseString(item.coste_maximo),
                    coste_minimo: parseString(item.coste_minimo),
                    costo_texto: parseString(item.costo_texto),
                    creat_per_id: parseString(item.creat_per_id),
                    descripcion_plan: parseString(item.descripcion_plan),
                    duracion_plan: parseString(item.duracion_plan),
                    edifici_id: parseString(item.edifici_id),
                    edifici_millora_plan_id: parseString(item.edifici_millora_plan_id),
                    elemento: parseString(item.elemento),
                    elemento_constructivo: parseString(item.elemento_constructivo),
                    elemento_texto: parseString(item.elemento_texto),
                    exigencia: parseString(item.exigencia),
                    exigencia_texto: parseString(item.exigencia_texto),
                    fase_id: parseString(item.fase_id),
                    medida: parseString(item.medida),
                    nivel_principal: parseString(item.nivel_principal),
                    nivel_principal_texto: parseString(item.nivel_principal_texto),
                    orden: parseString(item.orden),
                    reduccion_cepnren: parseString(item.reduccion_cepnren),
                    seleccionado: parseString(item.seleccionado),
                    total_coste_maximo_plan: parseString(item.total_coste_maximo_plan),
                    total_coste_minimo_plan: parseString(item.total_coste_minimo_plan),
                    total_reduccion_consumo_epnr_plan: parseString(item.total_reduccion_consumo_epnr_plan),
                    urgente: parseAny(item.urgente),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_REORDENAR: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    ids: parseArray(item.ids),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const MedidaAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    beneficio: parseAny(item.beneficio),
                    comentario: parseString(item.comentario),
                    complejidad: parseString(item.complejidad),
                    complejidad_texto: parseString(item.complejidad_texto),
                    coste: parseString(item.coste),
                    coste_maximo: parseString(item.coste_maximo),
                    coste_minimo: parseString(item.coste_minimo),
                    costo_texto: parseString(item.costo_texto),
                    creat_per_id: parseString(item.creat_per_id),
                    descripcion_plan: parseString(item.descripcion_plan),
                    duracion_plan: parseString(item.duracion_plan),
                    edifici_id: parseString(item.edifici_id),
                    edifici_millora_plan_id: parseString(item.edifici_millora_plan_id),
                    elemento: parseString(item.elemento),
                    elemento_constructivo: parseString(item.elemento_constructivo),
                    elemento_texto: parseString(item.elemento_texto),
                    exigencia: parseString(item.exigencia),
                    exigencia_texto: parseString(item.exigencia_texto),
                    fase_id: parseString(item.fase_id),
                    medida: parseString(item.medida),
                    nivel_principal: parseString(item.nivel_principal),
                    nivel_principal_texto: parseString(item.nivel_principal_texto),
                    orden: parseString(item.orden),
                    reduccion_cepnren: parseString(item.reduccion_cepnren),
                    seleccionado: parseString(item.seleccionado),
                    total_coste_maximo_plan: parseString(item.total_coste_maximo_plan),
                    total_coste_minimo_plan: parseString(item.total_coste_minimo_plan),
                    total_reduccion_consumo_epnr_plan: parseString(item.total_reduccion_consumo_epnr_plan),
                    urgente: parseAny(item.urgente),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    elemento: parseString(item.elemento),
                    beneficio: parseAny(item.beneficio),
                    complejidad: parseString(item.complejidad),
                    medida: parseString(item.medida),
                    comentario: parseString(item.comentario),
                    reduccion_cepnren: parseString(item.reduccion_cepnren, { removeNullable: true }),
                    coste: parseString(item.coste),
                    coste_maximo: parseString(item.coste_maximo, { removeNullable: true }),
                    coste_minimo: parseString(item.coste_minimo, { removeNullable: true }),
                    seleccionado: parseBoolean(item.seleccionado),

                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    beneficio: parseAny(item.beneficio),
                    comentario: parseString(item.comentario),
                    complejidad: parseString(item.complejidad),
                    coste: parseString(item.coste),
                    coste_maximo: parseString(item.coste_maximo, { removeNullable: true }),
                    coste_minimo: parseString(item.coste_minimo, { removeNullable: true }),
                    edifici_id: parseString(item.edifici_id),
                    elemento: parseString(item.elemento),
                    // fase_id: parseString(item.fase_id),
                    medida: parseString(item.medida),
                    orden: parseString(item.orden),
                    reduccion_cepnren: parseString(item.reduccion_cepnren, { removeNullable: true }),
                    seleccionado: parseBoolean(item.seleccionado),
                    urgente: parseAny(item.urgente),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_SELECCIONADO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    seleccionado: parseBoolean(item.seleccionado),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    
    PATCH_URGENTE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    urgente: parseBoolean(item.urgente),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
    PATCH_FASE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    fase_id: parseString(item.fase_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_ONBLUR_COSTE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    coste_maximo: parseString(item.coste_maximo, { removeNullable: true }),
                    coste_minimo: parseString(item.coste_minimo, { removeNullable: true }),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const PotencialMejoraItemAdminAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    admite_hijos: parseCheck(item.admite_hijos, 'admite_hijos'),
                    admite_dependencias: parseCheck(item.dependencias?.length > 0, 'admite_dependencias'),
                    formulario: parseCheck(item.formulario, 'formulario'),

                    param_id: parseString(item.param_id),
                    order: parseNumber(item.order),
                    padre_id: parseString(item.padre_id),
                    textos: groupBy(parseArray(item.textos), 'locale'),
                    es_seleccionable: receiveCheck(item.es_seleccionable, 'es_seleccionable'),

                    dependencias: parseArray(item.dependencias)?.map((item: any) => {
                        return item.dependencia_tipo
                    }),

                    metricas: parseArray(item.metricas)?.map((item: any) => {
                        return item.id
                    }),

                    aspectes: parseArray(item.aspectes),
                    fichas: groupBy(parseArray(item.fichas), 'locale'),
                    links: groupBy(parseArray(item.links), 'locale'),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    MedidasAdapters,
    MedidaAdapters,
    PotencialMejoraItemAdminAdapters
}