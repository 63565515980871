import { useNavigate } from "react-router";
import { useLang } from "../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { ModalDerrama } from "./ModalDerrama";
import { receiveFecha, receiveFechaFormat2 } from "../../../../../../../controllers/controllers";
import { parseMoneda } from "../../../../../../../helpers/helpers";

export const TableDerramas = ({ data, loading, total, perPage, handleFormChange, formValuesSearch, readOnly,
    handleInputChangeSearch, fetchFacturasGET, idEdificio, fetchPropiedadAgentesGET, fetchParamsAgentesGET,
    fetchParamsAgentesGETData, idCurrentProject, showFilter, setshowFilter, fetchPlanificacionFinancieraGET }: any) => {

    const Lang = useLang()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()


    let allActions: any = [
        // {
        //     label: <span><FontAwesomeIcon icon={faSliders} /></span>,
        //     variant: 'outlined',
        //     color: 'secondary',
        //     action: () => setshowFilter(!showFilter),
        // }
    ]

    let allActionsPerLine: any = []

    if (!readOnly) {

        allActions.unshift(
            {
                label: <span className='me-2'><FontAwesomeIcon icon={faPlus} className='me-2' />{Lang('NUEVA_DERRAMA')}</span>,
                variant: 'contained',
                action: () => handleShowUsuario({
                    action: 'CREATE',
                }),
                // disabled: !isEmpty(data?.find((item: any) => !item.fecha_fin_derrama))
                // tooltip: {
                //     text: 'Para crear una derrama, se debe dar de baja la derrama activa',
                //     placement: 'left'
                // }
            }
        )

        allActionsPerLine.push(
            {
                variant: 'outlined',
                label: <FontAwesomeIcon icon={faEdit} className='' />,
                action: (item: any) => {
                    handleShowUsuario({
                        ...item,
                        action: 'EDIT',
                    })
                },
                tooltip: {
                    text: Lang('EDITAR')
                },
            }
        )
    }

    const columnsTable = {

        derrama: {
            title: Lang('CONCEPTO'),
        },
        importe_total_financiar: {
            title: Lang('IMPORTE_TOTAL_A_FINANCIAR'),
            adapter: true,
            adapterFunction: (item: any = '') => parseMoneda(item) || '-'
        },
        fecha_inicio_derrama: {
            title: Lang('FECHA_INICIO'),
            classNameTitle: 'text-center',
            className: 'text-center',
            adapter: true,
            adapterFunction: (item: any = '') => receiveFechaFormat2(receiveFecha(item)) || '-',
        },
        meses: {
            title: Lang('MESES'),
            classNameTitle: 'text-center',
            className: 'text-center',
        },
        fecha_fin_derrama: {
            title: Lang('FECHA_FIN'),
            classNameTitle: 'text-center',
            className: 'text-center',
            adapter: true,
            adapterFunction: (item: any = '') => receiveFechaFormat2(receiveFecha(item)) || '-',
        },
        estado: {
            title: Lang('ESTADO'),
            classNameTitle: 'text-center',
            className: 'text-center',
            adapter: true,
            adapterFunction: (item: any, itemRow: any) => itemRow.fecha_fin_derrama ? Lang("FINALIZADO") : Lang("ACTIVO")
        },
    }

    // Acciones

    const classNameFunctionRow = (item: any) => {

        return item.fecha_fin_derrama ? 'tr-red' : ''
    }

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-0'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('DERRAMAS')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                    classNameFunctionRow={classNameFunctionRow}
                />

                {/* <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                /> */}
            </TableAdvanced>

            <ModalDerrama
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idEdificio={idEdificio}
                formValuesSearch={formValuesSearch}
                fetchPropiedadAgentesGET={fetchPropiedadAgentesGET}
                idCurrentProject={idCurrentProject}
                fetchPlanificacionFinancieraGET={fetchPlanificacionFinancieraGET}
            />
        </>
    )
}
