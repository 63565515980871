import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { InputText } from "../../../components/Inputs";
import { useValidator } from "../../../hooks/useValidator";
import { validAlMenosUnoMarcado, validCorrectEmail, validExiste, validIsEqual } from "../../../helpers/validations";
import { useLang } from "../../../language";
import { MaestrosServices } from "../../../controllers/maestros/maestros.services";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";
import { InputSelect } from "../../../components/Inputs/InputSelect";
import { InputChecks } from "../../../components/Inputs/InputChecks";
import { isStatusSuccess } from "../../../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { InputRadio } from "../../../components/Inputs/InputRadio";

export const ModalRegistroUsuarioStep2 = ({ formRegistro, handleInputRegistro, handleFormRegistro, handleClose,
    setIndexFocusWizard, data, fetchAuthPOST, fetchAuthPOSTData, handleShowModalAvisoLegal,
    handleShowModalAvisoLegal2 }: any) => {

    const Lang = useLang()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [fetchParamsPublicColegioProGET, fetchParamsPublicColegioProGETData] = MaestrosServices.GET_COLEGIOS_PROFESIONALES_PUBLICOS()

    const [fetchComunidadesAutGET, fetchComunidadesAutGETData] = MaestrosServices.GET_COLEGIOS_PROFESIONALES_PUBLICOS_COMUNIDADES()

    const [validRegistro, handleValidationRegistro, resetvalidRegistro] = useValidator({
        first_name: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        last_name: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        nif: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        email: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
            { functionValid: validCorrectEmail, msgError: Lang('VALID_CORRECT_EMAIL'), isOk: true },
        ],
        password: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        password_confirmation: [
            { functionValidMultipleKeys: (form: any) => validIsEqual(form.password, form.password_confirmation), msgError: Lang('VALID_CONFIRMAR_CLAVE'), isOk: true },
        ],
        politica_privacitat_acceptada_en: [
            { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        politica_privacitat_acceptada_en_aviso_legal: [
            { functionValid: validAlMenosUnoMarcado, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        tipo_usuario: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const [validRegistroProfesional, handleValidationRegistroProfesional, resetvalidRegistroProfesional] = useValidator({
        professio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        comunitat_autonoma: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        colegiado_externo_num_colegiado: [
            { functionValidMultipleKeys: (form: any) => {

                return (validIsEqual(form.professio, '4') || validIsEqual(form.professio, '5')) ? validExiste(form.colegiado_externo_num_colegiado) : true
            }, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true }
        ]
    })

    useEffect(() => {

        handleFormRegistro({
            ...formRegistro,
            collegi_professional: ''
        })

        fetchParamsPublicColegioProGET({
            params: {
                comunitat: formRegistro.comunitat_autonoma,
                tipo: formRegistro.professio,
            }
        })

    }, [formRegistro.professio, formRegistro.comunitat_autonoma]);

    const handleSubmitRegistrate = () => {

        if (handleValidationRegistro(formRegistro) &&
            ((formRegistro.tipo_usuario !== 'profesional') || (formRegistro.tipo_usuario === 'profesional' && handleValidationRegistroProfesional(formRegistro)))) {

            fetchAuthPOST({
                body: formRegistro
            })
        }
    }

    useEffect(() => {
        if (isStatusSuccess(fetchAuthPOSTData.status)) {

            handleClose()
        }
    }, [fetchAuthPOSTData]);

    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "comunidadautonoma",
                    },
                    {
                        parametroPadre: "profesion",
                    },
                    {
                        parametroPadre: "entidadconvenio",
                    },
                ]
            })

        } else {
            resetvalidRegistro()
            // resetvalidNIF()
        }

    }, [data.value]);


    useEffect(() => {

        if (data.value) {

            fetchComunidadesAutGET({
                params: {
                    tipo: formRegistro.professio
                }
            })
        }

    }, [data.value, formRegistro.professio]);


    return (
        <>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className="px-3 px-lg-4 pt-3 pt-lg-4 pb-0"
            >
                <Grid container spacing={2} columnSpacing={3} rowSpacing={0}>

                    <Grid item xs={12} className="">
                        <Typography variant='subtitle1' className="fw-bold">
                            {Lang('DATOS_PERSONALES')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <InputText
                            label={Lang('NOMBRE') + ': *'}
                            name='first_name'
                            value={formRegistro.first_name}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.first_name}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={5}>
                        <InputText
                            label={Lang('APELLIDOS') + ': *'}
                            name='last_name'
                            value={formRegistro.last_name}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.last_name}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <InputText
                            label='NIF: *'
                            name='nif'
                            defaultValue={formRegistro.nif}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.nif}
                            fetchData={fetchAuthPOSTData}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} className="">
                        <Typography variant='subtitle1' className='mb-0 pb-0 mt-0 fw-normal fs-105'>
                            <small>
                                {Lang('TIPO_DE_USUARIO')}:
                            </small>
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12} className="pt-0">
                        <InputRadio
                            onChange={handleInputRegistro}
                            name='tipo_usuario'
                            value={formRegistro.tipo_usuario}
                            values={
                                [
                                    {
                                        id: 'propietario', name: Lang('PROPIETARIO'),
                                    },
                                    {
                                        id: 'profesional', name: Lang('PROFESIONAL'),
                                    },
                                ]
                            }
                            classNameGroup=''
                            localValidation={validRegistro.tipo_usuario}

                        />
                        {
                            !validRegistro.tipo_usuario?.[0]?.isOk &&
                            <small className='d-block mt-2 color-error'>{validRegistro.tipo_usuario?.[0]?.msgError}</small>
                        }
                    </Grid>

                    {
                        formRegistro.tipo_usuario === 'profesional' &&
                        <>
                            <Grid item xs={12} md={6} lg={4}>
                                <InputSelect
                                    label={Lang('PROFESION') + ': *'}
                                    name='professio'
                                    value={formRegistro.professio}
                                    values={fetchParamsPublicGETData?.data?.['profesion'] || []}
                                    onChange={handleInputRegistro}
                                    localValidation={validRegistroProfesional.professio}
                                    fetchData={fetchAuthPOSTData}
                                />
                            </Grid>

                            {
                                formRegistro.professio === '10' &&
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputText
                                        label={Lang('OTRA_PROFESION') + ':'}
                                        name='role_altres'
                                        value={formRegistro.role_altres}
                                        onChange={handleInputRegistro}
                                        localValidation={validRegistro.role_altres}
                                        fetchData={fetchAuthPOSTData}
                                    />
                                </Grid>
                            }

                            {
                                !isEmpty(formRegistro.professio) && String(formRegistro.professio) !== '0' &&

                                <Grid item xs={12} md={6} lg={3}>
                                    <InputSelect
                                        label={Lang('COMUNIDAD_AUTONOMA') + ': *'}
                                        name='comunitat_autonoma'
                                        value={formRegistro.comunitat_autonoma}
                                        values={formRegistro.professio === '10' ? fetchParamsPublicGETData?.data?.['comunidadautonoma'] || [] : fetchComunidadesAutGETData?.data?.['comunidadautonoma'] || []}
                                        onChange={handleInputRegistro}
                                        localValidation={validRegistroProfesional.comunitat_autonoma}
                                        fetchData={fetchAuthPOSTData}
                                    />
                                </Grid>
                            }

                            {
                                !isEmpty(formRegistro.professio) && String(formRegistro.professio) !== '10' &&
                                <>
                                    <Grid item xs={12} md={6} lg={3} display='flex' alignItems='end'>
                                        <InputText
                                            label={`${Lang('NUMERO_COLEGIADO_A')}: *`}
                                            name='colegiado_externo_num_colegiado'
                                            value={formRegistro.colegiado_externo_num_colegiado}
                                            onChange={handleInputRegistro}
                                            localValidation={validRegistroProfesional.colegiado_externo_num_colegiado}
                                            fetchData={fetchAuthPOSTData}
                                        />
                                        {
                                            String(formRegistro.professio) === '4' &&
                                            <Tooltip title={Lang('P_NUMERO_COLEGIADO_PRECIO_CONVENIO')} placement="top">
                                                <FontAwesomeIcon icon={faInfoCircle} className="mb-2 ms-2" />
                                            </Tooltip>
                                        }
                                    </Grid>

                                    {
                                        formRegistro.comunitat_autonoma &&
                                        <Grid item xs={12} md={12} lg={8}>
                                            <InputSelect
                                                label={Lang('COLEGIO_PROFESIONAL') + ':'}
                                                name='collegi_professional'
                                                value={formRegistro.collegi_professional}
                                                values={fetchParamsPublicColegioProGETData?.data || []}
                                                onChange={handleInputRegistro}
                                                localValidation={validRegistro.collegi_professional}
                                                fetchData={fetchAuthPOSTData}
                                            />
                                        </Grid>
                                    }
                                </>
                            }

                            {
                                !isEmpty(fetchParamsPublicGETData?.data?.['entidadconvenio']) &&
                                <Grid item xs={12} md={6} lg={5}>
                                    <InputSelect
                                        label={Lang('CONVENIO') + ':'}
                                        name='entitat_conveni_id'
                                        value={formRegistro.entitat_conveni_id}
                                        values={fetchParamsPublicGETData?.data?.['entidadconvenio'] || []}
                                        onChange={handleInputRegistro}
                                        localValidation={validRegistro.entitat_conveni_id}
                                        fetchData={fetchAuthPOSTData}
                                    />
                                </Grid>
                            }
                        </>
                    }

                    <Grid item xs={12} className="mt-2">
                        <Typography variant='subtitle1' className="fw-bold">
                            {Lang('DATOS_CONTACTO')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={5}>
                        <InputText
                            label={Lang('CORREO_ELECTRONICO') + ':'}
                            name='email'
                            value={formRegistro.email}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.email}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <InputText
                            label={Lang('CONTRASENIA') + ':'}
                            name='password'
                            type='password'
                            value={formRegistro.password}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.password}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <InputText
                            label={Lang('CONFIRMACION_DE_CONTRASENIA') + ':'}
                            type='password'
                            name='password_confirmation'
                            value={formRegistro.password_confirmation}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.password_confirmation}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputChecks
                            name='politica_privacitat_acceptada_en'
                            values={formRegistro.politica_privacitat_acceptada_en}
                            valuesDefault={[
                                {
                                    id: 'politica_privacitat_acceptada_en',
                                    label: <span onClick={(e) => {
                                        e.preventDefault()
                                        handleShowModalAvisoLegal()
                                    }}>{Lang('MANIFIESTO_LEIDO_Y_ACEPTO_LAS')} <span className="color-primary">{Lang('CONDICIONES_REGISTRO')}</span>.</span>,
                                }
                            ]}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.politica_privacitat_acceptada_en}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>

                    <Grid item xs={12} className='pt-1'>
                        <InputChecks
                            name='politica_privacitat_acceptada_en_aviso_legal'
                            values={formRegistro.politica_privacitat_acceptada_en_aviso_legal}
                            valuesDefault={[
                                {
                                    id: 'politica_privacitat_acceptada_en_aviso_legal',
                                    label: <span onClick={(e) => {
                                        e.preventDefault()
                                        handleShowModalAvisoLegal2({
                                            indexAccordion: 10
                                        })
                                    }}>{Lang('MANIFIESTO_LEIDO_Y_ACEPTO_INFORMACION_RELATIVA_A')} <span className="color-primary">{Lang('PROTECCION_DATOS_CARACTER_PERSONAL')}</span>. </span>,
                                }
                            ]}
                            onChange={handleInputRegistro}
                            localValidation={validRegistro.politica_privacitat_acceptada_en_aviso_legal}
                            fetchData={fetchAuthPOSTData}
                        />
                    </Grid>


                    <Grid item xs={12} justifyContent='between' display='flex' className="mt-3">

                        <ButtonAdvanced
                            variant="outlined" type="button" className="me-auto" color='secondary'
                            handleSubmit={() => {
                                setIndexFocusWizard(0)
                            }}
                            value={Lang('REGRESAR')} icon={<FontAwesomeIcon icon={faArrowLeft} className='me-2' />}
                        />

                        <ButtonAdvanced
                            variant="contained" type="button" className="ms-auto"
                            handleSubmit={handleSubmitRegistrate}
                            loadings={[fetchAuthPOSTData.loading]}
                            value={Lang('FINALIZAR')}
                        />
                    </Grid>

                </Grid>

            </Box>

        </>
    );
};
