import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, receiveFecha, receiveFechaFormat2, renderReceiveAdapter, renderSchema, renderSendAdapter } from "../controllers"

const EdificiosAdapters = {
    
    GET_MIS_EDIFICIOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    created_at: parseString(item.created_at),
                    updated_at: parseString(item.updated_at),
                    tipus_edifici: parseString(item.tipus_edifici),
                    tipus_producte: parseObject(item.tipus_producte),
                    inmuebles: parseArray(item.inmuebles),
                    identificacion: parseArray(item.identificacion),
                    propiedad: parseArray(item.propiedad),
                    fotos: parseArray(item.fotos),
                    planos: parseArray(item.planos),
                    pagos: parseArray(item.pagos),
                    imagen: parseObject(item.imagen),
                    versio_estesa: parseBoolean(item.versio_estesa),
                    actiu: parseBoolean(item.actiu),
                    estado: parseString(item.estado),
                    perfil_llibre: parseArray(item.perfil_llibre),
                    tipus_edifici_id: parseAny(item.tipus_edifici_id),
                    user_id: parseNumber(item.user_id),
                    fecha_contratacion_led_gestion: parseString(item.fecha_contratacion_led_gestion),
                    fecha_contratacion_led_redaccion: parseString(item.fecha_contratacion_led_redaccion),
                    fecha_limite_uso: parseString(item.fecha_limite_uso),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
    GET_ALL_EDIFICIOS: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    created_at: parseString(),
                    label__created_at: receiveFechaFormat2(receiveFecha(item.created_at)),
                    updated_at: parseString(item.updated_at),
                    tipus_edifici: parseString(item.tipus_edifici),
                    tipus_producte: parseObject(item.tipus_producte),
                    label__tipus_producte: parseString(item.tipus_producte?.producte),
                    inmuebles: parseArray(item.inmuebles),
                    identificacion: parseArray(item.identificacion),
                    propiedad: parseArray(item.propiedad),
                    fotos: parseArray(item.fotos),
                    planos: parseArray(item.planos),
                    pagos: parseArray(item.pagos),
                    user_id: parseNumber(item.user_id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
    
}


const EdificioAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {
                
                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    created_at: parseString(item.created_at),
                    updated_at: parseString(item.updated_at),
                    tipus_edifici: parseString(item.tipus_edifici),
                    tipus_producte: parseObject(item.tipus_producte),
                    inmuebles: parseArray(item.inmuebles),
                    identificacion: parseArray(item.identificacion),
                    propiedad: parseArray(item.propiedad),
                    fotos: parseArray(item.fotos),
                    planos: parseArray(item.planos),
                    pagos: parseArray(item.pagos),
                    imagen: parseObject(item.imagen),
                    rutaImagen: parseObject(item.imagen)?.ruta,
                    actiu: parseBoolean(item.actiu),
                    estado: parseString(item.estado),
                    versio_estesa: parseBoolean(item.versio_estesa),
                    perfil_llibre: parseArray(item.perfil_llibre),
                    data_anulacio: parseString(item.data_anulacio),
                    data_confirmacio: parseString(item.data_confirmacio),
                    usuario_estado_edificio: parseAny(item.usuario_estado_edificio),
                    tipus_edifici_id: parseAny(item.tipus_edifici_id),
                    user_id: parseNumber(item.user_id),
                    fecha_contratacion_led_gestion: parseString(item.fecha_contratacion_led_gestion),
                    fecha_contratacion_led_redaccion: parseString(item.fecha_contratacion_led_redaccion),
                    fecha_limite_uso: parseString(item.fecha_limite_uso),
                    comunitat_autonoma: parseString(item.comunitat_autonoma),
                    sincronizado: parseBoolean(item.sincronizado),
                    es_catastro: parseBoolean(item.es_catastro),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {                

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    tipus: parseNumber(item.tipus),
                    execute: parseBoolean(item.execute),
                    producte_id: parseNumber(item.producte_id),
                    comunitat_autonoma: parseString(item.comunitat_autonoma),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_VERIFICAR_CATASTRO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    ref_cadastral: parseString(item.ref_cadastral),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_MIS_ROLES: {

        receive: {

            schema: function (item: any) {
                
                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    name: parseString(item.name),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {                

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_SINCRONIZAR: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    ref_cadastral: parseString(item.ref_cadastral),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_BAJA_SUSCRIPCION: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_ESTADO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    estado: parseString(item.estado)
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}


export {
    EdificiosAdapters,
    EdificioAdapters,
}