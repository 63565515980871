import { useAxios } from "../../hooks/useAxios"
import { AuthAdapters } from "./auth.adapters"

const AuthServices = {

    POST_LOGIN_GENERAL: function () {

        return useAxios({
            method: 'post',
            url: '/auth/login',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_LOGIN_GENERAL,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_LOGIN_CATEB: function () {

        return useAxios({
            method: 'post',
            url: '/auth/login_colegiado',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_LOGIN_GENERAL,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_LOGIN_EDIFICIO: function () {

        return useAxios({
            method: 'post',
            url: '/auth/login_usuario_edificio',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_LOGIN_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_REGISTER: function () {

        return useAxios({
            method: 'post',
            url: '/auth/register',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_REGISTER,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    
    POST_RECUPERAR_CONTRASENIA: function () {

        return useAxios({
            method: 'post',
            url: '/auth/forgo_password',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_RECUPERAR_CONTRASENIA,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_COMPROBAR_NIF: function () {

        return useAxios({
            method: 'post',
            url: '/auth/comprobar_nif',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_COMPROBAR_NIF,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    GET_EDIFICIOS: function () {

        return useAxios({
            method: 'post',
            url: '/auth/forgo_code_edificio',
            type: 'json',
            adapter: {
                method: AuthAdapters.GET_EDIFICIOS,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_RECUPERAR_CODIGO: function () {

        return useAxios({
            method: 'get',
            url: '/auth/send_code_edificio',
            type: 'json',
            adapter: {
                method: AuthAdapters.POST_COMPROBAR_NIF,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_USUARIO_NUEVO: function () {

        return useAxios({
            method: 'POST',
            url: '/auth/actualizar_datos_profesionales',
            type: 'json',
            adapter: {
                method: AuthAdapters.PATCH_USUARIO_NUEVO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_ACEPTAR_RECHAZAR_EDIFICIO: function () {

        return useAxios({
            method: 'post',
            url: '/edificio_usuarios/comprobacion',
            type: 'json',
            adapter: {
                method: AuthAdapters.PATCH_ACEPTAR_RECHAZAR_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

}

export {
    AuthServices
}