import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { convertStaticServiceToArray, isStatusSuccess } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IngresoServices } from './controllers/ingresos.services'
import { IngresoAdapters } from './controllers/ingresos.adapters'
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect'
import { receiveFecha } from "../../../../../../../controllers/controllers"

export const ModalIngresoProyecto = ({ data, handleClose, idCurrentProject, fetchSuccessActividad,
    tipoIngresosStaticServices, fetchPlanificacionFinancieraGET
 }: any) => {

    const Lang = useLang()

    const [fetchIngresoPOST, fetchIngresoPOSTData, resetfetchIngresoPOSTData] = IngresoServices.POST()

    const [fetchIngresoPATCH, fetchIngresoPATCHData, resetfetchIngresoPATCHData] = IngresoServices.PATCH()

    const [fetchGremioDELETE, fetchGremioDELETEData, resetfetchGremioDELETEData] = IngresoServices.DELETE()

    const [formIngreso, handleInputIngreso, handleFormIngreso, resetFormIngreso] = useForm({
        ...IngresoAdapters.GET.receive.schema({}),
        cp: '',
        data: '',
        tipus_projecte_ingressos: '',
    })

    const [validGremio, handleValidGremio, resetvalidGremio] = useValidator({
        ingres: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        tipus_projecte_ingressos: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ POST

    const handleSubmitGremioPOST = () => {

        if (handleValidGremio(formIngreso)) {

            fetchIngresoPOST({
                body: {
                    ...formIngreso,
                    projecte_id: idCurrentProject,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchIngresoPOSTData.status)) {

            fetchPlanificacionFinancieraGET()

            handleClose()
        }

    }, [fetchIngresoPOSTData]);


    // ------------ PATCH

    const handleSubmitEditarAgente = () => {

        if (handleValidGremio(formIngreso)) {

            fetchIngresoPATCH({
                id: data?.id,
                body: {
                    ...formIngreso,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchIngresoPATCHData.status)) {
            
            fetchPlanificacionFinancieraGET()
            
            handleClose()
        }

    }, [fetchIngresoPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchGremioDELETE({
            id: data?.id,
            body: {
                ...formIngreso,
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchGremioDELETEData.status)) {
            fetchPlanificacionFinancieraGET()
            handleClose()
        }

    }, [fetchGremioDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {
                
                handleFormIngreso({
                    ...data.item,
                    data: receiveFecha(data.item?.data)
                })
            }

        } else {
            resetFormIngreso()
            resetvalidGremio()
            resetfetchIngresoPOSTData()
            resetfetchIngresoPATCHData()
            resetfetchGremioDELETEData()
        }

    }, [data.value]);


    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='lg'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVO_INGRESO') : Lang('EDITAR_INGRESO')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        // fetchGremioGETData?.loading ?
                        //     <LoaderModalFormPrimary />
                        //     :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12} md={4} lg={4}>
                                        <InputText
                                            label={Lang('INGRESO') + ': *'}
                                            name='ingres'
                                            type='number'
                                            value={formIngreso.ingres}
                                            onChange={handleInputIngreso}
                                            fetchData={data?.action === 'CREATE' ? fetchIngresoPOSTData : fetchIngresoPATCHData}
                                            localValidation={validGremio.ingres}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                        <InputSelect
                                            label={Lang('TIPO_INGRESO')}
                                            name='tipus_projecte_ingressos'
                                            value={formIngreso.tipus_projecte_ingressos}
                                            values={convertStaticServiceToArray(tipoIngresosStaticServices)}
                                            onChange={handleInputIngreso}
                                            localValidation={validGremio.tipus_projecte_ingressos}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                        <InputText
                                            label={Lang('FECHA') + ': *'}
                                            type='date'
                                            name='data'
                                            value={formIngreso.data}
                                            onChange={handleInputIngreso}
                                            fetchData={data?.action === 'CREATE' ? fetchIngresoPOSTData : fetchIngresoPATCHData}
                                            localValidation={validGremio.data}
                                            className='labelFocusAlways'
                                        />
                                    </Grid>


                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitGremioPOST}
                                loadings={[fetchIngresoPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className=""
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchGremioDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <div>
                                    <ButtonAdvanced
                                        variant="contained" type="button" className=" ms-auto"
                                        handleSubmit={handleSubmitEditarAgente}
                                        loadings={[fetchIngresoPATCHData.loading]}
                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                    />
                                </div>
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchIngresoPOSTData} />

            <ModalStatusServer fetchData={fetchIngresoPATCHData} />

            <ModalStatusServer fetchData={fetchGremioDELETEData} />
        </>
    )
}