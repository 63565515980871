export const AvisoLegalSeccion9 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (

    <div>
      <p>
        <span className="text-decoration-underline">9.1.- Definición y función de las cookies</span>
      </p>
      <p>
        ¿Qué son las cookies? Una cookie o galleta es un archivo que se descarga a su dispositivo al acceder a determinadas páginas web. Las cookies permiten en una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, se pueden utilizar para reconocer el usuario.
      </p>

      <p>
        <span className="text-decoration-underline">9.2- Qué tipos de cookies utiliza esta página web?</span>
      </p>

      <ul>
        <li>
          Cookies técnicas y de personalización: necesarias o convenientes para la prestación de determinados servicios. Las primeras permiten al usuario la navegación a través de la página web y la utilización de los diferentes servicios que hay, y las segundas permiten acceder al usuario al servicio con algunas características de carácter general predefinidas. Se utilizan cookies por el idioma, por el gestor de contenidos y por el mantenimiento de sesión cuando es necesario. Si se desactivan estas Cookies se podría no recibir correctamente los contenidos del sitio web.
        </li>
        <li>
          Cookies analíticas, ya sea porque son tratadas por nosotros o por terceros, permiten hacer el seguimiento y análisis estadístico del comportamiento del conjunto de los usuarios. Si se desactivan estas galletas, el sitio web podrá seguir funcionando, sin perjuicio que la información captada por estas cookies sobre el uso de nuestro web y sobre su contenido permite mejorar nuestros servicios.
        </li>
        <li>
          Cookies de redes sociales externas, que se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (Facebook, YouTube, Twitter, LinkedIn, etc.)
        </li>
        <li>
          Cookies de publicidad: este tipo de "Cookies" permiten ampliar la información de los anuncios mostrados a cada usuario anónimo. Pueden ser tratadas por nosotros o por terceros y nos permiten gestionar de la forma más eficaz posible la oferta de espacios publicitarios que hay en la web, adecuando el contenido de cada anuncio al contenido del servicio solicitado o a el uso que realice de nuestra página web por el que podremos analizar sus hábitos de navegación y podremos mostrarle publicidad relacionada con su perfil de navegación. Entre otros, se almacena la duración o frecuencia de visualización de posiciones publicitarias, la interacción con las mismas, o los patrones de navegación y/o comportamientos del usuario, puesto que ayudan a conformar un perfil de interés publicitario.
        </li>
      </ul>

      <p>
        <span className="text-decoration-underline">9.3. Forma de desactivar o eliminar las cookies</span>
      </p>

      <p>
        Usted puede permitir, bloquear o eliminar las cookies instaladas en su dispositivo mediante la configuración de las opciones del navegador en vuestro dispositivo.
      </p>
      <ul>
        <li>
          Configuración de Internet Explorer
        </li>
        <li>
          Configuración de Mozilla Firefox
        </li>
        <li>
          Configuración de Google Chrome
        </li>
        <li>
          Configuración de Safari
        </li>
      </ul>

      <p>
        En caso de que no acepte las cookies es posible que la página web no funcione correctamente.
      </p>

      <p>
        <span className="text-decoration-underline">
          9.4. Información sobre la identificación de quien utiliza las cookies
        </span>
      </p>

      <p>
        La información obtenida por las cookies es tratada por el COLEGIO DE LA ARQUITECTURA TÉCNICA DE BARCELONA en qué se ha contratado la prestación del servicio de analítica con Google Analytics donde siguiendo el link podrá acceder a sus condiciones legales.
      </p>
      <p>
        También puede ser que haya complementos de otros proveedores en que siguiendo los siguientes vínculos podrá comprobar las condiciones legales respecto a las cookies de cada uno de ellos:
      </p>
      <ul>
        <li>
          Facebook
        </li>
        <li>
          YouTube
        </li>
        <li>
          Twitter
        </li>
        <li>
          LinkedIn
        </li>
      </ul>

      <p>
        <span className="text-decoration-underline">9.5. Actualización de la política de cookies</span>
      </p>

      <p>
        Es posible que, por nuevas interpretaciones realizadas por el organismo regulador competente, jueces o Tribunales, esta política de cookies sufra alguna modificación por el que se ruega al usuario que compruebe de forma regular su contenido.
      </p>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <span className="text-decoration-underline">9.1.- Definició i funció de les cookies</span>
      </p>
      <p>
        Què són les cookies? Una cookie o galeta és un arxiu que es descarrega al seu dispositiu en accedir a determinades pàgines web. Les cookies permeten en una pàgina web, entre altres coses, emmagatzemar i recuperar informació sobre els hàbits de navegació d'un usuari o del seu equip i, depenent de la informació que continguin i de la forma en què utilitzi el seu equip, es poden utilitzar per reconèixer l'usuari.
      </p>

      <p>
        <span className="text-decoration-underline">9.2- Quins tipus de cookies utilitza aquesta pàgina web?</span>
      </p>

      <ul>
        <li>
          Cookies tècniques i de personalització: necessàries o convenients per a la prestació de determinats serveis. Les primeres permeten a l'usuari la navegació a través de la pàgina web i la utilització dels diferents serveis que hi ha, i les segones permeten accedir a l'usuari al servei amb algunes característiques de caràcter general predefinides. S'utilitzen cookies per l'idioma, pel gestor de continguts i pel manteniment de sessió quan és necessari. Si es desactiven aquestes Cookies es podria no rebre correctament els continguts del lloc web.        </li>
        <li>
          Cookies analítiques, ja sigui perquè són tractades per nosaltres o per tercers, permeten fer el seguiment i anàlisi estadística del comportament del conjunt dels usuaris. Si es desactiven aquestes galetes, el lloc web podrà seguir funcionant, sense perjudici que la informació captada per aquestes cookies sobre l'ús del nostre web i sobre el seu contingut permet millorar els nostres serveis.        </li>
        <li>
          Cookies de xarxes socials externes, que s'utilitzen perquè els visitants puguin interactuar amb el contingut de diferents plataformes socials (Facebook, YouTube, Twitter, LinkedIn, etc.)        </li>
        <li>
          Cookies de publicitat: aquest tipus de "Cookies" permeten ampliar la informació dels anuncis mostrats a cada usuari anònim. Poden ser tractades per nosaltres o per tercers i ens permeten gestionar de la forma més eficaç possible l'oferta d'espais publicitaris que hi ha al web, adequant el contingut de cada anunci al contingut del servei sol·licitat o a l'ús que realitzi de la nostra pàgina web pel qual podrem analitzar els seus hàbits de navegació i podrem mostrar-li publicitat relacionada amb el seu perfil de navegació. Entre d'altres, s'emmagatzema la durada o freqüència de visualització de posicions publicitàries, la interacció amb aquestes, o els patrons de navegació i/o comportaments de l'usuari, ja que ajuden a conformar un perfil d'interès publicitari.
        </li>
      </ul>

      <p>
        <span className="text-decoration-underline">9.3. Forma de desactivar o eliminar les cookies</span>
      </p>

      <p>
        Vostè pot permetre, bloquejar o eliminar les cookies instal·lades en el seu dispositiu mitjançant la configuració de les opcions del navegador en el vostre dispositiu.
      </p>
      <ul>
        <li>
          Configuració d' Internet Explorer
        </li>
        <li>
          Configuració de Mozilla Firefox
        </li>
        <li>
          Configuració de Google Chrome
        </li>
        <li>
          Configuració de Safari
        </li>
      </ul>

      <p>
        En cas que no accepti les cookies és possible que la pàgina web no funcioni correctament.
      </p>

      <p>
        <span className="text-decoration-underline">
          9.4. Informació sobre la identificació de qui utilitza les cookies
        </span>
      </p>

      <p>
        La informació obtinguda per les cookies és tractada pel COL·LEGI DE L'ARQUITECTURA TÈCNICA DE BARCELONA en què s'ha contractat la prestació del servei d'analítica amb Google Analytics on seguint el link podrà accedir a les seves condicions legals.
      </p>
      <p>
        També pot ser que hi hagi complements d' altres proveïdors en què seguint els següents vincles podrà comprovar les condicions legals respecte a les cookies de cadascun d' ells:
      </p>
      <ul>
        <li>
          Facebook
        </li>
        <li>
          YouTube
        </li>
        <li>
          Twitter
        </li>
        <li>
          LinkedIn
        </li>
      </ul>

      <p>
        <span className="text-decoration-underline">9.5. Actualització de la política de cookies</span>
      </p>

      <p>
        És possible que, per noves interpretacions realitzades per l'organisme regulador competent, jutges o Tribunals, aquesta política de cookies pateixi alguna modificació pel qual es prega a l'usuari que comprovi de forma regular el seu contingut.      </p>

    </div>
  )
}