import './ListOptions.scss'

export const ListOptions = ({ list = [] }: any) => {
    return (
        <section className="ListOptions">

            <ul className="list-unstyled ListOptions__Items d-flex flex-wrap justify-content-center">
                {
                    list?.map((item: any) => {
                        return (
                            <li className="ListOptions__Item d-flex flex-column align-items-center p-3 m-3 shadow-sm" onClick={item.action} key={item.id}>
                                <div className="mb-3">{item.icon}</div>
                                <div>{item.title}</div>
                                <div>{item.subtitle}</div>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    )
};
