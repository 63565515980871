export const AvisoLegalSeccion5 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <span className="text-decoration-underline">
          5.1. Contenidos de nuestro sitio web
        </span>
      </p>
      <p>
        Nuestro sitio web ofrece un amplio contenido técnico con recursos de diversa índole, destacando las herramientas de apoyo para la toma de datos, la redacción del libro del edificio y la comprobación del cumplimiento de la normativa relativa a la edificación y urbanización y distintos contenidos técnicos en los que participa el CATEB.
      </p>
      <p>
        Todo el contenido es propiedad de CATEB o de sus licenciatarios, por lo que el usuario no tendrá ningún derecho de titularidad sobre los mismos.
      </p>
      <p>

        CATEB realiza trabajos constantes por mantener actualizado su contenido, pero no garantiza que puedan existir errores o fallos, no asumiendo ninguna responsabilidad por ello.
      </p>
      <p>
        <span className="text-decoration-underline">
          5.2. Limitaciones al uso de los contenidos
        </span>
      </p>

      <ul>
        <li>
          Los contenidos solo podrán ser utilizados con fines personales, quedando expresamente prohibido el uso con fines comerciales o lucrativos, resultando de aplicación las estipulaciones contenidas en la cláusula 6.1. de las presentes condiciones.
        </li>

        <li>
          El usuario deberá respetar y cumplir con las obligaciones y limitaciones establecidas en la cláusula 6 de las presentes condiciones en relación con el uso que haga de los diferentes tipos de contenidos disponibles en nuestro sitio web.
        </li>
      </ul>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <span className="text-decoration-underline">
          5.1. Continguts del nostre lloc web
        </span>
      </p>
      <p>
        El nostre lloc web ofereix un ampli contingut tècnic amb recursos de diversa índole, destacant les eines de suport per a la presa de dades, la redacció del llibre de l'edifici i la comprovació del compliment de la normativa relativa a l'edificació i urbanització i diferents continguts tècnics en els quals participa el CATEB.
      </p>
      <p>
        Tot el contingut és propietat de CATEB o dels seus llicenciataris, per la qual cosa l' usuari no tindrà cap dret de titularitat sobre els mateixos.
      </p>
      <p>
        CATEB realitza treballs constants per mantenir actualitzat el seu contingut, però no garanteix que puguin existir errors o fallades, no assumint cap responsabilitat per això.
      </p>
      <p>
        <span className="text-decoration-underline">
          5.2. Limitacions a l'ús dels continguts
        </span>
      </p>

      <ul>
        <li>
          Els continguts només podran ser utilitzats amb finalitats personals, quedant expressament prohibit l'ús amb finalitats comercials o lucratives, resultant d'aplicació les estipulacions contingudes en la clàusula 6.1. de les presents condicions.
        </li>

        <li>
          L'usuari haurà de respectar i complir amb les obligacions i limitacions establertes en la clàusula 6 de les presents condicions en relació amb l'ús que faci dels diferents tipus de continguts disponibles al nostre lloc web.
        </li>
      </ul>

    </div>
  )
}