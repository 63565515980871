import { Box, Grid } from '@mui/material'
import { InputText } from '../../../../../../../../../components/Inputs'
import { useLang } from '../../../../../../../../../language'
import { InputSelect } from '../../../../../../../../../components/Inputs/InputSelect'

export const FormSimple = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParamsAgentesGETData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={4}>

                    <Grid item xs={12} md={6} lg={3}>
                        <InputText
                            label={`${Lang('TITULO')} / ${Lang('DESCRIPCION')}:`}
                            name='magic'
                            value={formValuesSearch.magic}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <InputText
                            label={Lang('FECHA_FINALIZACION_DESDE') + ':'}
                            name='fechafinalizacion_desde'
                            type='date'
                            value={formValuesSearch.fechafinalizacion_desde}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <InputText
                            label={Lang('FECHA_FINALIZACION_HASTA') + ':'}
                            name='fechafinalizacion_hasta'
                            type='date'
                            value={formValuesSearch.fechafinalizacion_hasta}
                            onChange={handleInputChangeSearch}
                            className='mt-2 mb-3 w-100 labelFocusAlways'
                        />
                    </Grid>

                </Grid>

            </Box>
        </>
    )
}
