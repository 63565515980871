import { Grid } from '@mui/material'
import { useState } from 'react'

export const ItemsDynamics = ({ children, elements, handleOrderElement, setElements, classNameContainer = '', xs, sm, md, lg, xl }: any) => {

    const [indexDrag, setIndexDrag] = useState(-1)
    const [indexDrop, setIndexDrop] = useState(-1)

    // Al agarrar un elemento...

    const handleDraggingItem = (e: any, index: any) => {

        setIndexDrag(index)
    }

    // Durante el arrastre...

    function allowDropItem(ev: any, index: any) {

        ev.preventDefault();

        let InputFilesTemp = [...elements]

        if (
            !InputFilesTemp[index].fake // que no cree nuevos fakes cuando esta sobre un fake
            && indexDrag !== -1 // flag
            && indexDrag !== index // no puedo crear un fake antes de mi mismo
            // && !InputFilesTemp[index - 1].fake // el -1 es porque cuando se posiciona encima, se crea el fake
            && index !== (indexDrag + 1)
        ) {

            InputFilesTemp = InputFilesTemp.filter(item => !item['fake'])

            InputFilesTemp.splice(index, 0, { fake: true });

            setElements(InputFilesTemp)

            setIndexDrop(index)
        }
    }

    // Al soltar un elemento...

    const itemDropped = (e: any, index: any) => {

        let InputFilesTemp = [...elements]

        // const itemDrag = InputFilesTemp[indexDrag]

        if (indexDrag !== index && (index - 1) !== indexDrag) {

            let indexDragCorregido = indexDrag > index ? indexDrag + 1 : indexDrag

            InputFilesTemp[index] = InputFilesTemp[indexDragCorregido]

            InputFilesTemp.splice(indexDragCorregido, 1);

            setElements(InputFilesTemp.filter(item => !item['fake']))

            handleOrderElement([...InputFilesTemp])

            // setItemDragging(-1)
        }

        setIndexDrag(-1)
        setIndexDrop(-1)
    }

    return (
        <>
            {
                children?.map((childElement: any, indexchildElement: any) =>
                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}
                        className={`${classNameContainer} ${indexDrop === indexchildElement ? 'isDragOver' : ''}`}
                        key={'2' + indexchildElement}
                        draggable
                        onDragStart={(e) => handleDraggingItem(e, indexchildElement)}
                        onDragOver={(e) => allowDropItem(e, indexchildElement)}
                        onDrop={(e) => itemDropped(e, indexchildElement)}>
                        {childElement}
                    </Grid>
                )
            }
        </>
    )
}
