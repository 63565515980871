export const AvisoLegalSeccion10 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        En caso de que se produzca cualquier discrepancia en cuanto a la aplicación o interpretación de las presentes condiciones, resultará aplicable el Derecho Español y serán competentes para conocer la controversia los Juzgados y Tribunales de Barcelona capital (España).
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        En cas que es produeixi qualsevol discrepància quant a l'aplicació o interpretació de les presents condicions, resultarà aplicable el Dret Espanyol i seran competents per conèixer la controvèrsia els Jutjats i Tribunals de Barcelona capital (Espanya).
      </p>
    </div>
  )
}