import { useLang } from "../../../../../../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileImport, faSliders } from "@fortawesome/free-solid-svg-icons";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useModalData } from "../../../../../../../hooks/useModalData";
import { ModalImportarActividad } from "../ModalImportarActividad";
import { ModalMejora } from "./ModalMejora";

export const TableMejora = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, fetchFacturasGET, idedificio, fetchParamsMisSistemasGETData, fetchParamsAgentesGET,
    fetchParamsAgentesGETData, tiposIntervencionStaticServices, showFilter, setshowFilter, navLinksFases,
    fetchSuccess, fetchElementosConstructivosData, idCurrentFase }: any) => {

    const Lang = useLang()

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // --------------------- Modal Nuevo usuario ---------------------

    const [dataModal_NuevaActividad, handleShowModal_NuevaActividad, handleCloseModal_NuevaActividad] = useModalData()

    let allActions: any = [
        {
            label: <span><FontAwesomeIcon icon={faSliders} /></span>,
            variant: 'outlined',
            color: 'secondary',
            action: () => setshowFilter(!showFilter),
        }
    ]

    let allActionsPerLine: any = []

    allActionsPerLine.push(
        {
            variant: 'outlined',
            color: 'secondary',
            label: <FontAwesomeIcon icon={faEye} className='' />,
            action: (item: any) => {
                handleShowUsuario({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('VER_DETALLE')
            },
        },
        {
            variant: 'outlined',
            label: <FontAwesomeIcon icon={faFileImport} className='' />,
            action: (item: any) => {
                handleShowModal_NuevaActividad({
                    ...item,
                    descripcio: item.descripcio,
                    sistema_id: item.elemento,
                    tipus_intervencio: 'millora',
                    origen_id: item.id,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('IMPORTAR')
            },
        },
    )
    
    const columnsTable = {
        descripcio: {
            title: Lang('DESCRIPCION'),
        },
        nivel_principal_texto: {
            title: Lang('REQUISITO'),
            // adapter: convertParamToObject(fetchElementosConstructivosData?.data)
        },
        beneficio: {
            title: Lang('BENEFICIO'),
        },
        complejidad_texto: {
            title: Lang('COMPLEJIDAD'),
        },
    }

    // Acciones

    return (
        <>
            <TableAdvanced
                classNameTableAdvanced='minByDropdown mt-3'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('MEDIDAS_DE_MEJORA')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                />
            </TableAdvanced>

            <ModalImportarActividad
                data={dataModal_NuevaActividad}
                handleClose={handleCloseModal_NuevaActividad}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchParamsMisSistemasGETData={fetchParamsMisSistemasGETData}
                navLinksFases={navLinksFases}
                fetchSuccess={fetchSuccess}
                fetchElementosConstructivosData={fetchElementosConstructivosData}
                idCurrentFase={idCurrentFase}
                enableSistema={true}
            />

            <ModalMejora
                data={showUsuario}
                handleClose={handleCloseUsuario}
            />

        </>
    )
}
