import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../controllers/controllers"

const ElementosConstructivosAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    actiu: parseBoolean(item.actiu),
                    estado: parseString(item.estado),
                    admite_hijos: parseBoolean(item.admite_hijos),
                    es_seleccionable: parseBoolean(item.es_seleccionable),
                    metricas: parseArray(item.metricas),
                    order: parseAny(item.order),
                    padre_id: parseAny(item.padre_id),
                    texto: parseString(item.texto),
                    name: parseString(item.texto),
                    hijos: parseArray(item.hijos),
                    otros_elementos: parseArray(item.otros_elementos),
                    elemento_completado: parseBoolean(item.elemento_completado),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    GET_IMPORTAR_EDIFICIO: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}


const ElementoConstructivoAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    nom: parseString(item.nom),
                    ref_cadastral: parseString(item.ref_cadastral),
                    created_at: parseString(item.created_at),
                    updated_at: parseString(item.updated_at),
                    tipus_edifici: parseString(item.tipus_edifici),
                    tipus_producte: parseObject(item.tipus_producte),
                    inmuebles: parseArray(item.inmuebles),
                    identificacion: parseArray(item.identificacion),
                    propiedad: parseArray(item.propiedad),
                    fotos: parseArray(item.fotos),
                    planos: parseArray(item.planos),
                    pagos: parseArray(item.pagos),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseString(item.edifici_id),
                    nom: parseString(item.nom),
                    nif: parseString(item.nif),
                    codi_postal: parseString(item.codi_postal),
                    poblacio: parseString(item.poblacio),
                    provincia: parseString(item.provincia),
                    pais: parseString(item.pais),
                    telefon: parseString(item.telefon),
                    email: parseString(item.email),
                    web: parseString(item.web),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_BY_EDIFICIO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    items: parseArray(item.items),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_BY_METRICAS: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    metricas: parseArray(item.metricas),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_ELEMENTOS_BY_EDIFICIO: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    lista_ids_hijos: parseArray(item.lista_ids_hijos),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}


export {
    ElementosConstructivosAdapters,
    ElementoConstructivoAdapters,
}