import React, { useState } from 'react'
import '../../../theme/scss/layouts/LayoutLibro.scss'
import NavTop from './NavTop';
import Body from './Body';
import { NavTop2 } from './NavTop2';
import { Footer } from '../Footer/Footer';

interface ILayoutLibro {
    children: any;
    label?: string;
}

const LayoutLibro = ({ children, label }: ILayoutLibro) => {

    const [showNavLeft, setshowNavLeft] = useState(false)

    return (
        <section className='LayoutLibro d-flex flex-column'>
            {
                children?.map((childElement: any, indexchildElement: any) =>
                    addPropsToReactElement(childElement, { showNavLeft, setshowNavLeft, label, 'key': indexchildElement } )
                )
            }
        </section>
    )
}

function addPropsToReactElement(element: any, props: any) {
    if (React.isValidElement(element)) {
        return React.cloneElement(element, props)
    }
    return element
}

LayoutLibro.NavTop = NavTop
LayoutLibro.NavTop2 = NavTop2
LayoutLibro.Body = Body
LayoutLibro.Footer = Footer

export default LayoutLibro


