import { useEffect, useState } from "react";
import { useLang } from "../../../../../../../../language";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { clearEmptyKeys, getValuesParams, isAccesoRestringido, setUrlByParams } from "../../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../../hooks/useForm";
import { useLocation } from "react-router";
import { TableDocumentacion } from "./TableDocumentacion";
import { DocumentacionServices } from "./controllers/documentacion.services";
import { Grid } from "@mui/material";

export const Documentacion = ({ auth, idAnotacion, idCurrentProject, idActividad, readOnly }: any) => {

    const Lang = useLang()

    const [fetchDocumentacionGET, fetchDocumentacionGETData] = DocumentacionServices.GET()

    // --------------------- Modal Nuevo edificio ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    // Buscador de agentes

    const location = useLocation()

    const params: any = {};

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '15',
            // edifici_id: idEdificio,
            order: '',
            tipo_agente: '',
            projecteid: idCurrentProject,
            projecteseguimentid: idAnotacion,
            projecteintervencionid: idActividad,
            tipus: 'document',
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        // edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        tipo_agente: params.tipo_agente || allValuesSearch.tipo_agente,
        projecteid: params.projecteid || allValuesSearch.projecteid,
        projecteseguimentid: params.projecteseguimentid || allValuesSearch.projecteseguimentid,
        projecteintervencionid: params.projecteintervencionid || allValuesSearch.projecteintervencionid,
        tipus: params.tipus || allValuesSearch.tipus,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecteseguimentid', 'projecteintervencionid', 'tipus')

        if (formValues.page !== 1) {

            handleFormChange({
                ...formValues,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(formValues)

            fetchDocumentacionGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams = getValuesParams(formValuesSearch)

        setUrlByParams(urlParams, 'limit', 'edifici_id', 'projecteid', 'projecteseguimentid', 'projecteintervencionid', 'tipus')

        fetchDocumentacionGET({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    const [showFilter, setshowFilter] = useState(false);

    return (
        <>
            <div className="">

                <Grid container>

                    <Grid item xs={12} className='py-0 py-lg-0'>

                        <section>
                            <TableDocumentacion
                                data={fetchDocumentacionGETData.data?.items}
                                loading={fetchDocumentacionGETData.loading}
                                total={fetchDocumentacionGETData.data?.total}
                                perPage={fetchDocumentacionGETData.data?.per_page}

                                formValuesSearch={formValuesSearch}
                                handleFormChange={handleFormChange}
                                handleInputChangeSearch={handleInputChangeSearch}

                                // idEdificio={idEdificio}
                                fetchDocumentacionGET={fetchDocumentacionGET}
                                // fetchParamsDocumentosGETData={fetchParamsDocumentosGETData}
                                idCurrentProject={idCurrentProject}
                                idAnotacion={idAnotacion}
                                idActividad={idActividad}
                                readOnly={readOnly}
                            />
                        </section>

                    </Grid>

                </Grid>
            </div>
        </>
    );
};
