import { parseAny, parseArray, parseBoolean, parseNumber, parseObject, parseString, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../../../../controllers/controllers"

const FasesAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    creat_per_id: parseString(item.creat_per_id),
                    duracion: parseString(item.duracion),
                    edifici_id: parseString(item.edifici_id),
                    fase: parseString(item.fase),
                    orden: parseString(item.orden),
                    total_coste_maximo: parseString(item.total_coste_maximo),
                    total_coste_minimo: parseString(item.total_coste_minimo),
                    total_reduccion_consumo_epnr: parseString(item.total_reduccion_consumo_epnr),

                    name: parseString(item.fase)
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}


const FaseAdapters = {

    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseNumber(item.id),
                    creat_per_id: parseString(item.creat_per_id),
                    duracion: parseString(item.duracion),
                    edifici_id: parseString(item.edifici_id),
                    fase: parseString(item.fase),
                    orden: parseString(item.orden),
                    total_coste_maximo: parseString(item.total_coste_maximo),
                    total_coste_minimo: parseString(item.total_coste_minimo),
                    total_reduccion_consumo_epnr: parseString(item.total_reduccion_consumo_epnr),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    fase: parseString(item.fase),
                    orden: parseAny(item.orden)
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    edifici_id: parseNumber(item.edifici_id),
                    fase: parseString(item.fase),
                    orden: parseAny(item.orden)
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST_BY_METRICAS: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    metricas: parseArray(item.metricas),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_DESMARCAR: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    seleccionado: parseBoolean(item.seleccionado),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH_ONBLUR: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    total_coste_minimo: parseNumber(item.total_coste_minimo, { removeNullable: true }),
                    total_coste_maximo: parseNumber(item.total_coste_maximo, { removeNullable: true }),
                    total_reduccion_consumo_epnr: parseString(item.total_reduccion_consumo_epnr, { removeNullable: true }),
                    duracion: parseNumber(item.duracion, { removeNullable: true }),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },


}


export {
    FasesAdapters,
    FaseAdapters,
}