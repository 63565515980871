import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LanguageState {
  idCurrentFaseProgGes: string
}

const initialState: LanguageState = {
  idCurrentFaseProgGes: ''
}

export const idCurrentFaseProgGesSlice = createSlice({
  name: 'idCurrentFaseProgGes',
  initialState,
  reducers: {
    changeIdCurrentFaseProgGes: (state, action: PayloadAction<string>) => {
      state.idCurrentFaseProgGes = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeIdCurrentFaseProgGes } = idCurrentFaseProgGesSlice.actions

// export default counterSlice.reducer