import { useAxios } from "../../../../../../../../hooks/useAxios"
import { MantCorrectDeficienciaAdapters, MantCorrectDeficienciasAdapters } from "./mantpreventivo.adapters"

const MantCorrectDeficienciasServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/deficiencias',
            type: 'json',
            adapter: {
                method: MantCorrectDeficienciasAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const MantCorrectDeficienciaServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/deficiencias`,
            type: 'json',
            adapter: {
                method: MantCorrectDeficienciaAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/deficiencias',
            type: 'json',
            adapter: {
                method: MantCorrectDeficienciaAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/deficiencias',
            type: 'json',
            adapter: {
                method: MantCorrectDeficienciaAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/deficiencias',
            type: 'json',
            adapter: {
                method: MantCorrectDeficienciaAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    MantCorrectDeficienciasServices,
    MantCorrectDeficienciaServices
}