import { parseAny, parseArray, parseBoolean, parseCheck, parseNumber, parseObject, parseString, receiveCheck, renderReceiveAdapter, renderSchema, renderSendAdapter, sendCheck } from "../../../../../../../controllers/controllers"

const MantenimientosPreventivosAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    actiu: parseBoolean(item.actiu),
                    estado: parseString(item.estado),
                    admite_hijos: parseBoolean(item.admite_hijos),
                    es_seleccionable: parseBoolean(item.es_seleccionable),
                    metricas: parseArray(item.metricas),
                    order: parseAny(item.order),
                    padre_id: parseAny(item.padre_id),
                    texto: parseString(item.texto),
                    name: parseString(item.texto),
                    hijos: parseArray(item.hijos),
                    operations: parseArray(item.operations),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

const MantenimientoPreventivoAdapters = {
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    id: parseString(item.id),
                    elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    descripcio: parseString(item.descripcio),
                    periodicitat: parseNumber(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    document_referencia: parseString(item.document_referencia),
                    responsable: parseString(item.responsable),
                    obligatorietat: receiveCheck(item.obligatorietat, 'obligatorietat'),
                    manual_habitatge: receiveCheck(item.manual_habitatge, 'manual_habitatge'),
                    data_inici_any: parseNumber(item.data_inici_any),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    POST: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    descripcio: parseString(item.descripcio),
                    periodicitat: parseNumber(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    document_referencia: parseString(item.document_referencia),
                    responsable: parseString(item.responsable),
                    obligatorietat: sendCheck(item.obligatorietat),
                    manual_habitatge: sendCheck(item.manual_habitatge),
                    data_inici_any: parseNumber(item.data_inici_any),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    PATCH: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // elemento_constructivo_id: parseNumber(item.elemento_constructivo_id),
                    descripcio: parseString(item.descripcio),
                    periodicitat: parseNumber(item.periodicitat),
                    periodicitat_text: parseString(item.periodicitat_text),
                    document_referencia: parseString(item.document_referencia),
                    responsable: parseString(item.responsable),
                    obligatorietat: sendCheck(item.obligatorietat),
                    manual_habitatge: sendCheck(item.manual_habitatge),
                    data_inici_any: parseNumber(item.data_inici_any),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

    DELETE: {

        send: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {}

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderSendAdapter(data, type, this.schema)
            }
        },

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    data: parseObject(item.data),
                    message: parseString(item.message),
                    status: parseString(item.status),
                }

                return renderSchema(data)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },

}


const MejorasNivelPrincipalAdapters = {
    
    GET: {

        receive: {

            schema: function (item: any) {

                if (!item) item = {}

                const data = {
                    // id: parseString(item.id),
                }

                return renderSchema(item)
            },

            adapter: function (data: any, type: string) {

                return renderReceiveAdapter(data, type, this.schema)
            }
        },
    },
}

export {
    MantenimientosPreventivosAdapters,
    MantenimientoPreventivoAdapters,
    MejorasNivelPrincipalAdapters
}