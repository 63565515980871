import { useEffect } from "react"
import { useLang } from '../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../helpers/validations'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { convertStaticServiceToArray, getValuesParams, isStatusSuccess } from '../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { MantenimientoPreventivoServices } from "./controllers/mantpreventivo.services"
import { InputTextarea } from "../../../../../../components/Inputs/InputTextarea"
import { InputChecks } from "../../../../../../components/Inputs/InputChecks"
import { MesesStaticServices } from "../../../../../../controllers/statics/statics.services"

export const ModalMantPrevIntervencion = ({ data, handleClose, fetchParamsAgentesGETData, idedificio, fetchSuccess,
    formValuesSearch, fetchParamsDocumentosGETData, id }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchMantPrevGET, fetchMantPrevGETData] = MantenimientoPreventivoServices.GET()

    const [fetchMantPrevPOST, fetchMantPrevPOSTData] = MantenimientoPreventivoServices.POST()

    const [fetchMantPrevPATCH, fetchMantPrevPATCHData] = MantenimientoPreventivoServices.PATCH()

    const [fetchMantPrevDELETE, fetchMantPrevDELETEData] = MantenimientoPreventivoServices.DELETE()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        // nom: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // tipus_document: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevGETData.status)) {

            handleFormDocumento({
                ...fetchMantPrevGETData.data
            })
        }

    }, [fetchMantPrevGETData]);


    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchMantPrevPOST({
                id: idedificio,
                body: {
                    ...formDocumento,
                    elemento_constructivo_id: id
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevPOSTData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidDocumento(formDocumento)) {

            fetchMantPrevPATCH({
                id: data?.id + '/actualizar',
                body: {
                    ...formDocumento,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevPATCHData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchMantPrevDELETE({
            id: data?.id + '/eliminar',
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMantPrevDELETEData.status)) {
            fetchSuccess()
            handleClose()
        }

    }, [fetchMantPrevDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (isNumber(data?.id)) {

                fetchMantPrevGET({
                    id: data?.id + '/mostrar'
                })
            }

        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    const mesesStaticServices = MesesStaticServices.GET()


    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_INTERVENCION') : Lang('EDITAR_INTERVENCION')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchMantPrevGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ': *'}
                                            name='descripcio'
                                            value={formDocumento.descripcio}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.descripcio}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3}>

                                        <InputSelect
                                            label={Lang('FASE')}
                                            name='idtiponotificacion'
                                            values={[
                                                { id: 1, name: 'Proyecto 2023 - fase 1' },
                                                { id: 2, name: 'Proyecto 2023 - fase 2' },
                                                { id: 3, name: 'Proyecto 2023 - fase 3' },
                                            ]}
                                        // onChange={handleInputRelacion}
                                        />
                                    </Grid>

                                    <Grid width='100%'></Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_DE_OBRAS') + ' (€)'}
                                            name='periodicitat'
                                            type='number'
                                            value={formDocumento.periodicitat}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.periodicitat}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_DE_HONORARIOS') + ' (€)'}
                                            name='periodicitat'
                                            type='number'
                                            value={formDocumento.periodicitat}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.periodicitat}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_DE_TASAS') + ' (€)'}
                                            name='periodicitat'
                                            type='number'
                                            value={formDocumento.periodicitat}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.periodicitat}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={3}>
                                        <InputText
                                            label={Lang('OTROS') + ' (€)'}
                                            name='periodicitat'
                                            type='number'
                                            value={formDocumento.periodicitat}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.periodicitat}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('ANIO_INICIO')}
                                            name='data_inici_any'
                                            type='number'
                                            value={formDocumento.data_inici_any}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.data_inici_any}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputSelect
                                            label={Lang('MES_INICIO')}
                                            name='idtiponotificacion'
                                            value={formDocumento.idtiponotificacion}
                                            values={convertStaticServiceToArray(mesesStaticServices)}
                                            // onChange={handleInputRelacion}
                                            localValidation={validDocumento.idtiponotificacion}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('DURACION_MESES')}
                                            name='data_inici_any'
                                            type='number'
                                            value={formDocumento.data_inici_any}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchMantPrevPOSTData : fetchMantPrevPATCHData}
                                            localValidation={validDocumento.data_inici_any}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchMantPrevPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={handleSubmitEliminarAgente}
                                    loadings={[fetchMantPrevDELETEData.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchMantPrevPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchMantPrevPOSTData} />

            <ModalStatusServer fetchData={fetchMantPrevPATCHData} />

            <ModalStatusServer fetchData={fetchMantPrevDELETEData} />
        </>
    )
}