import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useModalData } from "../../../../../../hooks/useModalData";
import { useLang } from "../../../../../../language";
import { ModalMantPrevIntervencion } from "./ModalMantPrevIntervencion";
import { InputSelect } from "../../../../../../components/Inputs/InputSelect";
import { InputChecks } from "../../../../../../components/Inputs/InputChecks";
import { ModalConfirm } from "../../../../../../components/Modals/ModalConfirm";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";

import './TableMantPrevIntervencion.scss'
import { ModalMejoraFases } from "./ModalMejoraFases";
import { isStatusSuccess } from "../../../../../../helpers/helpers";
import { MedidaServices } from "../PotencialMejoras/Medidas/controllers/medidas.services";
import { Placeholder } from "../../../../../../components/Placeholder/Placeholder";

export const TableMantPrevIntervenciones = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, idedificio, fetchSuccess, fetchParamsAgentesGETData,
    fetchParamsDocumentosGETData, id, fetchMejoraFasesData, fetchParamPotencialMejoraGETData }: any) => {

    const Lang = useLang()

    // --------------------- Modal Nuevo usuario ---------------------

    const [showUsuario, handleShowUsuario, handleCloseUsuario] = useModalData()

    const [dataModal_ConfirmDelete, handleShowModal_ConfirmDelete, handleCloseModal_ConfirmDelete] = useModalData()

    const [dataModal_MejoraFases, handleshowModal_MejoraFases, handleCloseModal_MejoraFases] = useModalData()

    const fasesParams: any = fetchMejoraFasesData?.data?.items

    const [fetchMedidaSeleccionadoPATCH, fetchMedidaSeleccionadoPATCHData] = MedidaServices.PATCH_SELECCIONADO()

    const handleChangeMedidaSeleccionado = (id: string, value: any) => {

        fetchMedidaSeleccionadoPATCH({
            id,
            body: {
                seleccionado: value
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaSeleccionadoPATCHData.status)) {
            fetchSuccess()
        }

    }, [fetchMedidaSeleccionadoPATCHData]);

    const [fetchMedidaUrgentePATCH, fetchMedidaUrgentePATCHData] = MedidaServices.PATCH_URGENTE()

    const handleChangeMedidaUrgente = (id: string, value: any) => {

        fetchMedidaUrgentePATCH({
            id,
            body: {
                urgente: value
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchMedidaUrgentePATCHData.status)) {
            // fetchSuccess()
        }

    }, [fetchMedidaUrgentePATCHData]);



    const [fetchMedidaFasePATCH, fetchMedidaFasePATCHData] = MedidaServices.PATCH_FASE()


    const handleChangeMedidaFase = (id: string, value: any) => {

        fetchMedidaFasePATCH({
            id,
            body: {
                fase_id: value
            }
        })
    }

    const [idsHidden, setidsHidden] = useState<any>({});

    const handleToggleElemConst = (key: string) => {

        const idsHiddenTemp = { ...idsHidden }

        if (idsHiddenTemp[key]) {

            delete idsHiddenTemp[key]

        } else {

            idsHiddenTemp[key] = true
        }

        setidsHidden(idsHiddenTemp)
    }

    const [tree, settree] = useState<any>({});

    useEffect(() => {

        if (isStatusSuccess(fetchParamPotencialMejoraGETData.status)) {

            const treeRoot = fetchParamPotencialMejoraGETData.data?.[0]

            settree(treeRoot)            
        }

    }, [fetchParamPotencialMejoraGETData]);

    return (
        <>
            <div className="mt-3">

                <div className="d-flex justify-content-end mb-3">
                    <Button variant="contained" color="primary" size="small" onClick={handleshowModal_MejoraFases}>
                        <FontAwesomeIcon icon={faEdit} className="me-2" /> {Lang('FASES_DE_MEJORA')}
                    </Button>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>{Lang('REQUISITO')}</TableCell> */}
                                <TableCell >{Lang('EXIGENCIAS')}	</TableCell>
                                <TableCell >{Lang('ELEMENTOS')}</TableCell>
                                <TableCell >{Lang('MEDIDA')}</TableCell>
                                <TableCell className="text-center">{Lang('COSTE')}</TableCell>
                                <TableCell className="text-center">{Lang('FASE')}</TableCell>
                                <TableCell >{Lang('URGENTE')}</TableCell>
                                <TableCell ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                tree?.hijos?.map((i1: any) => i1.id)?.map((key: string) => {

                                    if (!data[key]) return null

                                    return (
                                        <>
                                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                                                <TableCell colSpan={12} className="TableRowAsignarFase hover-text-decoration" onClick={() => handleToggleElemConst(key)}>
                                                    <div className="text-center" >
                                                        <span>
                                                            {data[key]?.[0]?.nivel_principal_texto}
                                                        </span>
                                                        {
                                                            idsHidden?.[key] ? <FontAwesomeIcon icon={faChevronDown} className="ms-2" /> : <FontAwesomeIcon icon={faChevronUp} className="ms-2" />
                                                        }
                                                    </div>
                                                </TableCell>
                                            </TableRow>

                                            {
                                                data[key]?.map((item: any, index: number) => {

                                                    return (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={idsHidden?.[key] ? 'd-none' : ''} >

                                                            {/* <TableCell >{data[key]?.['medidas']?.requisito}</TableCell> */}
                                                            <TableCell >{item.exigencia_texto}</TableCell>
                                                            <TableCell >{item.elemento_texto}</TableCell>
                                                            <TableCell >{item.medida}</TableCell>
                                                            <TableCell className="text-nowrap text-center">
                                                                {item.costo_texto}
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{ width: '200px' }}>
                                                                    <InputSelect
                                                                        label={Lang('FASE') + ':'}
                                                                        name='idtiponotificacion'
                                                                        defaultValue={item?.fase_id}
                                                                        values={fasesParams}
                                                                        onChange={(e: any) => handleChangeMedidaFase(item.id, e.target?.value)}
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div>
                                                                    <InputChecks
                                                                        name='urgente'
                                                                        valuesDefault={[
                                                                            {
                                                                                id: 'urgente',
                                                                            }
                                                                        ]}
                                                                        defaultChecked={item.urgente}
                                                                        onChangeUnControlled={(val: string) => handleChangeMedidaUrgente(item.id, val)}
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button variant="outlined" color="primary" onClick={() => handleShowModal_ConfirmDelete({
                                                                    action: 'EDIT',
                                                                    id: item.id,
                                                                    val: false
                                                                })}>
                                                                    <FontAwesomeIcon icon={faTrash} className='' />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }

                                        </>

                                    )
                                }
                                )
                            }

                            {
                                loading &&
                                <TableAdvancedLoader />
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
            <ModalMantPrevIntervencion
                data={showUsuario}
                handleClose={handleCloseUsuario}
                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                idedificio={idedificio}
                formValuesSearch={formValuesSearch}
                fetchSuccess={fetchSuccess}
                fetchParamsDocumentosGETData={fetchParamsDocumentosGETData}
                id={id}
            />

            <ModalConfirm
                data={dataModal_ConfirmDelete}
                handleClose={handleCloseModal_ConfirmDelete}
                message={<p>{Lang('PARRAFO_DESEA_DESMARCAR_MEJORA')}</p>}
                textOnClickOk={Lang('SI_DESMARCAR')}
                variantClickOk='contained'
                onClickOK={() => handleChangeMedidaSeleccionado(dataModal_ConfirmDelete.id, dataModal_ConfirmDelete.val)}
                fetchData={fetchMedidaSeleccionadoPATCHData}
            />

            <ModalMejoraFases
                data={dataModal_MejoraFases}
                handleClose={handleCloseModal_MejoraFases}
                idedificio={idedificio}
            />
        </>
    )
}

const TableAdvancedLoader = () => {
    return (
        <>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-1' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-2' height='1.1rem' />
                </TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell colSpan={12} className="">
                    <Placeholder className='px-4 py-1 delay-3' height='1.1rem' />
                </TableCell>
            </TableRow>
        </>
    )
}
