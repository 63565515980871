export const CondParSeccion6 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Todas las dudas, consultas, información, incidencias o cualquier tipo de reclamación podrán realizarse mediante la dirección electrónica <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Tots els dubtes, consultes, informació, incidències o qualsevol tipus de reclamació podran realitzar-se mitjançant l' adreça electrònica <a className="link-primary" href="mailto:consultoriatecnica@cateb.cat">consultoriatecnica@cateb.cat</a>.
      </p>
    </div>
  )
}