export const AvisoLegalSeccion4 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <span className="text-decoration-underline">4.1. Responsabilidad del Usuario</span>
      </p>

      <p>
        El usuario será responsable de:
      </p>
      <p>

        (1) Los malos usos realizados sobre el contenido o servicios disponibles a través de nuestro sitio web de acuerdo con lo indicado en las presentes condiciones.
      </p>
      <p>

        (2) De los daños y perjuicios causados a CATEB, incluidos los daños emergentes y lucro cesante, así como los gastos judiciales y honorarios de abogado y procurador a los que se viera sometido como consecuencia de la actuación dolosa o negligente del usuario.
      </p>

      <p>
        <span className="text-decoration-underline">4.2. Limitación de la responsabilidad del titular</span>
      </p>

      <p>
        CATEB no será responsable de:
      </p>

      <ul>
        <li>
          La inexistencia de posibles errores o faltas de actualización en el sitio web, reservándose el derecho a efectuar en cualquier momento y sin necesidad de aviso previo, modificaciones y actualizaciones sobre la información contenida en su portal, configuración o presentación.
        </li>

        <li>
          Los fallos e incidencias que pudieran producirse en las comunicaciones, borrado o transmisiones incompletas dado que pueden producirse caídas de la red u otro tipo de situaciones de las que CATEB no es directamente responsable y no podrá asumir responsabilidad por ello ni garantizar que los servicios del sitio web estén constantemente operativos, quedando eximida de cualquier daño que esto pudiera provocar.
        </li>

        <li>
          Los errores o daños producidos por la negligencia o mala fe en el uso del servicio por parte del Usuario y/o Cliente.
        </li>

        <li>
          La producción de cualquier tipo de daño que el Cliente o terceros pudiesen ocasionar en el sitio Web.
        </li>
        <li>
          Sin perjuicio de lo que se dispone en materia de protección de datos personales, el Cateb no garantiza la privacidad y seguridad de la utilización de la Web y de los Servicios y, en particular, que terceros no autorizados no puedan tener conocimiento del tipo, las condiciones, las características y las circunstancias del uso que los Usuarios hagan de la Web y de los Servicios
          CATEB se reserva el derecho a suspender el acceso sin previo aviso cuando se detecte cualquier situación en la que el Usuario y/o Cliente no haya actuado diligentemente y/o se haya aprovechado de las funcionalidades de la página web. La suspensión del servicio podrá tener carácter temporal o definitivo e CATEB no será responsable de los perjuicios que la suspensión pudiera ocasionarle al usuario/cliente.
        </li>
      </ul>

    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <span className="text-decoration-underline">4.1. Responsabilitat de l'Usuari</span>
      </p>

      <p>
        L' usuari serà responsable de:
      </p>
      <p>

        (1) Els mals usos realitzats sobre el contingut o serveis disponibles a través del nostre lloc web d'acord amb el que s'indica en les presents condicions.
      </p>
      <p>

        (2) Dels danys i perjudicis causats a CATEB, inclosos els danys emergents i lucre cessant, així com les despeses judicials i honoraris d'advocat i procurador als quals es veiés sotmès com a conseqüència de l'actuació dolosa o negligent de l'usuari.
      </p>

      <p>
        <span className="text-decoration-underline">4.2. Limitació de la responsabilitat del titular</span>
      </p>

      <p>
        CATEB no serà responsable de:
      </p>

      <ul>
        <li>
          La inexistència de possibles errors o faltes d'actualització en el lloc web, reservant-se el dret a efectuar en qualsevol moment i sense necessitat d'avís previ, modificacions i actualitzacions sobre la informació continguda en el seu portal, configuració o presentació.
        </li>

        <li>
          Les fallades i incidències que poguessin produir-se en les comunicacions, esborrat o transmissions incompletes atès que poden produir-se caigudes de la xarxa o un altre tipus de situacions de les quals CATEB no és directament responsable i no podrà assumir responsabilitat per això ni garantir que els serveis del lloc web estiguin constantment operatius, quedant eximida de qualsevol dany que això pogués provocar.
        </li>

        <li>
          Els errors o danys produïts per la negligència o mala fe en l'ús del servei per part de l'Usuari i/o Client.
        </li>

        <li>
          La producció de qualsevol tipus de dany que el Client o tercers poguessin ocasionar al lloc Web.
        </li>
        <li>
          Sens perjudici del que es disposa en matèria de protecció de dades personals, el Cateb no garanteix la privacitat i seguretat de la utilització de la Web i dels Serveis i, en particular, que tercers no autoritzats no puguin tenir coneixement del tipus, les condicions, les característiques i les circumstàncies de l'ús que els Usuaris facin de la Web i dels Serveis
          CATEB es reserva el dret a suspendre l'accés sense previ avís quan es detecti qualsevol situació en què l'Usuari i/o Client no hagi actuat diligentment i/o s'hagi aprofitat de les funcionalitats de la pàgina web. La suspensió del servei podrà tenir caràcter temporal o definitiu i CATEB no serà responsable dels perjudicis que la suspensió pogués ocasionar-li a l'usuari/client.
        </li>
      </ul>

    </div>
  )
}