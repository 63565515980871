import '../PagesDefault.scss'
import { Box, Grid, Typography } from "@mui/material";
import { useLang } from "../../language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { InputText } from "../../components/Inputs";
import { useForm } from "../../hooks/useForm";
import { ButtonAdvanced } from "../../components/ButtonAdvanced/ButtonAdvanced";
import { InputTextarea } from "../../components/Inputs/InputTextarea";
import ModalAdvanced from "../../components/ModalAdvanced/ModalAdvanced";
import { validCorrectEmail, validExiste } from "../../helpers/validations";
import { useValidator } from "../../hooks/useValidator";
import { ContactoServices } from "../../controllers/comunicaciones/comunicaciones.services";
import { ModalStatusServer } from "../../components/Modals/ModalStatusServer";
import { useEffect } from "react";
import { isFetchDataInitLoader, isStatusSuccess } from "../../helpers/helpers";
import { LoaderModalFormPrimary } from "../../components/Loaders/LoaderModalFormPrimary";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { isEmpty } from "lodash";

export const ModalContacto = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const { auth } = useSelector((state: RootState) => state.auth)

    const [fetchContactoPOST, fetchContactoPOSTData] = ContactoServices.POST()

    const [fetchContactoPublicoPOST, fetchContactoPublicoPOSTData] = ContactoServices.POST_PUBLICO()

    const [fetchContactoCodeGET, fetchContactoCodeGETData] = ContactoServices.GET_UNIQUE_CODE()

    const [formContactar, handleInputContactar, , resetFormContactar] = useForm({
        assumpte: '',
        message: '',
        remitent_nom: '',
        remitent_email: '',
        telefono: '',
        code: '',
    })

    const [validContactoPublico, handleValidContactoPublico, resetvalidContactoPublico] = useValidator({
        remitent_nom: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        remitent_email: [
            { functionValid: validCorrectEmail, msgError: Lang('VALID_CORRECT_EMAIL'), isOk: true },
        ],
    })

    const [validContacto, handleValidContacto, resetvalidContacto] = useValidator({
        assumpte: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        message: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const handlefetchContactoPOST = () => {

        if (isEmpty(auth)) {

            if (handleValidContactoPublico(formContactar) && handleValidContacto(formContactar)) {

                fetchContactoPublicoPOST({
                    body: {
                        ...formContactar,
                        code: fetchContactoCodeGETData?.data?.code,
                    }
                })
            }

        } else {

            if (handleValidContacto(formContactar)) {

                fetchContactoPOST({
                    body: {
                        ...formContactar,
                        code: fetchContactoCodeGETData?.data?.code,
                    }
                })
            }
        }
    }

    useEffect(() => {

        if (data.value) {

            fetchContactoCodeGET()

        } else {

            resetFormContactar()
            resetvalidContacto()
        }

    }, [data.value]);

    useEffect(() => {

        if (isStatusSuccess(fetchContactoPOSTData.status)) {

            handleClose()
        }

    }, [fetchContactoPOSTData]);

    useEffect(() => {

        if (isStatusSuccess(fetchContactoPublicoPOSTData.status)) {

            handleClose()
        }

    }, [fetchContactoPublicoPOSTData]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='675'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('CONTACTANOS')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='p-0'>

                    <Grid container justifyContent='center' spacing={7} rowSpacing={5}>

                        <Grid item xs={12} className=''>

                            {
                                isFetchDataInitLoader(fetchContactoCodeGETData) ?
                                    <LoaderModalFormPrimary />
                                    :
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Grid container spacing={3} rowSpacing={3}>

                                            {
                                                isEmpty(auth) &&
                                                <>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <InputText
                                                            label={Lang('NOMBRE_Y_APELLIDOS') + ': *'}
                                                            name='remitent_nom'
                                                            value={formContactar.remitent_nom}
                                                            onChange={handleInputContactar}
                                                            localValidation={validContactoPublico.remitent_nom}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <InputText
                                                            label={Lang('EMAIL') + ': *'}
                                                            name='remitent_email'
                                                            value={formContactar.remitent_email}
                                                            onChange={handleInputContactar}
                                                            localValidation={validContactoPublico.remitent_email}
                                                        />
                                                    </Grid>
                                                </>
                                            }

                                            <Grid item xs={12} md={6} lg={6}>
                                                <InputText
                                                    label={Lang('TELEFONO') + ':'}
                                                    name='telefono'
                                                    value={formContactar.telefono}
                                                    onChange={handleInputContactar}
                                                    localValidation={validContactoPublico.telefono}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={12}>
                                                <InputText
                                                    label={Lang('ASUNTO') + ': *'}
                                                    name='assumpte'
                                                    value={formContactar.assumpte}
                                                    onChange={handleInputContactar}
                                                    localValidation={validContacto.assumpte}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <InputTextarea
                                                    label={Lang('MENSAJE') + ':'}
                                                    name='message'
                                                    value={formContactar.message}
                                                    onChange={handleInputContactar}
                                                    localValidation={validContacto.message}
                                                    className='w-100'
                                                />
                                            </Grid>

                                            <Grid item xs={12} justifyContent='end' display='flex'>

                                                <ButtonAdvanced
                                                    variant="contained" className="mt-3 ms-auto"
                                                    handleSubmit={handlefetchContactoPOST}
                                                    loadings={[fetchContactoPOSTData.loading, fetchContactoPublicoPOSTData.loading]}
                                                    value={Lang('ENVIAR')} icon={<FontAwesomeIcon icon={faPaperPlane} className="me-2" />}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Box>
                            }


                        </Grid>

                    </Grid>

                </ModalAdvanced.Body>

            </ModalAdvanced>

            <ModalStatusServer
                fetchData={fetchContactoPOSTData}
                customData={
                    {
                        200: {
                            status: Lang('OPERACION_EXITOSA'),
                            message: (
                                <>
                                    <p className="mb-0">{Lang('P_FORMULARIO_CONTACTO_ENVIADO')}</p>
                                </>
                            )
                        }
                    }
                }
            />

            <ModalStatusServer
                fetchData={fetchContactoPublicoPOSTData}
                customData={
                    {
                        200: {
                            status: Lang('OPERACION_EXITOSA'),
                            message: (
                                <>
                                    <p className="mb-0">{Lang('P_FORMULARIO_CONTACTO_ENVIADO')}</p>
                                </>
                            )
                        }
                    }
                }
            />
        </>

    );
};

