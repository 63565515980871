import React, { useState } from 'react'
import { useEffect } from "react"
import { useLang } from '../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../helpers/validations'
import { InputText } from '../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import { InputSelect } from '../../../../../../components/Inputs/InputSelect'
import ModalAdvanced from '../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { convertStaticServiceToArray, isStatusSuccess } from '../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { InputTextarea } from '../../../../../../components/Inputs/InputTextarea'
import { MesesStaticServices } from '../../../../../../controllers/statics/statics.services'
import { ImportarActividadServices } from './controllers/importar-actividades.services'
import { MaestrosServices } from '../../../../../../controllers/maestros/maestros.services'

export const ModalImportarActividad = ({ data, handleClose, enableSistema, idedificio,
    fetchPropiedadAgentesGET, fetchParamsMisSistemasGETData, navLinksFases = [], fetchSuccess, idCurrentFase }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const mesesStaticServices = MesesStaticServices.GET()

    const [fetchActividadPOST, fetchActividadPOSTData, resetfetchActividadPOSTData] = ImportarActividadServices.POST(idedificio)

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    const [formActividad, handleInputActividad, handleFormActividad, resetFormActividad] = useForm({
        codi_postal: '',
        numero: '',
        adreca: '',
        provincia: '',
        poblacio: '',
        tipo: '',
        data_inici_mes: '',
        descripcio: '',
        projecte_fase_id: idCurrentFase,
    })

    const [validActividad, handleValidActividad, resetvalidActividad] = useValidator({
        sistema_id: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        titol: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        projecte_fase_id: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // import_obres: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // import_honoraris: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // import_taxes: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        data_inici_any: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data_inici_mes: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        // durada_mesos: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],

    })

    const [validPeriodicidad, handleValidPeriodicidad, resetvalidPeriodicidad] = useValidator({

        periodicitat: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat_unidad: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ POST

    const handleActividadPOST = () => {

        if (handleValidActividad(formActividad) &&
            ((formActividad.tipus_intervencio !== 'manteniment') || (formActividad.tipus_intervencio === 'manteniment' && handleValidPeriodicidad(formActividad)))) {

            fetchActividadPOST({
                body: {
                    ...formActividad,
                    edifici_id: idedificio
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchActividadPOSTData.status)) {

            fetchSuccess()
            handleClose()
        }

    }, [fetchActividadPOSTData]);

    useEffect(() => {

        if (data.value) {

            fetchParamsPublicGET({
                body: [
                    {
                        parametroPadre: "periodicitat_unidad",
                    },
                ]
            })

            if (data.tipus_intervencio === 'manteniment') {
                
                handleFormActividad({
                    ...formActividad,
                    descripcio: data.descripcio,
                    sistema_id: data.sistema_id,
                    tipus_intervencio: data.tipus_intervencio,
                    origen_id: data.origen_id,
                    periodicitat: data.periodicitat,
                    periodicitat_unidad: data.periodicitat_unidad,
                })
    
            } else {
                handleFormActividad({
                    ...formActividad,
                    descripcio: data.descripcio,
                    sistema_id: data.sistema_id,
                    tipus_intervencio: data.tipus_intervencio,
                    origen_id: data.origen_id,
                })
    
            }

            
        } else {

            resetFormActividad()
            resetvalidActividad()
            resetfetchActividadPOSTData()
        }

    }, [data.value]);


    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('IMPORTAR_A_ACTIVIDAD')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={3} rowSpacing={3}>

                            <Grid item xs={12}>
                                <InputTextarea
                                    label={Lang('TITULO') + ': *'}
                                    name='titol'
                                    value={formActividad.titol}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.titol}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputTextarea
                                    label={Lang('DESCRIPCION') + ': *'}
                                    name='descripcio'
                                    value={formActividad.descripcio}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.descripcio}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <InputSelect
                                    label={Lang('FASE') + ': *'}
                                    name='projecte_fase_id'
                                    value={formActividad.projecte_fase_id}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.projecte_fase_id}
                                    values={navLinksFases?.map((item: any) => {
                                        return {
                                            id: item.link,
                                            name: item.title
                                        }
                                    })}
                                />
                            </Grid>

                            {
                                enableSistema &&
                                <Grid item xs={12} md={6} lg={4}>
                                    <InputSelect
                                        label={Lang('SISTEMA') + ': *'}
                                        name='sistema_id'
                                        value={formActividad.sistema_id}
                                        onChange={handleInputActividad}
                                        fetchData={fetchActividadPOSTData}
                                        localValidation={validActividad.sistema_id}
                                        values={fetchParamsMisSistemasGETData?.data || []}
                                    />
                                </Grid>
                            }

                            <Grid item xs={12} className='p-0 m-0'></Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <InputText
                                    label={Lang('IMPORTE_DE_OBRAS') + ' (€):'}
                                    name='import_obres'
                                    type='number'
                                    value={formActividad.import_obres}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.import_obres}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <InputText
                                    label={Lang('IMPORTE_DE_HONORARIOS') + ' (€):'}
                                    name='import_honoraris'
                                    type='number'
                                    value={formActividad.import_honoraris}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.import_honoraris}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <InputText
                                    label={Lang('IMPORTE_DE_TASAS') + ' (€):'}
                                    name='import_taxes'
                                    type='number'
                                    value={formActividad.import_taxes}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.import_taxes}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <InputText
                                    label={Lang('OTROS') + ' (€):'}
                                    name='import_altres'
                                    type='number'
                                    value={formActividad.import_altres}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.import_altres}
                                />
                            </Grid>

                            <Grid item xs={12} className='p-0 m-0'></Grid>


                            <Grid item xs={12} md={6} lg={3}>
                                <InputText
                                    label={Lang('ANIO_INICIO') + ':'}
                                    name='data_inici_any'
                                    type='number'
                                    value={formActividad.data_inici_any}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.data_inici_any}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <InputSelect
                                    label={Lang('MES_INICIO') + ':'}
                                    name='data_inici_mes'
                                    type='number'
                                    value={formActividad.data_inici_mes}
                                    values={convertStaticServiceToArray(mesesStaticServices)}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.data_inici_mes}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <InputText
                                    label={Lang('DURACION_MESES') + ':'}
                                    name='durada_mesos'
                                    type='number'
                                    value={formActividad.durada_mesos}
                                    onChange={handleInputActividad}
                                    fetchData={fetchActividadPOSTData}
                                    localValidation={validActividad.durada_mesos}
                                />
                            </Grid>

                            {
                                formActividad.tipus_intervencio === 'manteniment' &&
                                <>
                                    <Grid item style={{ 'width': '100%' }} className='p-0'></Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('PERIODICIDAD') + ': *'}
                                            name='periodicitat'
                                            type='number'
                                            value={formActividad.periodicitat}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPOSTData}
                                            localValidation={validPeriodicidad.periodicitat}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputSelect
                                            label={Lang('UNIDAD') + ': *'}
                                            name='periodicitat_unidad'
                                            value={formActividad.periodicitat_unidad}
                                            values={fetchParamsPublicGETData?.data?.['periodicitat_unidad'] || []}
                                            fetchData={fetchActividadPOSTData}
                                            onChange={handleInputActividad}
                                            localValidation={validPeriodicidad.periodicitat_unidad}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>

                                        <Typography variant='body1' className='mb-1' component="h5">
                                            {Lang('P_IMPORTAR_ACTIVIDAD')}
                                        </Typography>
                                    </Grid>

                                </>

                            }

                        </Grid>
                    </Box>

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="contained" type="button" className="mt-3 ms-auto"
                        handleSubmit={handleActividadPOST}
                        loadings={[fetchActividadPOSTData.loading]}
                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                    />

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchActividadPOSTData} />
        </>
    )
}