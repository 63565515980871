import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import ModalAdvanced from '../ModalAdvanced/ModalAdvanced';

// import './Modal.scss'

export const ModalInfo = ({ data, handleClose, message, size = 'md', disableIconTitle = false }: any) => {

    return (
        <div>
            <ModalAdvanced data={{ value: data.value }} handleClose={handleClose} size={size}>
                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {
                            !disableIconTitle && <FontAwesomeIcon icon={faInfoCircle} style={{ 'fontSize': '2.5rem' }} />
                        }
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-3 p-0'>
                    <div className='text-center' style={{ lineHeight: '1.5' }}>
                        {message}
                    </div>
                </ModalAdvanced.Body>

            </ModalAdvanced>

        </div>
    )
}
