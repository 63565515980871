import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useValidator } from "../../../../../../../../hooks/useValidator";
import { ActividadServices } from "../../controllers/actividades.services";
import { useForm } from "../../../../../../../../hooks/useForm";
import { validExiste } from "../../../../../../../../helpers/validations";
import { useLang } from "../../../../../../../../language";
import { convertStaticServiceToArray, isStatusSuccess } from "../../../../../../../../helpers/helpers";
import { InputTextarea } from "../../../../../../../../components/Inputs/InputTextarea";
import { InputSelect } from "../../../../../../../../components/Inputs/InputSelect";
import { InputText } from "../../../../../../../../components/Inputs";
import { MesesStaticServices, TiposIntervencionStaticServices } from "../../../../../../../../controllers/statics/statics.services";
import { ButtonAdvanced } from "../../../../../../../../components/ButtonAdvanced/ButtonAdvanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSave, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ElementosConstructivosParamServices } from "../../../../controllers/elemconst.services";
import { LoaderModalFormPrimary } from "../../../../../../../../components/Loaders/LoaderModalFormPrimary";
import { ModalStatusServer } from "../../../../../../../../components/Modals/ModalStatusServer";
import { useNavigate } from "react-router";
import { ModalConfirm } from "../../../../../../../../components/Modals/ModalConfirm";
import { useModalData } from "../../../../../../../../hooks/useModalData";
import { ModalMantPreventivo } from "../../../ImportarActividades/Preventivo/ModalMantPreventivo";
import { ModalMantCorrectivo } from "../../../ImportarActividades/Correctivo/ModalMantCorrectivo";
import { ModalMejora } from "../../../ImportarActividades/Mejoras/ModalMejora";
import { MaestrosServices } from "../../../../../../../../controllers/maestros/maestros.services";

export const DatosGenerales = ({ navLinksFases, idCurrentFase, idActividad, idedificio, readOnly, fetchActividadGET,
    fetchActividadGETData = {} }: any) => {


    const [fetchActividadPATCH, fetchActividadPATCHData] = ActividadServices.PATCH()

    const [fetchActividadEstadoPATCH, fetchActividadEstadoPATCHData] = ActividadServices.PATCH_ESTADO()

    const [fetchActividadDELETE, fetchActividadDELETEData] = ActividadServices.DELETE()

    const [fetchParamsMisSistemasGET, fetchParamsMisSistemasGETData] = ElementosConstructivosParamServices.GET(idedificio)

    const tiposIntervencionStaticServices = TiposIntervencionStaticServices.GET()

    const Lang = useLang()

    const mesesStaticServices = MesesStaticServices.GET()

    const [formActividad, handleInputActividad, handleFormActividad, resetFormActividad] = useForm({
        codi_postal: '',
        numero: '',
        adreca: '',
        provincia: '',
        poblacio: '',
        tipo: '',
        data_inici_mes: '',
        projecte_fase_id: '',
        sistema_id: '',
        tipus_intervencio: '',
        estado_actividad: '',
    })

    const [validActividad, handleValidActividad, resetvalidActividad] = useValidator({
        sistema_id: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        titol: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        descripcio: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        projecte_fase_id: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        import_obres: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        import_honoraris: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        import_taxes: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data_inici_any: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data_inici_mes: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        durada_mesos: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const [validPeriodicidad, handleValidPeriodicidad, resetvalidPeriodicidad] = useValidator({

        periodicitat: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        periodicitat_unidad: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const fetchSuccess = () => {

        fetchActividadGET({
            id: idActividad + '/mostrar'
        })
    }

    // ------------ GET Agente

    useEffect(() => {

        if (isStatusSuccess(fetchActividadGETData.status)) {

            handleFormActividad({
                ...fetchActividadGETData.data,
            })
        }

    }, [fetchActividadGETData]);


    // ------------ PATCH 


    const handleActividadPATCH = () => {

        if (handleValidActividad(formActividad) &&
            ((formActividad.tipus_intervencio !== 'manteniment') || (formActividad.tipus_intervencio === 'manteniment' && handleValidPeriodicidad(formActividad)))) {

            fetchActividadPATCH({
                id: idActividad + '/actualizar',
                body: {
                    ...formActividad,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchActividadPATCHData.status)) {

            fetchSuccess()
        }

    }, [fetchActividadPATCHData]);


    // ------------ DELETE
    const [dataModal_ConfirmDelete, handleShowModal_ConfirmDelete, handleCloseModal_ConfirmDelete] = useModalData()

    const handleSubmitEliminarAgente = () => {

        fetchActividadDELETE({
            id: idActividad + '/eliminar',
        })
    }

    const navigate = useNavigate()

    useEffect(() => {

        if (isStatusSuccess(fetchActividadDELETEData.status)) {

            // fetchSuccess()
            navigate(`../../../actividades/${idCurrentFase}`)
        }

    }, [fetchActividadDELETEData]);



    useEffect(() => {

        // fetchSuccess()

        fetchParamsMisSistemasGET()

    }, []);


    // ------------- Modales externas

    const [dataModal_Preventivo, handleShowModal_Preventivo, handleCloseModal_Preventivo] = useModalData()

    const [dataModal_Correctivo, handleShowModal_Correctivo, handleCloseModal_Correctivo] = useModalData()

    const [dataModal_Mejora, handleShowModal_Mejora, handleCloseModal_Mejora] = useModalData()

    const [fetchParamsPublicGET, fetchParamsPublicGETData] = MaestrosServices.GET_PARAMETROS_PUBLICOS()

    useEffect(() => {

        fetchParamsPublicGET({
            body: [
                {
                    parametroPadre: "estado_actividad",
                },
                {
                    parametroPadre: 'periodicitat_unidad',
                }
            ]
        })

    }, []);

    const [dataModal_ConfirmFinalizarPATCH, handleShowModal_ConfirmFinalizarPATCH, handleCloseModal_ConfirmFinalizarPATCH] = useModalData()

    const [formFechaFin, handleInputFechaFin, handleFormFechaFin, resetFormFechaFin] = useForm({
        data_fi_activitat: '',
    })

    // ---------- PATCH estado actividad

    const cambiarEstadoActividad = () => {

        const nuevo_estado_id = fetchParamsPublicGETData?.data?.['estado_actividad']?.filter((item: any) => item.id !== formActividad.estado_actividad)?.[0]?.id

        if (nuevo_estado_id === 'finalizada') {

            handleShowModal_ConfirmFinalizarPATCH({
                nuevo_estado_id
            })

        } else {

            handleSubmitCambiarEstadoActividad(nuevo_estado_id, '')
        }
    }

    const handleSubmitCambiarEstadoActividad = (nuevo_estado_id: string, data_fi_activitat: any) => {

        fetchActividadEstadoPATCH({
            id: idActividad + '/actualizar',
            body: {
                estado_actividad: nuevo_estado_id,
                data_fi_activitat
            }
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchActividadEstadoPATCHData.status)) {

            fetchSuccess()
        }

    }, [fetchActividadEstadoPATCHData]);


    return (
        <>

            <Card className="mb-5">
                <CardContent className="p-3 p-lg-3">

                    {
                        fetchActividadGETData?.loading || fetchParamsMisSistemasGETData.loading || fetchParamsPublicGETData.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12} md={6} lg={12}>
                                        <InputTextarea
                                            label={Lang('TITULO') + ': *'}
                                            name='titol'
                                            value={formActividad.titol}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.titol}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ': *'}
                                            name='descripcio'
                                            value={formActividad.descripcio}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.descripcio}
                                            readOnly={readOnly}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputSelect
                                            label={Lang('ESTADO') + ':'}
                                            name='estado_actividad'
                                            value={formActividad.estado_actividad}
                                            values={fetchParamsPublicGETData?.data?.['estado_actividad']}
                                            onChange={handleInputActividad}
                                            readOnly={true}
                                            className='text-capitalize'
                                        />
                                    </Grid>

                                    {
                                        fetchActividadGETData?.data?.data_fi_activitat &&
                                        <Grid item xs={12} md={6} lg={4}>
                                            <InputText
                                                label={Lang('FECHA_FINALIZACION') + ':'}
                                                name='data_fi_activitat'
                                                type='date'
                                                value={formActividad.data_fi_activitat}
                                                readOnly={true}
                                            />
                                        </Grid>
                                    }

                                    <Grid item className="w-100 pb-0 pt-3">
                                        <div className="border-top"></div>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputSelect
                                            label={Lang('FASE') + ': *'}
                                            name='projecte_fase_id'
                                            value={formActividad.projecte_fase_id}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.projecte_fase_id}
                                            readOnly={readOnly}
                                            values={navLinksFases?.map((item: any) => {
                                                return {
                                                    id: item.link,
                                                    name: item.title
                                                }
                                            })}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <InputSelect
                                            label={Lang('SISTEMA') + ': *'}
                                            name='sistema_id'
                                            value={formActividad.sistema_id}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.sistema_id}
                                            values={fetchParamsMisSistemasGETData?.data || []}
                                            readOnly={(formActividad.origen_id || readOnly) ? true : false}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={4} lg={4}>
                                        <InputSelect
                                            label={Lang('TIPO_ACTIVIDAD') + ': *'}
                                            name='tipus_intervencio'
                                            value={formActividad.tipus_intervencio}
                                            values={convertStaticServiceToArray(tiposIntervencionStaticServices)}
                                            onChange={handleInputActividad}
                                            readOnly={true}
                                            fetchData={fetchActividadPATCHData}
                                        />
                                    </Grid> */}

                                    <Grid item xs={12} className='p-0 m-0'></Grid>


                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_DE_OBRAS') + ' (€): *'}
                                            name='import_obres'
                                            type='number'
                                            value={formActividad.import_obres}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.import_obres}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_DE_HONORARIOS') + ' (€): *'}
                                            name='import_honoraris'
                                            type='number'
                                            value={formActividad.import_honoraris}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.import_honoraris}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_DE_TASAS') + ' (€): *'}
                                            name='import_taxes'
                                            type='number'
                                            value={formActividad.import_taxes}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.import_taxes}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('OTROS') + ' (€):'}
                                            name='import_altres'
                                            type='number'
                                            value={formActividad.import_altres}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.import_altres}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className='p-0 m-0'></Grid>


                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('ANIO_INICIO') + ': *'}
                                            name='data_inici_any'
                                            type='number'
                                            value={formActividad.data_inici_any}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.data_inici_any}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputSelect
                                            label={Lang('MES_INICIO') + ': *'}
                                            name='data_inici_mes'
                                            type='number'
                                            value={formActividad.data_inici_mes}
                                            values={convertStaticServiceToArray(mesesStaticServices)}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.data_inici_mes}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('DURACION_MESES') + ': *'}
                                            name='durada_mesos'
                                            type='number'
                                            value={formActividad.durada_mesos}
                                            onChange={handleInputActividad}
                                            fetchData={fetchActividadPATCHData}
                                            localValidation={validActividad.durada_mesos}
                                            readOnly={readOnly}
                                        />
                                    </Grid>

                                    {
                                        formActividad.tipus_intervencio === 'manteniment' &&
                                        <>
                                            <Grid item style={{ 'width': '100%' }} className='p-0'></Grid>

                                            <Grid item xs={12} md={6} lg={3}>
                                                <InputText
                                                    label={Lang('PERIODICIDAD') + ': *'}
                                                    name='periodicitat'
                                                    type='number'
                                                    value={formActividad.periodicitat}
                                                    onChange={handleInputActividad}
                                                    fetchData={fetchActividadPATCHData}
                                                    localValidation={validPeriodicidad.periodicitat}
                                                    readOnly={readOnly}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={3}>
                                                <InputSelect
                                                    label={Lang('UNIDAD') + ': *'}
                                                    name='periodicitat_unidad'
                                                    value={formActividad.periodicitat_unidad}
                                                    values={fetchParamsPublicGETData?.data?.['periodicitat_unidad'] || []}
                                                    fetchData={fetchActividadPATCHData}
                                                    onChange={handleInputActividad}
                                                    localValidation={validPeriodicidad.periodicitat_unidad}
                                                    readOnly={readOnly}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>

                                                <Typography variant='body1' className='mb-1' component="h5">
                                                    {Lang('P_IMPORTAR_ACTIVIDAD')}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }

                                    {
                                        !readOnly &&
                                        <Grid item xs={12}>
                                            <div className='d-flex justify-content-between'>
                                                <ButtonAdvanced
                                                    variant="outlined" type="button" className="mt-3"
                                                    handleSubmit={handleShowModal_ConfirmDelete}
                                                    // loadings={[fetchActividadDELETEData.loading]}
                                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                                />
                                                <div className="mt-3 ">
                                                    <ButtonAdvanced
                                                        variant="outlined" type="button" className="me-3" color="secondary"
                                                        handleSubmit={cambiarEstadoActividad} loadings={[fetchActividadEstadoPATCHData.loading]}
                                                        value={Lang('CAMBIAR_A') + ' ' + fetchParamsPublicGETData?.data?.['estado_actividad']?.filter((item: any) => item.id !== formActividad.estado_actividad)?.[0]?.name}
                                                        icon={<FontAwesomeIcon icon={faSync} className='me-1' />}
                                                    />
                                                    {
                                                        formActividad.origen_id &&
                                                        <>
                                                            {
                                                                formActividad.tipus_intervencio === 'manteniment' &&
                                                                <Button className="me-3" variant="outlined" color="secondary" onClick={() => {
                                                                    handleShowModal_Preventivo({
                                                                        id: Number(formActividad.origen_id)
                                                                    })
                                                                }}>
                                                                    <FontAwesomeIcon icon={faEye} className='me-2' /> {Lang('VER_MANT_PREVENTIVO')}
                                                                </Button>
                                                            }

                                                            {
                                                                formActividad.tipus_intervencio === 'rehabilitacio' &&
                                                                <Button className="me-3" variant="outlined" color="secondary" onClick={() => {
                                                                    handleShowModal_Correctivo({
                                                                        id: Number(formActividad.origen_id)
                                                                    })
                                                                }}>
                                                                    <FontAwesomeIcon icon={faEye} className='me-2' /> {Lang('VER_MANT_CORRECTIVO')}
                                                                </Button>
                                                            }

                                                            {
                                                                formActividad.tipus_intervencio === 'millora' &&
                                                                <Button className="me-3" variant="outlined" color="secondary" onClick={() => {
                                                                    handleShowModal_Mejora({
                                                                        id: Number(formActividad.origen_id)
                                                                    })
                                                                }}>
                                                                    <FontAwesomeIcon icon={faEye} className='me-2' /> {Lang('VER_MEJORA')}
                                                                </Button>
                                                            }
                                                        </>
                                                    }

                                                    <ButtonAdvanced
                                                        variant="contained" type="button" className="ms-auto"
                                                        handleSubmit={handleActividadPATCH}
                                                        loadings={[fetchActividadPATCHData.loading]}
                                                        value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    }



                                </Grid>
                            </Box>
                    }

                </CardContent>
            </Card>

            <ModalConfirm
                data={dataModal_ConfirmDelete}
                handleClose={handleCloseModal_ConfirmDelete}
                message={<p>{Lang('PARRAFO_DESEA_ELIMINAR_ACTIVIDAD')}</p>}
                textOnClickOk={Lang('SI_ELIMINAR')}
                variantClickOk='contained'
                onClickOK={handleSubmitEliminarAgente}
                fetchData={fetchActividadDELETEData}
            />

            <ModalConfirm
                data={dataModal_ConfirmFinalizarPATCH}
                handleClose={handleCloseModal_ConfirmFinalizarPATCH}
                message={<>
                    <h4 className="mb-4">{Lang('FINALIZAR_ACTIVIDAD')}</h4>
                    <Grid container spacing={3} rowSpacing={3} className="text-start justify-content-center mb-3">

                        <Grid item xs={12} md={8} lg={6}>
                            <InputText
                                label={Lang('FECHA_FINALIZACION') + ':'}
                                name='data_fi_activitat'
                                onChange={handleInputFechaFin}
                                type='date'
                                value={formFechaFin.data_fi_activitat}
                                className='labelFocusAlways'
                            />
                        </Grid>
                    </Grid>
                </>}
                textOnClickOk={Lang('FINALIZAR')}
                variantClickOk='contained'
                fetchData={fetchActividadEstadoPATCHData}
                onClickOK={() => handleSubmitCambiarEstadoActividad(dataModal_ConfirmFinalizarPATCH.nuevo_estado_id, formFechaFin.data_fi_activitat)}
            />

            <ModalStatusServer fetchData={fetchActividadPATCHData} />

            <ModalMantPreventivo
                data={dataModal_Preventivo}
                handleClose={handleCloseModal_Preventivo}
            />

            <ModalMantCorrectivo
                data={dataModal_Correctivo}
                handleClose={handleCloseModal_Correctivo}
            />

            <ModalMejora
                data={dataModal_Mejora}
                handleClose={handleCloseModal_Mejora}
            />

            <ModalStatusServer fetchData={fetchActividadEstadoPATCHData} />

        </>
    )
};
