import { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { useLang } from '../../language'

import './Input.css'

export const InputFile = ({ label, className = '', name, type = 'text', value, onChange, multiple = false,
    readOnly, min, referencia, path, accept, remove }: any) => {

    const Lang = useLang()

    const handleOnchange = (e: any) => {

        onChange({
            target: {
                name,
                value: multiple ? e.target.files : e.target.files?.[0]
            }
        })
    }

    const handleOnchangeDelete = () => {

        onChange({
            target: {
                name,
                value: ''
            }
        })
    }

    const [fileLoad, setfileLoad] = useState('');

    useEffect(() => {
        if (path) {
            setfileLoad(path)
        }
    }, [path]);

    useEffect(() => {
        if (remove) {
            handleOnchangeDelete()
            setfileLoad('')
        }
    }, [remove]);

    return (
        <>
            <Typography variant='body2' className='mb-1' component="span">
                {label}
            </Typography>
            <div className='d-flex align-items-center flex-wrap'>
                {
                    fileLoad &&
                    <>
                        <span className='InputFile__ContainerName flex-grow-1 me-2 py-2'>{fileLoad?.split('/')?.pop()}</span>
                        {
                            !readOnly &&
                            <Button
                                id='eliminar-file'
                                variant="outlined"
                                component="label"
                                size='small'
                                onClick={(e: any) => {
                                    handleOnchangeDelete()
                                    setfileLoad('')
                                }}
                            >
                                {Lang('ELIMINAR_ARCHIVO')}
                            </Button>
                        }
                    </>
                }

                {
                    !fileLoad &&
                    <>
                        <span className='InputFile__ContainerName flex-grow-1 me-2 py-2'>{Lang('SIN_ARCHIVOS_SELECCIONADOS')}</span>
                        <Button
                            variant="outlined"
                            component="label"
                            size='small'
                        >
                            {Lang('SELECCIONAR_ARCHIVO')}
                            <input
                                type="file"
                                hidden
                                ref={referencia}
                                accept={accept}
                                onChange={(e: any) => {
                                    handleOnchange(e)
                                    setfileLoad(e.target.files?.[0]?.name)
                                }}
                            />
                        </Button>
                    </>
                }
            </div>
        </>
    )
}
