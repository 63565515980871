import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faFileImport, faFileZipper, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useModalData } from "../../../../../../../hooks/useModalData";
import TableAdvanced from "../../../../../../../components/Tables/TableAdvanced/TableAdvanced";
import { useLang } from "../../../../../../../language";
import { ModalCargarITE } from "./ModalCargarITE/ModalCargarITE";
import { ModalConfirm } from '../../../../../../../components/Modals/ModalConfirm';
import { isStatusSuccess } from '../../../../../../../helpers/helpers';
import { FicheroITEServices } from './controllers/ficheroite.services';
import { useNavigate } from 'react-router';
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer';
import { Alert, AlertTitle, Button } from '@mui/material';
import FilterAdvanced from '../../../../../../../components/FilterAdvanced/FilterAdvanced';
import { FormSimple } from './Filters/FormSimple';
import { ModalImportarDeficiencia } from './ModalImportarDeficiencia/ModalImportarDeficiencia';

export const TableMantCorrectivo = ({ data, loading, total, perPage, handleFormChange, formValuesSearch,
    handleInputChangeSearch, idedificio, fetchSuccess, fetchParamsAgentesGETData, setactiveImportITE,
    fetchParamsDocumentosGETData, id, fetchSuccessDeficiencias, valuesSearch = {}, handleSubmitSearch,
    fetchData, resetformValuesSearch }: any) => {

    const Lang = useLang()

    const [dataModal_ITE, handleShowModal_ITE, handleCloseModal_ITE] = useModalData()

    const [dataModal_ConfirmImportar, handleShowModal_ConfirmImportar, handleCloseModal_ConfirmImportar] = useModalData()

    const [dataModal_ImportarDef, handleShowModal_ImportarDef, handleCloseModal_ImportarDef] = useModalData()

    // --------------------- Modal Nuevo usuario ---------------------

    const allActions = [
        // {
        //     disabled: dataTableChecks.incluidos?.length === 0,
        //     label: <span><FontAwesomeIcon icon={faFileZipper} className='me-2' />{Lang('AGREGAR_A_DEFICIENCIAS')}</span>,
        //     variant: 'outlined',
        //     color: 'primary',
        //     action: () => handleShowModal_ConfirmImportar({
        //         action: 'CREATE',
        //     }),
        // },
        {
            label: <span><FontAwesomeIcon icon={faFileZipper} className='me-2' />{Lang('REEMPLAZAR_ITE')}</span>,
            variant: 'outlined',
            color: 'success',
            action: () => handleShowModal_ITE({
                action: 'CREATE',
                avoidRedirect: true,
            }),
        }
    ]

    const allActionsPerLine: any = [
        {
            variant: 'contained',
            label: <FontAwesomeIcon icon={faFileImport} className='' />,
            action: (item: any) => {
                handleShowModal_ImportarDef({
                    ...item,
                    action: 'EDIT',
                })
            },
            tooltip: {
                text: Lang('IMPORTAR')
            }
        }
    ]

    const columnsTable: any = {
        descripcio: {
            title: Lang('DESCRIPCION'),
        },
        gravetat_des: {
            title: Lang('CALIFICACION'),
        },
    }

    // Acciones

    const [fetchImportarITEPOST, fetchImportarITEPOSTData] = FicheroITEServices.POST_AGREGAR_A_DEFICIENCIAS()

    // const handleSubmitImportarITE = () => {

    //     fetchImportarITEPOST({
    //         // id: 'rechazar/' + formRegistro.id,
    //         body: {
    //             edifici_id: idedificio,
    //             elemento_contructivo_id: id,
    //             deficiencias: dataTableChecks.incluidos,
    //         }
    //     })
    // }


    const navigate = useNavigate()

    useEffect(() => {

        if (isStatusSuccess(fetchImportarITEPOSTData.status)) {

            handleCloseModal_ConfirmImportar()
            // setactiveImportITE(false)
            fetchSuccessDeficiencias()
            navigate('../')
        }

    }, [fetchImportarITEPOSTData]);


    const handleImportarADeficiencias = () => {

        // setactiveImportITE(false)
        navigate('../')

    }

    const [showAlert, setshowAlert] = useState(false);


    return (
        <>
            <Alert severity="info" className="mb-3 d-flex align-items-center"
                action={
                    <div className="me-1">
                        <Button
                            variant='outlined' color='secondary' size="small" className='me-3'
                            onClick={handleImportarADeficiencias}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> {Lang('REGRESAR')}
                        </Button>
                    </div>
                }>
                <AlertTitle>{Lang('AGREGAR_A_DEFICIENCIAS')}:</AlertTitle>
                <span>
                    {Lang('SELECCIONE_DEFICIENCIA_ITE_ACTUACION')}
                </span>
            </Alert>

            {
                <div className="my-3 animate__animated animate__fadeIn animate__faster">

                    <FilterAdvanced
                        fetchData={fetchData}
                        handleSubmitSearch={handleSubmitSearch}
                        formValuesSearch={formValuesSearch}
                        handleFormChange={handleFormChange}
                        handleInputChangeSearch={handleInputChangeSearch}
                        resetformValuesSearch={resetformValuesSearch}
                        valuesDefault={valuesSearch.default}
                        valuesSimple={valuesSearch.onlySimple}
                        valuesExpanded={valuesSearch.onlyExpanded}
                    >

                        <FilterAdvanced.Simple label={Lang('BUSCADOR')}>

                            <FormSimple
                                fetchParamsAgentesGETData={fetchParamsAgentesGETData}
                            />

                        </FilterAdvanced.Simple>

                        <FilterAdvanced.Expanded></FilterAdvanced.Expanded>

                    </FilterAdvanced>
                </div>
            }

            <TableAdvanced
                classNameTableAdvanced='minByDropdown'
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChange={handleInputChangeSearch}
            >
                <TableAdvanced.Head
                    title={Lang('DEFICIENCIAS_FICHERO_ITE')}
                    allActions={allActions}
                />

                <TableAdvanced.Body
                    data={data}
                    isLoading={loading}
                    columnsTable={columnsTable}
                    allActionsPerLine={allActionsPerLine}
                />

                {/* <TableAdvanced.Footer
                    total={total}
                    perPage={perPage}
                /> */}
            </TableAdvanced>

            <ModalCargarITE
                data={dataModal_ITE}
                handleClose={handleCloseModal_ITE}
                idedificio={idedificio}
                fetchSuccess={fetchSuccess}
            />

            <ModalImportarDeficiencia
                data={dataModal_ImportarDef}
                handleClose={handleCloseModal_ImportarDef}
                idedificio={idedificio}
                fetchSuccess={fetchSuccess}
                elemento_contructivo_id={id}
            />

            {/* <ModalConfirm
                data={dataModal_ConfirmImportar}
                handleClose={handleCloseModal_ConfirmImportar}
                message={<p>{Lang('PARRAFO_DESEA_AGREGAR_ELEMENTOS_SELECCIONADOS_A_DEFICIENCIAS')}</p>}
                textOnClickOk={Lang('SI_AGREGAR')}
                variantClickOk='contained'
                onClickOK={handleSubmitImportarITE}
                fetchData={fetchImportarITEPOSTData}
            /> */}

            <ModalStatusServer fetchData={fetchImportarITEPOSTData} />

        </>
    )
}
