import React from "react";
import { Alert, Typography, AlertTitle } from "@mui/material";
import { useLang } from "../../../../../../../../language";
import { FotosDeficiencia } from "./FotosDeficiencia";

export const DeficienciaFotos = ({ iddeficiencia }: any) => {

    const Lang = useLang()

    return (
        <>
            <Typography variant='body1' className='mb-0 pb-2 mt-0 fw-normal'>
                {Lang('IMAGENES')}:
            </Typography>

            {
                iddeficiencia ?
                    <FotosDeficiencia
                        iddeficiencia={iddeficiencia}
                    />
                    :
                    <Alert severity="info" className="mb-3 d-flex align-items-center">
                        <span>
                            {Lang('PARRAFO_ADJUNTAR_IMAGENES_CREAR_DEFICIENCIA')}
                        </span>
                    </Alert>
            }
        </>
    )
};
