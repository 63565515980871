import { Grid, Typography } from "@mui/material";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { useModalData } from "../../../hooks/useModalData";
import ReactPlayer from "react-player";

export const SeccionQueEs = ({ lang }: any) => {

    const [dataModal_video, handleShowModal_video, handleCloseModal_video] = useModalData()

    return (
        <>
            {
                lang === 'ES' && <SeccionQueEsES handleShowModal_video={handleShowModal_video} />
            }
            {
                lang === 'CA' && <SeccionQueEsCA handleShowModal_video={handleShowModal_video} />
            }

            <ModalAdvanced data={dataModal_video} handleClose={handleCloseModal_video} size='xl'>

                <ModalAdvanced.Head>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-0'>

                    <div className="" >
                        <ReactPlayer
                            className="react-player"
                            width="100%"
                            height="60vh"
                            url={lang === 'ES' ? 'https://vimeo.com/946883658' : 'https://vimeo.com/950732290' }
                            controls={true}
                        />
                    </div>

                </ModalAdvanced.Body>


            </ModalAdvanced>
        </>
    )
};

const SeccionQueEsES = ({ handleShowModal_video }: any) => {

    return (
        <Grid container>

            <Grid item xs={12} md={5} className=''>
                <img src={`${process.env.PUBLIC_URL}/images/libro.png`} alt="" className='mx-auto mb-4' />
            </Grid>

            <Grid item xs={12} md={7} className='PageIndex__Beneficios--text animate__animated animate__fadeInLeftSmall'>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    ¿Qué es el Libro del Edificio Digital?
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    La gestión del Libro del Edificio Digital representa una revolución en la convergencia de la tecnología y la edificación contemporánea. Una oportunidad sin precedentes para que el técnico gestor de mantenimiento asuma un papel proactivo en la revolución digital de la edificación. <span className="text-uppercase cursor-pointer color-primary fw-500 text-decoration-underline" onClick={handleShowModal_video}>
                        Ver vídeo de presentación
                    </span>
                </Typography>


            </Grid>

            <Grid item xs={12} className=''>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    El Libro del Edificio Digital LED constituye una herramienta de redacción y de gestión para documentar la vida del edificio en sus aspectos técnicos y administrativos. Sirve también para informar sobre las pautas necesarias para hacer un correcto uso de cada edificio y facilitar la planificación de las actividades de mantenimiento para alargar su vida útil. También para preservar sus características iniciales durante el mayor tiempo posible o planificar su renovación.
                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Permite:
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>

                    <ul>
                        <li>Digitalizar la información del edificio y convertir la información en datos.</li>
                        <li>Tener la información en un entorno accesible y promover la circularidad y la sostenibilidad.</li>
                        <li>Facilitar un mecanismo de mantenimiento dinámico de gestión integral.</li>
                    </ul>

                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Ventajas de usar LED:
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    LED como herramienta que permite gestionar los edificios de forma digital, integral y en tiempo real, ayuda a promover la cultura del mantenimiento preventivo, con lo que se alarga el ciclo de vida de los edificios. En consecuencias reduce su impacto ambiental y los costes de reparación.
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    Además, LED permite disponer de la información actualizada y accesible para todos los usuarios para facilitar la interoperatividad en el sistema de gestión, al tener la información guardada en una nube siempre está disponible y protegida; y permite acceder a los contenidos desde cualquier dispositivo y navegador.
                </Typography>

                <div id="before-productos"></div>

            </Grid>

        </Grid>
    )
}


const SeccionQueEsCA = ({ handleShowModal_video }: any) => {

    return (
        <Grid container>

            <Grid item xs={12} md={5} className=''>
                <img src={`${process.env.PUBLIC_URL}/images/libro.png`} alt="" className='mx-auto mb-4' />
            </Grid>

            <Grid item xs={12} md={7} className='PageIndex__Beneficios--text animate__animated animate__fadeInLeftSmall'>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Què és el Llibre de l'Edifici Digital?
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    La gestió del Llibre de l'Edifici Digital representa una revolució en la convergència de la tecnologia i l'edificació contemporània. Una oportunitat sense precedents perquè el tècnic gestor de manteniment assumeixi un paper proactiu a la revolució digital de l'edificació. <span className="text-uppercase cursor-pointer color-primary fw-500 text-decoration-underline" onClick={handleShowModal_video}>
                        Veure vídeo de presentació
                    </span>
                </Typography>

            </Grid>

            <Grid item xs={12} className=''>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    El Llibre de l'Edifici Digital LED constitueix una eina de redacció i de gestió per documentar la vida de l'edifici en els aspectes tècnics i administratius. Serveix també per informar sobre les pautes necessàries per fer un ús correcte de cada edifici i facilitar la planificació de les activitats de manteniment per allargar-ne la vida útil. També per preservar-ne les característiques inicials durant el major temps possible o planificar-ne la renovació.
                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Permet:
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>

                    <ul>
                        <li>Digitalitzar la informació de l'edifici i convertir la informació en dades.</li>
                        <li>Tenir la informació en un entorn accessible i promoure la circularitat i la sostenibilitat.</li>
                        <li>Facilitar un mecanisme de manteniment dinàmic de gestió integral.</li>
                    </ul>

                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Avantatges d'usar LED:
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    LED com a eina que permet gestionar els edificis de forma digital, integral i en temps real, ajuda a promoure la cultura del manteniment preventiu, amb la qual cosa s'allarga el cicle de vida dels edificis. En conseqüències en redueix l'impacte ambiental i els costos de reparació.
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    A més, LED permet disposar de la informació actualitzada i accessible per a tots els usuaris per facilitar la interoperativitat al sistema de gestió, com ara la informació guardada en un núvol sempre estigui disponible i protegida; i permeti accedir als continguts des de qualsevol dispositiu i navegador.
                </Typography>

                <div id="before-productos"></div>

            </Grid>

        </Grid>
    )
}
