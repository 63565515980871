import { Grid } from "@mui/material";
import { useEffect } from 'react'
import { TableMantCorrectivo } from "./TableMantCorrectivo";
import { useLang } from "../../../../../../../language";
import { clearEmptyKeys, getValuesParams, isChecked } from "../../../../../../../helpers/helpers";
import { useForm } from "../../../../../../../hooks/useForm";
import { FicheroITEServices } from "./controllers/ficheroite.services";

export const MantenimientoCorrectivoITE = ({ idedificio, id, fetchSuccessDeficiencias }: any) => {

    const Lang = useLang()


    const [fetchDeficienciasITEGET, fetchDeficienciasITEGETData] = FicheroITEServices.GET_DEFICIENCIAS()

    // Buscador de agentes

    // const location = useLocation()

    // const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '200',
            order: '',
            edifici_id: idedificio,
            elemento_constructivo_id: id,
            excluir_incluido: ['excluir_incluido'],
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: allValuesSearch.page,
        limit: allValuesSearch.limit,
        magic: allValuesSearch.magic,
        order: allValuesSearch.order,
        edifici_id: allValuesSearch.edifici_id,
        elemento_constructivo_id: allValuesSearch.elemento_constructivo_id,
        excluir_incluido: allValuesSearch.excluir_incluido,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)

        urlParams.excluir_incluido = isChecked(urlParams.excluir_incluido)

        // setUrlByParams(urlParams, 'limit', 'edifici_id', 'elemento_constructivo_id', 'excluir_incluido')

        if (urlParams.page !== 1) {

            handleFormChange({
                ...urlParams,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(urlParams)

            fetchDeficienciasITEGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams: any = getValuesParams(formValuesSearch)

        urlParams.excluir_incluido = isChecked(urlParams.excluir_incluido)

        // setUrlByParams(urlParams, 'limit', 'edifici_id', 'elemento_constructivo_id', 'excluir_incluido')

        fetchDeficienciasITEGET({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])


    return (
        <Grid container spacing={2} columnSpacing={3}>

            <Grid item xs={12} >
                <TableMantCorrectivo
                    data={fetchDeficienciasITEGETData.data?.items}
                    loading={fetchDeficienciasITEGETData.loading}
                    total={fetchDeficienciasITEGETData.data?.total}
                    perPage={fetchDeficienciasITEGETData.data?.per_page}

                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    handleInputChangeSearch={handleInputChangeSearch}

                    idedificio={idedificio}
                    fetchSuccess={() => {
                        fetchDeficienciasITEGET({
                            params: {
                                ...getValuesParams(formValuesSearch),
                                excluir_incluido: isChecked(formValuesSearch.excluir_incluido)
                            }
                        })
                    }}
                    id={id}
                    fetchSuccessDeficiencias={fetchSuccessDeficiencias}

                    valuesSearch={valuesSearch}
                    fetchData={fetchDeficienciasITEGETData}
                    handleSubmitSearch={handleSubmitSearch}
                    resetformValuesSearch={resetformValuesSearch}
                />
            </Grid>

        </Grid>
    );
};
