import React from "react";
import { Backdrop, Fade, Grid, Modal } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './ModalAdvanced.scss'

const ModalAdvanced = ({ data, handleClose, size = 'md', children, className = '' }: any) => {

    const sizeModal: any = {
        sm: 300,
        md: 500,
        lg: 850,
        xl: 1000,
        675: 675,
        w90: '90%'
    }

    const style = {
        position: 'absolute' as 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        top: '10%',
        left: '10%',
        right: '10%',
        maxWidth: sizeModal[size],
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 3,
        // marginLeft: 3,
        // marginRight: 3,
        m: 0,
        // marginBottom: '14px',
        width: 'auto',
        // maxHeight: '80vh',
        // overflowY: 'auto',
        outline: 0,
    };

    function addPropsToReactElement(element: any, props: any) {
        if (React.isValidElement(element)) {
            return React.cloneElement(element, props)
        }
        return element
    }

    return (
        <>
            <Modal
                open={data.value}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={`ModalAdvanced`}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={data.value}>
                    <Grid container spacing={1} sx={style} className={`z-depth-4 d-block mx-auto p-3 p-lg-4 ${className}`}>
                        <>
                            {
                                children?.map((childElement: any, indexchildElement: any) =>
                                    addPropsToReactElement(childElement, { 'key': indexchildElement, 'handleClose': handleClose })
                                )
                            }
                        </>

                    </Grid>
                </Fade>

            </Modal>
        </>
    );
};

const Head = ({ children, handleClose }: any) => {
    return (
        <>
            <Grid item xs={12} className='mb-3 p-0'>
                <section className='ModalHeader border-0 text-center'>
                    {children}
                    <span className='ModalHeader__containericonclose' onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} className='cursor-pointer' />
                    </span>
                </section>
            </Grid>
        </>
    )
}

const Body = ({ children, className = '' }: any) => {

    return (
        <>
            <Grid item xs={12} className={className ? className : 'mb-4 p-0'}>
                <section className='ModalBody d-flex flex-column'>
                    {children}
                </section>
            </Grid>
        </>
    )

}

const Footer = ({ children, className = ''}: any) => {

    return (
        <>
            <Grid item xs={12} className={`p-0 ${className}`}>
                <section className={`ModalBody text-center d-flex flex-column `}>
                    {children}
                </section>
            </Grid>
        </>
    )

}

ModalAdvanced.Head = Head
ModalAdvanced.Body = Body
ModalAdvanced.Footer = Footer

export default ModalAdvanced