import { configureStore } from '@reduxjs/toolkit'

import { authSlice } from './slices/auth'
import { languageSlice } from './slices/language'
import { idCurrentFaseProgGesSlice } from './slices/idCurrentFaseProgGes'

export const store = configureStore({
  reducer: {
    auth : authSlice.reducer,
    language : languageSlice.reducer,
    idCurrentFaseProgGes: idCurrentFaseProgGesSlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

