import './ListAutocomplete.scss'

export const ListAutocomplete = ({ list, handleClick, nameTitle, onMouseOver, onMouseOut }: any) => {
    return (
        <>
            {
                list.length > 0 && <>

                    <ul className='ListAutocomplete list-unstyled shadow-sm'
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}>
                        {
                            list?.map((item: any, index: number) => {
                                return (
                                    <li key={index} onClick={() => handleClick(item)}>{item[nameTitle]}</li>
                                )
                            })
                        }
                        <div className="d-flex justify-content-end py-1 px-2">
                            <img src={`${process.env.PUBLIC_URL}/powered-by-google.png`} alt="" width={70} />
                        </div>
                    </ul>
                </>
            }
        </>

    );
};
