import './Footer.scss'
import { useLang } from '../../../language';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material'
import { DropdownLanguage } from "../DropdownLanguage";
import { useModalData } from '../../../hooks/useModalData';
import { Faqs } from '../../../pages/Faqs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const Footer = ({ showLoginAdmin }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [dataModal_Faqs, handleShowModal_Faqs, handleCloseModal_Faqs] = useModalData()


    return (
        <>
            <footer className="Footer mt-auto">
                <div className='d-flex justify-content-between align-items-center flex-column flex-md-row px-3'>
                    <small className='my-2 my-md-0'>
                        © {(new Date()).getFullYear()} Cateb
                    </small>
                    <small>
                        <div className='d-flex align-items-center flex-column flex-md-row'>
                            {
                                showLoginAdmin &&
                                <a href={process.env.REACT_APP_URL_APP_ADMINISTRACION} className='cursor-pointer me-3'>{Lang('ACCESO_ADMINISTRACION')}</a>
                            }

                            <span className='cursor-pointer ms-md-3 my-2 my-md-0 text-decoration-underline-hover' onClick={handleShowModal_Faqs}>
                                <FontAwesomeIcon icon={faQuestionCircle} className='me-1' />
                                <span className='text-capitalize'>FAQs</span>
                            </span>

                            <Link underline="hover"
                                color="inherit"
                                className='cursor-pointer ms-md-3 my-2 my-md-0'
                                onClick={() => navigate('/aviso-legal')}
                            >
                                {Lang('AVISO_LEGAL_POLITICA_PRIVACIDAD')}
                            </Link>

                            <Link underline="hover"
                                color="inherit"
                                className='cursor-pointer ms-md-3 my-2 my-md-0'
                                onClick={() => navigate('/condiciones-contratacion')}
                            >
                                {Lang('CONDICIONES_CONTRATACION')}
                            </Link>

                            <DropdownLanguage />
                        </div>
                    </small>
                </div>
            </footer>
            <Faqs
                data={dataModal_Faqs}
                handleClose={handleCloseModal_Faqs}
            />
        </>
    )
};
