import { useAxios } from "../../../../../../../../../hooks/useAxios"
import { GremioActividadAdapters, GremiosActividadAdapters } from "./gremios-actividades.adapters"

const GremiosActividadServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/gremis-activitats',
            type: 'json',
            adapter: {
                method: GremiosActividadAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const GremioActividadServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/gremi-activitat`,
            type: 'json',
            adapter: {
                method: GremioActividadAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio_proyectos/gremi-activitat',
            type: 'json',
            adapter: {
                method: GremioActividadAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/gremi-activitat',
            type: 'json',
            adapter: {
                method: GremioActividadAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/gremi-activitat',
            type: 'json',
            adapter: {
                method: GremioActividadAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    GremiosActividadServices,
    GremioActividadServices
}