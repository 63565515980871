export const AvisoLegalSeccion3 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        <strong>3.1. Obligaciones del Usuario</strong>
      </p>

      <p>El usuario asume las obligaciones de:</p>

      <p>
        (1) Realizar un uso responsable y razonable de nuestro sitio web acorde con estas condiciones de uso y la legalidad vigente;
      </p>

      <p>
        (2) No realizar actividades contrarias a la legislación española, especialmente en cuanto a actuaciones constitutivas de delitos según el Código Penal. En concreto, el usuario no deberá acceder a información de terceros que no haya sido autorizada, no suplantará la identidad de terceros y será responsable de proporcionar datos exactos y veraces.
      </p>

      <p>
        <strong>3.2. Usos permitidos</strong>
      </p>
      <p>
        El Usuario podrá hacer un uso responsable de nuestro sitio web conforme a los usos y costumbres, buena fe, moral y orden público. Toda aquella conducta ajena a estos principios estará prohibida y perseguida conforme la normativa vigente.
      </p>
      <p>
        <strong>3.3. Usos prohibidos</strong>
      </p>
      <p>
        Queda prohibido el acceso o la utilización de los productos ofrecidos en nuestro sitio web con fines ilegales o no autorizados. En concreto, a modo enunciativo pero no limitativo, queda prohibido:
      </p>

      <ul>
        <li>
          Usar el servicio con fines ilícitos o realizar actividades contrarias a la buena fe y al orden público.
        </li>
        <li>
          Difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos.
        </li>
        <li>
          Usar cualquiera de los servicios del sitio web de forma que se pueda dañar, sobrecargar o perjudicar el servicio.
        </li>
        <li>
          Utilizar o introducir en el servicio malware que pueda producir daños o alteraciones en nuestros sistemas, en los contenidos o en el sistema informático de otros usuarios y/o en sus terminales móviles.
        </li>
        <li>
          El uso de recursos técnicos, lógicos o tecnológicos en virtud de los cuales alguien, usuario o no del servicio, pueda beneficiarse, directa o indirectamente, con o sin lucro, de los perfiles y de los contenidos del sitio web.
        </li>
      </ul>
      <p>
        En definitiva, quedan prohibidos los usos de la WEB que sean contrarios a la normativa de Propiedad Intelectual e Industrial, Protección de Datos, Ley de Competencia Desleal, Código Civil y Mercantil, así como cualquier otra que pudiera resultar aplicable.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        <strong>3.1. Obligacions de l'Usuari</strong>
      </p>

      <p>L' usuari assumeix les obligacions de:</p>

      <p>
        (1) Realitzar un ús responsable i raonable del nostre lloc web d'acord amb aquestes condicions d'ús i la legalitat vigent;
      </p>

      <p>
        (2) No realitzar activitats contràries a la legislació espanyola, especialment pel que fa a actuacions constitutives de delictes segons el Codi Penal. En concret, l'usuari no haurà d'accedir a informació de tercers que no hagi estat autoritzada, no suplantarà la identitat de tercers i serà responsable de proporcionar dades exactes i veraces.
      </p>

      <p>
        <strong>3.2. Usos permesos</strong>
      </p>
      <p>
        L'Usuari podrà fer un ús responsable del nostre lloc web conforme als usos i costums, bona fe, moral i ordre públic. Tota aquella conducta aliena a aquests principis estarà prohibida i perseguida conforme la normativa vigent.
      </p>
      <p>
        <strong>3.3. Usos prohibits</strong>
      </p>
      <p>
        Queda prohibit l'accés o la utilització dels productes oferts al nostre lloc web amb finalitats il·legals o no autoritzades. En concret, a tall enunciatiu però no limitatiu, queda prohibit:
      </p>

      <ul>
        <li>
          Usar el servei amb finalitats il·lícites o realitzar activitats contràries a la bona fe i a l'ordre públic.
        </li>
        <li>
          Difondre continguts o propaganda de caràcter racista, xenòfob, pornogràfic-il·legal, d'apologia del terrorisme o atemptatotori contra els drets humans.
        </li>
        <li>
          Usar qualsevol dels serveis del lloc web de manera que es pugui danyar, sobrecarregar o perjudicar el servei.
        </li>
        <li>
          Utilitzar o introduir en el servei malware que pugui produir danys o alteracions en els nostres sistemes, en els continguts o en el sistema informàtic d'altres usuaris i/o en els seus terminals mòbils.
        </li>
        <li>
          L'ús de recursos tècnics, lògics o tecnològics en virtut dels quals algú, usuari o no del servei, pugui beneficiar-se, directament o indirectament, amb o sense lucre, dels perfils i dels continguts del lloc web.
        </li>
      </ul>

      <p>
        En definitiva, queden prohibits els usos de la WEB que siguin contraris a la normativa de Propietat Intel·lectual i Industrial, Protecció de Dades, Llei de Competència Deslleial, Codi Civil i Mercantil, així com qualsevol altra que pogués resultar aplicable.
      </p>
    </div>
  )
}