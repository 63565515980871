import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { InputText } from "../../../components/Inputs";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validExiste } from "../../../helpers/validations";
import { useLang } from "../../../language";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useValidator } from "../../../hooks/useValidator";
import { isStatusError, isStatusSuccess } from "../../../helpers/helpers";

export const ModalRegistroUsuarioStep1 = ({ formRegistro, handleInputRegistro, handleFormRegistro, handleClose,
    setIndexFocusWizard, data, fetchComprobarNIFPOST, fetchComprobarNIFPOSTData, resetfetchComprobarNIFPOSTData }: any) => {

    const Lang = useLang()

    // -------- Preguntar NIF

    const [validNIF, handleValidationNIF, resetvalidNIF] = useValidator({
        nif: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    const handleSubmitStep1 = () => {

        if (handleValidationNIF(formRegistro)) {

            fetchComprobarNIFPOST({
                body: {
                    nif: formRegistro.nif
                }
            })
        }
    }

    useEffect(() => {

        if (data.value) {


        } else {

            resetvalidNIF()
        }

    }, [data.value]);

    useEffect(() => {

        if (isStatusError(fetchComprobarNIFPOSTData.status) && fetchComprobarNIFPOSTData?.error?.code === 404) {

            setIndexFocusWizard(1)
            resetfetchComprobarNIFPOSTData()
        }

        if (isStatusSuccess(fetchComprobarNIFPOSTData.status)) {

            handleClose()
        }

    }, [fetchComprobarNIFPOSTData]);

    return (
        <>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className="px-3 px-lg-4 pt-3 pt-lg-4 pb-2 mb-0"
            >
                <Grid container spacing={2} columnSpacing={3} rowSpacing={3} justifyContent='center'>

                    <Grid item xs={12} className="text-center">
                        <Typography variant='subtitle1' className="fw-bold">
                            {Lang('ESCRIBA_SU_NIF')} / NIE
                        </Typography>
                    </Grid>

                    <Grid item xs={10} md={4} lg={4} xl={4} className="pt-00">
                        <InputText
                            label='NIF / NIE:'
                            name='nif'
                            value={formRegistro.nif}
                            onChange={(e: any) => handleInputRegistro({
                                target: {
                                    name: 'nif',
                                    value: (e.target.value).toUpperCase()
                                }
                            })}
                            localValidation={validNIF.nif}
                            fetchData={fetchComprobarNIFPOSTData}
                            enablePrefilterError={false}
                        />
                    </Grid>

                    <Grid item width='100%' className="p-0"></Grid>

                    <Grid item xs={11} md={8} lg={8}>
                        <Typography variant="body2" component="h2" className="ms-md-3 text-start mt-3 mt-md-0 text-center fw-500">
                            {Lang('PARRAFO_COLEGIADO_CATEB_NO_REGISTRARSE')}.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} justifyContent='between' display='flex' className="mt-4">

                        <ButtonAdvanced
                            variant="outlined" type="button" className="me-auto" color='secondary'
                            handleSubmit={() => {
                                setIndexFocusWizard(0)
                                handleClose()
                            }}
                            value={Lang('SALIR')}
                        />

                        <ButtonAdvanced
                            variant="contained" type="button" className="ms-auto mt-md-0"
                            handleSubmit={handleSubmitStep1}
                            loadings={[fetchComprobarNIFPOSTData.loading]}
                            value={Lang('CONTINUAR')} iconEnd={<FontAwesomeIcon icon={faArrowRight} className='ms-2' />}
                        />
                    </Grid>
                </Grid>
            </Box>



        </>
    );
};
