import { useAxios } from "../../../../../../../../../hooks/useAxios"
import { AnotacionAdapters, AnotacionesAdapters } from "./anotaciones.adapters"

const AnotacionesServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: '/edificio_proyectos/seguimientos/listar',
            type: 'json',
            adapter: {
                method: AnotacionesAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },
}

const AnotacionServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/seguimientos`,
            type: 'json',
            adapter: {
                method: AnotacionAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/edificio_proyectos/seguimientos/crear',
            type: 'json',
            adapter: {
                method: AnotacionAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/seguimientos',
            type: 'json',
            adapter: {
                method: AnotacionAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/seguimientos',
            type: 'json',
            adapter: {
                method: AnotacionAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    AnotacionesServices,
    AnotacionServices
}