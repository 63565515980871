import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useNavigate } from 'react-router'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { isNumber } from 'lodash'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../../../../helpers/helpers'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { InputTextarea } from "../../../../../../../components/Inputs/InputTextarea"
import { InputChecks } from "../../../../../../../components/Inputs/InputChecks"
import { ImportarActividadServices } from "../controllers/importar-actividades.services"
import { InputSelect } from "../../../../../../../components/Inputs/InputSelect"

export const ModalMejora = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [fetchMejoraGET, fetchMejoraGETData] = ImportarActividadServices.GET_MEJORA()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
    })

    const [validDocumento, handleValidDocumento, resetvalidDocumento] = useValidator({
        // nom: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
        // tipus_document: [
        //     { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        // ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchMejoraGETData.status)) {

            handleFormDocumento({
                ...fetchMejoraGETData.data
            })
        }

    }, [fetchMejoraGETData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {

                fetchMejoraGET({
                    id: data?.id
                })
            }

        } else {
            resetFormDocumento()
            resetvalidDocumento()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {Lang('MEDIDA')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchMejoraGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ': *'}
                                            name='descripcio'
                                            value={formDocumento.descripcio}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={Lang('BENEFICIO') + ': *'}
                                            name='beneficio'
                                            type='number'
                                            value={formDocumento.beneficio}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('REDUCCION') + ' Cep,nren (%)'}
                                            name='reduccion_cepnren'
                                            value={formDocumento.reduccion_cepnren}
                                            onChange={handleInputDocumento}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={3}>
                                        <InputText
                                            label={Lang('COSTE')}
                                            name='costo_texto'
                                            type='number'
                                            value={formDocumento.costo_texto}
                                            // values={fetchParamsPublicGETData?.data?.['tipus_cost_milloras']}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} lg={4}>
                                        <InputText
                                            label={Lang('COMPLEJIDAD')}
                                            name='complejidad_texto'
                                            value={formDocumento.complejidad_texto}
                                            readOnly={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('COMENTARIOS') + ': *'}
                                            name='comentario'
                                            value={formDocumento.comentario}
                                            readOnly={true}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <ButtonAdvanced
                        variant="outlined" type="button" className="mt-3 ms-auto"
                        handleSubmit={handleClose}
                        value={Lang('SALIR')}
                    />
                </ModalAdvanced.Footer>

            </ModalAdvanced>
        </>
    )
}