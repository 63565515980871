import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { useLang } from '../../../language';
import LayoutLibro from '../../../components/Layouts/LayoutLibro/LayoutLibro';
import LibroEdificio from '../pages/LibroEdificio/LibroEdificio';
import { LayoutPotencialMejora } from '../pages/LibroEdificio/PotencialMejoraPlanActuaciones/LayoutPotencialMejora/LayoutPotencialMejora';
import { EdificioServices } from '../../../controllers/edificios/edificios.services';
import { useEffect, useState } from 'react';
import { isAdministrador, isEdificioObraNueva, isFetchDataInitLoader, isGestorMantenimiento, isStatusSuccess, isTecnicoRedactor } from '../../../helpers/helpers';
import { LoaderFormPrimary } from '../layout/LoaderFormPrimary';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export default function LibroRouter() {

    const Lang = useLang()

    let { id }: any = useParams()

    const navigate = useNavigate()

    const [navLinksTop, setnavLinksTop] = useState(
        [
            {
                icon: <FontAwesomeIcon icon={faScrewdriverWrench} className='me-2' />,
                title: Lang('DESCRIPCION_CONSTRUCTIVA'),
                link: 'descripcion-constructiva'
            },
        ]

    );

    const [fetchEdificioGET, fetchEdificioGETData] = EdificioServices.GET(id)

    // const [fetchMisRolesEdificioGET, fetchMisRolesEdificioGETData] = EdificioServices.GET_MIS_ROLES(id)

    useEffect(() => {

        fetchEdificioGET()

    }, []);

    const { auth } = useSelector((state: RootState) => state.auth)

    useEffect(() => {

        if (isStatusSuccess(fetchEdificioGETData.status)) {

            const misRolesEdificio = fetchEdificioGETData?.data?.perfil_llibre

            if (!(isAdministrador(auth?.user?.user?.role) ||
                (isGestorMantenimiento(misRolesEdificio) || isTecnicoRedactor(misRolesEdificio)))) {

                navigate(`/edificios/${id}`)

            } else {

                const tipoEdificio = fetchEdificioGETData.data?.tipus_edifici_id

                if (!isEdificioObraNueva(tipoEdificio)) {

                    setnavLinksTop(
                        [
                            ...navLinksTop,
                            {
                                icon: <FontAwesomeIcon icon={faFileAlt} className='me-2' />,
                                title: Lang('DESCRIPCION_POTENCIAL_MEJORA'),
                                link: 'descripcion-potencial-mejora'
                            },
                        ]
                    )
                }
            }
        }

    }, [fetchEdificioGETData]);

    return (
        <section className='LayoutLibro animate__animated animate__fadeIn'>

            {
                isFetchDataInitLoader(fetchEdificioGETData) ?
                    <div className="p-3">
                        <LoaderFormPrimary />
                    </div>
                    :
                    <LayoutLibro>

                        <LayoutLibro.NavTop auth={auth} />

                        <LayoutLibro.NavTop2
                            titleStart={
                                <>
                                    <FontAwesomeIcon icon={faScrewdriverWrench} className='me-2' />
                                    {
                                        isEdificioObraNueva(fetchEdificioGETData.data?.tipus_edifici_id) ? Lang('MANTENIMIENTO') : Lang('MANTENIMIENTO_Y_MEJORAS')
                                    }
                                </>
                            }
                            navLinks={navLinksTop}
                        />

                        <LayoutLibro.Body>

                            <Routes>

                                <Route
                                    path="descripcion-constructiva/*"
                                    element={
                                        <LibroEdificio
                                            isObraNueva={isEdificioObraNueva(fetchEdificioGETData.data?.tipus_edifici_id)}
                                        />
                                    }
                                />

                                <Route
                                    path="descripcion-potencial-mejora/*"
                                    element={<LayoutPotencialMejora
                                        idedificio={id}
                                    />}
                                />

                                <Route
                                    path="*"
                                    element={<Navigate to={`/edificios/${id}/detalle/descripcion-constructiva`} replace />}
                                />
                            </Routes>

                        </LayoutLibro.Body>

                        <LayoutLibro.Footer />

                    </LayoutLibro>
            }

        </section>
    )
}