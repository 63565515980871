import { useEffect } from "react"
import { useLang } from '../../../../../../../language'
import { useForm } from '../../../../../../../hooks/useForm'
import { useValidator } from '../../../../../../../hooks/useValidator'
import { validExiste } from '../../../../../../../helpers/validations'
import { InputText } from '../../../../../../../components/Inputs'
import { ButtonAdvanced } from '../../../../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { getValuesParams, isFetchDataInitLoader, isStatusSuccess, parseMoneda } from '../../../../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useModalData } from "../../../../../../../hooks/useModalData"
import { DerramaServices } from "./controllers/derramas.services"
import { ModalConfirm } from "../../../../../../../components/Modals/ModalConfirm"

export const ModalDerrama = ({ data, handleClose, idCurrentProject, idEdificio, fetchPropiedadAgentesGET,
    formValuesSearch, fetchPlanificacionFinancieraGET }: any) => {

    const Lang = useLang()

    const [fetchDerramaGET, fetchDerramaGETData, resetfetchDerramaGETData] = DerramaServices.GET()

    const [fetchAnotacionPOST, fetchAnotacionPOSTData, resetfetchAnotacionPOSTData] = DerramaServices.POST()

    const [fetchAnotacionPATCH, fetchAnotacionPATCHData, resetfetchAnotacionPATCHData] = DerramaServices.PATCH()

    const [fetchDerramaPATCHDELETE, fetchDerramaPATCHDELETEData, resetfetchDerramaPATCHDELETEData] = DerramaServices.PATCH()

    const [fetchDerramaDELETE, fetchDerramaDELETEData, resetfetchDerramaDELETEData] = DerramaServices.DELETE()

    const [formDerrama, handleInputDerrama, handleFormDerrama, resetFormDerrama] = useForm({
        derrama: '',
        importe_total_financiar: '',
        fecha_inicio_derrama: '',
        meses: '',
        fecha_fin_derrama: '',
    })

    const [validLogin, handleValidationLogin, resetvalidLogin] = useValidator({
        derrama: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        importe_total_financiar: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        fecha_inicio_derrama: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        meses: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET

    useEffect(() => {

        if (isStatusSuccess(fetchDerramaGETData.status)) {

            handleFormDerrama({
                ...fetchDerramaGETData.data
            })
        }

    }, [fetchDerramaGETData]);


    // ------------ POST

    const handleSubmitNuevoAgente = () => {

        if (handleValidationLogin(formDerrama)) {

            fetchAnotacionPOST({
                body: {
                    ...formDerrama,
                    projecte_id: idCurrentProject
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionPOSTData.status)) {
            
            fetchPlanificacionFinancieraGET()
            handleClose()
        }

    }, [fetchAnotacionPOSTData]);


    // ------------ PATCH

    const handleSubmitEditarAgente = () => {

        if (handleValidationLogin(formDerrama)) {

            fetchAnotacionPATCH({
                id: data?.id,
                body: {
                    derrama: formDerrama.derrama,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchAnotacionPATCHData.status)) {
            
            fetchPlanificacionFinancieraGET()
            handleClose()
        }

    }, [fetchAnotacionPATCHData]);


    // ------------ DELETE Agente

    const [formDerrama2, handleInputDerrama2, handleFormDerrama2, resetFormDerrama2] = useForm({
        importe_total_financiar: '',
        fecha_fin_derrama: '',
    })

    const [validDerrama2, handleValidDerrama2, resetvalidDerrama2] = useValidator({

        importe_total_financiar: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        fecha_fin_derrama: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })


    const handleSubmitPATCHDELETE = () => {

        if (handleValidDerrama2(formDerrama2)) {

            fetchDerramaPATCHDELETE({
                id: data?.id,
                body: {
                    fecha_fin_derrama: formDerrama2.fecha_fin_derrama,
                    importe_total_financiar: formDerrama2.importe_total_financiar,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchDerramaPATCHDELETEData.status)) {

            
            fetchPlanificacionFinancieraGET()
            handleClose()
            handleCloseModal_FinalizarDerrama()
        }

    }, [fetchDerramaPATCHDELETEData]);


    //  ---------------- DELETE

    const [dataModal_ConfirmDelete, handleShowModal_ConfirmDelete, handleCloseModal_ConfirmDelete] = useModalData()

    const handleSubmitDELETE = () => {

        fetchDerramaDELETE({
            id: data?.id,
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchDerramaDELETEData.status)) {

            
            fetchPlanificacionFinancieraGET()
            handleClose()
            handleCloseModal_ConfirmDelete()
        }

    }, [fetchDerramaDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (data?.id) {
                fetchDerramaGET({
                    id: data?.id
                })
            }

        } else {
            resetFormDerrama()
            resetFormDerrama2()
            resetvalidLogin()
            resetfetchAnotacionPOSTData()
            resetfetchDerramaGETData()
            resetfetchAnotacionPATCHData()
            resetfetchDerramaPATCHDELETEData()
        }

    }, [data.value]);

    // --------- Finalizar derrama

    const [dataModal_FinalizarDerrama, handleShowModal_FinalizarDerrama, handleCloseModal_FinalizarDerrama] = useModalData()


    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVA_DERRAMA') : Lang('EDITAR_DERRAMA')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    {
                        fetchDerramaGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12}>
                                        <InputText
                                            label={Lang('CONCEPTO') + ': *'}
                                            name='derrama'
                                            value={formDerrama.derrama}
                                            onChange={handleInputDerrama}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validLogin.derrama}
                                            readOnly={formDerrama.fecha_fin_derrama}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('IMPORTE_TOTAL_A_FINANCIAR') + ': *'}
                                            name='importe_total_financiar'
                                            type='number'
                                            value={data?.action === 'EDIT' ? parseMoneda(formDerrama.importe_total_financiar) : formDerrama.importe_total_financiar}
                                            onChange={handleInputDerrama}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validLogin.importe_total_financiar}
                                            readOnly={data?.action === 'EDIT'}

                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('FECHA_INICIO') + ': *'}
                                            name='fecha_inicio_derrama'
                                            type='date'
                                            value={formDerrama.fecha_inicio_derrama}
                                            onChange={handleInputDerrama}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validLogin.fecha_inicio_derrama}
                                            className='labelFocusAlways'
                                            readOnly={data?.action === 'EDIT'}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={3}>
                                        <InputText
                                            label={Lang('MESES') + ': *'}
                                            name='meses'
                                            type='number'
                                            value={formDerrama.meses}
                                            onChange={handleInputDerrama}
                                            fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                            localValidation={validLogin.meses}
                                            readOnly={data?.action === 'EDIT'}

                                        />
                                    </Grid>

                                    {
                                        formDerrama.fecha_fin_derrama &&
                                        <Grid item xs={12} md={6} lg={3}>
                                            <InputText
                                                label={Lang('FECHA_FIN') + ': *'}
                                                name='fecha_fin_derrama'
                                                type='date'
                                                value={formDerrama.fecha_fin_derrama}
                                                onChange={handleInputDerrama}
                                                fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                                localValidation={validLogin.fecha_fin_derrama}
                                                className='labelFocusAlways'
                                                readOnly={data?.action === 'EDIT'}
                                            />
                                        </Grid>
                                    }

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                {

                    <ModalAdvanced.Footer>
                        {
                            data?.action === 'CREATE' ?
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitNuevoAgente}
                                    loadings={[fetchAnotacionPOSTData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                                :
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <ButtonAdvanced
                                            variant="outlined" type="button" className=""
                                            handleSubmit={handleShowModal_ConfirmDelete}
                                            // loadings={[fetchDerramaPATCHDELETEData.loading]}
                                            value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                        />
                                    </div>
                                    <div>
                                        {
                                            !isFetchDataInitLoader(fetchDerramaGETData) && !fetchDerramaGETData?.data?.fecha_fin_derrama &&
                                            <>
                                                <ButtonAdvanced
                                                    variant="outlined" type="button" className=""
                                                    handleSubmit={handleShowModal_FinalizarDerrama}
                                                    // loadings={[fetchDerramaPATCHDELETEData.loading]}
                                                    value={Lang('FINALIZAR')} icon={<FontAwesomeIcon icon={faArrowDown} className='me-2' />}
                                                />

                                                <ButtonAdvanced
                                                    variant="contained" type="button" className=" ms-3"
                                                    handleSubmit={handleSubmitEditarAgente}
                                                    loadings={[fetchAnotacionPATCHData.loading]}
                                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                                />
                                            </>
                                        }

                                    </div>
                                </div>
                        }

                    </ModalAdvanced.Footer>
                }


            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchAnotacionPOSTData} />

            <ModalStatusServer fetchData={fetchAnotacionPATCHData} />

            <ModalAdvanced data={dataModal_FinalizarDerrama} handleClose={handleCloseModal_FinalizarDerrama} size='md'>

                <ModalAdvanced.Head>
                    <Typography variant="h6" component="h2">
                        {Lang('FINALIZAR_DERRAMA')}
                    </Typography>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className="py-3"
                    >
                        <Grid container spacing={3} rowSpacing={3} justifyContent='center'>
                            <Grid item xs={12} md={7}>
                                <InputText
                                    label={Lang('IMPORTE_TOTAL_FINANCIADO') + ': *'}
                                    name='importe_total_financiar'
                                    type='number'
                                    value={formDerrama2.importe_total_financiar}
                                    onChange={handleInputDerrama2}
                                    // fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                    localValidation={validDerrama2.importe_total_financiar}
                                // className='labelFocusAlways'
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <InputText
                                    label={Lang('FECHA_FIN') + ': *'}
                                    name='fecha_fin_derrama'
                                    type='date'
                                    value={formDerrama2.fecha_fin_derrama}
                                    onChange={handleInputDerrama2}
                                    // fetchData={data?.action === 'CREATE' ? fetchAnotacionPOSTData : fetchAnotacionPATCHData}
                                    localValidation={validDerrama2.fecha_fin_derrama}
                                    className='labelFocusAlways'

                                />
                            </Grid>
                        </Grid>
                    </Box>
                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <div className="d-flex justify-content-between">
                        <ButtonAdvanced
                            variant="outlined" type="submit" className="me-auto" color="secondary"
                            handleSubmit={handleCloseModal_FinalizarDerrama}
                            // loadings={[fetchPropiedadesPOSTData.loading]}
                            value={Lang('SALIR')}
                        />
                        <ButtonAdvanced
                            variant="contained" type="submit" className="ms-auto"
                            handleSubmit={handleSubmitPATCHDELETE}
                            loadings={[fetchDerramaPATCHDELETEData.loading]}
                            value={Lang('FINALIZAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                        />
                    </div>
                </ModalAdvanced.Footer>


            </ModalAdvanced>

            <ModalConfirm
                data={dataModal_ConfirmDelete}
                handleClose={handleCloseModal_ConfirmDelete}
                message={<p>{Lang('PARRAFO_DESEA_ELIMINAR_ELEMENTO')}</p>}
                textOnClickOk={Lang('SI_ELIMINAR')}
                variantClickOk='contained'
                onClickOK={handleSubmitDELETE}
                fetchData={fetchDerramaDELETEData}
            />
        </>
    )
}