import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLang } from "../../../../../../../../language";
import { InputSelect } from "../../../../../../../../components/Inputs/InputSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { isEmpty } from "lodash";
import { ActividadServices } from "../../controllers/actividades.services";
import { ModalStatusServer } from "../../../../../../../../components/Modals/ModalStatusServer";
import TableAdvanced from "../../../../../../../../components/Tables/TableAdvanced/TableAdvanced";

export const TablePeriodicidadesEdit = ({ data, navLinksFases, total, perPage, loading, handleFormChange,
    formValuesSearch }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [navLinksFasesT, setnavLinksFasesT] = useState([]);

    const [fetchActividadGET, fetchActividadGETData] = ActividadServices.PATCH_FASE()

    const handleChangeFase = (id: any, value: any) => {

        fetchActividadGET({
            id: id + '/actualizar',
            body: {

                fase_id: value
            }
        })
    }

    useEffect(() => {

        if (!isEmpty(navLinksFases)) {

            setnavLinksFasesT(navLinksFases?.map((item: any) => {
                return {
                    ...item,
                    name: item.title,
                    id: item.link,
                }
            }))
        }

    }, [navLinksFases]);


    return (
        <>
            <Typography variant='h6' className={`m-0 p-0 fw-bolder mb-3`}>
                Periodicidades
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{Lang('TITULO')}</TableCell>
                            <TableCell>{Lang('DESCRIPCION')}</TableCell>
                            <TableCell>{Lang('FECHA_INICIO')}</TableCell>
                            <TableCell className="shrink-td">{Lang('FASE')}</TableCell>
                            <TableCell className="shrink-td"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.map((item: any, index: number) => {
                                return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >

                                        <TableCell >{item.titol}</TableCell>
                                        <TableCell >{item.descripcio}</TableCell>
                                        <TableCell >{item.data_inici_calculada}</TableCell>
                                        <TableCell>
                                            <div style={{ width: '200px' }}>
                                                <InputSelect
                                                    label={Lang('FASE') + ':'}
                                                    name='idtiponotificacion'
                                                    defaultValue={item.fase_id}
                                                    values={navLinksFasesT}
                                                    onChange={(e: any) => handleChangeFase(item.id, e.target?.value)}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title={Lang('VER_DETALLE')} placement="top">
                                                <Button variant='outlined' onClick={() => navigate(`../../actividad/${item.id}`)}>
                                                    <FontAwesomeIcon icon={faExternalLink} className='' />
                                                </Button>
                                            </Tooltip>

                                        </TableCell>

                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </Table>
            </TableContainer>

            <TableAdvanced.Footer
                total={total}
                perPage={perPage}
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
            />

            <ModalStatusServer fetchData={fetchActividadGETData} onlyError={true} />
        </>
    )
};
