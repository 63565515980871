export const CondParSeccion3 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Los precios productos del Libro del Edificio Digital (LED) están informados en la web antes de aceptar la compra y llevan el IVA incluido.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Els preus productes del Llibre de l'Edifici Digital (LED) estan informats a la web abans d'acceptar la compra i porten l'IVA inclòs.
      </p>
    </div>
  )
}