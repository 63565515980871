import './LayoutPotencialMejoraNavTop.scss'
import { NavLink } from "react-router-dom";
import { useLang } from '../../../../../../language';

export const LayoutPotencialMejoraNavTop = ({ navLinks, className = '' }: any) => {

    const handleActiveClassName = (isActive: boolean, item: any) => {

        if (isActive) {

            return 'LayoutPotencialMejoraNavTop__nav--active'
        }

        return ''
    }

    return (
        <>
            <nav className={`LayoutPotencialMejoraNavTop w-100 d-flex justify-content-center align-items-center  ${className}`}>

                {/* <h3 className="LayoutPotencialMejoraNavTop--title h3 mb-0 fw-bold mt-0">
                    <span>{title}</span>
                </h3> */}

                <ul className='LayoutPotencialMejoraNavTop__nav list-unstyled d-flex mb-0 mt-auto pt-0 pe-3'>
                    {
                        navLinks?.map((item: any, index: number) => {
                            return (
                                <NavLink
                                    to={`./${item.link}`}
                                    className={({ isActive }) => handleActiveClassName(isActive, item)}
                                    key={item.link}
                                    style={{left: -2*index}}
                                >
                                    <div className='LayoutPotencialMejoraNavTop__navArrowTop'></div>
                                    <div className='LayoutPotencialMejoraNavTop__navArrowBottom'></div>
                                    <span className='LayoutPotencialMejoraNavTop__navText'>{item.title}</span>
                                </NavLink>
                            )
                        })
                    }
                </ul>
            </nav>
        </>
    );
};
