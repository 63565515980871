import { useNavigate, useParams } from "react-router-dom";
import { useLang } from '../../../../../../language';
import './LayoutProgramacionGestionNavTop.scss'
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

export const LayoutProgramacionGestionNavTop = ({ id, idedificio, navLinks, className = '', text, defaultLink,
    elemento_completado = false, setidCurrentFase, navLinksFases, idCurrentFase }: any) => {

    const Lang = useLang()

    // const { idCurrentFaseProgGes } = useSelector((state: RootState) => state.idCurrentFaseProgGes)

    const handleActiveClassName = (isActive: boolean, item: any) => {

        if (isActive) {

            // setDefaultLink(item.link)

            return 'LayoutProgramacionGestionNavTop__nav--active'
        }

        return ''
    }

    const navigate = useNavigate()

    const params = useParams()

    return (
        <>
            <nav className={`LayoutProgramacionGestionNavTop w-100 d-flex justify-content-between border-0 align-items-center ${className}`}>

                <div className="d-flex align-items-center mb-0 mt-2">
                    <ul className='LayoutFasesNavTop__navStart list-unstyled d-flex  pt-0 pe-0'>
                        {
                            navLinksFases?.map((item: any, index: number) => {
                                return (
                                    <div
                                        // to={`/${item.link}`}
                                        // className={({ isActive }) => handleActiveFasesClassName(isActive, item)}
                                        onClick={() => setidCurrentFase(item.link)}
                                        className={`LayoutProgramacionGestionNavTo__a ms-0 ${String(item.link) === String(idCurrentFase) ? 'LayoutFasesNavTop__navStart--active' : ''}`}
                                        key={item.link}
                                        style={{ left: -2 * index }}
                                    >
                                        <div className='LayoutFasesNavTop__navStartArrowTop'></div>
                                        <div className='LayoutFasesNavTop__navStartArrowBottom'></div>
                                        <span className='LayoutFasesNavTop__navStartText'>{item.title}</span>
                                    </div>
                                )
                            })
                        }
                    </ul>

                    {
                        params['*'] === 'planificacion-financiera' &&
                        <Button variant="outlined" size="small" className="d-block ms-4" onClick={() => navigate('../resumen-financiero')}>
                            <FontAwesomeIcon icon={faChartLine} className='me-1' /> {Lang('RESUMEN_FINANCIERO')}
                        </Button>
                    }
                </div>


                {/* <ul className='LayoutProgramacionGestionNavTop__nav list-unstyled d-flex mb-0 mt-auto pt-0 pe-0'>
                    {
                        navLinks?.map((item: any) => {
                            return (
                                <NavLink
                                    to={`./${item.link}`}
                                    // className={({ isActive }) => isActive ? "LayoutProgramacionGestionNavTop__nav--active" : ""}
                                    className={({ isActive }) => handleActiveClassName(isActive, item)}
                                    key={item.link}
                                >
                                    {item.title}
                                </NavLink>
                            )
                        })
                    }
                </ul> */}
            </nav>

        </>
    );
};
