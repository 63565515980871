import GENERALS from "./generals";
import PARRAFOS from "./parrafos";
import VALIDATIONS from "./validations";
import LINKS from "./links";

const lang = {
    CA: { 
        ...GENERALS, 
        ...PARRAFOS, 
        ...VALIDATIONS,
        ...LINKS,
    }
}

export default lang;