import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { useLang } from "../../../language";
import { ButtonAdvanced } from "../../../components/ButtonAdvanced/ButtonAdvanced";
import { FormAvisoLegal } from "../../AvisoLegal/FormAvisoLegal";

export const ModalAvisoLegal = ({ data, handleClose, handleInput, name = '' }: any) => {

    const Lang = useLang()

    const handleClickAceptar = () => {
        handleInput({
            target: {
                name: name,
                value: [name]
            }
        })
        handleClose()
    }

    const handleClickRechazar = () => {
        handleInput({
            target: {
                name: name,
                value: []
            }
        })

        handleClose()
    }

    // useEffect(() => {

    //     if (data.value && data.index) {



    //     }

    // }, [data.value]);


    return (
        <>

            <ModalAdvanced data={data} handleClose={handleClose} size='xl'>

                <ModalAdvanced.Head>
                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <FormAvisoLegal index={data.indexAccordion || ''} />

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <div className="d-flex justify-content-between">
                        <ButtonAdvanced
                            variant="outlined" type="submit" className=""
                            handleSubmit={handleClickRechazar}
                            value={Lang('RECHAZAR')}
                        />
                        <ButtonAdvanced
                            variant="contained" type="submit" className=""
                            handleSubmit={handleClickAceptar}
                            value={Lang('ACEPTAR')}
                        />
                    </div>
                </ModalAdvanced.Footer>


            </ModalAdvanced>
        </>
    );
};
