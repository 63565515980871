export const CondGenSeccion2 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Toda la información del web <a href="http://www.libroedificiodigital.es" target="_blank" rel="noreferrer" className="link-primary">www.libroedificiodigital.es</a>, en las transacciones comerciales o telefónicas, atención al público y los servicios ofrecidos serán en catalán y en castellano.
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
        Tota la informació del web <a href="http://www.llibreedificidigital.cat" target="_blank" rel="noreferrer" className="link-primary">www.llibreedificidigital.cat</a>, en les transaccions comercials o telefòniques, atenció al públic i els serveis oferts seran en català i en castellà.
      </p>
    </div>
  )
}