import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { useLang } from '../../language'

import './BreadcrumbAdvanced.scss'

export const BreadcrumbAdvanced = ({ items, className, disabledHome = false, pathHome = '/' }: any) => {

    const navigate = useNavigate()

    return (
        <div className={`BreadcrumbAdvanced ${className ? className : ''}`}>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">
                    <FontAwesomeIcon icon={faHome} className={`cursor-pointer ${disabledHome ? 'pe-none' : ''}`} onClick={() => navigate(pathHome)} />
                </Typography>
                
                {
                    items?.map((item: any, index: number) => {
                        return (
                            index !== (items.length - 1) ? (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    className='cursor-pointer fw-bold'
                                    onClick={() => navigate(item.link)} key={index}
                                >
                                    {item.title}
                                </Link>
                            ) : (
                                <Typography color="text-primary" key={index}>{item.title}</Typography>
                            )
                        )
                    })
                }
            </Breadcrumbs>
        </div>
    )
}
