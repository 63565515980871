import { useAxios } from "../../../../../../../hooks/useAxios"
import { ActividadAdapters, ActividadesAdapters } from "./actividades.adapters"

const ActividadesServices = {

    GET: function (idedificio: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/intervenciones/${idedificio}`,
            type: 'json',
            adapter: {
                method: ActividadesAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_BLOB: function (idedificio: string) {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/intervenciones/${idedificio}`,
            type: 'blob',
            adapter: {
                method: ActividadesAdapters.GET_BLOB,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'blob', // object, array, list (with pagination)
                }
            },
        })
    },  
}

const ActividadServices = {

    GET: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio_proyectos/intervenciones`,
            type: 'json',
            adapter: {
                method: ActividadAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function (idedificio: string) {

        return useAxios({
            method: 'POST',
            url: `/edificio_proyectos/intervenciones/${idedificio}`,
            type: 'json',
            adapter: {
                method: ActividadAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/intervenciones',
            type: 'json',
            adapter: {
                method: ActividadAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/edificio_proyectos/intervenciones',
            type: 'json',
            adapter: {
                method: ActividadAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_ESTADO: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/intervenciones',
            type: 'json',
            adapter: {
                method: ActividadAdapters.PATCH_ESTADO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_CLONAR: function (idActividad: string) {

        return useAxios({
            method: 'POST',
            url: `/edificio_proyectos/intervenciones/${idActividad}/clonar`,
            type: 'json',
            adapter: {
                method: ActividadAdapters.POST_CLONAR,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_FASE: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/intervenciones',
            type: 'json',
            adapter: {
                method: ActividadAdapters.PATCH_FASE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    ActividadesServices,
    ActividadServices
}