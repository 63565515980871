import { useState } from 'react'
import { PaginationAdvanced } from '../../PaginationAdvanced/PaginationAdvanced'
import { useLang } from '../../../language'

interface ITableAdvancedFooter {
  total: any,
  handleFormChange?: any,
  formValuesSearch?: any,
  perPage: any,
  onlyOnePage?: any
}

export const TableAdvancedFooter = ({ total, handleFormChange, formValuesSearch, perPage, onlyOnePage }: ITableAdvancedFooter) => {

  const [showAll, setshowAll] = useState(false)

  const Lang = useLang()

  return (
    <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center overflow-auto mt-3'>
      <div className='flex-1 text-start mb-3 mb-lg-0'>
        <small><strong>{Lang('TOTAL')}:</strong> {total} {Lang('ELEMENTOS')}</small>
      </div>
      <div className="flex-2 text-center">
        <PaginationAdvanced
          total={total}
          handleFormChange={handleFormChange}
          formValuesSearch={formValuesSearch}
          perPage={perPage}
        />
      </div>
      {/* <div className='flex-1 text-end'>
        {
          !onlyOnePage && <Button size='small' className='' variant='outlined' onClick={() => {

            if (showAll) {
  
              const {limit, ...formValuesSearchT} = formValuesSearch
              handleFormChange({
                ...formValuesSearchT,
                page: 1,
              })
            } else {
              handleFormChange({
                ...formValuesSearch,
                limit: total,
                page: 1,
              })
            }
            setshowAll(!showAll)
            
          }}>
            {
              !showAll ? 'Mostrar Tots' : 'Paginar tots'
            }
          </Button>
        }
        
      </div> */}
    </div>
  )
}
