import { useLang } from "../../../../../../language";
import { MantenimientoPreventivo } from "./Operaciones";
import { Grid } from "@mui/material";

export const MantenimientoPreventivoIndex = ({ id, idedificio }: any) => {

    return (

        <>
            <Grid container spacing={3} className="pt-3">

                <Grid item xs={12} className=''>

                    <MantenimientoPreventivo
                        idedificio={idedificio}
                        id={id}
                    />

                </Grid>

            </Grid>
        </>
    )
};
