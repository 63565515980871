export const CondGenSeccion12 = ({ lang }: any) => {

  return (
    <>
      {
        lang === 'ES' && <SeccionES />
      }
      {
        lang === 'CA' && <SeccionCA />
      }
    </>
  )
};

const SeccionES = () => {
  return (
    <div>
      <p>
        Se ponen hojas de reclamación a disposición del consumidor en: <a className="link-primary" href="https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf" target="_blank" rel="noreferrer">https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf</a>
      </p>
    </div>
  )
}

const SeccionCA = () => {
  return (
    <div>
      <p>
      Es posen fulls de reclamació a disposició del consumidor a: <a className="link-primary" href="https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf" target="_blank" rel="noreferrer">https://consum.gencat.cat/web/.content/50_RECOMANACIONS/nadal/doc_59629304_1.pdf</a>
      </p>
    </div>
  )
}