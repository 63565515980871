import { useAxios } from "../../hooks/useAxios"
import { ComunicacionAdapters, ComunicacionesAdapters, ContactoAdapters } from "./comunicaciones.adapters"

const ComunicacionesServices = {

    GET: function (id: string) {

        return useAxios({
            method: 'GET',
            url: `/comunicaciones/comunicaciones_edificios/${id}`,
            type: 'json',
            adapter: {
                method: ComunicacionesAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_CURRENT_PLANTILLAS: function () {

        return useAxios({
            method: 'GET',
            url: '/comunicaciones/plantillas_email',
            type: 'json',
            adapter: {
                method: ComunicacionesAdapters.GET_CURRENT_PLANTILLAS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_MIS_COMUNICACIONES: function () {

        return useAxios({
            method: 'GET',
            url: `/comunicaciones/my_comunicacions`,
            type: 'json',
            adapter: {
                method: ComunicacionesAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'list', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_MIS_COMUNICACIONES_SIMPLE: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio/notificaciones/listar`,
            type: 'json',
            adapter: {
                method: ComunicacionesAdapters.GET_MIS_COMUNICACIONES_SIMPLE,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    GET_MIS_NO_LEIDOS: function () {

        return useAxios({
            method: 'GET',
            url: `/edificio/notificaciones/listar`,
            type: 'json',
            adapter: {
                method: ComunicacionesAdapters.GET_MIS_NO_LEIDOS,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },
}

const ComunicacionServices = {

    GET: function (id: string) {

        return useAxios({
            method: 'GET',
            url: `/comunicaciones/comunicaciones_edificios/${id}`,
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'post',
            url: '/agentes',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_COMUNICACION_EDIFICIO: function () {

        return useAxios({
            method: 'POST',
            url: '/comunicaciones/comunicacion_edificio',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.POST_COMUNICACION_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH: function () {

        return useAxios({
            method: 'post',
            url: '/agentes',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.PATCH,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    DELETE: function () {

        return useAxios({
            method: 'DELETE',
            url: '/agentes',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.DELETE,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    GET_MI_COMUNICACION: function (id: string) {

        return useAxios({
            method: 'GET',
            url: `/comunicaciones/my_comunicacion/${id}`,
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.GET,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'object', // object, array, list (with pagination)
                }
            },
        })
    },

    PATCH_LEER: function () {

        return useAxios({
            method: 'PATCH',
            url: '/comunicaciones/mensaje_leido',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.PATCH_LEER,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_LEER_DOCS_EDIFICIO: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_documentos/notificaciones',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.PATCH_LEER_DOCS_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_LEER_DOCS_INMUEBLE: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_inmuebles/notificaciones',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.PATCH_LEER_DOCS_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    PATCH_LEER_ACTIVIDADES: function () {

        return useAxios({
            method: 'PATCH',
            url: '/edificio_proyectos/intervenciones',
            type: 'json',
            adapter: {
                method: ComunicacionAdapters.PATCH_LEER_DOCS_EDIFICIO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

const ContactoServices = {

    GET_UNIQUE_CODE: function () {

        return useAxios({
            method: 'GET',
            url: `/comunicaciones/get-one-times-code`,
            type: 'json',
            adapter: {
                method: ContactoAdapters.GET_UNIQUE_CODE,
                types: {
                    send: 'object', // object, array, list (with pagination)
                    receive: 'default', // object, array, list (with pagination)
                }
            },
        })
    },

    POST: function () {

        return useAxios({
            method: 'POST',
            url: '/comunicaciones/contacto',
            type: 'json',
            adapter: {
                method: ContactoAdapters.POST,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },

    POST_PUBLICO: function () {

        return useAxios({
            method: 'POST',
            url: '/comunicaciones/contacto-publico',
            type: 'json',
            adapter: {
                method: ContactoAdapters.POST_PUBLICO,
                types: {
                    send: 'object',
                    receive: 'default',
                }
            },
        })
    },
}

export {
    ComunicacionesServices,
    ComunicacionServices,
    ContactoServices
}